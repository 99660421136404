import {
  Airplane20Filled,
  Building20Filled,
  VehicleCar16Filled,
  VehicleSubway20Filled,
} from "@fluentui/react-icons";
import i18next from "i18next";
import Styles from "../../../ShareJoin.module.scss";

export default function getSegmentImage(segmentType: string) {
  const { t } = i18next;

  let img;

  const segmentClassName = Styles.segmentIcon;

  switch (segmentType) {
    case "train":
      img = (
        <VehicleSubway20Filled
          className={[segmentClassName, Styles.train].join(" ")}
          aria-label={t("shareJoin.train")}
          role="img"
        />
      );
      break;
    case "car":
      img = (
        <VehicleCar16Filled
          className={[segmentClassName, Styles.car].join(" ")}
          aria-label={t("shareJoin.transfer")}
          role="img"
        />
      );
      break;
    case "hotel":
      img = (
        <Building20Filled
          className={[segmentClassName, Styles.hotel].join(" ")}
          aria-label={t("shareJoin.hotel")}
          role="img"
        />
      );
      break;
    default:
      img = (
        <Airplane20Filled
          className={[segmentClassName, Styles.air].join(" ")}
          aria-label={t("shareJoin.flight")}
          role="img"
        />
      );
  }

  return img;
}
