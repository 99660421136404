import { TeamsUserCredential } from "@amadeus-cytric/cytric-teams-react-common-library";

interface GetDataModel {
  recipientSelected: any;
  messageToShare: string;
  cardToShare: string;
  cardToShareChannels: string | undefined | null;
  tripName?: any;
  tripToShareId?: string | null;
  segmentsData: any;
  recipientGroupsSelected?: any;
  recipientChannelsSelected?: any;
  appCatalogId?: any;
  origin: string;
  postOnChannel: boolean;
  device: string;
  t?: any;
}

const checkIsMobile = (device: string) => {
  if (device === "mobile" || device === "tablet") {
    return "mobile";
  }
  return "";
};

async function GetData(props: GetDataModel) {
  const {
    recipientSelected,
    messageToShare,
    cardToShare,
    cardToShareChannels,
    tripName,
    tripToShareId,
    segmentsData,
    recipientGroupsSelected,
    recipientChannelsSelected,
    appCatalogId,
    origin,
    postOnChannel,
    device,
    t,
  } = props;
  const credential = new TeamsUserCredential();
  const accessToken = await credential.getToken("User.Read");
  const action = "dispatchCardToUsers";
  const domain = "dispatch-card";

  if (accessToken && cardToShare) {
    let data;
    const primaryMessageText = t("moduleResponse.success.primaryMessage");

    switch (origin) {
      case "events":
        data = {
          action,
          hostConfig: {},
          adaptiveCard: cardToShare,
          recipientIDs: [],
          recipientGroupIDs: recipientGroupsSelected || undefined,
          recipientChannelIDs: recipientChannelsSelected || undefined,
          tripName,
          tripId: tripToShareId,
          segments: segmentsData,
          domain,
          accessToken: `${accessToken?.token}`,
          messageToShare: "",
          tryAgainAttempts: "0",
          appCatalogId,
          postOnChannel,
          origin,
          successMessage: t("moduleResponse.success.eventsMessage"),
        };
        break;
      case "messaging":
        data = {
          action,
          hostConfig: {},
          adaptiveCard: cardToShare,
          tripName,
          domain,
          accessToken: `${accessToken?.token}`,
          tryAgainAttempts: "0",
          appCatalogId,
          successMessage: primaryMessageText,
        };
        break;
      case "classic":
        data = {
          action,
          hostConfig: {},
          adaptiveCard: cardToShare,
          adaptiveCardForChannels: cardToShareChannels,
          recipientIDs: recipientSelected,
          recipientGroupIDs: recipientGroupsSelected || undefined,
          recipientChannelIDs: recipientChannelsSelected || undefined,
          tripName,
          segments: segmentsData,
          messageToShare: messageToShare || "",
          domain,
          accessToken: `${accessToken?.token}`,
          tryAgainAttempts: "0",
          appCatalogId,
          origin,
          device: checkIsMobile(device),
          successMessage: primaryMessageText,
        };
        break;
      case "classic-expenses-chat":
        data = {
          action,
          hostConfig: {},
          adaptiveCard: cardToShare,
          adaptiveCardForChannels: "",
          recipientIDs: recipientSelected,
          recipientGroupIDs: undefined,
          recipientChannelIDs: undefined,
          tripName,
          segments: undefined,
          messageToShare,
          domain,
          accessToken: `${accessToken?.token}`,
          tryAgainAttempts: "0",
          appCatalogId,
          origin,
          device: checkIsMobile(device),
          successMessage: t("moduleResponse.success.chatWithTravelerMessage"),
        };
        break;
      default:
        data = {
          action,
          hostConfig: {},
          adaptiveCard: cardToShare,
          adaptiveCardForChannels: cardToShareChannels,
          recipientIDs: recipientSelected,
          recipientGroupIDs: recipientGroupsSelected || undefined,
          recipientChannelIDs: recipientChannelsSelected || undefined,
          tripName,
          tripId: tripToShareId,
          segments: segmentsData,
          messageToShare: messageToShare || "",
          domain,
          accessToken: `${accessToken?.token}`,
          tryAgainAttempts: "0",
          appCatalogId,
          origin,
          device: checkIsMobile(device),
          successMessage: primaryMessageText,
        };
    }

    localStorage.setItem("try_again_data", JSON.stringify(data));
    return data;
  }
  return undefined;
}

export default GetData;
