import {
  Http,
  TeamsUserCredential,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import moment from "moment";
import { CollaboratorsModel } from "../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import HostSettings from "../../utils/host.settings";

function getDateAsUTC(date: string | undefined) {
  if (!date) {
    return null;
  }
  const isUTCFormat = date.endsWith("Z");
  if (isUTCFormat) {
    return date;
  }
  return moment(date).utc(true).format();
}

export default class CloseCollaboratorService {
  private static readonly SERVER_URL = HostSettings.getBaseAPI;

  private static readonly CLOSE_COLLABORATORS_PATH =
    HostSettings.getCloseCollaboratorsPath;

  private static readonly CLOSE_COLLABORATORS_NOTIFICATION_PATH =
    HostSettings.getCloseCollaboratorsNotificationPath;

  private static readonly REVERSE_COLLABORATORS_PATH =
    HostSettings.reverseCollaboratorsPath;

  public static async getCloseCollaborators(
    countryCode: string,
    latitude?: number,
    longitude?: number,
    iataCode?: string,
    tripId?: string
  ) {
    const credential = new TeamsUserCredential();
    const graphToken = await credential.getToken("User.Read");

    if (!graphToken?.token) {
      throw new Error("Graph Token can not be retrieved.");
    }

    const resp = await Http.get(
      `${this.SERVER_URL}${this.CLOSE_COLLABORATORS_PATH}`,
      {
        headers: {
          GraphToken: graphToken.token,
        },
        params: {
          countryCode,
          latitude,
          longitude,
          airportCode: iataCode,
          tripId,
        },
      }
    );
    return resp?.data?.data;
  }

  public static async notifyCloseCollaborators(
    senderID: string,
    senderUserPrincipleName: string,
    cards: {
      recipient: {
        id: string;
        userPrincipalName: string;
      };
      payload: string;
    }[]
  ) {
    const credential = new TeamsUserCredential();
    const graphToken = await credential.getToken("Chat.ReadWrite");

    if (!graphToken?.token) {
      throw new Error("Graph Token can not be retrieved.");
    }

    const resp = await Http.post(
      `${this.SERVER_URL}${this.CLOSE_COLLABORATORS_NOTIFICATION_PATH}`,
      {
        data: {
          sender: {
            id: senderID,
            userPrincipalName: senderUserPrincipleName,
          },
          cards,
        },
      },
      {
        headers: {
          GraphToken: graphToken.token,
        },
      }
    );
    return resp?.data;
  }

  public static async getReverseCollaborators() {
    const resp = await Http.get(
      `${this.SERVER_URL}${this.REVERSE_COLLABORATORS_PATH}`
    );
    return resp?.data?.data;
  }

  public static async addReverseCollaborators(
    sender: CollaboratorsModel,
    recipients: CollaboratorsModel[],
    trip: {
      tripId: string;
      startDate?: string;
      endDate?: string;
      cityLocation?: string;
    }
  ) {
    const resp = await Http.post(
      `${this.SERVER_URL}${this.REVERSE_COLLABORATORS_PATH}`,
      {
        sender: {
          id: sender.id,
          displayName: sender.displayName,
          userPrincipalName: sender.userPrincipalName,
          jobTitle: sender.jobTitle,
        },
        recipients: recipients.map((recipient) => ({
          id: recipient.id,
          displayName: recipient.displayName,
          userPrincipalName: recipient.userPrincipalName,
          jobTitle: recipient.jobTitle,
        })),
        trip: {
          ...trip,
          startDate: getDateAsUTC(trip.startDate),
          endDate: getDateAsUTC(trip.endDate),
        },
      }
    );
    return resp?.data?.data;
  }
}
