import {
  logout,
  logoutFromTravelTab,
  switchAccount,
} from "../../../store/authentication/AuthenticationAction";
import { resetCytricContext } from "../../../store/cytric-context/CytricContextSlice";

import Store from "../../../store/Store";

function doLogout() {
  Store.dispatch(logout());
  Store.dispatch(resetCytricContext());
}

export function doLogoutFromTravelTab() {
  Store.dispatch(logoutFromTravelTab());
  Store.dispatch(resetCytricContext());
}

export function doSwitchAccount() {
  Store.dispatch(switchAccount());
  Store.dispatch(resetCytricContext());
}

export default doLogout;
