import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDictionaries } from "../../components/upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import { ITripV1 } from "../../components/upcoming-trips-dashboard/UpcomingTrips/UpcomingTripsV1.model";
import TripListService from "../../services/trip-list/TripListService";

export const fetchUserTripsV1 = createAsyncThunk(
  "trips/fetchUserTrips",
  async (_data, { rejectWithValue }) => {
    try {
      const tripsV1 = await TripListService.getTripsV1();
      return tripsV1;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const tripsV1Slice = createSlice({
  name: "tripsV1",
  initialState: {
    trips: [] as ITripV1[],
    dictionaries: {} as IDictionaries,
    status: "" as string,
    errorInfo: "" as string,
    errorCode: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserTripsV1.fulfilled, (state, action) => ({
      ...state,
      trips: action.payload?.data?.data || [],
      dictionaries: action.payload?.data?.dictionaries,
      status: "success",
    }));
    builder.addCase(fetchUserTripsV1.pending, (state) => ({
      ...state,
      status: "loading",
    }));
    builder.addCase(fetchUserTripsV1.rejected, (state, action: any) => ({
      ...state,
      status: "error",
      errorInfo: action.payload.statusText,
      errorCode: action.payload.status,
    }));
  },
});

export default tripsV1Slice.reducer;
