import { Flex, Text } from "@fluentui/react-northstar";
import i18next from "i18next";
import { useState } from "react";
import Trip from "../../../../../common/models/Trip";
import { CP_FLOW_IDENTIFIER } from "../../../../../utils/constants";
import { ITripV1 } from "../../../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import Styles from "../../../ShareJoin.module.scss";
import { AdaptiveCardViewerModel } from "../../../models/AdaptiveCardViewerModel";
import AdaptiveCardTripV1 from "../adaptive-card-trip-v1/AdaptiveCardTripV1";
import AdaptiveCardTrip from "../adaptive-card-trip/AdaptiveCardTrip";

const { t } = i18next;

function AdaptiveCardViewer({
  trips,
  tripList,
  origin,
}: AdaptiveCardViewerModel) {
  const [selectedTripId, setSelectedTripId] = useState();
  const adaptiveCardTitle =
    origin === CP_FLOW_IDENTIFIER
      ? t("shareTrip.adaptiveCardTitleSharingForOtherUser")
      : t("shareTrip.adaptiveCardTitle");

  return trips ? (
    <div className={Styles.formBlock}>
      {!tripList && <Text content={adaptiveCardTitle} />}
      <Flex column id="adaptive" tabIndex={0}>
        {origin === CP_FLOW_IDENTIFIER
          ? trips.map((trip) => {
              const currentTrip = trip as unknown as ITripV1;
              return (
                <AdaptiveCardTripV1
                  key={`trip-card-${currentTrip.data.id}`}
                  trip={currentTrip}
                  tripList={tripList}
                  selectedTripId={selectedTripId}
                  setSelectedTripId={setSelectedTripId}
                />
              );
            })
          : trips.map((trip) => {
              const currentTrip = trip as unknown as Trip;
              return (
                <AdaptiveCardTrip
                  key={`trip-card-${currentTrip.id}`}
                  trip={currentTrip}
                  tripList={tripList}
                  selectedTripId={selectedTripId}
                  setSelectedTripId={setSelectedTripId}
                />
              );
            })}
      </Flex>
    </div>
  ) : null;
}

export default AdaptiveCardViewer;
