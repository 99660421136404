import { Flex, Image, Text } from "@fluentui/react-northstar";
import i18next from "i18next";
import HostSettings from "../../../../utils/host.settings";
import EmptyChatStyles from "./EmptyChat.module.scss";

function EmptyChat({ themeName }: any) {
  const { t } = i18next;

  return (
    <Flex
      column
      vAlign="center"
      hAlign="center"
      className={EmptyChatStyles.container}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables?.colorScheme?.default.background,
      })}
      id="emptyChat"
    >
      <Image
        src={`${HostSettings.getCDNIcons}empty-chat-${themeName}.svg`}
        className={EmptyChatStyles.image}
      />
      <Text className={EmptyChatStyles.text}>
        {t("shareTrip.emptyChatMessage")}
      </Text>
    </Flex>
  );
}

export default EmptyChat;
