import Trip from "../../../common/models/Trip";

function getItems(products: any, type: string) {
  let numOfFlights = 0;
  let numOfHotels = 0;
  let numOfCars = 0;
  let numOfTrains = 0;
  let numOfItems = 0;
  if (products) {
    const productArray = products[0];
    productArray.products.forEach((prod: any) => {
      if (prod.Trip_Product_Air) {
        numOfFlights += 1;
      }
      if (prod.Trip_Product_Train) {
        numOfTrains += 1;
      }
      if (prod.Trip_Product_Hotel) {
        numOfHotels += 1;
      }
      if (prod.Trip_Product_Car) {
        numOfCars += 1;
      }
    });
    numOfItems = numOfFlights + numOfTrains + numOfCars + numOfHotels;
  }

  if (type === "shared") {
    return {
      numOfSharedFlights: numOfFlights,
      numOfSharedTrains: numOfTrains,
      numOfSharedHotels: numOfHotels,
      numOfSharedCars: numOfCars,
      numOfSharedItems: numOfItems,
    };
  }

  return {
    numOfInitialFlights: numOfFlights,
    numOfInitialTrains: numOfTrains,
    numOfInitialHotels: numOfHotels,
    numOfInitialCars: numOfCars,
    numOfInitialItems: numOfItems,
  };
}

function GetTripTrackInformation(
  trip: Trip,
  tripTemplate: Trip,
  initialSegments: any
) {
  const { products } = trip;
  const { products: productsTemplate } = tripTemplate;

  const {
    numOfSharedFlights,
    numOfSharedTrains,
    numOfSharedHotels,
    numOfSharedCars,
    numOfSharedItems,
  } = getItems(products, "shared");

  const {
    numOfInitialFlights,
    numOfInitialTrains,
    numOfInitialHotels,
    numOfInitialCars,
    numOfInitialItems,
  } = initialSegments
    ? getItems([{ products: initialSegments }], "initial")
    : getItems(productsTemplate, "initial");

  const trackObject = {
    numOfSharedFlights,
    numOfSharedTrains,
    numOfSharedHotels,
    numOfSharedCars,
    numOfSharedItems,
    numOfInitialFlights,
    numOfInitialHotels,
    numOfInitialCars,
    numOfInitialTrains,
    numOfInitialItems,
  };

  return trackObject;
}

export default GetTripTrackInformation;
