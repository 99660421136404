import { RootState } from "../StoreHooks";

export const isLoggedInSelector = (state: RootState): boolean =>
  state.authentication.isLoggedIn;

export const isAuthenticationErrorSelector = (state: RootState): boolean =>
  state.authentication.isAuthError;

export const isAuthenticationPendingSelector = (state: RootState): boolean =>
  state.authentication.isPending;

export const authenticationErrorMessageSelector = (state: RootState): string =>
  state.authentication.errorMessage;

export const userObjectIdSelector = (state: RootState): string =>
  state.authentication.userObjectId;

export const isTokenPendingSelector = (state: RootState): boolean =>
  state.authentication.isPendingToken;

export const isTokenErrorSelector = (state: RootState): boolean =>
  state.authentication.isTokenError;

export const authErrorCodeSelector = (state: RootState): number | undefined =>
  state.authentication.authErrorCode;
