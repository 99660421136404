import { ComponentStyleFunctionParam, Text } from "@fluentui/react-northstar";
import styles from "./StatusPill.module.scss";

interface IStatusPillProps {
  text: string;
  color: string;
  title: string;
}

function StatusPill({ text, color, title }: IStatusPillProps) {
  const STATUS_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.statusPill[`${color}`]?.color
      : "",
    borderColor: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.statusPill[`${color}`]?.borderColor
      : "",
    background: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.statusPill[`${color}`]?.background
      : "",
  });

  return (
    <div title={title}>
      <Text
        className={styles["status-pill"]}
        content={text}
        aria-hidden
        styles={STATUS_STYLE}
      />
    </div>
  );
}

export default StatusPill;
