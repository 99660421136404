import GenericInfo from "../../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfoAction,
  InfoImage,
} from "../../../common/components/generic-info-component/GenericInfo.model";
import { IError } from "../../../common/models/Error";
import i18next from "../../../i18n/i18n";

const getError = () => {
  const errorInfo: IError = {
    status: 0,
    code: 0,
    title: i18next.t(`messages.ERR_Generic`),
    detail: i18next.t(`upcoming_trips_dashboard.ERR_api_call`),
  };
  const action: IGenericInfoAction = {
    text: i18next.t(`upcoming_trips_dashboard.Button_try_again`),
    command: () => {
      window.location.reload();
    },
  };
  return (
    <GenericInfo infoData={errorInfo} action={action} image={InfoImage.ERROR} />
  );
};

export default getError;
