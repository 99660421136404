import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import shareJoinReducer from "./ShareJoinReducer";

interface Props {
  children: ReactNode;
}

const initialState = {
  tripTemplate: {},
  segmentsToShare: [],
  initialSegments: [],
  hasAir: false,
  hasHotel: false,
  hasRail: false,
  hasCar: false,
};

const ShareJoinContext = createContext<any[]>([initialState, () => null]);

function ShareJoinProvider({ children }: Props) {
  const [state, dispatch] = useReducer(shareJoinReducer, initialState);

  const value = useMemo(() => [state, dispatch], [state]);

  return (
    <ShareJoinContext.Provider value={value}>
      {children}
    </ShareJoinContext.Provider>
  );
}

const useStore = () => useContext<any>(ShareJoinContext)[0];
const useDispatch = () => useContext<any>(ShareJoinContext)[1];

export { ShareJoinContext, useDispatch, useStore };
export default ShareJoinProvider;
