/* eslint-disable react/react-in-jsx-scope */
import {
  statusIcon,
  statusIconClass,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import stylesUserStatus from "@amadeus-cytric/cytric-teams-react-common-library/dist/components/user-status/UserStatus.module.scss";
import { Avatar, Flex, Label, Text } from "@fluentui/react-northstar";
import i18next from "i18next";
import Responsive from "../../../../../common/components/responsive/Responsive";
import stylesCloseCollaborators from "../../../common/styles/close-collaborators.module.scss";
import { StatusModel } from "../../models/CloseCollaboratorsModel";

export default function User(props: StatusModel) {
  const { displayName, image, presence, jobTitle, hasBeenNotified } = props;

  return (
    <Flex
      gap="gap.small"
      vAlign="center"
      className={stylesCloseCollaborators.userText}
    >
      <Flex.Item>
        <Avatar
          image={{ src: image }}
          name={displayName}
          className={stylesCloseCollaborators.avatar}
          status={{
            styles: (theme) => ({
              backgroundColor:
                theme.theme.siteVariables.widgetContainer?.widget,
            }),
            icon: statusIcon(presence?.availability),
            title: presence?.activity,
            className: `${stylesUserStatus.statusIndicator} ${
              statusIconClass(presence?.availability).icon.class
            }`,
          }}
        />
      </Flex.Item>
      <Flex.Item className={stylesCloseCollaborators.userText}>
        <Flex
          column
          className={
            stylesCloseCollaborators.CloseCollaboratorsUserInformationContainer
          }
        >
          <Flex>
            <Text
              content={displayName}
              title={displayName}
              className={stylesCloseCollaborators.userNameText}
            />
            <Responsive forMinSize="tablet">
              {hasBeenNotified && (
                <Label
                  circular
                  content={i18next.t("closeCollaborators.table.notified")}
                  className={stylesCloseCollaborators.notifiedLabel}
                />
              )}
            </Responsive>
          </Flex>
          <Responsive forRange="mobile">
            <Text
              content={jobTitle}
              weight="regular"
              className={stylesCloseCollaborators.roleText}
            />
          </Responsive>
        </Flex>
      </Flex.Item>
    </Flex>
  );
}
