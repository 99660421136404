import ColorBlock from "./colorBlockObject";

const brand = new ColorBlock(
  /* Basics */
  "#5b5fc7",
  "#5b5fc7",
  "#C5CBFA",
  "#000",
  /* Hovers */
  "#5b5fc7",
  "#4F52B2",
  "#9299f7",
  "#000",
  /* Actives */
  "#5B5FC7",
  "#5b5fc7",
  "#bdbdbd",
  /* Focus */
  "#5b5fc7",
  "#5b5fc7",
  "#000",
  /* Pressed */
  "#4f52b2",
  "#444791",
  "rgba(0,0,0,0.5)",
  /* Disabled */
  "#c7c7c7",
  "#f0f0f0",
  "#3d3d3d",
  /* Foreground */
  "#5B5FC7",
  "#4f52b2",
  "#c5cbfa",
  "#fff",
  "#fff",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#E8EBFA",
  "#3d3e78",
  "#2f2f4a",
  "#444791",
  "#dce0fa",
  "#5b5fc7",
  undefined,
  /* Border */
  "#9299f7",
  "#c5cbfa",
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  "#fff",
  "#c5cbfa",
  /* Background Hover */
  "#e8ebfa",
  "#dcdffa",
  "#5b5fc7",
  undefined,
  /* Fore and Back Pressed */
  "#fff",
  "#dcdffa",
  "#dcdffa",
  undefined,
  /* Fore and Back Active */
  "#5b5fc7",
  "#e8ebfa",
  "#5b5fc7",
  undefined,
  /* Border Active */
  "#e8ebfa",
  "#9299f7",
  undefined,
  undefined,
  /* Foreground Focus */
  "#5b5fc7",
  "#4f52b2",
  "#e8ebfa",
  "#fff",
  /* Background Focus */
  "#e8ebfa",
  "#3d3e78",
  "#2f2f4a",
  /* Border Focus */
  "#fff",
  "#5B5FC7",
  /* Fore and Back disabled */
  "#c7c7c7",
  "#f0f0f0",
  undefined,
  undefined
);
const defaultColorBlock = new ColorBlock(
  /* Basics */
  "#242424",
  "#fff",
  "#d1d1d1",
  "#000",
  /* Hovers */
  "#242424",
  "#f5f5f5",
  "#C7C7C7",
  "#000",
  /* Actives */
  "#242424",
  "#ebebeb",
  "#bdbdbd",
  /* Focus */
  "#252423",
  "#fff",
  "#000",
  /* Pressed */
  "#242424",
  "#E0E0E0",
  "#C8C6C4",
  /* Disabled */
  "#C7C7C7",
  "#f0f0f0",
  "#e0e0e0",
  /* Foreground */
  "#424242",
  "#616161",
  "#fff",
  "#fff",
  "#f0f0f0",
  "#e0e0e0",
  "#616161",
  "#242424",
  "#707070",
  /* Background */
  "#FAFAFA",
  "#f5f5f5",
  "#f0f0f0",
  "#EBEBEB",
  "#e0e0e0",
  "#EBEBEB",
  "#f5f5f5",
  /* Border */
  "#e0e0e0",
  "#f0f0f0",
  "#f0f0f0",
  /* Border Transparent */
  "rgba(0, 0, 0, 0)",
  "rgba(0, 0, 0, 0)",
  /* Foreground Hover */
  "#fff",
  "#fff",
  /* Background Hover */
  "#D1D1D1",
  "rgba(0, 0, 0, 0)",
  "#EBEBEB",
  "#fafafa",
  /* Fore and Back Pressed */
  undefined,
  undefined,
  "#f5f5f5",
  "#e0e0e0",
  /* Fore and Back Active */
  "#fff",
  undefined,
  "#fff",
  "#ebebeb",
  /* Border Active */
  "#e8ebfa",
  "#9299f7",
  "#EDEBE9",
  "#8A8886",
  /* Foreground Focus */
  "#424242",
  "#424242",
  "#fff",
  undefined,
  /* Background Focus */
  "#fafafa",
  "#f5f5f5",
  "#ebebeb",
  /* Border Focus */
  "#D1D1D1",
  undefined,
  /* Fore and Back disabled */
  "#bdbdbd",
  "#f0f0f0",
  "#FAF9F8",
  "#FAF9F8"
);
/// ///////////
//    RED   //
/// ///////////
const red = new ColorBlock(
  /* Basics */
  "#C4314B",
  "#C4314B",
  "#F3D6D8",
  undefined,
  /* Hovers */
  "#fff",
  "rgba(167,32,55,0.9)",
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  "#fff",
  "#8E192E",
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#fff",
  "#fff",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#FCF4F6",
  "#C4314B",
  "#C4314B",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "#E73550",
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  "#C4314B",
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);
/// ///////////
//  GREEN   //
/// ///////////
const green = new ColorBlock(
  /* Basics */
  "#237B4B",
  "#6BB700",
  "#BDDA9B",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#fff",
  "#13A40E",
  "#fff",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#E7F2DA",
  "#E7F2DA",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "#237B4B",
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//  YELLOW  //
/// ///////////
const yellow = new ColorBlock(
  /* Basics */
  "#835C00",
  "#FFAA44",
  "#F2E384",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#201F1F",
  "#fff",
  "#252423",
  "#835C00",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#FBF6D9",
  "#FFB900",
  "#FBF6D9",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "#835C00",
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//  ORANGE  //
/// ///////////
const orange = new ColorBlock(
  /* Basics */
  "#CC4A31",
  "#CC4A31",
  "#EDC2A7",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#E97548",
  "#fff",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#CC4A31",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//   PINK   //
/// ///////////
const pink = new ColorBlock(
  /* Basics */
  "#943670",
  "#FCF2FA",
  "#F1DFEE",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#B4009E",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//  SILVER  //
/// ///////////
const silver = new ColorBlock(
  /* Basics */
  "#fff",
  "rgba(0,0,0,0)",
  "rgba(255,255,255,0.3)",
  undefined,
  /* Hovers */
  "#fff",
  "rgba(255,255,255,0.1)",
  "rgba(255,255,255,0.3)",
  undefined,
  /* Actives */
  undefined,
  "rgba(255, 255, 255, 0.2)",
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  "#fff",
  "rgba(255,255,255,0.2)",
  "rgba(255,255,255,0.3)",
  /* Disabled */
  "rgba(255,255,255,0.3)",
  "rgba(255,255,255,0.05)",
  undefined,
  /* Foreground */
  "rgba(255,255,255,0.75)",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);
/// ///////////
//   ONYX   //
/// ///////////
const onyx = new ColorBlock(
  /* Basics */
  undefined,
  "rgba(41, 40, 40, 0.9)",
  "rgba(27,26,26,0.9)",
  undefined,
  /* Hovers */
  undefined,
  "rgba(41,40,40,0.9)",
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  "rgba(27,26,26,0.9)",
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "rgba(59,58,57,0.9)",
  "rgba(41,40,40,0.9)",
  "rgba(37,36,35,0.65)",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "rgba(0,0,0,0)",
  "rgba(37,36,35,0.2)",
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////////
//   AMETHYST   //
/// ///////////////
const amethyst = new ColorBlock(
  /* Basics */
  undefined,
  "rgba(70,71,117,0.4)",
  undefined,
  undefined,
  /* Hovers */
  undefined,
  "rgba(98,100,167,0.15)",
  undefined,
  undefined,
  /* Actives */
  undefined,
  "rgba(98,100,167,0.15)",
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  "rgba(51,52,74,0.5)",
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

const colorSchemeLight = {
  brand,
  default: defaultColorBlock,
  green,
  red,
  yellow,
  orange,
  pink,
  silver,
  onyx,
  amethyst,
};

export default colorSchemeLight;
