import { Flex, Image, Text } from "@fluentui/react-northstar";

import { useTranslation } from "react-i18next";
import HostSettings from "../../../../utils/host.settings";
import styles from "./Welcome.module.scss";

function Welcome() {
  const { t } = useTranslation();

  return (
    <Flex hAlign="center" vAlign="center" className={styles.welcome}>
      <Flex column hAlign="center">
        <Image src={`${HostSettings.getCDNIcons}welcome.svg`} />
        <Text
          content={t("shareJoin.welcome.title")}
          className={styles.welcome__title}
        />
        <Text
          content={t("shareJoin.welcome.subTitle")}
          data-testid="notrip"
          className={`${styles.welcome__subtitle}`}
        />
      </Flex>
    </Flex>
  );
}

export default Welcome;
