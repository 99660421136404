/* eslint-disable react/react-in-jsx-scope */
import { Table } from "@fluentui/react-northstar";
import peopleListStyles from "./PeopleList.module.scss";

function PeopleList({ rows, header, label }: any) {
  return (
    <div className={peopleListStyles.tableWrapper}>
      <Table
        header={header}
        rows={rows}
        aria-label={label}
        className={peopleListStyles.table}
      />
    </div>
  );
}

export default PeopleList;
