/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-dupe-else-if */
/* eslint-disable arrow-body-style */
import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  IDictionaries,
  IEmission,
} from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import { IBaggage } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetails/SegmentTabs/Tabs/Flight/Flight.model";
import { IFlightV1 } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/Flight/Flight.model";
import {
  TRIP_PRODUCT_AIR,
  UPCOMING_TRIP_DETAIL,
} from "../../../components/upcoming-trips-dashboard/data/constants";
import {
  composeBoundsV1,
  getAllStopsDetailsV1,
  getServiceProvidersV1,
  getTotalTravelDurationV1,
  validateProviderLogoUrl,
} from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import i18n from "../../../i18n/i18n";
import { RootState } from "../../StoreHooks";
import {
  getProductsByTypeV1,
  sortFlightProductsV1,
} from "./trip-detail-v1-selectors";
import { IFlightBoundDetails } from "./trip-detail-v1-selectors.model";

const getFlightBoundBasicDetailsV1 = (bound: IFlightV1[]) => {
  try {
    const selectedLogo = bound[0].media.filter(
      (content: any) => content.category === "AIRLINE_LOGO"
    )[0];
    const logo = validateProviderLogoUrl(selectedLogo?.href);
    const departure =
      bound[0].departure.iataCode ||
      bound[0].departureAirportLocation.address.cityName;
    const arrival =
      bound[bound.length - 1].arrival.iataCode ||
      bound[0].arrivalAirportLocation?.address.cityName;
    const flightDurations = bound.map(
      (boundSegment: { duration: string }) => boundSegment.duration
    );
    const totalTravelDuration = getTotalTravelDurationV1(flightDurations);
    const numberOfStops = bound.length - 1;
    const type = bound[0].productType;
    const departureName = bound[0].departureAirportLocation.address.cityName;
    const arrivalName = bound[0].arrivalAirportLocation.address.cityName;

    const getters = {
      getDepartureTime: (segment: IFlightV1) => segment?.departure?.dateTime,
      getArrivalTime: (segment: IFlightV1) => segment?.arrival?.dateTime,
      getDepartureStation: (segment: IFlightV1) =>
        segment?.departureAirportLocation?.name,
      getArrivalStation: (segment: IFlightV1) =>
        segment?.arrivalAirportLocation?.name,
      getLayoverLocation: (segment: IFlightV1) =>
        segment?.arrivalAirportLocation?.address.cityName,
    };

    const stopsIn = getAllStopsDetailsV1(bound, getters);

    const serviceProvidersWithNumbers = getServiceProvidersV1(
      bound,
      (segment: IFlightV1) => segment?.marketing?.carrier?.name,
      (segment: IFlightV1) => segment?.operating?.flightDesignator?.flightNumber
    );

    const departureDateTime = bound[0].departure.dateTime;
    const arrivalDateTime = bound[bound.length - 1].arrival.dateTime;

    return {
      logo,
      departure,
      arrival,
      totalTravelDuration,
      numberOfStops,
      stopsIn,
      serviceProvidersWithNumbers,
      departureDateTime,
      arrivalDateTime,
      getters,
      type,
      departureName,
      arrivalName,
    };
  } catch {
    return {};
  }
};

const getBaggages = (baggages: IBaggage[]) =>
  baggages?.map((baggage: IBaggage) => {
    if (!baggage?.description) {
      return "";
    }
    if (baggage.weight.amount) {
      const amount = Math.round(parseFloat(baggage.weight.amount));
      return `${baggage?.description} (${amount}kg)`;
    }

    return `${baggage?.description}`;
  });

const getReservationInfoV1 = (segment: IFlightV1) => {
  const operatedBy = segment?.operating?.carrier?.name;
  const airCraftType = segment?.aircraft?.aircraftType || " - ";

  const header = {
    title: `${segment?.marketing?.carrier?.name}, ${segment?.operating?.flightDesignator?.flightNumber}`,
    subTitle: operatedBy
      ? `(${UPCOMING_TRIP_DETAIL.detailCard.operatedBy}${operatedBy})
${airCraftType}`
      : `${airCraftType}`,
  };

  const items = [
    {
      label: (
        <span>
          CO<sub>2</sub>
        </span>
      ),
      value: getCO2EmissionsV1(segment?.co2emission),
    },
    { label: "Seat", value: segment?.seats[0]?.number },
  ];

  return { header, items };
};

const getCO2EmissionsV1 = (emission: IEmission) => {
  const emissionAmount = Math.round(parseFloat(emission?.emission?.amount));

  return emissionAmount ? `${emissionAmount} lbs` : " - ";
};

function getDestination(
  flight: any,
  destination: string,
  dictionaries: IDictionaries
) {
  const airportLocation = `${destination}AirportLocation`;
  const countrycode = flight?.[airportLocation]?.address?.countryCode;
  const countryname = dictionaries.country[countrycode]?.name;

  return {
    address: flight?.[airportLocation]?.address.cityName,
    terminal: flight?.[destination]?.terminal,
    cityName: flight?.[airportLocation]?.address?.cityName,
    countryCode: countrycode,
    geoCode: {
      latitude: flight?.[airportLocation]?.geoCode?.latitude,
      longitude: flight?.[airportLocation]?.geoCode?.longitude,
    },
    countryName: countryname,
    iataCode: flight?.[destination]?.iataCode,
    name: flight?.[airportLocation].name,
    airportName: flight?.[airportLocation].name,
    dateTime: flight?.[destination]?.dateTime,
  };
}

function getFlightSegmentExtendedDetailsV1(
  flight: IFlightV1,
  dictionaries: IDictionaries
) {
  const selectedLogo = flight.media.filter(
    (content: any) => content.category === "AIRLINE_LOGO"
  )[0];
  return {
    serviceProvider: flight?.marketing?.carrier?.name,
    number: flight?.operating?.flightDesignator?.flightNumber,
    departure: getDestination(flight, "departure", dictionaries),
    duration: flight?.duration,
    arrival: getDestination(flight, "arrival", dictionaries),
    logo: validateProviderLogoUrl(selectedLogo?.href),
    serviceClass: flight?.marketing.bookingClass?.levelOfService,
    operatedBy: flight?.operating?.carrier?.name,
    carrierCode: flight?.carrierCode,
    airCraftType: flight?.aircraft?.aircraftType,
    emissions: getCO2EmissionsV1(flight?.co2emission),
    seats: flight?.seats[0]?.number,
    baggage: getBaggages(flight?.baggageAllowances),
    meal: flight?.mealServices?.[0]?.label,
    reservationInfo: getReservationInfoV1(flight),
    confirmationNumber: flight?.tripReference.providerConfirmationNumber,
  };
}

export const createFlightDetails = (state: any) => {
  const segmentsV1 = getProductsByTypeV1(state, TRIP_PRODUCT_AIR);
  const sortedSegmentsV1 = sortFlightProductsV1(segmentsV1);
  const boundsV1 = composeBoundsV1(sortedSegmentsV1);
  return boundsV1.reduce((acc: any, bound: IFlightV1[]) => {
    const basicDetails = getFlightBoundBasicDetailsV1(bound);
    const extendedDetails = bound.reduce((acc2: any, segment: IFlightV1) => {
      const segmentExtendedDetail = getFlightSegmentExtendedDetailsV1(
        segment,
        state.tripDetailV1.dictionaries
      );
      acc2.push(segmentExtendedDetail);
      return acc2;
    }, []);
    const flightDetail = { basicDetails, extendedDetails };

    acc.push(flightDetail);

    return acc;
  }, []);
};

export const areFlightBoundsRoundTrip = (bounds: IFlightBoundDetails[]) => {
  const firstStation = bounds?.[0]?.extendedDetails?.[0]?.departure?.cityName;

  const lastBound = bounds?.[bounds.length - 1];
  const lastExtendedDetailsLength = lastBound?.extendedDetails?.length;
  const lastStation =
    lastBound?.extendedDetails?.[lastExtendedDetailsLength - 1]?.arrival
      ?.cityName;

  if (firstStation === lastStation) {
    return true;
  }
  return false;
};

export const getRoundtripFlightDetails = (state: RootState) => {
  const flights = createFlightDetails(state);

  const boundGroups: string[] = [];

  // Group flight bounds into roundtrips
  while (flights.length > 0) {
    const firstElem = flights?.[0];
    const secondElem = flights?.[1];

    if (areFlightBoundsRoundTrip([firstElem, secondElem])) {
      boundGroups.push(flights.splice(0, 2));
    } else {
      boundGroups.push(flights.splice(0, 1));
    }
  }

  return boundGroups;
};

export const getFlightDetailsGroupedByDateV1 = (state: RootState) => {
  const boundGroups = getRoundtripFlightDetails(state);

  return boundGroups.reduce((acc: any, boundGroup: any) => {
    const departureDate = DateUtils.getDateFormat(
      boundGroup?.[0]?.extendedDetails?.[0]?.departure?.dateTime,
      i18n.language,
      DateFormat.DATE_SHORT
    );

    acc[departureDate] = acc[departureDate] || [];
    acc[departureDate].push(boundGroup);

    return acc;
  }, {});
};

export const getInboundDateOfFlightBoundGroupV1 = (
  boundGroup: [IFlightBoundDetails[]]
) => {
  try {
    const lastBound = boundGroup?.[boundGroup?.length - 1];

    if (lastBound?.length > 1) {
      const segments = lastBound?.[lastBound?.length - 1]?.extendedDetails;
      return segments?.[0]?.departure?.dateTime || null;
    }
  } catch {
    return null;
  }
  return null;
};
