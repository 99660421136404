import Breadcrumb from "./breadcrumb/Breadcrumb";
import styles from "./CytricClassic.module.scss";

interface CytricProps {
  feature: boolean | undefined;
  breadCrumbType: string;
  redirectionIsPending: boolean;
  isCytricLoaded: boolean;
  loaderComponent: JSX.Element;
  iframe: JSX.Element;
}

function CytricComponent({
  feature,
  breadCrumbType,
  redirectionIsPending,
  isCytricLoaded,
  loaderComponent,
  iframe,
}: CytricProps) {
  if (feature === true && breadCrumbType !== "") {
    return (
      <div className={styles["cytric-classic__container"]}>
        {Breadcrumb({ type: breadCrumbType })}
        {(redirectionIsPending || !isCytricLoaded) && loaderComponent}
        {iframe}
      </div>
    );
  }
  return (
    <>
      {(redirectionIsPending || !isCytricLoaded) && loaderComponent}
      {iframe}
    </>
  );
}

export default CytricComponent;
