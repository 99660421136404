import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import shareMyRideReducer from "./ShareMyRideReducer";

interface Props {
  children: ReactNode;
}

const newInitialState = {
  tripId: "",
  productsToShare: [],
  activeSegment: null,
  globalLoading: true,
  commonError: false,
};

const ShareMyRideContext = createContext<any[]>([newInitialState, () => null]);

function ShareMyRideProvider({ children }: Props) {
  const [state, dispatch] = useReducer(shareMyRideReducer, newInitialState);

  const value = useMemo(() => [state, dispatch], [state]);

  return (
    <ShareMyRideContext.Provider value={value}>
      {children}
    </ShareMyRideContext.Provider>
  );
}

const useStore = () => useContext<any>(ShareMyRideContext)[0];
const useDispatch = () => useContext<any>(ShareMyRideContext)[1];

export { ShareMyRideContext, useDispatch, useStore };
export default ShareMyRideProvider;
