/* eslint-disable react/react-in-jsx-scope */
import {
  Button,
  Flex,
  FlexItem,
  ProviderConsumer,
  Text,
} from "@fluentui/react-northstar";
import { dialog, UrlDialogInfo } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import TripDestination from "../../../common/models/TripDestination";
import CloseCollaboratorsStatus from "../../../common/models/enums/ActivationStatusEnum";
import i18next from "../../../i18n/i18n";
import Store from "../../../store/Store";
import { useAppSelector } from "../../../store/StoreHooks";
import {
  selectCloseCollaboratorsIsError,
  selectCloseCollaboratorsStatus,
} from "../../../store/collaborators/close-collaborators/CloseCollaboratorsSelector";
import { searchCloseCollaborators } from "../../../store/collaborators/close-collaborators/CloseCollabroratorsAction";
import { notifyCloseCollaborators } from "../../../store/collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationAction";
import {
  selectCloseCollaboratorsIdsToNotify,
  selectIsPendingCloseCollaboratorsToNotify,
} from "../../../store/collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationSelector";
import { selectFeatureToggle } from "../../../store/feature-toggles/FeatureTogglesSelector";
import { selectedTravelTripId } from "../../../store/travel-trip-id/TravelTripIdSelector";
import GetAppRoot from "../../../utils/GetAppRoot";
import { FeatureToggleDefinition } from "../../../utils/constants";
import WidgetContainer from "../../upcoming-trips-dashboard/UpcomingTripDetail/WidgetContainer/WidgetContainer";
import CollaboratorsDisabled from "../common/collaborators-disabled/CollaboratorsDisabled";
import stylesCloseCollaborators from "../common/styles/close-collaborators.module.scss";
import CloseCollaboratorsList from "./close-collaborators-list/CloseCollaboratorsList";
import { CollaboratorsModel } from "./models/CloseCollaboratorsModel";

export default function CloseCollaborators({
  destination,
}: {
  destination: TripDestination;
}) {
  const [displayedCollaborators, setDisplayedCollaborators] = useState<
    CollaboratorsModel[]
  >([]);

  const status: CloseCollaboratorsStatus = useAppSelector((state) =>
    selectCloseCollaboratorsStatus(state)
  );

  const isError: boolean = useAppSelector((state) =>
    selectCloseCollaboratorsIsError(state)
  );

  const closeCollaboratorIdsToNotify: string[] = useAppSelector((state) =>
    selectCloseCollaboratorsIdsToNotify(state)
  );

  const isNotificationPending: boolean = useAppSelector((state) =>
    selectIsPendingCloseCollaboratorsToNotify(state)
  );

  const notificationSentConfirmation = useAppSelector(
    (state) => state.closeCollaborators.notificationSentConfirmation
  );

  const travelTripId: string = useAppSelector((state) =>
    selectedTravelTripId(state)
  );

  const [notifiedCollaborators, setNotifiedCollaborators] = useState(0);

  const fetchCloseCollaborators = searchCloseCollaborators({
    countryCode: destination.countryCode || "",
    latitude: destination.latitude,
    longitude: destination.longitude,
    iataCode: destination.iataCode,
    tripId: travelTripId,
  });

  const closeCollaboratorsCustomizeText = useAppSelector((state) =>
    selectFeatureToggle(
      state,
      FeatureToggleDefinition.closeCollaboratorsCustomizeText.id
    )
  );

  useEffect(() => {
    Store.dispatch(
      searchCloseCollaborators({
        countryCode: destination.countryCode || "",
        latitude: destination.latitude,
        longitude: destination.longitude,
        iataCode: destination.iataCode,
        tripId: travelTripId,
      })
    );
  }, [destination, travelTripId, notifiedCollaborators]);

  const shouldDisplayDisabledComponent: boolean =
    isError ||
    status === CloseCollaboratorsStatus.DEACTIVATED_FOR_DESTINATION ||
    status === CloseCollaboratorsStatus.NO_USERS_AT_DESTINATION ||
    status === CloseCollaboratorsStatus.NO_COUNTRY_CODE_PROVIDED ||
    status === CloseCollaboratorsStatus.DEACTIVATED_FOR_USER_LOCATION;

  const extractThemeName = (theme: any): string =>
    theme.siteVariables.bodyBackground === "#fff" ? "light" : "dark";

  const dispatchNotifyCC = (themeName: string) => {
    // If the feature toggle is active, open the dialog to customize the message
    if (closeCollaboratorsCustomizeText?.isActive) {
      const appRoot = GetAppRoot();

      const closeCollaboratorIds = displayedCollaborators.map(
        (collab) => collab.id
      );

      const closeCollaboratorsInfo = {
        destination,
        travelTripId,
        closeCollaboratorIdsToNotify,
        closeCollaboratorIds,
        themeName,
      };

      const encodeCloseCollaboratorsInfo = encodeURI(
        JSON.stringify(closeCollaboratorsInfo)
      );

      const urlDialogInfo: UrlDialogInfo = {
        title: i18next.t("closeCollaborators.editor.notifyCloseCollaborators"),
        url: `${appRoot}/index.html#/close-collaborators-editor?closeCollaboratorsInfo=${encodeCloseCollaboratorsInfo}`,
        size: {
          width: 600,
          height: 550,
        },
      };

      const submitHandler: dialog.DialogSubmitHandler = (
        submissionResponse: dialog.ISdkResponse
      ) => {
        const { err, result } = submissionResponse;
        // When the user clicks the "X" button, the result is undefined and err is "User canceled or closed the dialog". If the user clicks the "Close" button on the confirmation page dialog, the result is "notified." Clicking the "Cancel" button will prevent the CC widget from being updated.
        if (
          err === "User canceled or closed the dialog" ||
          result === undefined ||
          result === "notified"
        ) {
          setNotifiedCollaborators((prevCounter) => prevCounter + 1);
        }
      };

      // Open the dialog to customize the message
      dialog.url.open(urlDialogInfo, submitHandler);
    } else {
      Store.dispatch(
        notifyCloseCollaborators({
          checkedCollaboratorIds: closeCollaboratorIdsToNotify,
          collaborators: displayedCollaborators,
          tripDestination: destination,
          tripId: travelTripId,
        })
      );
    }
  };

  const getNotifyButtonText = () => {
    const { length: numberOfSelectedCloseCollaborators } =
      closeCollaboratorIdsToNotify;

    return numberOfSelectedCloseCollaborators > 0
      ? i18next.t("closeCollaborators.footer.notifyButton", {
          ccNumber: `${numberOfSelectedCloseCollaborators}`,
        })
      : i18next.t("closeCollaborators.footer.notifyButtonDisabled");
  };

  const renderCloseCollaborator = (themeName: string) => (
    <WidgetContainer
      className={stylesCloseCollaborators.closeCollaboratorsCard}
    >
      <Flex
        data-testid="close-collaborators-component"
        space="between"
        className={[
          themeName === "light"
            ? stylesCloseCollaborators.white
            : stylesCloseCollaborators.dark,
          stylesCloseCollaborators.cardContentContainer,
        ].join(" ")}
        column
        style={{
          paddingBottom: notificationSentConfirmation.isVisible ? "20px" : "0",
        }}
      >
        <Flex className={stylesCloseCollaborators.closeCollaboratorsContainer}>
          {shouldDisplayDisabledComponent ? (
            <CollaboratorsDisabled themeName={themeName} />
          ) : (
            <CloseCollaboratorsList
              destination={destination}
              themeName={themeName}
              updateDisplayedCollaborators={(collabs: CollaboratorsModel[]) => {
                setDisplayedCollaborators(collabs);
              }}
              fetchCloseCollaborators={fetchCloseCollaborators}
            />
          )}
        </Flex>
        {!shouldDisplayDisabledComponent &&
          !!displayedCollaborators.length &&
          (!notificationSentConfirmation.isVisible ||
            isNotificationPending) && (
            <Flex
              className={stylesCloseCollaborators.cardFooterContainer}
              vAlign="center"
            >
              <Text
                content={i18next.t("closeCollaborators.footer.description")}
                className={stylesCloseCollaborators.cardFooterDescription}
              />
              <FlexItem push>
                <Button
                  onClick={() => dispatchNotifyCC(themeName)}
                  loading={isNotificationPending}
                  disabled={!closeCollaboratorIdsToNotify.length}
                  content={getNotifyButtonText()}
                  primary
                  aria-label={i18next.t(
                    "closeCollaborators.footer.notifySelectedUsers",
                    {
                      ccNumber: `${closeCollaboratorIdsToNotify.length}`,
                    }
                  )}
                />
              </FlexItem>
            </Flex>
          )}
      </Flex>
    </WidgetContainer>
  );

  return (
    <ProviderConsumer
      render={(globalTheme) =>
        renderCloseCollaborator(extractThemeName(globalTheme))
      }
    />
  );
}
