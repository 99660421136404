import { Box } from "@fluentui/react-northstar";
import React from "react";
import { IDividerProps } from "./Divider.model";
import Styles from "./Divider.module.scss";

function Divider({ colorTheme }: IDividerProps) {
  return (
    <Box
      data-testid="divider"
      className={Styles.divider}
      styles={(theme) => ({
        color: theme.theme.siteVariables.divider
          ? theme.theme.siteVariables.divider[colorTheme]
          : "",
      })}
    />
  );
}

export default Divider;
