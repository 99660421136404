import { app, dialog } from "@microsoft/teams-js";
import HostSettings from "../../../utils/host.settings";

const submitHandlerForTAJoinATrip: dialog.DialogSubmitHandler = (
  submissionResponse: dialog.ISdkResponse
) => {
  const { err, result } = submissionResponse;
  // result is undefined when user clicks on the X button to close the dialog
  // result is "" when users clicks on regular button with Cancel or Close text (dialog.url.submit())
  if (err != null || result === undefined || result === "") {
    const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel`;
    app.openLink(deepLink);
  } else if (result != null && typeof result === "object") {
    // NOTE: This introduces double JSON.stringify,
    // necessary for historical reasons, should be removed when possible
    const subEntityId = {
      ...result,
      dialogHandled: true,
    };
    const subEntityIdString = JSON.stringify(subEntityId);
    const context = {
      subEntityId: subEntityIdString,
    };
    const encodedContext = encodeURIComponent(JSON.stringify(context));
    const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel?context=${encodedContext}`;
    app.openLink(deepLink);
  }
};

const submitHandlerForSharingFromClassic: dialog.DialogSubmitHandler = (
  submissionResponse: dialog.ISdkResponse
) => {
  const { err, result } = submissionResponse;
  // result is undefined when user clicks on the X button to close the dialog
  // result is "" when users clicks on regular button with Cancel or Close text (dialog.url.submit())
  if (err != null || result === undefined || result === "") {
    const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel`;
    app.openLink(deepLink);
  }
};

export { submitHandlerForSharingFromClassic, submitHandlerForTAJoinATrip };
