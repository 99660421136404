/* eslint-disable no-template-curly-in-string */
const shareHotelFromCytricCard = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.3",
  body: [
    {
      type: "TextBlock",
      text: "${bookingOptionText}",
      wrap: true,
      weight: "Bolder",
      size: "Large",
      $when: "${isPreview != true}",
    },
    {
      type: "TextBlock",
      text: "${bookingDetailsText}",
      wrap: true,
      size: "Small",
      weight: "Lighter",
      isSubtle: true,
      spacing: "None",
      $when: "${isPreview != true}",
    },
    {
      type: "Container",
      spacing: "Medium",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "Image",
                  url: "${mediaHref}",
                  size: "Small",
                  width: "72px",
                  height: "automatic",
                },
              ],
              $when: "${mediaHref != ''}",
            },
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "TextBlock",
                  text: "${hotelName}",
                  wrap: true,
                  weight: "Bolder",
                  size: "Large",
                },
                {
                  type: "TextBlock",
                  text: "${street}, ${postalCode} ${cityName}, ${stateCode}, ${countryName}",
                  wrap: true,
                  $when: "${stateCode != ''}",
                },
                {
                  type: "TextBlock",
                  text: "${street}, ${postalCode} ${cityName}, ${countryName}",
                  wrap: true,
                  $when: "${stateCode == ''}",
                },
                {
                  type: "RichTextBlock",
                  inlines: [
                    {
                      type: "TextRun",
                      text: "${distanceFromCenterText} ",
                      weight: "Lighter",
                    },
                    {
                      type: "TextRun",
                      text: "${distanceToCenter}",
                    },
                  ],
                  spacing: "None",
                  $when: "${distanceToCenter != ''}",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      style: "emphasis",
      items: [
        {
          type: "TextBlock",
          text: "${roomTypeText} - ${stayDuration} ${nightText}",
          wrap: true,
          isSubtle: false,
          weight: "Bolder",
        },
        {
          type: "TextBlock",
          text: "${checkinDate} - ${checkoutDate}",
          wrap: true,
          size: "Small",
          weight: "Lighter",
          spacing: "None",
        },
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "auto",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${breakfastLabel}",
                          wrap: true,
                          isSubtle: true,
                          size: "Small",
                          horizontalAlignment: "Left",
                        },
                      ],
                      $when: "${breakfast != undefined}",
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${maxOccupancyText} ${occupancyLabel}",
                          wrap: true,
                          isSubtle: true,
                          size: "Small",
                          horizontalAlignment: "Left",
                        },
                      ],
                      separator: true,
                      $when: "${occupancyLabel != undefined}",
                    },
                  ],
                  spacing: "None",
                  horizontalAlignment: "Left",
                  bleed: true,
                },
              ],
            },
          ],
          horizontalAlignment: "Left",
          spacing: "Small",
        },
      ],
      horizontalAlignment: "Left",
      height: "stretch",
      separator: true,
      spacing: "Large",
    },
    {
      type: "TextBlock",
      text: "${bookingAvailabilityText}",
      wrap: true,
      size: "Small",
      $when: "${isPreview != true}",
    },
    {
      type: "ColumnSet",
      columns: [
        {
          type: "Column",
          width: "auto",
          selectAction: {
            type: "Action.OpenUrl",
            title: "${bookingButtonText}",
            url: "${redirectionURL}",
          },
          items: [
            {
              type: "ActionSet",
              actions: [
                {
                  type: "Action.OpenUrl",
                  title: "${bookingButtonText}",
                  url: "${redirectionURL}",
                },
              ],
            },
          ],
        },
      ],
      $when: "${isPreview != true}",
    },
  ],
};

export default shareHotelFromCytricCard;
