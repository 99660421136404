import {
  PROVIDER_FALLBACK_LOGO,
  UPCOMING_TRIPS,
} from "../../../components/upcoming-trips-dashboard/data/constants";
import { validateProviderLogoUrl } from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import DateUtils from "../../../utils/date.utils";
import { IHotelDetails } from "./trip-detail-selectors.model";

const DELIMITER = UPCOMING_TRIPS.delimiterForDestinations;

export const getNumberOfNights = (
  checkInDate: string,
  checkOutDate: string
) => {
  const checkIn: number = DateUtils.getDateInMiliseconds(checkInDate);
  const checkOut: number = DateUtils.getDateInMiliseconds(checkOutDate);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(checkIn) || isNaN(checkOut)) {
    return 0;
  }

  const differenceInTime: number = checkOut - checkIn;

  return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
};

export const getCheckInOutDate = (date: string) =>
  DateUtils.formatLongDate(DateUtils.getUTCDate(date));

export const getHotelImage = (
  hotelImage: { description: string; href: string }[]
) => {
  const image = hotelImage.filter(
    (img: { description: string }) => img.description === "Thumbnail"
  );

  return validateProviderLogoUrl(image?.[0]?.href);
};

export const getHotelDetails = (hotel: IHotelDetails) => ({
  name: hotel?.name,
  address: hotel?.location?.address?.lines?.join(` ${DELIMITER} `),
  postalCode: hotel?.location?.address?.postalCode,
  city: hotel?.location?.cityName,
  country: hotel?.location?.countryName,
  checkInDate: getCheckInOutDate(hotel?.checkInDate),
  checkOutDate: getCheckInOutDate(hotel?.checkOutDate),
  policiesDescription: hotel?.policies?.cancellation?.description,
  ratingNumber: hotel?.rating,
  numberOfNights: getNumberOfNights(
    getCheckInOutDate(hotel?.checkInDate),
    getCheckInOutDate(hotel?.checkOutDate)
  ),
  hotelPicture: getHotelImage(hotel?.media) || PROVIDER_FALLBACK_LOGO,
  phone: hotel?.contact?.phone,
  email: hotel?.contact?.email,
  confirmationNumber: hotel?.confirmationNumber,
});
