import { Flex, Text } from "@fluentui/react-northstar";
import moment from "moment";
import { SegmentAir } from "../../../../../../common/models/ProductAir";
import SegmentWrapper from "../SegmentWrapper";

interface CustomInfo {
  timeAndName: string[];
  nameAndNumber: string;
  travelDuration: string;
  numberOfStops: string;
}

interface AirSegmentContentProps {
  airSegment: SegmentAir;
  customInfo: CustomInfo;
  topDate: any;
}

function AirSegmentContent(props: AirSegmentContentProps) {
  const { airSegment, customInfo, topDate } = props;
  const boundStopsAir = customInfo?.timeAndName;
  const airInfo = customInfo?.nameAndNumber;
  const numberOfStops = customInfo?.numberOfStops;

  function GetBoundStopsAirText() {
    const boundStopsAirText =
      boundStopsAir && boundStopsAir.toString().replaceAll(",", ", ");
    return boundStopsAirText;
  }

  const header = (
    <Flex>
      <Text>
        <Text weight="bold">
          {moment
            .utc(airSegment.Trip_Product_Air?.departure.dateTime)
            .format("HH:mm")}
          &nbsp;
          {airSegment.Trip_Product_Air?.departure?.iataCode}
        </Text>
        &nbsp; - &nbsp;
        <Text weight="bold">
          {moment
            .utc(airSegment.Trip_Product_Air?.arrival.dateTime)
            .format("HH:mm")}
          &nbsp;
          {airSegment.Trip_Product_Air?.arrival?.iataCode}
        </Text>
        &nbsp; | &nbsp;
        <Text weight="bold">{`${numberOfStops}`}</Text>
        {GetBoundStopsAirText()}
        &nbsp; | &nbsp;
        {airInfo}
      </Text>
    </Flex>
  );

  return (
    <SegmentWrapper segment={airSegment} topDate={topDate} segmentType="air">
      {header}
    </SegmentWrapper>
  );
}

export default AirSegmentContent;
