/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RedirectionTypeEnum from "../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import { useAppSelector } from "../../../../../../../../store/StoreHooks";
import { getProductsByType } from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors";
import {
  getInboundDateOfRailBoundGroup,
  getRailDetailsGroupedByDate,
} from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors-rail";
import DateUtils from "../../../../../../../../utils/date.utils";
import HostSettings from "../../../../../../../../utils/host.settings";
import { TRIP_PRODUCT_RAIL } from "../../../../../../data/constants";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import Accordion from "../components/Accordion/Accordion";
import { IBound } from "../components/Accordion/Accordion.model";
import NewProduct from "../components/NewProduct/NewProduct";
import Styles from "./Rail.module.scss";

function Rail({ pastTrip }: any) {
  const [context, setContext] = useState<app.Context | undefined>();

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((value: app.Context) => setContext(value));
    });
  }, []);

  const { t } = useTranslation();
  const trip = useAppSelector((state) => state.tripDetail.trip);

  const bookRail = () => {
    telemetryService.trackEvent(
      { name: "travel-booking-bookTrain" },
      {
        numOfTrains: 0,
        tripId: trip.id,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.RAIL,
    };
    const urlEncodedDeepLinkData: any = {
      subEntityId: JSON.stringify(redirectionInput),
    };
    const deepLink = `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/cytricweb?context=${encodeURI(JSON.stringify(urlEncodedDeepLinkData))}`;

    app.openLink(deepLink);
  };

  if (trip?.products?.[0]?.hasRail) {
    const railSegments = useAppSelector((state) =>
      getProductsByType(state, TRIP_PRODUCT_RAIL)
    );

    const railDetails = useAppSelector(getRailDetailsGroupedByDate);
    const content = Object.keys(railDetails).map((key: string, i: number) => {
      const endDate = getInboundDateOfRailBoundGroup(railDetails?.[key]);
      const titleScreenReader = endDate
        ? `${t(
            "upcoming_trips_dashboard.tripcard.from"
          )} ${DateUtils.formatLongestDate(
            key,
            context?.app?.locale || "en-GB"
          )} ${t(
            "upcoming_trips_dashboard.tripcard.to"
          )} ${DateUtils.formatLongestDate(
            endDate,
            context?.app?.locale || "en-GB"
          )} ${railDetails?.[key]?.[0].length} ${t("tripDetails.item/s")}`
        : `${DateUtils.formatLongestDate(
            key,
            context?.app?.locale || "en-GB"
          )} ${railDetails?.[key]?.[0].length} ${t("tripDetails.item/s")}`;
      return (
        <div
          key={`${key}-${i}-date-section`}
          role="grid"
          aria-label={titleScreenReader}
          title={titleScreenReader}
          aria-busy="true"
        >
          <Text
            className={Styles.date}
            as="div"
            styles={(theme) => ({
              color: theme.theme.siteVariables.bookingDetail?.date,
            })}
          >
            <Text aria-hidden="true" data-testid="date_trip_details">
              {key}
              {endDate ? ` - ${DateUtils.formatLongDate(endDate)}` : null}
            </Text>
          </Text>
          {railDetails[key].map((boundGroup: IBound[], index: number) => (
            <div
              role="row"
              key={`${key}-${index}-accordion`}
              title={`${t("upcoming_trips_dashboard.tripcard.icons.Train")} ${
                i + 1
              } ${t("of")} ${Object.keys(railDetails).length} `}
            >
              <Accordion boundGroup={boundGroup} type="Rail" />
            </div>
          ))}
        </div>
      );
    });

    return (
      <>
        {content}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({
            productType: "train",
            numOfProducts: railSegments.length,
          })}
      </>
    );
  }

  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.train")}
      />
      {CheckFeatureEnabled() && (
        <Button
          aria-label={t(
            "upcoming_trips_dashboard.tripDetails.newProduct.buttons.train"
          )}
          content={t(
            "upcoming_trips_dashboard.tripDetails.newProduct.buttons.train"
          )}
          onClick={() => bookRail()}
          flat
        />
      )}
    </Flex>
  );
}

export default Rail;
