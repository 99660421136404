import { Flex, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { complexTitleNameManager } from "../../../../../helpers/TripTitleName";
import ContextMenu from "../../ContextMenu/ContextMenu";
import DateComponent from "../../Date/DateComponent";
import { ITripCardPropsV1 } from "../TripCard.model";
import Styles from "./Destination.module.scss";

function Destination({ trip, index, totalitems }: ITripCardPropsV1) {
  const { t } = useTranslation();
  const tripIndexContent = `. ${t(
    "upcoming_trips_dashboard.tripDetails.breadcrumb.currentPage"
  )} ${index + 1} ${t("of")} ${totalitems}`;

  return (
    <Flex space="between" className={Styles.itemsPlacement}>
      <Flex column className={Styles.tripNameDate}>
        <h3>
          <Text
            className={Styles.destination}
            role="document"
            styles={(theme) => ({
              color: theme.theme.siteVariables.tripCard?.title,
            })}
            data-testid="trip-name"
          >
            {complexTitleNameManager(t, trip, true)}
            <Text
              className={Styles.screenReaderOnly}
              content={tripIndexContent}
            />
          </Text>
        </h3>
        <div className={Styles["trip-card__date"]}>
          <DateComponent
            start_date={trip.start?.localDateTime}
            end_date={trip.end?.localDateTime}
          />
        </div>
      </Flex>
      <ContextMenu tripName={trip.title} trip={trip} />
    </Flex>
  );
}

export default Destination;
