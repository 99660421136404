/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import { Feature } from "@paralleldrive/react-feature-toggles";
import React, { useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import { DropdownItem } from "../../common/models/Location.model";
import AutoCompleteService from "../../services/search-flight/autocomplete.service";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../utils/constants";
import AirportDropDown from "./components/AirportDropDown";
import CreateOptions from "./components/CreateOptions";
import ErrorPage from "./components/ErrorPage";
import GetAirportInformation from "./components/GetAirportInformation";
import GetNewAirValues from "./functions/GetNewAirValues";
import OverlappingLocations, {
  HasOverlappingLocations,
} from "./functions/OverlappingLocations";
import AirCityModel from "./models/AirSearch";
import SearchModel from "./models/SearchModel";
import SearchFlightRedirection from "./redirection/SearchFlightRedirection";
import matchMyTrip from "./styles/match-my-trip.module.scss";

interface MatchMyTripProps {
  t: any;
  closeTask: any;
  submitTask: any;
  isMobileOrTablet: boolean | undefined;
  isMobile: boolean | undefined;
  location: RouteProps["location"];
  deprecatedMMT: boolean;
  isShareJoin: boolean;
}

// sonar fix
const shareJoinButtonClassName = "share-join-buttons";

function escapeRegExp(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const prepareParsedObject = (search: string) => {
  const find = "\\";
  const find2 = '"{';
  const find3 = '}"}';
  const reg1 = new RegExp(escapeRegExp(find), "g");
  const reg2 = new RegExp(find2, "g");
  const reg3 = new RegExp(find3, "g");
  const decodeData = decodeURIComponent(search);
  const objectToParse = decodeData
    .toString()
    .substring(1)
    .replace(reg1, "")
    .replace(reg2, "{")
    .replace(reg3, "}}")
    .replace("context=", "");
  try {
    return JSON.parse(objectToParse);
  } catch (error) {
    return null;
  }
};

function LoadingComponent({
  getThemeStyles,
  t,
  isShareJoin,
}: {
  getThemeStyles: any;
  t: any;
  isShareJoin: boolean;
}) {
  return (
    <Loader
      styles={getThemeStyles}
      size="large"
      className={[
        "trip-loader",
        isShareJoin ? matchMyTrip["sj-loader"] : undefined,
      ].join(" ")}
      data-testid="mmt-loader"
      label={t("match-my-trip.popup.loadingDefaultAirport")}
    />
  );
}

function MatchMyTrip({
  t,
  closeTask,
  submitTask,
  isMobileOrTablet,
  isMobile,
  location,
  deprecatedMMT,
  isShareJoin,
}: MatchMyTripProps) {
  const [airState, setAirState] = useState<SearchModel>();
  const [airOptions, setAirOptions] = useState<DropdownItem[]>([]);
  const [airCityModel, setAirCityModel] = useState<AirCityModel>(
    {} as AirCityModel
  );
  const [initialAirCityModel, setInitialAirCityModel] = useState<AirCityModel>(
    {} as AirCityModel
  );
  const [isPending, setIsPending] = useState<boolean>(false);
  const [showError, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const autoCompleteService = new AutoCompleteService();

  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPAppCatalogId.id
  );
  const featureRedirectionInTravelTab = CheckFeatureToggle(
    FeatureToggleDefinition.redirectionJoinInTravelTab.id
  );

  const getThemeStyles = (theme: any) => ({
    backgroundColor: theme.theme.siteVariables.colorScheme?.default.background,
  });

  useEffect(() => {
    if (location != null) {
      const parsedObject = prepareParsedObject(location.search);
      if (parsedObject) {
        const airObject =
          parsedObject.subEntityId != null
            ? (parsedObject.subEntityId as SearchModel)
            : (parsedObject as SearchModel);
        setAirState(airObject);
        getAirports(airObject.departure[0]);
      }
    }
  }, []);

  useEffect(() => {
    const getNewValues = GetNewAirValues(airState, airCityModel);
    if (getNewValues) {
      setAirState(getNewValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airCityModel.iatacode]);

  useEffect(() => {
    const time = setTimeout(async () => {
      if (airCityModel.name && airCityModel.name.length > 2) {
        setLoading(true);
        try {
          const getAirportItems =
            await autoCompleteService.getAirportsAndCitiesv2(airCityModel.name);
          if (getAirportItems?.data?.data) {
            setAirOptions(CreateOptions(getAirportItems?.data));
          } else {
            setAirOptions([]);
          }
        } catch (catchError: any) {
          setError(true);
          setAirOptions([]);
        }
        setLoading(false);
      }
    }, 1000);
    return () => clearTimeout(time);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airCityModel.name]);

  const getAirports = async (defaultAirport: string): Promise<any> => {
    setIsPending(true);

    const airportInfo = await GetAirportInformation(defaultAirport);

    setAirCityModel({
      ...airCityModel,
      name: airportInfo.name,
      iatacode: airportInfo.iatacode,
    });
    setInitialAirCityModel({
      ...airCityModel,
      name: airportInfo.name,
      iatacode: airportInfo.iatacode,
    });
    setIsPending(false);
  };

  const searchFlightRedirectionSubmit = (
    airStateToSubmit: SearchModel | undefined
  ) => {
    // NOTE: To support MMT from the old cards already in the 1:1 chat
    // we distinguish between deprecated MMT (old) that opens in 1:1 chat
    // and new MMT that redirects to Cytric Easy app and opens popup there
    //
    // This can be removed after 1-2 Cytric Easy releases
    if (deprecatedMMT) {
      SearchFlightRedirection(
        airStateToSubmit,
        isTRIPPUser,
        featureRedirectionInTravelTab
      );
      submitTask(null);
    } else {
      submitTask(airStateToSubmit);
    }
  };

  const handleClick = async () => {
    const airStateToUse = HasOverlappingLocations(airState)
      ? OverlappingLocations(airState)
      : airState;
    searchFlightRedirectionSubmit(airStateToUse as SearchModel);
  };

  const isInitialDepartureLocation = (): boolean =>
    initialAirCityModel.iatacode === airCityModel.iatacode;

  const newHandleClick = () => (
    <Button
      data-testid="mmt-searchFlightButton"
      content={
        isInitialDepartureLocation()
          ? t("match-my-trip.popup.bookFlight")
          : t("match-my-trip.popup.searchFlight")
      }
      disabled={
        airCityModel.iatacode === undefined || airCityModel.iatacode === ""
      }
      primary
      flat
      tinted={isMobileOrTablet && !isShareJoin}
      fluid={isMobile}
      className={[
        matchMyTrip.buttons,
        isShareJoin && matchMyTrip[shareJoinButtonClassName],
        isMobileOrTablet && matchMyTrip.mobile,
      ].join(" ")}
      onClick={handleClick}
    />
  );

  const handleClickOld = async () => {
    searchFlightRedirectionSubmit(airState);
  };
  const oldHandleClick = () => (
    <Button
      data-testid="mmt-searchFlightButton"
      content={
        isInitialDepartureLocation()
          ? t("match-my-trip.popup.bookFlight")
          : t("match-my-trip.popup.searchFlight")
      }
      disabled={
        airCityModel.iatacode === undefined || airCityModel.iatacode === ""
      }
      primary
      flat
      tinted={isMobileOrTablet && !isShareJoin}
      fluid={isMobile}
      className={[
        matchMyTrip.buttons,
        isShareJoin && matchMyTrip[shareJoinButtonClassName],
        isMobileOrTablet && matchMyTrip.mobile,
      ].join(" ")}
      onClick={handleClickOld}
    />
  );

  return (
    <>
      {showError === true && ErrorPage()}
      {isPending ? (
        <LoadingComponent getThemeStyles={getThemeStyles} t={t} isShareJoin />
      ) : (
        <>
          <Text
            content={t("match-my-trip.popup.confirmDepartureLocation")}
            className={isShareJoin ? matchMyTrip["main-text"] : undefined}
          />
          <Flex
            column
            gap="gap.small"
            vAlign="start"
            hAlign="start"
            styles={getThemeStyles}
          >
            <Text
              content={t("match-my-trip.popup.dropdownLabel")}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground2,
              })}
              className={matchMyTrip["text-minor"]}
            />
            <AirportDropDown
              data-testid="cytric-fromDropDown"
              options={airOptions}
              isLoading={loading}
              airCityModel={airCityModel}
              setAirCityModel={setAirCityModel}
            />
          </Flex>

          <Flex
            hAlign="end"
            gap="gap.small"
            styles={getThemeStyles}
            className={[matchMyTrip.actions, matchMyTrip.flex].join(" ")}
          >
            <Button
              data-testid="mmt-cancelButton"
              content={t("match-my-trip.popup.cancel")}
              flat
              tinted={isMobileOrTablet}
              fluid={isMobile}
              className={[
                matchMyTrip.buttons,
                isShareJoin && matchMyTrip[shareJoinButtonClassName],
                isMobileOrTablet && matchMyTrip.mobile,
              ].join(" ")}
              onClick={() => closeTask()}
            />

            <Feature
              name={FeatureToggleDefinition.OverlappingLocations.id}
              activeComponent={newHandleClick}
              inactiveComponent={oldHandleClick}
            />
          </Flex>
        </>
      )}
    </>
  );
}

export default MatchMyTrip;
