/* eslint-disable react-hooks/rules-of-hooks */
import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../../../../../../common/components/responsive/Responsive";
import { useAppSelector } from "../../../../../../../../../store/StoreHooks";
import { UPCOMING_TRIP_DETAIL } from "../../../../../../../data/constants";
import { handleProviderLogoError } from "../../../../../../../utils/trip-utils";
import { ICarSegmentProps } from "../CarSegment/CarSegment.model";
import Styles from "./Header.module.scss";

const getTimeText = (pickDropDatetime: string) => {
  const myDate = new Date(pickDropDatetime);

  const minutes =
    myDate.getMinutes() < 10 ? `0${myDate.getMinutes()}` : myDate.getMinutes();
  const hours =
    myDate.getHours() < 10 ? `0${myDate.getHours()}` : myDate.getHours();

  return `${hours}:${minutes}`;
};

function DropOffLocationContent(dropOff: string, pickUp: string, t: any) {
  return dropOff === pickUp ? ` ${t("tripDetails.car.sameLocation")}` : dropOff;
}

const displayCarManagement = (
  pickOrDrop: string,
  pickDropDetails: string,
  pickDropDatetime: string,
  t: any,
  pickUp?: string
) => {
  const { carDetailCard } = UPCOMING_TRIP_DETAIL;
  const pickOrDropDetails =
    pickOrDrop === "dropOff" && pickDropDetails === pickUp
      ? DropOffLocationContent(pickDropDetails, pickUp, t)
      : pickDropDetails;

  return (
    <Text
      className={Styles["car-header__pickup-dropoff"]}
      as="div"
      styles={(theme) => ({
        color: theme.theme.siteVariables.carDetails?.textColor,
      })}
      aria-hidden
    >
      {t(`tripDetails.car.${carDetailCard[pickOrDrop as keyof Object]}`)}
      {` ${getTimeText(pickDropDatetime)} -`}
      {`${pickOrDropDetails}`}
    </Text>
  );
};

const displayCarVendorNumber = (
  confirmationNumber: string,
  vendorLabel: string
) => (
  <Flex gap="gap.small" className={Styles["car-header__car-vendor-detail"]}>
    <Text className={Styles["car-header__car-vendor"]} aria-hidden>
      {vendorLabel}
    </Text>
    <Text
      className={Styles["car-header__car-vendor"]}
      aria-hidden
      styles={(theme) => ({
        color: theme.theme.siteVariables.carDetails?.textColor,
      })}
    >
      {confirmationNumber}
    </Text>
  </Flex>
);

function Header({ details }: ICarSegmentProps) {
  const {
    carModel,
    carPicture,
    pickUp,
    dropOff,
    confirmationNumber,
    pickUpDateTime,
    dropOffDateTime,
  } = details;
  const { t } = useTranslation();
  const vendorLabel = `${t("tripDetails.car.carVendor")}`;

  const trip = useAppSelector((state) => state.tripDetailV1.tripDetails);

  const carImage = (
    <Text
      as="div"
      className={Styles["car-header__image-container"]}
      styles={(theme) => ({
        borderColor: theme.theme.siteVariables
          ? theme.theme.siteVariables.carDetails?.imageBorder
          : "",
        backgroundColor: theme.theme.siteVariables
          ? theme.theme.siteVariables.carDetails?.background
          : "",
      })}
    >
      <Image
        className={Styles["car-header__image"]}
        src={carPicture}
        alt="Car picture"
        aria-hidden
        onError={handleProviderLogoError}
      />
    </Text>
  );

  const carModelInfo = carModel.split(", ");

  const carName = carModelInfo[0] && (
    <Text
      className={Styles["car-header__model-name"]}
      styles={(theme) => ({
        color: theme.theme.siteVariables.carDetails?.headingColor,
      })}
      title={carModelInfo[0]}
    >
      {carModelInfo[0]}
    </Text>
  );

  const carDoors = (
    <Text
      className={Styles["car-header__model-doors"]}
      styles={(theme) => ({
        color: theme.theme.siteVariables.carDetails?.textColor,
      })}
      title={`${carModelInfo[1]} ${t("tripDetails.car.door/s")}`}
    >
      {carModelInfo[1]}
    </Text>
  );

  const reservationId = () => {
    if (trip.bookingCode) {
      return (
        <div
          className={Styles["car-header__reservationID"]}
          title={`${t("tripDetails.car.bookingCode")} ${trip.bookingCode}`}
        >
          <Text
            className={Styles["car-header__reservationID--heading"]}
            styles={(theme) => ({
              color: theme.theme.siteVariables.carDetails?.textColor,
            })}
            aria-hidden
            as="div"
          >
            {t("tripDetails.car.bookingCode")}
          </Text>
          <Text
            className={Styles["car-header__reservationID--number"]}
            styles={(theme) => ({
              color: theme.theme.siteVariables.carDetails?.headingColor,
            })}
            as="div"
            aria-hidden
          >
            {trip.bookingCode}
          </Text>
        </div>
      );
    }
    return null;
  };

  return (
    <div role="cell" className={Styles["car-header"]}>
      <div className={Styles["car-header__main"]}>
        <div className={Styles["car-header__wrapper"]}>
          {carImage}
          <div>
            <div>
              {carName} {carDoors}
            </div>
            <div
              title={`${t("tripDetails.car.Pick up at")} ${getTimeText(
                pickUpDateTime
              )} - ${pickUp} ${t("tripDetails.car.Drop off at")} ${getTimeText(
                dropOffDateTime
              )} - ${DropOffLocationContent(
                dropOff,
                pickUp,
                t
              )} ${vendorLabel} ${confirmationNumber}`}
            >
              <Responsive forMinSize="tablet">
                {displayCarManagement("pickUp", pickUp, pickUpDateTime, t)}
                {displayCarManagement(
                  "dropOff",
                  dropOff,
                  dropOffDateTime,
                  t,
                  pickUp
                )}
                {displayCarVendorNumber(confirmationNumber, vendorLabel)}
              </Responsive>
            </div>
          </div>
        </div>
        <Responsive forMaxSize="mobile">
          <div
            className={Styles["car-header__reservationID--mobile"]}
            title={`${t("tripDetails.car.Pick up at")} ${getTimeText(
              pickUpDateTime
            )} - ${pickUp} ${t("tripDetails.car.Drop off at")} ${getTimeText(
              dropOffDateTime
            )} - ${dropOff} ${vendorLabel} ${confirmationNumber}`}
          >
            {displayCarManagement("pickUp", pickUp, pickUpDateTime, t)}
            {displayCarManagement(
              "dropOff",
              dropOff,
              dropOffDateTime,
              t,
              pickUp
            )}
            {displayCarVendorNumber(confirmationNumber, vendorLabel)}
          </div>
        </Responsive>
        {reservationId()}
      </div>
    </div>
  );
}

export default Header;
