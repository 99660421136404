/* eslint-disable import/prefer-default-export */

import { ICarV1 } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/Car/Car.model";
import { ImediaV1 } from "../../../components/upcoming-trips-dashboard/UpcomingTrips/UpcomingTripsV1.model";
import { PROVIDER_FALLBACK_LOGO } from "../../../components/upcoming-trips-dashboard/data/constants";
import { validateProviderLogoUrl } from "../../../components/upcoming-trips-dashboard/utils/trip-utils";

export const getCarDetailsV1 = (car: ICarV1) => {
  const pickUp = ` ${car.pickup.location.name}`;
  const dropOff = ` ${car.dropoff.location.name}`;

  const selectedLogo = car.media.filter(
    (content: any) => content.category === "CAR_COMPANY_LOGO"
  )[0];
  const logoImage = validateProviderLogoUrl(selectedLogo.href);

  const selectedCarPicture =
    car?.media.filter(
      (content: ImediaV1) => content.category === "CAR_IMAGE"
    )[0] || PROVIDER_FALLBACK_LOGO;

  return {
    confirmationNumber: car.tripReference?.providerConfirmationNumber,
    carModel: `${car?.vehicle?.carModelReference}, ${car?.vehicle?.doors}`,
    pickUp,
    dropOff,
    pickUpDateTime: car?.pickup?.localDateTime,
    dropOffDateTime: car?.dropoff?.localDateTime,
    carPicture: selectedCarPicture.href,
    logo: logoImage,
    associatedEquipments: car.associatedEquipments,
    serviceProviderName: car.serviceProviderName,
  };
};
