/* eslint-disable react/react-in-jsx-scope */
import {
  Button,
  ChatIcon,
  Checkbox,
  EmailIcon,
  Flex,
  MeetingNewIcon,
  Menu,
  MoreIcon,
  Popup,
  Table,
} from "@fluentui/react-northstar";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import Responsive, {
  tablet,
} from "../../../../common/components/responsive/Responsive";
import useScreenSize from "../../../../common/components/responsive/useScreenSize";
import Store from "../../../../store/Store";
import { useAppSelector } from "../../../../store/StoreHooks";
import { selectCloseCollaboratorsIdsToNotify } from "../../../../store/collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationSelector";
import {
  addCloseCollaboratorToNotify,
  removeCloseCollaboratorToNotify,
} from "../../../../store/collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationSlice";
import { selectedTravelTripId } from "../../../../store/travel-trip-id/TravelTripIdSelector";
import stylesCloseCollaborators from "../../common/styles/close-collaborators.module.scss";
import CloseCollaboratorsNoItems from "./close-collaborators-table-components/CloseCollaboratorsNoItems";
import {
  getHeader,
  getMobileHeader,
  openCalendar,
  openChat,
  openOutlook,
  optionItemsCollaborator,
  role,
} from "./close-collaborators-table-components/CloseCollaboratorsTableComponents";
import User from "./close-collaborators-table-components/CloseCollaboratorsUser";

export function PopupSubmenuItems({ icon, content, event, ariaLabel }: any) {
  return (
    <Button
      as="div"
      text
      icon={icon}
      content={content}
      onClick={event}
      aria-label={ariaLabel}
    />
  );
}

export const getUser = (
  data: any,
  index: any,
  closeCollaboratorsIds: string[],
  showCheckbox: boolean = true
) => ({
  content: (
    <Flex className={stylesCloseCollaborators.userNameContainer}>
      {showCheckbox && (
        <Checkbox
          checked={closeCollaboratorsIds.includes(data.id)}
          onClick={(_e, { checked }: any) => {
            if (checked) {
              Store.dispatch(
                addCloseCollaboratorToNotify({ closeCollaboratorId: data.id })
              );
            } else {
              Store.dispatch(
                removeCloseCollaboratorToNotify({
                  closeCollaboratorId: data.id,
                })
              );
            }
          }}
          aria-label={`Name column 1 graphic ${data.availability} Name ${
            data.displayName
          } ${data.hasBeenNotified && "Notified"} Role ${data.jobTitle}`}
        />
      )}
      <User
        id={data.id}
        displayName={data.displayName}
        jobTitle={data.jobTitle}
        image={data.image}
        presence={{
          availability: data.availability,
          activity: data.activity,
        }}
        hasBeenNotified={data.hasBeenNotified}
      />
    </Flex>
  ),
  key: `${index}-user-cc`,
  className: stylesCloseCollaborators.firstCol,
});

export const getRole = (data: any, index: any) => ({
  content: role(data.jobTitle, data.hasBeenNotified),
  key: `${index}-role-cc`,
  className: stylesCloseCollaborators.roleCol,
});

export const getMenu = (
  data: any,
  index: any,
  closeCollaboratorsIds: string[],
  tripId: string
) => {
  const items = optionItemsCollaborator(
    data.displayName,
    data.userPrincipalName,
    closeCollaboratorsIds.length > 0,
    tripId
  );

  const popupSubmenuContent = (
    <>
      <PopupSubmenuItems
        icon={<ChatIcon outline />}
        content={i18next.t("closeCollaborators.table.startChat")}
        event={() => openChat(data.userPrincipalName, tripId)}
        ariaLabel={i18next.t("closeCollaborators.table.chatWith", {
          user: `${data.displayName}`,
        })}
      />
      <PopupSubmenuItems
        icon={<EmailIcon outline />}
        content={i18next.t("closeCollaborators.table.sendEmail")}
        event={() => openOutlook(data.userPrincipalName, tripId)}
        ariaLabel={i18next.t("closeCollaborators.table.sendEmailTo", {
          user: `${data.userPrincipalName}`,
        })}
      />
      <PopupSubmenuItems
        icon={<MeetingNewIcon outline />}
        content={i18next.t("closeCollaborators.table.scheduleMeeting")}
        event={() => openCalendar(data.userPrincipalName, tripId)}
        ariaLabel={i18next.t("closeCollaborators.table.scheduleMeetingWith", {
          user: `${data.displayName}`,
        })}
      />
    </>
  );

  const optionsMenu = (
    <>
      <Responsive forMinSize="tablet">
        <Menu iconOnly items={items} />
      </Responsive>
      <Responsive forRange="mobile">
        <Popup
          trigger={
            <Button
              text
              iconOnly
              icon={<MoreIcon aria-label="More icon button" />}
              disabled={closeCollaboratorsIds.length > 0}
            />
          }
          content={popupSubmenuContent}
          inline
          position="below"
          align="end"
        />
      </Responsive>
    </>
  );

  return {
    content: optionsMenu,
    key: `${index}-options-cc`,
    className: stylesCloseCollaborators.options,
  };
};

export default function CloseCollaboratorsTable({
  collaborators,
  themeName,
}: any) {
  const [rows, setRows] = useState([]);
  const screenSize = useScreenSize();
  const isMobile = screenSize < tablet;

  const closeCollaboratorsIds: string[] = useAppSelector((state) =>
    selectCloseCollaboratorsIdsToNotify(state)
  );
  const travelTripId: string = useAppSelector((state) =>
    selectedTravelTripId(state)
  );

  useEffect(() => {
    const currentRows = collaborators.map((collaborator: any, index: any) => ({
      key: `${index}-row-cc`,
      items: [
        getUser(collaborator, index, closeCollaboratorsIds),
        getRole(collaborator, index),
        getMenu(collaborator, index, closeCollaboratorsIds, travelTripId),
      ],
      className: stylesCloseCollaborators.userRow,
    }));

    setRows(currentRows);
  }, [collaborators, closeCollaboratorsIds, travelTripId]);

  return (
    <div className={stylesCloseCollaborators.tableContainer}>
      {rows.length > 0 ? (
        <Flex column>
          <Table
            header={
              isMobile
                ? getMobileHeader(collaborators, closeCollaboratorsIds.length)
                : getHeader(collaborators, closeCollaboratorsIds.length)
            }
            rows={rows}
            className={[
              themeName === "light"
                ? stylesCloseCollaborators.white
                : stylesCloseCollaborators.dark,
              stylesCloseCollaborators.table,
            ].join(" ")}
            aria-label={i18next.t("closeCollaborators.table.tableAriaLabel", {
              numberOfRows: rows.length + 1,
              numberOfColumns: "3",
            })}
          />
        </Flex>
      ) : (
        <CloseCollaboratorsNoItems />
      )}
    </div>
  );
}
