/* eslint-disable react/react-in-jsx-scope */
import {
  createMicrosoftGraphClient,
  TeamsUserCredential,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Dropdown, SearchIcon } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { tablet } from "../../../../common/components/responsive/Responsive";
import useScreenSize from "../../../../common/components/responsive/useScreenSize";
import getFilteredSearch from "../../../../common/components/search-contacts/utils/GetFilteredSearch";
import i18next from "../../../../i18n/i18n";
import stylesCloseCollaborators from "../../common/styles/close-collaborators.module.scss";
import getPresenceCollaborators from "../utils/getPresenceCollaborators";
import searchUsersInCountry from "./utils/SearchUserInCountry";

export async function changeHandlerFunction(
  event: any,
  setCollaboratorsValue: any,
  collaboratorsValue: any,
  setLoadingCollaborators: any,
  client: any
) {
  setLoadingCollaborators(true);
  if (event.value) {
    const { responses } = await getPresenceCollaborators(client, [
      event.value.id,
    ]);
    const presenceState = responses[0].body;
    const newContactData: any = [
      {
        id: event.value.id,
        displayName: event.value.header,
        userPrincipalName: event.value.className,
        jobTitle: event.value.content.split(")")[1],
        image: event.value.image,
        activity: presenceState.activity,
        availability: presenceState.availability,
      },
    ];
    setCollaboratorsValue(newContactData);
  } else {
    setCollaboratorsValue(collaboratorsValue);
  }
  setLoadingCollaborators(false);

  return event;
}

export default function CloseCollaboratorsListComponents(
  countryCode: string,
  setCollaboratorsValue: any,
  collaboratorsValue: any,
  setLoadingCollaborators: any,
  userOId: string
) {
  const [findcontacts, setFindcontacts] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");

  const scope = ["User.Read"];
  const credential = new TeamsUserCredential();
  const client = createMicrosoftGraphClient(credential, scope);
  const noMatches = i18next.t("closeCollaborators.list.noMatches");

  const screenSize = useScreenSize();
  const isMobile = screenSize < tablet;

  const onChangeHandler = async (_: any, event: any) => {
    changeHandlerFunction(
      event,
      setCollaboratorsValue,
      collaboratorsValue,
      setLoadingCollaborators,
      client
    );
  };

  const getA11ySelectionMessage = {
    onAdd: (item: any) =>
      i18next.t("closeCollaborators.list.a11yToSelect", {
        a11y: `${item.header}`,
      }),
    onRemove: (item: any) =>
      i18next.t("closeCollaborators.list.a11yToRemove", {
        a11y: `${item.header}`,
      }),
  };

  useEffect(() => {
    let timer: any;
    setFindcontacts([]);

    if (query.length) {
      timer = setTimeout(() => {
        searchUsersInCountry(
          query,
          countryCode,
          setFindcontacts,
          setLoading,
          userOId
        );
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [query, countryCode, userOId]);

  return (
    <Dropdown
      search={(items: any) => getFilteredSearch(items, loading)}
      fluid
      clearable
      items={findcontacts}
      loading={loading}
      loadingMessage={i18next.t("closeCollaborators.list.loading")}
      placeholder={
        isMobile
          ? i18next.t("closeCollaborators.list.typeColleagueName")
          : i18next.t("closeCollaborators.list.findContact")
      }
      data-testid="select"
      getA11ySelectionMessage={getA11ySelectionMessage}
      noResultsMessage={noMatches}
      a11ySelectedItemsMessage={i18next.t(
        "closeCollaborators.list.a11ySelected"
      )}
      toggleIndicator={isMobile ? false : <SearchIcon />}
      className={stylesCloseCollaborators.findaContact}
      onChange={onChangeHandler}
      onSearchQueryChange={(_e: any, { searchQuery }: any) => {
        setLoading(true);
        setQuery(searchQuery);
      }}
    />
  );
}
