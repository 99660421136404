import { createSlice } from "@reduxjs/toolkit";
import FeatureToggle from "../../common/models/FeatureToggle";
import { getFeatureToggles } from "./FeatureTogglesAction";

interface FeatureTogglesStoreModel {
  featureToggles: FeatureToggle[];
  isPending: boolean;
  isError: boolean;
  isCompleted: boolean;
}

const INITIAL_STATE: FeatureTogglesStoreModel = {
  featureToggles: [],
  isPending: false,
  isError: false,
  isCompleted: false,
};

export const featureTogglesSlice = createSlice({
  name: "featureToggles",
  initialState: INITIAL_STATE,
  reducers: {
    resetFeatureToggles: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getFeatureToggles.pending, (state) => ({
      ...state,
      featureToggles: [],
      isPending: true,
      isError: false,
      isCompleted: false,
    }));

    builder.addCase(getFeatureToggles.fulfilled, (state, { payload }) => ({
      ...state,
      featureToggles: payload,
      isPending: false,
      isError: false,
      isCompleted: true,
    }));

    builder.addCase(getFeatureToggles.rejected, (state: any) => ({
      ...state,
      ...INITIAL_STATE,
      isPending: false,
      isError: true,
      isCompleted: true,
    }));
  },
});

export const { resetFeatureToggles } = featureTogglesSlice.actions;

export default featureTogglesSlice.reducer;
