export default function GetChannelsRecipients(
  searchItems: any[],
  channels: any
) {
  let searchCopy = searchItems;
  if (channels && channels.length > 0) {
    searchCopy = [...searchCopy, ...channels];
    return searchCopy;
  }

  return searchCopy;
}
