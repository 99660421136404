import { Button, Flex } from "@fluentui/react-northstar";
import { dialog } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";

import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import TripApprovalFooterStyles from "./TripApprovalFooter.module.scss";

function TripApprovalFooter({ action, isDisable, handleClick }: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: "tripApproval.button",
  });

  const getTitle = () => {
    let title = "";
    switch (action) {
      case "approve":
        title = t("confirmApproval");
        break;
      case "reject":
        title = t("confirmRejection");
        break;
      case "viewInCytric":
        title = t("continue");
        break;
      default:
        title = "";
    }

    return title;
  };

  const handleCancel = (): void => {
    telemetryService.trackEvent(
      { name: "travel-tripApproval-approveRejectActionCancel" },
      {
        action: "cancel",
      }
    );
    dialog.url.submit();
  };

  return (
    <Flex
      className={TripApprovalFooterStyles.footer}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables?.colorScheme?.default.background,
      })}
    >
      <Flex className={TripApprovalFooterStyles.buttons}>
        <Button
          className={TripApprovalFooterStyles.cancel}
          data-testid="trip-approval-cancel-button"
          content={t("cancel")}
          aria-label={t("cancel")}
          onClick={() => {
            handleCancel();
          }}
        />
        <Button
          className={TripApprovalFooterStyles.continue}
          data-testid="trip-approval-continue-button"
          content={getTitle()}
          aria-label={getTitle()}
          size="medium"
          primary
          onClick={handleClick}
          disabled={isDisable}
        />
      </Flex>
    </Flex>
  );
}

export default TripApprovalFooter;
