/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { Flex } from "@fluentui/react-northstar";
import i18next from "i18next";
import { useEffect, useState } from "react";
import TripHelperV1, { ProductType } from "../../../../helpers/TripHelperV1";
import {
  IArrivalDepartureFlight,
  IDataV1,
  IProductV1,
} from "../../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import Styles from "../../ShareJoin.module.scss";
import { useDispatch } from "../../context/ShareJoinContext";
import { types } from "../../context/ShareJoinReducer";
import AirSegmentContentV1 from "./segments-v1/AirSegmentContenV1";
import CarSegmentContentV1 from "./segments-v1/CarSegmentContentV1";
import HotelSegmentContentV1 from "./segments-v1/HotelSegmentContentV1";
import RailSegmentContentV1 from "./segments-v1/RailSegmentContentV1";

function TripPanelContentV1(trip: IDataV1) {
  const dispatch = useDispatch();
  const [airSegments, setAirSegments] = useState<IProductV1[][]>([]);
  const [railSegments, setRailSegments] = useState<IProductV1[][]>([]);
  const [hotelSegments, setHotelSegments] = useState<IProductV1[]>([]);
  const [carSegments, setCarSegments] = useState<IProductV1[]>([]);
  const [orderedSegments, setOrderedSegments] = useState([]);

  const { t } = i18next;
  const { products } = trip;

  const aggregateBySegmentType = (segments: IProductV1[], type: ProductType) =>
    segments.filter((segment) => segment.productType === type);

  const aggregateBySegmentIdentifier = (
    segments: IProductV1[],
    isAir: boolean
  ): Array<IProductV1[]> => {
    const segmentsToReturn: Array<IProductV1[]> = [];
    if (segments) {
      const uniqueStepNumbers = Array.from(
        new Set(
          segments.map((segment) =>
            isAir ? segment.boundNumber : segment.stepNumber
          )
        )
      );
      uniqueStepNumbers.forEach((identifier) => {
        segmentsToReturn.push(
          segments.filter(
            (segment) =>
              (isAir ? segment.boundNumber : segment.stepNumber) === identifier
          )
        );
      });
    }
    return segmentsToReturn;
  };

  const getTopDate = (segment: IProductV1, index: number) => {
    const currentDate = TripHelperV1.getSegmentDate(segment);
    const prevDate =
      index > 0 && products
        ? TripHelperV1.getSegmentDate(products[index - 1])
        : null;

    return prevDate !== currentDate ? currentDate : null;
  };

  useEffect(() => {
    if (products) {
      dispatch({
        type: types.setTrip,
        payload: trip,
      });
    }

    setAirSegments(
      aggregateBySegmentIdentifier(
        aggregateBySegmentType(
          products,
          ProductType.Flight
        ) as unknown as IProductV1[],
        true
      )
    );
    setRailSegments(
      aggregateBySegmentIdentifier(
        aggregateBySegmentType(
          products,
          ProductType.Train
        ) as unknown as IProductV1[],
        false
      )
    );
    setHotelSegments(
      aggregateBySegmentType(
        products,
        ProductType.Hotel
      ) as unknown as IProductV1[]
    );
    setCarSegments(
      aggregateBySegmentType(
        products,
        ProductType.Car
      ) as unknown as IProductV1[]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // inside this hook, we sort segments based on start date
    // so we persist current logic of share-join

    let segmentsUIComponents: any = [];
    if (airSegments && airSegments.length > 0) {
      const airArrayHelper = airSegments.map((stepSegments, index) => {
        const orderDate = stepSegments[0].departure as IArrivalDepartureFlight;
        return [
          orderDate.dateTime,
          <AirSegmentContentV1
            key={`air-segment-${index.toString()}`}
            airSegment={stepSegments}
            id={index}
          />,
        ];
      });
      segmentsUIComponents = [...segmentsUIComponents, ...airArrayHelper];
    }
    if (railSegments && railSegments.length > 0) {
      const railArrayHelper = railSegments.map((stepSegments, index) => [
        stepSegments[0].departureDateTime,
        <RailSegmentContentV1
          key={`rail-segment-${index.toString()}`}
          railSegment={stepSegments}
          id={index + airSegments.length}
        />,
      ]);
      segmentsUIComponents = [...segmentsUIComponents, ...railArrayHelper];
    }
    if (hotelSegments && hotelSegments.length > 0) {
      const hotelArrayHelper = hotelSegments.map((stepSegment, index) => {
        const topDate = getTopDate(stepSegment, index);
        return [
          TripHelperV1.getSegmentDate(stepSegment)?.start,
          <HotelSegmentContentV1
            key={`hotel-segment-${index.toString()}`}
            hotelSegment={stepSegment}
            topDate={topDate}
            id={index + airSegments.length + railSegments.length}
          />,
        ];
      });
      segmentsUIComponents = [...segmentsUIComponents, ...hotelArrayHelper];
    }
    if (carSegments && carSegments.length > 0) {
      const carArrayHelper = carSegments.map((stepSegment, index) => {
        const topDate = getTopDate(stepSegment, index);
        return [
          TripHelperV1.getSegmentDate(stepSegment)?.start,
          <CarSegmentContentV1
            key={`car-segment-${index.toString()}`}
            carSegment={stepSegment}
            topDate={topDate}
            id={
              index +
              airSegments.length +
              railSegments.length +
              hotelSegments.length
            }
          />,
        ];
      });
      segmentsUIComponents = [...segmentsUIComponents, ...carArrayHelper];
    }

    setOrderedSegments(
      segmentsUIComponents.sort((a: any, b: any) => {
        const aDate = new Date(a[0]);
        const bDate = new Date(b[0]);
        if (aDate > bDate) {
          return 0;
          // eslint-disable-next-line no-else-return
        } else if (aDate < bDate) {
          return -1;
        }
        return 0;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airSegments, carSegments, hotelSegments, railSegments]);

  return (
    <Flex
      gap="gap.medium"
      column
      aria-label={t("tripDetailsText")}
      role="list"
      className={Styles.shareTripDetails}
    >
      {orderedSegments &&
        orderedSegments.length > 0 &&
        orderedSegments.map((segment: any) => segment[1])}
    </Flex>
  );
}

export default TripPanelContentV1;
