/* eslint-disable @typescript-eslint/no-unused-vars */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";

import {
  Box,
  Button,
  Flex,
  ProviderConsumer,
  Text,
} from "@fluentui/react-northstar";
import { Open16Regular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

import CheckFeatureToggle from "../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../utils/constants";
import SettingsOptionsStyles from "./SettingsOptions.module.scss";
import { isMobileOrTablet } from "../../../utils/device.utils";
import doLogout from "../../../common/components/logout/logout-component";
import GetUrlCytricNewTab from "../../upcoming-trips-dashboard/utils/GetUrlCytricNewTab";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";

interface AccountSettingsOptionsLegacyProps {
  isUserProfileAllowed: boolean;
}

function AccountSettingsOptionsLegacy({
  isUserProfileAllowed,
}: AccountSettingsOptionsLegacyProps) {
  const { t } = useTranslation("translation");

  const isMobileOrTabletDevice = isMobileOrTablet();

  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPSettings.id
  );

  // Hide Account section on TRIPPSettings users
  if (isTRIPPUser) {
    return null;
  }

  const redirectionCytricUserProfile = () => (
    <Flex className="flexContainer">
      <Button
        className="legacyUserProfile"
        styles={(theme) => ({
          color: isMobileOrTabletDevice
            ? theme.theme.siteVariables?.settings?.mobileButtonColor
            : theme.theme.siteVariables?.settings?.desktopButtonColor,
        })}
        text
        primary
        fluid
        onClick={() => {
          telemetryService.trackEvent({
            name: "redirect-user-profile",
          });
          GetUrlCytricNewTab(RedirectionTypeEnum.USER_PROFILE);
        }}
        size="small"
        iconPosition="before"
        icon={<Open16Regular />}
        content={t("Settings.account.Cytric_user_profile_link")}
        aria-label={t("Settings.account.Cytric_user_profile_link")}
        data-testid="cytric-user-profile"
      />
      <Button
        className="accountButton justifySelfRight logoutButton"
        styles={(theme) => ({
          color: isMobileOrTabletDevice
            ? theme.theme.siteVariables?.settings?.mobileButtonColor
            : theme.theme.siteVariables?.settings?.desktopButtonColor,
        })}
        secondary
        text
        onClick={doLogout}
        size="small"
      >
        {t("Settings.account.Logout_btn")}
      </Button>
    </Flex>
  );

  const noRedirectionCytricUserProfile = () => (
    <Button
      className="accountButton justifySelfRight"
      primary
      onClick={doLogout}
      size="small"
    >
      {t("Settings.account.Logout_btn")}
    </Button>
  );

  const renderRedirectionCytricUserProfile = () =>
    isUserProfileAllowed &&
    CheckFeatureToggle(
      FeatureToggleDefinition.redirectionCytricUserProfile.id
    ) ? (
      <ProviderConsumer render={() => redirectionCytricUserProfile()} />
    ) : (
      <ProviderConsumer render={() => noRedirectionCytricUserProfile()} />
    );

  return (
    <>
      <Flex className="sectionTitle" style={{ backgroundColor: "transparent" }}>
        <Text weight="bold">{t("Settings.titles.Account")}</Text>
      </Flex>
      <Box
        className={SettingsOptionsStyles.divider}
        styles={(theme) => ({
          color: theme.theme.siteVariables
            ? theme.theme.siteVariables.settings?.divider
            : "",
        })}
      />
      <Flex
        className="sectionItem"
        space="between"
        style={{ backgroundColor: "transparent" }}
      >
        <Text className="loggedIn">
          {t("Settings.account.You_are_logged_in")}
        </Text>
        <ProviderConsumer render={() => renderRedirectionCytricUserProfile()} />
      </Flex>
    </>
  );
}

export default AccountSettingsOptionsLegacy;
