import Trip from "../../../common/models/Trip";
import { ITripV1 } from "../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";

function getTripToShare(tripTemplate: Trip | ITripV1, productsToShare: any) {
  if (productsToShare.products.length) {
    return { ...tripTemplate, products: [productsToShare] };
  }
  return null;
}

export default getTripToShare;
