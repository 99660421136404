import GetName from "./GetName";

export default function GetGroups(groups: any) {
  return groups.map((group: any) => {
    const groupNameId = {
      name: "",
      id: "",
      members: "",
      membersEmail: "",
    };
    let members = "";
    let membersEmail = "";

    const numOfMembers = group.members.length - 1;

    group.members.map((member: any, index: number) => {
      members = GetName(members, member, index, numOfMembers);
      return members;
    });
    group.members.forEach((member: any) => {
      membersEmail += `${member.email}, `;
    });
    groupNameId.name = group.topic !== null ? group.topic : members;
    groupNameId.id = group.id;
    groupNameId.members = members;
    groupNameId.membersEmail = membersEmail;
    return groupNameId;
  });
}
