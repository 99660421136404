/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-use-before-define */
import CarRedirectionInput from "../../../common/models/redirection/CarRedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import i18n from "../../../i18n/i18n";
import {
  ShareCarCardData,
  ShareCarParamsModel,
  ShareCarTripDetail,
} from "../models/ShareCarModel";
import {
  ShareCarParamsModelShort,
  ShareCarTripDetailShort,
} from "../models/ShareCarShortModel";
import {
  getAdaptiveCardImageUrl,
  getSearchDeeplink,
  getformattedDateTime,
} from "./ShareHelper";

function hasRequiredParams(testedObject: any, requiredParams: string[]) {
  return requiredParams.every((param) => !!testedObject[param]);
}

/**
 * Validates deeplink data for ShareCar.
 *
 * @param data deeplink data to be validated
 * @returns true if data is of type type ShareCarParamsModel otherwise returns false
 */
export function isValidCarData(data: any): data is ShareCarParamsModel {
  if (!data.carTripDetails) {
    return false;
  }

  const firstLevelParams = [
    "pickupDateAndTime",
    "dropOffDateAndTime",
    "pickupLocation",
    "dropOffLocation",
  ];

  // NOTE: code not included in required because it is not present for all locations
  const locationParams = ["locationName", "longitude", "latitude", "country"];

  const isValid = data.carTripDetails.every((carTrip: any) => {
    if (typeof carTrip.pickupLocation === "string") {
      return hasRequiredParams(carTrip, firstLevelParams);
    }
    return (
      hasRequiredParams(carTrip, firstLevelParams) &&
      hasRequiredParams(carTrip.pickupLocation, locationParams) &&
      hasRequiredParams(carTrip.dropOffLocation, locationParams)
    );
  });

  return isValid;
}

export function getShareCarCardData(
  car: ShareCarParamsModel,
  isPreview: boolean
) {
  setTripDetailsData(car);

  const isChauffeurAndTaxi = car.carTripDetails[0].isChauffeurAndTaxi;
  const bookingButtonText =
    isChauffeurAndTaxi === "true" || isChauffeurAndTaxi === true
      ? i18n.t("shareTrip.bookTransferDeepLink")
      : i18n.t("shareTrip.bookCarDeepLink");

  const shareCarCardData: ShareCarCardData = {
    ...car,
    doorText: i18n.t("shareTrip.adaptiveCard.car.door"),
    atTimeText: i18n.t("shareTrip.adaptiveCard.atTime"),
    atPlaceText: i18n.t("shareTrip.adaptiveCard.atPlace"),
    pickUpText: i18n.t("shareTrip.adaptiveCard.car.pickUp"),
    dropOffText: i18n.t("shareTrip.adaptiveCard.car.dropOff"),
    capacityText: i18n.t("shareTrip.adaptiveCard.car.capacity"),
    luggageCapacityText: i18n.t("shareTrip.adaptiveCard.luggageCapacity"),
    carModelText: i18n.t("shareTrip.adaptiveCard.car.model"),
    bookingOptionText: i18n.t("shareTrip.adaptiveCard.bookingOption"),
    bookingDetailsText: i18n.t("shareTrip.adaptiveCard.bookingOptionDetails"),
    bookingAvailabilityText: i18n.t(
      "shareTrip.adaptiveCard.bookingAvailability"
    ),
    bookingButtonText,
    isPreview,
    redirectionURL: getCarDeeplinkURL(car),
  };

  return shareCarCardData;
}

/**
 * Sets data field for respective trip details.
 *
 * @param car car data
 */
function setTripDetailsData(car: ShareCarParamsModel) {
  car.carTripDetails.forEach((carTripDetail) => {
    // sets formatted dates
    carTripDetail.formattedPickupDateAndTime = getformattedDateTime(
      carTripDetail.pickupDateAndTime
    );
    carTripDetail.formattedDropOffDateAndTime = getformattedDateTime(
      carTripDetail.dropOffDateAndTime
    );
    // sets image url
    carTripDetail.rentalCarPictureUrl = getAdaptiveCardImageUrl(
      carTripDetail.rentalCarPictureUrl
    );
    // sets title
    // NOTE: temporary workaround until CYT-18340 fix is deployed to production
    carTripDetail.titleText =
      carTripDetail.isChauffeurAndTaxi === true ||
      carTripDetail.isChauffeurAndTaxi === "true"
        ? i18n.t("shareTrip.adaptiveCard.car.transfer")
        : i18n.t("shareTrip.adaptiveCard.car.rentalCar");

    carTripDetail.pickupLocationText =
      typeof carTripDetail.pickupLocation === "string"
        ? carTripDetail.pickupLocation
        : carTripDetail.pickupLocation.locationName;
    carTripDetail.dropOffLocationText =
      typeof carTripDetail.dropOffLocation === "string"
        ? carTripDetail.dropOffLocation
        : carTripDetail.dropOffLocation.locationName;
  });
}

function prepareCorrectDateTimes(carTripDetails: any[]) {
  const initialTrip = carTripDetails[0];
  const dateTimes = [initialTrip.pickupDateAndTime];

  // NOTE: this is a temporary workaround until CYT-18340 is deployed to production and isChauffeurAndTaxi will be boolean
  const isChauffeurAndTaxi =
    initialTrip.isChauffeurAndTaxi === "true" ||
    initialTrip.isChauffeurAndTaxi === true;
  // NOTE: There are multiple carTripDetails only for transfers (Chauffeur and Taxi)
  const hasReturnTrip = carTripDetails.length > 1;

  if (isChauffeurAndTaxi) {
    if (hasReturnTrip) {
      const returnTrip = carTripDetails[1];
      dateTimes.push(returnTrip.pickupDateAndTime);
    }
  } else {
    dateTimes.push(initialTrip.dropOffDateAndTime);
  }
  return dateTimes;
}

/**
 * Creates search car deeplink with given booking data.
 *
 * @param car car booking data
 * @returns deeplink url for search car
 */
function getCarDeeplinkURL(car: ShareCarParamsModel): string {
  const carTrip = car.carTripDetails[0];
  const dateTimes = prepareCorrectDateTimes(car.carTripDetails);
  const pick = carTrip.pickupLocation;
  const drop = carTrip.dropOffLocation;
  const isSimpleLocation = typeof pick === "string" && typeof drop === "string";
  const locationNames = isSimpleLocation
    ? [pick, drop]
    : [pick.locationName, drop.locationName];
  const longitudes = isSimpleLocation ? [] : [pick.longitude, drop.longitude];
  const latitudes = isSimpleLocation ? [] : [pick.latitude, drop.latitude];
  const countryCodes = isSimpleLocation ? [] : [pick.country, drop.country];
  const codes = isSimpleLocation ? [] : [pick.code, drop.code];
  const carDelivery = isSimpleLocation ? false : carTrip.carDelivery;
  const carCollection = isSimpleLocation ? false : carTrip.carCollection;
  // NOTE: this is a temporary workaround until CYT-18340 is deployed to production and isChauffeurAndTaxi will be boolean
  const isChauffeurAndTaxi =
    carTrip.isChauffeurAndTaxi === "true" ||
    carTrip.isChauffeurAndTaxi === true;

  const deeplinkData: CarRedirectionInput = {
    segmentType: RedirectionTypeEnum.CAR,
    name: locationNames,
    dateTime: dateTimes,
    purpose: car.tripPurposeId,
    // NOTE: isSimpleLocation is a temporary workaround until CYT-18340 is deployed to production
    stopOnStartPage: isSimpleLocation,
    longitude: longitudes,
    latitude: latitudes,
    country: countryCodes,
    code: codes,
    carDelivery: carDelivery,
    carCollection: carCollection,
    isChauffeurAndTaxi,
    buttonType: "bookCar",
  };

  return getSearchDeeplink(deeplinkData);
}

function mapToCarTripDetailShort(
  detail: ShareCarTripDetailShort
): ShareCarTripDetail {
  return {
    rentalCarPictureUrl: detail.rcp,
    rentalCompany: detail.rco,
    carName: detail.cna,
    pickupDateAndTime: detail.pdt,
    pickupLocationText: detail.plo.lna,
    pickupLocation: {
      locationName: detail.plo.lna,
      longitude: detail.plo.lon,
      latitude: detail.plo.lat,
      country: detail.plo.cou,
      code: detail.plo.cod,
    },
    dropOffDateAndTime: detail.ddt,
    dropOffLocationText: detail.dlo.lna,
    dropOffLocation: {
      locationName: detail.dlo.lna,
      longitude: detail.dlo.lon,
      latitude: detail.dlo.lat,
      country: detail.dlo.cou,
      code: detail.dlo.cod,
    },
    capacity: detail.cap,
    suitcaseCapacity: detail.sca,
    carModel: detail.cmo,
    isChauffeurAndTaxi: detail.ict,
    carDelivery: detail.cde,
    carCollection: detail.cco,
  };
}

/**
 * Ensures correct mapping from data shared from Cytric Classic to Cytric Easy.
 * Links shared from Cytric Classic have limitation of 1900 characters, so all keys were shortened to only 3 characters.
 *
 * Example: pickupDateAndTime -> pdt
 * One exception: segmentType key is not shortened.
 *
 * @param data from Cytric Classic
 * @returns The mapped ShareCarParamsModel or null if an error occurs.
 */
export function mapToShareCarParamsModel(
  data: ShareCarParamsModelShort
): ShareCarParamsModel | null {
  try {
    return {
      segmentType: "ShareCar",
      tripPurposeId: data.tpi,
      carTripDetails: data.ctd.map((ctd) => mapToCarTripDetailShort(ctd)),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in mapToShareCarParamsModel: ", error);
    return null;
  }
}
