import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USER_OBJECT_ID } from "../../utils/constants";
import { login, logout, token } from "./AuthenticationAction";

interface AuthenticationModel {
  isLoggedIn: boolean;
  isPending: boolean;
  isPendingToken: boolean;
  isAuthError: boolean;
  isTokenError: boolean;
  errorMessage: string;
  userObjectId: string;
  authErrorCode: number | undefined;
}

const INITIAL_STATE: AuthenticationModel = {
  isLoggedIn: false,
  isPending: false,
  isPendingToken: false,
  isAuthError: false,
  isTokenError: false,
  errorMessage: "",
  userObjectId: localStorage.getItem(USER_OBJECT_ID) || "",
  authErrorCode: undefined,
};

const pendingStateReducer = (state: any) => ({
  ...state,
  isPending: true,
  isPendingToken: true,
  isAuthError: false,
  errorMessage: "",
});

export const authenticationSlice = createSlice({
  name: "segmentSelected",
  initialState: INITIAL_STATE,
  reducers: {
    updateUserObjectId: (
      state,
      action: PayloadAction<{ userObjectId: string }>
    ) => {
      localStorage.setItem(USER_OBJECT_ID, action.payload.userObjectId);
      return {
        ...state,
        userObjectId: action.payload.userObjectId,
      };
    },

    resetAuthentication: (state) => ({
      ...state,
      isLoggedIn: false,
      isPending: false,
      isPendingToken: false,
      isAuthError: false,
      isTokenError: false,
      errorMessage: "",
    }),

    setAuthErrorCode: (state, action: PayloadAction<number>) => ({
      ...state,
      authErrorCode: action.payload,
    }),

    setIsLoggedIn: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoggedIn: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, pendingStateReducer);

    builder.addCase(login.fulfilled, (state) => ({
      ...state,
      isLoggedIn: true,
      isPending: false,
      isAuthError: false,
      errorMessage: "",
    }));

    builder.addCase(login.rejected, (state, action) => ({
      ...state,
      isLoggedIn: false,
      isPending: false,
      isPendingToken: false,
      isAuthError: true,
      errorMessage: (action.payload as string) || "",
    }));

    builder.addCase(logout.pending, pendingStateReducer);

    builder.addCase(logout.fulfilled, (state) => ({
      ...state,
      isLoggedIn: false,
      isPending: false,
      isPendingToken: false,
      isAuthError: false,
      errorMessage: "",
    }));

    builder.addCase(logout.rejected, (state) => ({
      ...state,
      isPending: false,
      isPendingToken: false,
      isAuthError: true,
      errorMessage: "",
    }));

    builder.addCase(token.pending, pendingStateReducer);

    builder.addCase(token.fulfilled, (state) => ({
      ...state,
      isPendingToken: false,
      isPending: false,
      isTokenError: false,
      errorMessage: "",
    }));

    builder.addCase(token.rejected, (state, action) => ({
      ...state,
      isPendingToken: false,
      isPending: false,
      isTokenError: true,
      errorMessage: (action.payload as string) || "",
    }));
  },
});

// Action creators are generated for each case reducer function
export const {
  resetAuthentication,
  updateUserObjectId,
  setAuthErrorCode,
  setIsLoggedIn,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
