/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-dupe-else-if */
/* eslint-disable arrow-body-style */
import React from "react";
import {
  IBaggage,
  IFlightSegment,
} from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetails/SegmentTabs/Tabs/Flight/Flight.model";
import {
  TRIP_PRODUCT_AIR,
  UPCOMING_TRIP_DETAIL,
} from "../../../components/upcoming-trips-dashboard/data/constants";
import {
  composeBounds,
  getAllStopsDetails,
  getServiceProviders,
  getTotalTravelDuration,
  validateProviderLogoUrl,
} from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import DateUtils from "../../../utils/date.utils";
import { RootState } from "../../StoreHooks";
import { getProductsByType } from "./trip-detail-selectors";
import { IFlightBoundDetails } from "./trip-detail-selectors.model";

const getFlightBoundBasicDetails = (bound: IFlightSegment[]) => {
  try {
    const logo = validateProviderLogoUrl(bound[0].marketing.carrier.logo[0]);
    const departure =
      bound[0].departure.iataCode || bound[0].departure.cityName;
    const arrival =
      bound[bound.length - 1].arrival.iataCode || bound[0].arrival.cityName;
    const flightDurations = bound.map(
      (boundSegment: { duration: string }) => boundSegment.duration
    );
    const totalTravelDuration = getTotalTravelDuration(flightDurations);
    const numberOfStops = bound.length - 1;
    const type = bound[0].productType;
    const departureName = bound[0].departure.cityName;
    const arrivalName = bound[0].arrival.cityName;

    const getters = {
      getDepartureTime: (segment: IFlightSegment) =>
        segment?.departure?.dateTime,
      getArrivalTime: (segment: IFlightSegment) => segment?.arrival?.dateTime,
      getDepartureStation: (segment: IFlightSegment) =>
        segment?.departure?.airportName,
      getArrivalStation: (segment: IFlightSegment) =>
        segment?.arrival?.airportName,
      getLayoverLocation: (segment: IFlightSegment) =>
        segment?.arrival?.cityName,
    };

    const stopsIn = getAllStopsDetails(bound, getters);

    const serviceProvidersWithNumbers = getServiceProviders(
      bound,
      (segment: IFlightSegment) => segment?.marketing?.carrier?.name,
      (segment: IFlightSegment) => segment?.number
    );

    const departureDateTime = bound[0].departure.dateTime;
    const arrivalDateTime = bound[bound.length - 1].arrival.dateTime;

    return {
      logo,
      departure,
      arrival,
      totalTravelDuration,
      numberOfStops,
      stopsIn,
      serviceProvidersWithNumbers,
      departureDateTime,
      arrivalDateTime,
      getters,
      type,
      departureName,
      arrivalName,
    };
  } catch {
    return {};
  }
};

const getBaggages = (baggages: IBaggage[]) =>
  baggages?.map((baggage: IBaggage) => {
    if (!baggage?.description) {
      return "";
    }
    if (baggage.weight.amount && baggage.weight.unit) {
      return `${baggage?.description} (${baggage?.weight?.amount} ${baggage?.weight?.unit})`;
    }

    return `${baggage?.description}`;
  });

const getReservationInfo = (segment: IFlightSegment) => {
  const operatedBy = segment?.operating?.carrier?.name;
  const airCraftType = segment?.aircraft?.aircraftType || " - ";

  const header = {
    title: `${segment?.marketing?.carrier?.name}, ${segment?.number}`,
    subTitle: operatedBy
      ? `(${UPCOMING_TRIP_DETAIL.detailCard.operatedBy}${operatedBy})
${airCraftType}`
      : `${airCraftType}`,
  };

  const items = [
    {
      label: (
        <span>
          CO<sub>2</sub>
        </span>
      ),
      value: getCO2Emissions(segment?.aircraft?.co2Emission),
    },
    { label: "Seat", value: segment?.seats[0]?.number },
  ];

  return { header, items };
};

const getCO2Emissions = (emission: string) => {
  const emissionSplited = emission?.split(" ");
  const emissionAmount = Math.round(parseFloat(emissionSplited?.[0]));

  const emissionUnit = emissionSplited?.[1];

  return emissionAmount && emissionUnit
    ? `${emissionAmount} ${emissionUnit}`
    : " - ";
};

function getFlightSegmentExtendedDetails(flight: IFlightSegment) {
  return {
    serviceProvider: flight?.marketing?.carrier?.name,
    number: flight?.number,
    departure: flight?.departure,
    duration: flight?.duration,
    arrival: flight?.arrival,
    logo: flight?.operating?.carrier?.logo?.[0],
    serviceClass: flight?.bookingClass?.levelOfService,
    operatedBy: flight?.operating?.carrier?.name,
    carrierCode: flight?.carrierCode,
    airCraftType: flight?.aircraft?.aircraftType,
    emissions: getCO2Emissions(flight?.aircraft?.co2Emission),
    seats: flight?.seats[0]?.number,
    baggage: getBaggages(flight?.flightBaggages),
    meal: flight?.mealServices?.[0]?.label,
    reservationInfo: getReservationInfo(flight),
    confirmationNumber: flight?.confirmationNumber,
  };
}

export const createFlightDetails = (state: RootState) => {
  const segments = getProductsByType(state, TRIP_PRODUCT_AIR);
  const bounds = composeBounds(segments);

  return bounds.reduce((acc: any, bound: IFlightSegment[]) => {
    const basicDetails = getFlightBoundBasicDetails(bound);
    const extendedDetails = bound.reduce(
      (acc2: any, segment: IFlightSegment) => {
        const segmentExtendedDetail = getFlightSegmentExtendedDetails(segment);
        acc2.push(segmentExtendedDetail);
        return acc2;
      },
      []
    );
    const flightDetail = { basicDetails, extendedDetails };
    acc.push(flightDetail);
    return acc;
  }, []);
};

export const areFlightBoundsRoundTrip = (bounds: IFlightBoundDetails[]) => {
  const firstStation = bounds?.[0]?.extendedDetails?.[0]?.departure?.cityName;

  const lastBound = bounds?.[bounds.length - 1];
  const lastExtendedDetailsLength = lastBound?.extendedDetails?.length;
  const lastStation =
    lastBound?.extendedDetails?.[lastExtendedDetailsLength - 1]?.arrival
      ?.cityName;

  if (firstStation === lastStation) {
    return true;
  }
  return false;
};

export const getRoundtripFlightDetails = (state: RootState) => {
  const flights = createFlightDetails(state);
  const boundGroups: string[] = [];

  // Group flight bounds into roundtrips
  while (flights.length > 0) {
    const firstElem = flights?.[0];
    const secondElem = flights?.[1];

    if (areFlightBoundsRoundTrip([firstElem, secondElem])) {
      boundGroups.push(flights.splice(0, 2));
    } else {
      boundGroups.push(flights.splice(0, 1));
    }
  }

  return boundGroups;
};

export const getFlightDetailsGroupedByDate = (state: RootState) => {
  const boundGroups = getRoundtripFlightDetails(state);

  return boundGroups.reduce((acc: any, boundGroup: any) => {
    const departureDate = DateUtils.formatLongDate(
      DateUtils.getUTCDate(
        boundGroup?.[0]?.extendedDetails?.[0]?.departure?.dateTime
      )
    );

    acc[departureDate] = acc[departureDate] || [];
    acc[departureDate].push(boundGroup);

    return acc;
  }, {});
};

export const getInboundDateOfFlightBoundGroup = (
  boundGroup: [IFlightBoundDetails[]]
) => {
  try {
    const lastBound = boundGroup?.[boundGroup?.length - 1];

    if (lastBound?.length > 1) {
      const segments = lastBound?.[lastBound?.length - 1]?.extendedDetails;
      return segments?.[0]?.departure?.dateTime || null;
    }
  } catch {
    return null;
  }
  return null;
};
