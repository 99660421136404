import { TeamsUserCredential } from "@amadeus-cytric/cytric-teams-react-common-library";
import { ShortQueryParamsModel } from "../../../models/QueryParamsModel";

export default class ClasQueryGroups implements ShortQueryParamsModel {
  top = 3;

  credential: any;

  select = "id, displayName, jobTitle, userPrincipalName";

  scope = ["People.Read", "People.Read.All"];

  users = "users";

  constructor() {
    this.credential = new TeamsUserCredential();
  }
}
