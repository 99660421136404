/* eslint-disable react/react-in-jsx-scope */

import {
  GenericInfo,
  InfoImage,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { app } from "@microsoft/teams-js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import doLogout from "../../common/components/logout/logout-component";
import { useAppSelector } from "../../store/StoreHooks";
import {
  isAuthenticationErrorSelector,
  isLoggedInSelector,
} from "../../store/authentication/AuthenticationSelector";
import { isNotificationErrorSelector } from "../../store/notification-settings/NotificationSettingsSelector";
import { CYTRIC_EASY_LOGOUT } from "../../utils/constants";
import "./Settings.scss";
import SettingsOptions from "./components/SettingsOptions";

function Settings() {
  const { t } = useTranslation("translation");

  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );

  const isAuthError: boolean = useAppSelector((state) =>
    isAuthenticationErrorSelector(state)
  );

  const isNotificationError: boolean = useAppSelector((state) =>
    isNotificationErrorSelector(state)
  );

  const authError = {
    info: {
      title: t("messages.ERR_Generic"),
      detail: t("Settings.ERR_while_log_out"),
    },

    action: {
      text: t("App.Try_again"),
      command: doLogout,
    },
  };

  const settingsApiError = {
    info: {
      title: t("messages.ERR_Generic"),
      detail: t("Settings.ERR_while_fetching_data"),
    },
    action: {
      text: t("App.Try_again"),
      command: () => {
        window.location.reload();
      },
    },
  };

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((context: app.Context) => {
        // Was the logout workflow triggered from PersonalTab?
        // In that case subEntityId ="logout" is set (in the URL) AND
        // CYTRIC_EASY_LOGOUT was set as well
        const performLogout = localStorage.getItem(CYTRIC_EASY_LOGOUT);
        if (context?.page?.subPageId === "logout" && performLogout) {
          doLogout();
          // make sure logout is performed just once
          localStorage.removeItem(CYTRIC_EASY_LOGOUT);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthError) {
    return (
      <GenericInfo
        infoData={authError.info}
        action={authError.action}
        image={InfoImage.ERROR}
      />
    );
  }

  if (isNotificationError) {
    return (
      <GenericInfo
        infoData={settingsApiError.info}
        action={settingsApiError.action}
        image={InfoImage.ERROR}
      />
    );
  }

  if (isLoggedIn) {
    return <SettingsOptions />;
  }

  return null;
}

export default Settings;
