import { EmptyState } from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-expenses";
import { Flex } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../../../common/components/divider/Divider";
import HostSettings from "../../../../utils/host.settings";
import { ItemInterface } from "../TodoInterfaces";
import styles from "./TodoContent.module.scss";
import TodoItem from "./todo-item/TodoItem";

interface TodoContentPropsInterface {
  items: ItemInterface[];
  counts: number;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setDidMergeAction: React.Dispatch<React.SetStateAction<boolean>>;
  dispatchDialog: any;
}

function TodoContent({
  items,
  counts,
  openDialog,
  setDidMergeAction,
  dispatchDialog,
}: TodoContentPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.todo",
  });

  const itemsToShow = items.filter((item) => item.count !== 0);

  const emptyState = {
    image: `${HostSettings.getCDNIcons}no-tasks.svg`,
    title: t("emptyState.allDone"),
    message: t("emptyState.noTasks"),
  };

  return counts !== 0 ? (
    <ul className={styles["todo-list"]} data-testid="expense__todo-list--list">
      {itemsToShow.map((item, index) => (
        <li data-testid={`todo__list-item-action-${item.id}`} key={item.id}>
          <TodoItem
            item={item}
            openDialog={openDialog}
            setDidMergeAction={setDidMergeAction}
            dispatchDialog={dispatchDialog}
          />
          {index !== itemsToShow.length - 1 && <Divider />}
        </li>
      ))}
    </ul>
  ) : (
    <Flex hAlign="center" vAlign="center">
      <EmptyState
        image={emptyState.image}
        title={emptyState.title}
        message={emptyState.message}
      />
    </Flex>
  );
}

export default TodoContent;
