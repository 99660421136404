import returnKeepSession from "./KeepSessionFunctions";
import HostSettings from "./host.settings";

interface ActivityProvisional {
  activityMap: Map<string, any>;
  update(key: string, value: any): void;
  removeElementOrChildren(part: string): void;
  get(key: string, defaultParam?: any): any;
}

class ActivityClass implements ActivityProvisional {
  activityMap: Map<string, any> = new Map();

  constructor() {
    this.activityMap =
      ActivityClass.getLocalStorageInstance() || new Map<string, any>();
  }

  update(key: string, value: any): void {
    this.activityMap.set(key, value);

    ActivityClass.updateKeepSessionTime();
    localStorage.setItem("KeepUserActivityCache", this.toJSON());
  }

  removeElementOrChildren(part: string): void {
    Array.from(this.activityMap.keys()).forEach((key) => {
      if (key.startsWith(part)) {
        this.activityMap.delete(key);
      }
    });

    localStorage.setItem("KeepUserActivityCache", this.toJSON());
  }

  get(key: string, defaultParam?: any): any {
    if (defaultParam) {
      return this.activityMap.get(key) || defaultParam;
    }
    return this.activityMap.get(key);
  }

  toJSON(): string {
    const obj: {
      [key: string]: any;
    } = {};
    this.activityMap.forEach((value, key) => {
      obj[key] = value;
    });
    return JSON.stringify(obj);
  }

  static fromJSON(json: string): Map<string, any> {
    const obj = JSON.parse(json);
    const localStorageMap = new Map<string, any>();
    Object.keys(obj).forEach((key) => {
      localStorageMap.set(key, obj[key]);
    });
    return localStorageMap;
  }

  static updateKeepSessionTime() {
    const keepSessionObject = returnKeepSession();
    if (keepSessionObject) {
      keepSessionObject.expiration = JSON.stringify(
        JSON.stringify(
          new Date().getTime() +
            parseInt(HostSettings.getKeepSessionTimeout, 10)
        )
      );
      localStorage.setItem(
        "KeepSessionCache",
        JSON.stringify(keepSessionObject)
      );
    } else {
      const newKeepSessionObject = {
        expiration: JSON.stringify(
          new Date().getTime() +
            parseInt(HostSettings.getKeepSessionTimeout, 10)
        ),
      };
      localStorage.setItem(
        "KeepSessionCache",
        JSON.stringify(newKeepSessionObject)
      );
    }
  }

  static handleTimeOut = () => {
    const keepSessionObject = returnKeepSession();
    if (keepSessionObject) {
      const lastUpdate = keepSessionObject.expiration;
      if (lastUpdate !== "" && lastUpdate !== null) {
        const now = new Date();
        if (now.getTime() > JSON.parse(lastUpdate)) {
          localStorage.removeItem("KeepUserActivityCache");
        } else {
          return true;
        }
      }
    }
    return false;
  };

  static getLocalStorageInstance(): Map<string, any> | null {
    const keepSessionObject = returnKeepSession();
    const keepUserActivityInfo = localStorage.getItem("KeepUserActivityCache");
    let keepUserActivityObject: Map<string, any> | null;

    if (
      keepUserActivityInfo &&
      keepUserActivityInfo !== "{}" &&
      keepSessionObject
    ) {
      if (this.handleTimeOut()) {
        keepUserActivityObject = ActivityClass.fromJSON(keepUserActivityInfo);
      } else {
        keepUserActivityObject = null;
      }
    } else {
      keepUserActivityObject = null;
    }
    return keepUserActivityObject;
  }
}

export default ActivityClass;
