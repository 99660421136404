/* eslint-disable react/react-in-jsx-scope */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  Box,
  Breadcrumb,
  Button,
  ChevronDownIcon,
  ChevronEndIcon,
  Flex,
  MenuButton,
  ShareGenericIcon,
  Text,
} from "@fluentui/react-northstar";
import { BotUrlDialogInfo, app, dialog } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Responsive from "../../../../common/components/responsive/Responsive";
import { useAppDispatch, useAppSelector } from "../../../../store/StoreHooks";
import { updateTravelTripId } from "../../../../store/travel-trip-id/TravelTripIdSlice";
import GetAppRoot from "../../../../utils/GetAppRoot";
import HostSettings from "../../../../utils/host.settings";
import ConfirmationPageButton from "../../ConfirmationPageButton";
import { ITrip } from "../../UpcomingTrips/UpcomingTrips.model";
import { CancelPopUpProvider } from "../../cancel/context/CancelPopUpContext";
import {
  composeTripName,
  extractTripId,
  getSubEntityId,
} from "../../utils/trip-utils";
import Styles from "./Breadcrumb.module.scss";

function TripBreadcrumb({ pastTrip }: any) {
  const trip = useAppSelector(
    (state: { tripDetail: { trip: ITrip } }) => state.tripDetail.trip
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const breadcrumbClickHandler = () => {
    localStorage.removeItem("KeepSessionCache");
    localStorage.removeItem("breadCrumbInformation");
    localStorage.setItem("tripDetailTab", "");
    // when arriving from the chat if we do a redirection to travel the context still has the trip id the first time
    app.getContext().then((context: app.Context) => {
      let deepLink = "";
      let shouldUseDeeplink = false;
      if (context) {
        const subEntityId = getSubEntityId(context?.page?.subPageId);
        if (subEntityId) {
          const tripId = extractTripId(subEntityId);
          dispatch(updateTravelTripId({ tripId }));
          if (
            subEntityId.includes("segmentType") ||
            subEntityId.includes("notifyCloseCollaborators")
          ) {
            deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel`;
            shouldUseDeeplink = true;
          }
        }
      }
      if (shouldUseDeeplink) {
        app.openLink(deepLink);
      } else {
        history.push("/travel");
      }
    });
  };

  const tripName = composeTripName(trip, t("tripDetails.tripTo"));
  const taskModuleUrl = "share-join";

  const onShowShareTrip = (): void => {
    if (trip) {
      telemetryService.trackEvent({ name: "travel-tripDetails-sharePopup" });

      const taskModuleInfo: BotUrlDialogInfo = {
        title: t("upcoming_trips_dashboard.upcomingTrips.tripCardShareTitle"),
        url: `${GetAppRoot()}/index.html#/${taskModuleUrl}?origin=sharetrip&tripId=${
          trip.id
        }`,
        completionBotId: HostSettings.getBotId,
        size: {
          width: 600,
          height: 570,
        },
      };
      dialog.url.bot.open(taskModuleInfo);
    }
  };

  const breadcrumbMobile = (
    <div className={Styles.breadcrumb__mobileMenuWrapper}>
      <Text
        content={tripName}
        aria-label={tripName}
        className={Styles.breadcrumb__mobileMenu}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.currentPage,
        })}
      />
      <ChevronDownIcon
        size="small"
        className={Styles.breadcrumb__divider}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.path,
        })}
      />
    </div>
  );

  return (
    <Box
      className={Styles.breadcrumb}
      styles={(theme) => ({
        background: theme.theme.siteVariables.tripBreadcrumb?.background,
        borderBottomColor:
          theme.theme.siteVariables.tripBreadcrumb?.mobileBorderBottom,
      })}
    >
      <Responsive forMinSize="desktopSmall">
        <div className={Styles.desktop}>
          <Breadcrumb aria-label="breadcrumb">
            <Breadcrumb.Item
              onClick={breadcrumbClickHandler}
              className={Styles["breadcrumb__my-trips"]}
              styles={(theme) => ({
                color: theme.theme.siteVariables.tripBreadcrumb?.path,
              })}
            >
              <Breadcrumb.Link
                title={t("tripDetails.optionBreadcrumb1")}
                className={Styles.link}
              >
                {t(
                  "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage"
                )}
              </Breadcrumb.Link>
            </Breadcrumb.Item>
            <Breadcrumb.Divider>
              <ChevronEndIcon
                size="small"
                className={Styles.breadcrumb__divider}
                styles={(theme) => ({
                  color: theme.theme.siteVariables.tripBreadcrumb?.path,
                })}
              />
            </Breadcrumb.Divider>
            <Breadcrumb.Item
              style={{ gap: "4px" }}
              className={Styles.breadcrumb__trip}
              data-testid="breadcrumb_top"
              styles={(theme) => ({
                color: theme.theme.siteVariables.tripBreadcrumb?.currentPage,
              })}
            >
              <div title={`${tripName} ${t("tripDetails.optionBreadcrumb2")}`}>
                <span
                  aria-hidden
                  className={Styles["breadcrumb__destination-name"]}
                  data-testid="breadcrumb-trip-name"
                >
                  {tripName}
                </span>
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Responsive>
      <Responsive forMaxSize="tablet">
        <MenuButton
          className={Styles.mobile}
          trigger={breadcrumbMobile}
          menu={[
            t("upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage"),
          ]}
          on="click"
          onMenuItemClick={breadcrumbClickHandler}
        />
      </Responsive>

      {!pastTrip && (
        <Flex className={Styles.actions}>
          <Button
            data-testid="shareButtonTrip"
            content={t(
              "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.share"
            )}
            text
            className={Styles.actions__shareTrip}
            onClick={onShowShareTrip}
            icon={<ShareGenericIcon />}
            style={{ height: "1rem" }}
          />
          <CancelPopUpProvider>
            <ConfirmationPageButton
              tripId={trip.id}
              tripName={tripName}
              showText
            />
          </CancelPopUpProvider>
        </Flex>
      )}
    </Box>
  );
}

export default TripBreadcrumb;
