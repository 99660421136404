/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {
  Button,
  CloseIcon,
  Flex,
  Image,
  Text,
} from "@fluentui/react-northstar";
import i18next from "../../../../i18n/i18n";
import Store from "../../../../store/Store";
import { useAppSelector } from "../../../../store/StoreHooks";
import { setNotificationSentConfirmation } from "../../../../store/collaborators/close-collaborators/CloseCollaboratorsSlice";
import HostSettings from "../../../../utils/host.settings";
import stylesCloseCollaborators from "../../common/styles/close-collaborators.module.scss";

function CloseCollaboratorsConfirmation({
  themeName,
  fetchCloseCollaborators,
  fromDialog = false,
}: any) {
  const { isError, fetchTimoutId } = useAppSelector(
    (state) => state.closeCollaborators.notificationSentConfirmation
  );

  const confirmationMessage = i18next.t(
    "closeCollaborators.confirmationMessage"
  );
  const errorMessageTitle = i18next.t("closeCollaborators.errorMessageTitle");
  const errorMessageSubtitle = i18next.t(
    "closeCollaborators.errorMessageSubtitle"
  );
  const messageAriaLabel = i18next.t("closeCollaborators.messageAriaLabel");

  return (
    <Flex
      column
      className={stylesCloseCollaborators["confirmation-container"]}
      aria-label={
        !isError
          ? `${confirmationMessage} ${messageAriaLabel}`
          : `${errorMessageTitle} ${errorMessageSubtitle} ${messageAriaLabel}`
      }
      role="alert"
    >
      {!fromDialog && ( // If fromDialog is true, the confirmation dialog should not auto-close
        <Flex hAlign="end">
          <Button
            icon={<CloseIcon />}
            text
            iconOnly
            title={i18next.t("closeCollaborators.closeButton")}
            className={
              stylesCloseCollaborators["confirmation-container__button"]
            }
            onClick={() => {
              if (!isError) {
                Store.dispatch(fetchCloseCollaborators);
                clearTimeout(fetchTimoutId);
              }

              Store.dispatch(
                setNotificationSentConfirmation({
                  isVisible: false,
                })
              );
            }}
            data-testid="close-button"
          />
        </Flex>
      )}
      <Flex column hAlign="center" data-testid="title">
        <Image
          src={
            !isError
              ? `${HostSettings.getCDNIcons}success.svg`
              : `${HostSettings.getCDNIcons}request-error-${themeName}.svg`
          }
          className={
            !isError
              ? stylesCloseCollaborators[
                  "confirmation-container__confirmation-image"
                ]
              : stylesCloseCollaborators["confirmation-container__error-image"]
          }
        />
        <Flex column aria-hidden>
          <Text
            content={!isError ? confirmationMessage : errorMessageTitle}
            className={
              stylesCloseCollaborators["confirmation-container__title"]
            }
          />
          {isError && (
            <Text
              content={errorMessageSubtitle}
              className={
                stylesCloseCollaborators["confirmation-container__subtitle"]
              }
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CloseCollaboratorsConfirmation;
