import {
  Card,
  cardsContainerBehavior,
  Divider,
  Flex,
  Grid,
  Skeleton,
} from "@fluentui/react-northstar";
import Styles from "../UpcomingTrips.module.scss";

const firstCard = (key: number) => (
  <Card fluid compact key={key} className={Styles.skeletonFirstCard}>
    <Card.Body>
      <Skeleton animation="pulse">
        <Flex column gap="gap.small" style={{ overflow: "hidden" }}>
          <Flex className={Styles.skeletonShape}>
            <Skeleton.Shape height="80px" width="80px" />
          </Flex>

          <div className={Styles.skeletonCardBody}>
            <Skeleton animation="pulse">
              <Flex className={Styles.skeletonCardElementFirstCard}>
                <Skeleton.Shape
                  className={Styles.skeletonCardRoundShape}
                  width="125px"
                  height="20px"
                />
              </Flex>
              <Flex className={Styles.skeletonCardElementFirstCard}>
                <Skeleton.Shape
                  className={Styles.skeletonCardRoundShape}
                  width="150px"
                  height="16px"
                />
              </Flex>

              <Flex gap="gap.small" className={Styles.skeletonCardElement}>
                <Flex column gap="gap.small" style={{ width: "100%" }}>
                  <Flex className={Styles.skeletonCardElementFirstCard}>
                    <Skeleton.Shape
                      className={Styles.skeletonCardRoundShape}
                      width="100%"
                      height="16px"
                    />
                  </Flex>
                  <Flex className={Styles.skeletonCardElementFirstCard}>
                    <Skeleton.Shape
                      className={Styles.skeletonCardRoundShape}
                      width="100%"
                      height="16px"
                    />
                  </Flex>
                </Flex>
                <Flex column gap="gap.small" style={{ width: "100%" }}>
                  <Flex className={Styles.skeletonCardElementFirstCard}>
                    <Skeleton.Shape
                      className={Styles.skeletonCardRoundShape}
                      width="100%"
                      height="16px"
                    />
                  </Flex>
                  <Flex className={Styles.skeletonCardElementFirstCard}>
                    <Skeleton.Shape
                      className={Styles.skeletonCardRoundShape}
                      width="100%"
                      height="16px"
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex className={Styles.skeletonCardElementFirstCard}>
                <Skeleton.Shape
                  className={Styles.skeletonCardRoundShape}
                  width="210px"
                  height="16px"
                />
              </Flex>
            </Skeleton>
          </div>
        </Flex>
      </Skeleton>
    </Card.Body>
  </Card>
);

const card = (key: number) => (
  <Card fluid compact key={key}>
    <Card.Body>
      <Skeleton animation="pulse">
        <Flex column gap="gap.small" style={{ overflow: "hidden" }}>
          <Skeleton.Shape height="180px" width="500px" />

          <div className={Styles.skeletonCardBody}>
            <Skeleton animation="pulse">
              <Flex className={Styles.skeletonCardElement}>
                <Skeleton.Shape width="120px" height="31px" />
              </Flex>
              <Flex className={Styles.skeletonCardElement}>
                <Skeleton.Line
                  className={Styles.skeletonCardLine}
                  width="100%"
                  height="12px"
                />
              </Flex>
              <Divider />
              <Flex gap="gap.medium" className={Styles.skeletonCardElement}>
                <Skeleton.Line
                  className={Styles.skeletonCardLinePosition}
                  width="20%"
                  height="12px"
                />

                <Skeleton.Button circular size="small" />
                <Skeleton.Button circular size="small" />
                <Skeleton.Button circular size="small" />
                <Skeleton.Button circular size="small" />
              </Flex>
            </Skeleton>
          </div>
        </Flex>
      </Skeleton>
    </Card.Body>
  </Card>
);

const loopCards = () => {
  const arrayCards = [];

  for (let i = 0; i < 6; i += 1) {
    if (i === 0) {
      arrayCards.push(firstCard(i));
    } else {
      arrayCards.push(card(i));
    }
  }
  return arrayCards;
};

export default function CardSkeleton() {
  return (
    <div className={Styles.main_container}>
      <Grid
        accessibility={cardsContainerBehavior}
        className={Styles.grid_container}
      >
        {loopCards()}
      </Grid>
    </div>
  );
}
