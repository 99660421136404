import { app } from "@microsoft/teams-js";
import { createSlice } from "@reduxjs/toolkit";
import HttpData from "../../common/models/HttpData";
import HostSettings from "../../utils/host.settings";

const CONTENT_TYPE = "application/vnd.amadeus+json";
const APP_KEY = "cytric-easy-teams-app";
const LANG_SEPARATOR = "|";
const DEFAULT_LANGUAGE = "en-us";
const TOKEN_KEY = "insights-key";

const getDefaultLanguageByCountry = (
  acceptedLanguages: string[],
  language: string
): string => {
  // language not included -> return default
  let result = DEFAULT_LANGUAGE;
  // language starts with any of accepted languages -> return first associated
  const filteredLanguages = acceptedLanguages.filter(
    (current) =>
      language &&
      language.length >= 2 &&
      current.startsWith(language.substring(0, 2))
  );
  if (filteredLanguages && filteredLanguages.length > 0) {
    result = filteredLanguages[0];
  }
  return result;
};

const getLanguage = (lang: string) => {
  let result = DEFAULT_LANGUAGE;
  if (
    lang &&
    lang !== "" &&
    HostSettings.getAcceptedLanguages !== "" &&
    HostSettings.getDefaultLanguage !== ""
  ) {
    const language = lang.toLowerCase();
    const acceptedLanguages =
      HostSettings.getAcceptedLanguages.split(LANG_SEPARATOR);
    // Language inside accepted languages -> return it
    if (acceptedLanguages.indexOf(language) >= 0) {
      result = language;
    } else {
      result = getDefaultLanguageByCountry(acceptedLanguages, language);
    }
  }

  // set language
  return result;
};

const getLocale = (lang: string) => {
  let result = "EN";
  if (lang && lang.length >= 2) {
    result = lang.substring(0, 2).toUpperCase();
  }
  return result;
};

const getHttpData = (context?: app.Context) => {
  const result = {
    acceptLanguage: getLanguage(context?.app?.locale || ""),
    apiKey: HostSettings.getApiKey,
    appKey: APP_KEY,
    aid: context?.user?.id,
    tid: context?.user?.tenant?.id,
    contentType: CONTENT_TYPE,
  } as HttpData;
  return result;
};

const initialState = {
  locale: "EN",
  httpData: {} as HttpData,
  context: {} as app.Context,
  isHttpDataFilled: false,
};

const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    resetAppSettings: (state) => {
      localStorage.removeItem(TOKEN_KEY);
      return {
        ...state,
        ...initialState,
      };
    },
    setContext: (state, action) => ({
      ...state,
      context: action.payload,
    }),
    setLocale: (state, action) => ({
      ...state,
      locale: getLocale(action.payload?.app?.locale),
    }),
    setHttpData: (state, action) => {
      const data = getHttpData(action.payload);
      localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
      return {
        ...state,
        httpData: data,
        isHttpDataFilled: true,
      };
    },
  },
});

export const appSettingsActions = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
