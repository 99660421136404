import { Box } from "@fluentui/react-northstar";
import styles from "./Divider.module.scss";

function Divider() {
  return (
    <Box
      className={styles.divider}
      styles={(theme) => ({
        background: theme.theme.siteVariables.colorScheme?.default.border,
      })}
    />
  );
}

export default Divider;
