/* eslint-disable no-debugger */
import { Button, Checkbox, Flex, FlexItem } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import ShareJoinFormStyles from "../../../../../ShareJoin.module.scss";

function EventsButtons({
  handleClick,
  closeTask,
  themeName,
  disableShare,
  loadingShare,
  segmentsLength,
  device,
  classTabletDevice,
  postOnChannel,
  setPostOnChannel,
}: any) {
  const { t } = useTranslation();

  const getComponentBackground = (theme: any) => {
    if (device === "mobile" || device === "tablet") {
      return theme.theme.siteVariables.colorScheme?.default.background3;
    }
    return theme.theme.siteVariables.colorScheme?.default.background;
  };

  return (
    <Flex
      column={device === "mobile" || device === "tablet"}
      space="between"
      gap="gap.small"
      className={[
        themeName === "default"
          ? ShareJoinFormStyles.white
          : ShareJoinFormStyles.dark,
        ShareJoinFormStyles.actions,
        classTabletDevice,
        device === "mobile" || device === "tablet"
          ? ShareJoinFormStyles.eventsActionsMobile
          : ShareJoinFormStyles.eventsActions,
      ].join(" ")}
    >
      <FlexItem
        align="start"
        styles={(theme) => ({
          backgroundColor: getComponentBackground(theme),
        })}
      >
        <Checkbox
          label={t("events.taskModuleEvents.postCheckLabel")}
          checked={postOnChannel}
          onChange={() => setPostOnChannel(!postOnChannel)}
          className={
            device === "mobile" || device === "tablet"
              ? ShareJoinFormStyles.checkboxStyles
              : ""
          }
        />
      </FlexItem>

      <Flex
        gap="gap.small"
        className={
          device === "mobile" || device === "tablet"
            ? ShareJoinFormStyles.buttonsStyles
            : ""
        }
      >
        <Button
          content={t("events.taskModuleEvents.backButton")}
          flat
          onClick={() => closeTask()}
          data-testid="closeButtonShareTrip"
          tinted={device === "tablet" || device === "mobile"}
          fluid={device === "mobile"}
          className={ShareJoinFormStyles.buttons}
        />

        <Button
          data-testid="addTripButton"
          flat
          fluid={device === "mobile"}
          disabled={disableShare}
          loading={loadingShare}
          aria-label={
            disableShare
              ? t("events.taskModuleEvents.addButtonDisabled")
              : t("events.taskModuleEvents.addButtonEnabled")
          }
          primary
          onClick={() => handleClick()}
          className={ShareJoinFormStyles.buttons}
        >
          {t("events.taskModuleEvents.addButtonEnabled")}{" "}
          {segmentsLength && segmentsLength > 0 ? `(${segmentsLength})` : ""}
        </Button>
      </Flex>
    </Flex>
  );
}

export default EventsButtons;
