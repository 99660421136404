import { Text } from "@fluentui/react-northstar";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ProductCar from "../../../../../common/models/ProductCar";
import { FormattedDate } from "../../../../../helpers/TripHelperV1";
import {
  IPickUpDropOff,
  IProductV1,
} from "../../../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import { useDispatch } from "../../../context/ShareJoinContext";
import { types } from "../../../context/ShareJoinReducer";
import SegmentWrapper from "../segments/SegmentWrapper";

interface CarSegmentContentProps {
  carSegment: IProductV1;
  topDate: FormattedDate | null;
  id: number;
}

function getDateTime(pickupDropoff: IPickUpDropOff | undefined): string {
  return pickupDropoff?.localDateTime ?? "";
}

function getLocationName(pickupDropoff: IPickUpDropOff | undefined): string {
  return pickupDropoff?.location.name ?? "";
}

function getLocationIataCode(
  pickupDropoff: IPickUpDropOff | undefined
): string {
  return pickupDropoff?.location.iataCode ?? "";
}

function getCountryCode(pickupDropoff: IPickUpDropOff | undefined): string {
  return pickupDropoff?.location.country_codes
    ? pickupDropoff?.location.country_codes[0] ?? ""
    : "";
}

function getLocationLatitude(
  pickupDropoff: IPickUpDropOff | undefined
): number {
  return pickupDropoff?.location.geoCode
    ? pickupDropoff?.location.geoCode.latitude ?? 0
    : 0;
}

function getLocationLongitude(
  pickupDropoff: IPickUpDropOff | undefined
): number {
  return pickupDropoff?.location.geoCode
    ? pickupDropoff?.location.geoCode.longitude ?? 0
    : 0;
}

function CarSegmentContentV1(props: CarSegmentContentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { carSegment, topDate } = props;
  const { pickup, dropoff, serviceProviderName, id } = carSegment;
  let range;
  if (topDate) {
    // eslint-disable-next-line prefer-destructuring
    range = topDate.range;
  }

  const header = <Text weight="bold">{serviceProviderName}</Text>;

  function GetPickUpInfo() {
    return `${t("shareTrip.adaptiveCard.pickUp")} ${pickup?.location.name}`;
  }

  function GetDropOffInfo() {
    return `${t("shareTrip.adaptiveCard.dropOff")} ${dropoff?.location.name}`;
  }

  const content = (
    <div>
      <Text>{GetPickUpInfo()}</Text>
      <br />
      <Text>{GetDropOffInfo()}</Text>
    </div>
  );

  const productToShare: ProductCar = {
    productType: "car",
    pickup: {
      dateTime: getDateTime(pickup),
      location: {
        name: getLocationName(pickup),
        iataCode: getLocationIataCode(pickup),
        countryCode: getCountryCode(pickup),
        countryName: getCountryCode(pickup),
        cityName: "",
        address: {
          lines: [],
          cityName: "",
          postalCode: "",
          countryCode: "",
        },
        geoCode: {
          latitude: getLocationLatitude(pickup),
          longitude: getLocationLongitude(pickup),
        },
      },
    },
    dropoff: {
      dateTime: getDateTime(dropoff),
      location: {
        name: getLocationName(dropoff),
        iataCode: getLocationIataCode(dropoff),
        countryCode: getCountryCode(dropoff),
        countryName: getCountryCode(dropoff),
        cityName: "",
        address: {
          lines: [],
          cityName: "",
          postalCode: "",
          countryCode: "",
        },
        geoCode: {
          latitude: getLocationLatitude(dropoff),
          longitude: getLocationLongitude(dropoff),
        },
      },
    },
    serviceProviderName: serviceProviderName ?? "",
  };

  useEffect(() => {
    dispatch({
      types: types.setTrip,
      payload: productToShare,
    });
    dispatch({
      type: types.addSegment,
      payload: { Trip_Product_Car: productToShare, id },
    });
    dispatch({
      type: types.setInitialSegments,
      payload: { Trip_Product_Car: productToShare, id },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <SegmentWrapper segment={carSegment} topDate={range} segmentType="car">
      {header}
      {content}
    </SegmentWrapper>
  );
}

export default CarSegmentContentV1;
