import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../common/models/redirection/RedirectionTypeEnum";
import { selectFeatureToggle } from "../../../../../store/feature-toggles/FeatureTogglesSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/StoreHooks";
import { FeatureToggleDefinition } from "../../../../../utils/constants";
import HostSettings from "../../../../../utils/host.settings";
import styles from "./BookButtons.module.scss";
import BookRedirection from "./BookRedirection";

export default function BookFlightButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const featureTravelTab = useAppSelector((state) =>
    selectFeatureToggle(state, FeatureToggleDefinition.deprecateCytricTab.id)
  );

  const flightButton = t(
    "upcoming_trips_dashboard.upcomingTrips.newBook.Flight"
  );

  const bookFlight = () => {
    telemetryService.trackEvent({ name: "travel-trip-bookFlight" });

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.FLIGHT_CARD,
      stopOnStartPage: true,
    };
    if (featureTravelTab?.isActive) {
      BookRedirection(t, dispatch, history, redirectionInput);
    } else {
      const urlEncodedDeepLinkData = {
        subEntityId: JSON.stringify(redirectionInput),
      };
      const deepLink = `https://teams.microsoft.com/l/entity/${
        HostSettings.teamsAppId
      }/cytricweb?context=${encodeURI(JSON.stringify(urlEncodedDeepLinkData))}`;

      app.openLink(deepLink);
    }
  };
  return (
    <Button
      aria-label={flightButton}
      className={styles.buttons}
      content={flightButton}
      data-testid="button-card-book-flight"
      flat
      name={flightButton}
      onClick={bookFlight}
      role="button"
      tabIndex={0}
    />
  );
}
