import { CloseIcon, Flex, Image, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { enterEventHandler } from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import HostSettings from "../../../utils/host.settings";
import Responsive from "../responsive/Responsive";
import { IDialogHeaderProps } from "./DialogHeader.model";
import Styles from "./DialogHeader.module.scss";

function DialogHeader({ dispatchDialog, headerTitle }: IDialogHeaderProps) {
  const { t } = useTranslation();

  const headerForDesktop = (
    <Responsive forMinSize="tablet">
      <div className={Styles["dialog-header"]}>
        <Flex>
          <Image
            src={`${HostSettings.getCDNIcons}cytric-easy-app-logo.svg`}
            alt="Cytric logo"
            className={Styles["dialog-header__logo"]}
            aria-hidden
          />
          <div className={Styles["dialog-header__title-container"]}>
            <Text
              as="div"
              className={Styles["dialog-header__title-container--title"]}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground,
              })}
            >
              {t("tripDetails.dialogHeader.cytricEasy")}
            </Text>
            <Text
              as="div"
              className={Styles["dialog-header__title-container--description"]}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground2,
              })}
              data-testid="dialog-header-title"
            >
              {headerTitle}
            </Text>
          </div>
        </Flex>
        <div className={Styles["dialog-header__close-button-container"]}>
          <CloseIcon
            data-testid="close-icon"
            onClick={() => dispatchDialog()}
            aria-label={t("tripDetails.dialogHeader.accessibleClose")}
            tabIndex={0}
            onKeyDown={enterEventHandler}
            styles={(theme) => ({
              color: theme.theme.siteVariables?.toDoList?.dialog?.closeIcon,
            })}
          />
        </div>
      </div>
    </Responsive>
  );

  const headerForMobile = (
    <Responsive forRange="mobile">
      <div className={Styles["dialog-header"]}>
        <CloseIcon
          onClick={dispatchDialog}
          aria-label={t("tripDetails.dialogHeader.accessibleClose")}
          styles={(theme) => ({
            color: theme.theme.siteVariables?.dialogHeader?.mainContent,
          })}
        />
        <div className={Styles["dialog-header__main-information"]}>
          <Text
            className={Styles["dialog-header__main-information--title"]}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground,
            })}
          >
            {t("tripDetails.dialogHeader.cytricEasy")}
          </Text>
          <Text
            className={Styles["dialog-header__main-information--description"]}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground2,
            })}
          >
            {headerTitle}
          </Text>
        </div>
      </div>
    </Responsive>
  );

  return (
    <>
      {headerForMobile}
      {headerForDesktop}
    </>
  );
}

export default DialogHeader;
