import { Image } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  handleDestinationImageError,
  validateDestinationImageUrl,
} from "../../../utils/trip-utils";
import { IDestinationImagePropsV1 } from "./DestinationImage.model";
import Styles from "./DestinationImage.module.scss";

function DestinationImage({ trip }: IDestinationImagePropsV1) {
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.upcomingTrips",
  });

  return (
    <div className={Styles.container}>
      <Image
        alt={`${t("altTextTripPhoto")}  ${trip.description}`}
        className={Styles.container__image}
        src={validateDestinationImageUrl(trip.media[0]?.href)}
        data-testid="destination-image"
        onError={handleDestinationImageError}
      />
    </div>
  );
}

export default DestinationImage;
