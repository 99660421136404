interface SegmentsDataModel {
  segmentType: string;
  segmentId: string;
}

export default function getSegmentsData(segments: any) {
  const segmentsData: SegmentsDataModel[] = [];
  if (segments) {
    segments.forEach((segment: any) => {
      if (segment.Trip_Product_Air) {
        segmentsData.push({
          segmentType: "FLIGHT",
          segmentId: segment.id,
        });
      }
      if (segment.Trip_Product_Hotel) {
        segmentsData.push({
          segmentType: "HOTEL",
          segmentId: segment.id,
        });
      }
      if (segment.Trip_Product_Car) {
        segmentsData.push({
          segmentType: "CAR",
          segmentId: segment.id,
        });
      }
      if (segment.Trip_Product_Train) {
        segmentsData.push({
          segmentType: "RAIL",
          segmentId: segment.id,
        });
      }
    });
  }

  return segmentsData;
}
