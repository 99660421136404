import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { app } from "@microsoft/teams-js";
import FeatureToggle from "../../../../../common/models/FeatureToggle";
import RedirectionTypeEnum from "../../../../../common/models/redirection/RedirectionTypeEnum";
import RedirectionService from "../../../../../services/redirection/RedirectionService";
import HostSettings from "../../../../../utils/host.settings";
import { isValidUrlAndDomain } from "../../../../../utils/validation.utils";
import { BookingTargets } from "../model/Permissions";
import { isPunchOut } from "../PunchOutLogic";
import BookRedirection from "./BookRedirection";

async function BookTrain(
  value: string,
  trainPermissions: BookingTargets[],
  toggle: FeatureToggle | undefined,
  dispatch: any,
  history: any,
  t: any,
  featureTravel: FeatureToggle | undefined,
  val?: string
) {
  telemetryService.trackEvent({ name: "travel-trip-bookTrain" });
  let param = "";

  let punckOutElement = false;

  if (val) {
    param = val;
    punckOutElement = isPunchOut(val, toggle);
  }
  if (value !== "") {
    param = value;
    punckOutElement = isPunchOut(value, toggle);
  } else if (trainPermissions.length === 1) {
    const company = trainPermissions[0].type;
    param = company;
    punckOutElement = isPunchOut(company, toggle);
  }

  let deeplinkData: any = {};

  if (param === "TRAIN") {
    deeplinkData = {
      segmentType: RedirectionTypeEnum.RAIL,
    };
  } else {
    deeplinkData = {
      segmentType: RedirectionTypeEnum.RAIL_CARD,
      railCompany: param,
    };
  }

  if (punckOutElement === true) {
    const urlObject = await RedirectionService.getCytricUrl(deeplinkData);
    if (urlObject?.data?.data?.cytricUrl) {
      const url = urlObject.data.data.cytricUrl as string;
      if (isValidUrlAndDomain(url)) {
        window.open(url, "_blank", "noopener noreferrer");
      }
    }
  } else if (featureTravel?.isActive) {
    BookRedirection(t, dispatch, history, deeplinkData);
  } else {
    const urlEncodedDeepLinkData = {
      subEntityId: JSON.stringify(deeplinkData),
    };

    const deepLink = `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/cytricweb?context=${encodeURI(JSON.stringify(urlEncodedDeepLinkData))}`;

    app.openLink(deepLink);
  }
}

export default BookTrain;
