import { Image, Text } from "@fluentui/react-northstar";
import { IStepContent } from "./StepContent.model";
import Styles from "./StepContent.module.scss";

function StepContent({ title, content, imageSrc }: IStepContent) {
  return (
    <div className={Styles["tooltip-container"]}>
      {imageSrc && (
        <Image src={imageSrc} className={Styles["tooltip-container__image"]} />
      )}
      <div className={Styles["tooltip-content"]}>
        <Text as="div" className={Styles["tooltip-content__title"]}>
          {title}
        </Text>
        <Text as="p" className={Styles["tooltip-content__description"]}>
          {content}
        </Text>
      </div>
    </div>
  );
}

export default StepContent;
