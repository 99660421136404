import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  EmptyStatePastTrips,
  ErrorPastTrip,
  PastTripsList,
  PastTripsSkeleton,
} from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-travel";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { useEffect, useState } from "react";
import RedirectionTypeEnum from "../../../../common/models/redirection/RedirectionTypeEnum";
import { useAppSelector } from "../../../../store/StoreHooks";
import { selectFeatureToggle } from "../../../../store/feature-toggles/FeatureTogglesSelector";
import { FeatureToggleDefinition } from "../../../../utils/constants";
import GetUrlCytricNewTab from "../../utils/GetUrlCytricNewTab";
import getPastTrips from "../CardBookTripButtons/services/getPastTrips";

interface PastTripProps {
  setPastLength: React.Dispatch<React.SetStateAction<undefined>>;
  isSidebarHidden: boolean;
  themeString: string;
}
function PastTripsComponent({
  setPastLength,
  isSidebarHidden,
  themeString,
}: PastTripProps) {
  const [loadingPastTrips, setLoadingPastTrips] = useState(true);
  const [pastTrips, setPastTrips] = useState([]);

  const [pastTripsError, setPastTripsError] = useState(false);

  const featureTooglePastTrip = useAppSelector((stateKeepSession: any) =>
    selectFeatureToggle(stateKeepSession, FeatureToggleDefinition.PastTrips.id)
  );

  const getLinkHistory = () => {
    GetUrlCytricNewTab(RedirectionTypeEnum.PAST_TRIPS);
    telemetryService.trackEvent({ name: "travel-pastTrips-historyLink" });
  };

  useEffect(() => {
    setLoadingPastTrips(true);
    if (featureTooglePastTrip && featureTooglePastTrip.status === "true") {
      getPastTrips()
        .then(async (dataPastTrips) => {
          setPastTrips(dataPastTrips);
          setPastLength(dataPastTrips.length);
        })
        .catch(() => {
          setPastTripsError(true);
        })
        .finally(() => {
          setLoadingPastTrips(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ShowPastTripsSkeleton = () => PastTripsSkeleton(isSidebarHidden);

  const pastTripsElement = () => {
    if (pastTripsError) {
      return ErrorPastTrip(themeString);
    }
    if (pastTrips && pastTrips.length) {
      return PastTripsList({
        trips: pastTrips,
        isSidebarHidden,
        themeString,
        getLinkHistory,
      });
    }
    return EmptyStatePastTrips({ getLinkHistory });
  };

  return loadingPastTrips ? (
    <Feature
      activeComponent={ShowPastTripsSkeleton}
      name={FeatureToggleDefinition.PastTrips.id}
    />
  ) : (
    <Feature
      activeComponent={pastTripsElement}
      name={FeatureToggleDefinition.PastTrips.id}
    />
  );
}

export default PastTripsComponent;
