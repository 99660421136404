/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardBody,
  cardSelectableBehavior,
} from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { KeepSessionActions } from "../../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../../utils/KeepUserActivityContext";
import Destination from "./Destination/Destination";
import DestinationImage from "./DestinationImage/DestinationImage";
import SegmentIcons from "./Segments/SegmentIcons";
import { ITripCardPropsV1 } from "./TripCard.model";
import Styles from "./TripCard.module.scss";

function TripCardV1({ trip, index, totalitems }: ITripCardPropsV1) {
  const history = useHistory();

  const selectTripClickHandler = (e: any) => {
    if (
      e.currentTarget.id === `tripCardID${index}` &&
      !e.target.id.includes("menubutton")
    ) {
      const activity = new ActivityClass();
      activity.removeElementOrChildren(KeepSessionActions.GENERIC_DASHBOARD);
      const { id } = trip;
      history.push(`/trip${id}`);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.currentTarget.id === `tripCardID${index}`) {
      selectTripClickHandler(e);
    }
  };

  const [themeName, setThemeName] = useState<any>();

  useEffect(() => {
    app.getContext().then((ctx: app.Context) => setThemeName(ctx.app.theme));
  }, []);

  return (
    <Card
      id={`tripCardID${index}`}
      accessibility={cardSelectableBehavior}
      aria-labelledby={`card${index === 0 ? "tripCard" : ""}`}
      fluid
      compact
      className={
        themeName === "default"
          ? Styles["trip-card__default"]
          : Styles["trip-card__dark"]
      }
      tabIndex={index + 1}
      style={{
        borderStyle: "none",
        borderRadius: "4px",
      }}
      onKeyDown={handleKeyDown}
      onClick={selectTripClickHandler}
      styles={(theme) => ({
        background: theme.theme.siteVariables.tripCard?.background,
      })}
      data-testid="card"
      data-joyride-id={index === 0 ? "tripCard" : ""}
    >
      <DestinationImage trip={trip} />
      <CardBody className={Styles["trip-card__body"]}>
        <Destination trip={trip} index={index} totalitems={totalitems} />
        <Box
          className={Styles["trip-card__line"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.tripCard?.divider,
          })}
        />
        <SegmentIcons trip={trip} />
      </CardBody>
    </Card>
  );
}

export default TripCardV1;
