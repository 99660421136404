import GetAppCatalogId from "../components/share-join/functions/GetAppCatalogId";

import HostSettings from "./host.settings";

export default async function GetGraphAppCatalogId(
  setAppCatalogId: (text: string) => void,
  isTRIPPUser: boolean = false
) {
  if (isTRIPPUser && HostSettings.teamsTrippAppCatalogId) {
    setAppCatalogId(HostSettings.teamsTrippAppCatalogId);
  } else if (HostSettings.teamsAppCatalogId) {
    setAppCatalogId(HostSettings.teamsAppCatalogId);
  } else {
    setAppCatalogId(await GetAppCatalogId(HostSettings.teamsAppId));
  }
}
