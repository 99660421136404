import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IError } from "../../common/models/Error";
// eslint-disable-next-line import/no-cycle
import { loadCytricUrl, REDIRECTION_STORE_NAME } from "./RedirectionActions";

interface RedirectionState {
  cytricUrl: string;
  isPending: boolean;
  isError: boolean;
  error: IError;
}

const initialState: RedirectionState = {
  cytricUrl: "",
  isPending: false,
  isError: false,
  error: {} as IError,
};

export const redirectionSlice = createSlice({
  name: REDIRECTION_STORE_NAME,
  initialState,
  reducers: {
    updateCytricUrl: (state, action: PayloadAction<{ url: string }>) => ({
      ...state,
      cytricUrl: action.payload.url,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loadCytricUrl.pending, (state) => ({
      ...state,
      cytricUrl: "",
      isPending: true,
      isError: false,
      error: {} as IError,
    }));

    builder.addCase(
      loadCytricUrl.fulfilled,
      (state, action: PayloadAction<string>) => ({
        ...state,
        cytricUrl: action.payload,
        isPending: false,
        isError: false,
        error: {} as IError,
      })
    );

    builder.addCase(loadCytricUrl.rejected, (state: any, action: any) => ({
      ...state,
      isPending: false,
      isError: true,
      error: action.payload as IError,
    }));
  },
});

export const { updateCytricUrl } = redirectionSlice.actions;

export default redirectionSlice.reducer;
