import { Box } from "@fluentui/react-northstar";
import React from "react";
import { IWidgetContainerProps } from "./WidgetContainer.model";
import Styles from "./WidgetContainer.module.scss";

function WidgetContainer({ children, className }: IWidgetContainerProps) {
  return (
    <Box
      style={{
        borderStyle: "none",
        borderRadius: "4px",
      }}
      className={`${Styles["widget-container"]} ${className}`}
      styles={(theme) => ({
        background: theme.theme.siteVariables.widgetContainer?.widget,
      })}
    >
      {children}
    </Box>
  );
}

export default WidgetContainer;
