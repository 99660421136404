import React from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../generic-info-component/GenericInfo";
import {
  IGenericInfo,
  InfoImage,
} from "../generic-info-component/GenericInfo.model";

function UnauthorizedComponent(): React.ReactElement {
  const { t } = useTranslation();

  const errorInfoCytricEasyAccess: IGenericInfo = {
    title: t("messages.ERR_AccessCytricEasy"),
    detail: t("messages.ERR_AccessCytricEasyDetail"),
    subdetail: t("messages.ERR_AccessCytricEasySubdetail"),
  };

  return (
    <GenericInfo infoData={errorInfoCytricEasyAccess} image={InfoImage.ERROR} />
  );
}

export default UnauthorizedComponent;
