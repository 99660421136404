interface NotificationSettings {
  scheduleUnassignedExpenses: ScheduleUnassignedExpenses | null;
  scheduleExpenseStatements: ScheduleExpenseStatements | null;
  scheduleShareMyTransfer: ScheduleShareMyTransfer | null;
  isChatAssistantEnabled: boolean;
}

export enum ScheduleUnassignedExpenses {
  Always = "always",
  Never = "never",
  OncePerDay = "oncerPerDay",
  OncePerWeek = "oncePerWeek",
}

export enum ScheduleExpenseStatements {
  Always = "always",
  Never = "never",
  OncePerDay = "oncerPerDay",
  OncePerWeek = "oncePerWeek",
}

export enum ScheduleShareMyTransfer {
  Always = "always",
  Never = "never",
  OncePerDay = "oncerPerDay",
  OncePerWeek = "oncePerWeek",
}

export const InitiallyIsChatAssistantEnabled = false;

export default NotificationSettings;
