import { Image } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { DESTINATION_FALLBACK_IMAGE } from "../../../data/constants";
import {
  composeTripName,
  handleDestinationImageError,
  validateDestinationImageUrl,
} from "../../../utils/trip-utils";
import { IDestinationImageProps } from "./DestinationImage.model";
import Styles from "./DestinationImage.module.scss";

function DestinationImage({ trip }: IDestinationImageProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.upcomingTrips",
  });

  return (
    <div className={Styles.container}>
      <Image
        alt={`${t("altTextTripPhoto")}  ${composeTripName(trip)}`}
        className={Styles.container__image}
        src={
          validateDestinationImageUrl(trip?.destinationPicture?.[0]) ??
          DESTINATION_FALLBACK_IMAGE
        }
        data-testid="destination-image"
        onError={handleDestinationImageError}
      />
    </div>
  );
}

export default DestinationImage;
