/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from "@fluentui/react-northstar";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/StoreHooks";
import { shareTransferEnableAllActions } from "../../../../store/shareTransferEnableAll/shareTransferEnableAllSlice";
import CancelContext from "../../../upcoming-trips-dashboard/cancel/CancelContext";
import { useDispatch, useStore } from "../../context/ShareMyRideContext";
import { types } from "../../context/ShareMyRideReducer";
import getEnableParams from "../../utils/GetEnableParams";
import { appInsightsTracker } from "../../utils/shareMyRideUtils";
import {
  disableShare,
  enableShare,
} from "../share-my-ride-container/functions/shareMyRideApi";
import Styles from "./EnableAllToggle.module.scss";

function EnableAllToggle({ isMobile, toggleChecked }: any) {
  const enableAll = true;
  const dispatch = useDispatch();
  const [enableAllTourActivated, setEnableAllTourActivated] = useState(false);
  const isTourRunning = useAppSelector(
    (state) => state.coachmarks.isJoyrideActive
  );
  const storedEnableAll = useAppSelector(
    (state) => state.shareTransferEnableAll.enableAll
  );

  const { tripId, productsToShare, errorEnableAll, commonError } = useStore();
  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide",
  });

  const handleEnableAll = () => {
    const polling = "POST";
    dispatch({ type: types.setGlobalLoading, payload: true });

    Promise.all(
      productsToShare.map((product: any) => {
        const enableParams = getEnableParams(product);
        if (enableParams.locationCode && !product.sharingEnabled) {
          enableShare(
            tripId,
            enableParams,
            dispatch,
            polling,
            product,
            enableAll
          );
          appInsightsTracker(
            { name: "travel-shareRide-activation" },
            {
              tripId,
              isActivated: true,
              numberOfPeople: product.numberOfPeopleSharing,
            }
          );
        }
        return null;
      })
    ).then(() => {
      setTimeout(() => {
        dispatch({ type: types.setGlobalLoading, payload: false });
      }, 3000);
    });
  };

  const handleDisableAll = () => {
    const polling = "DELETE";
    dispatch({ type: types.setGlobalLoading, payload: true });
    Promise.all(
      productsToShare.map((product: any) => {
        const enableParams = getEnableParams(product);
        if (enableParams.locationCode && product.sharingEnabled) {
          disableShare(
            tripId,
            enableParams,
            dispatch,
            polling,
            enableAll,
            product
          );
          appInsightsTracker(
            { name: "travel-shareRide-activation" },
            {
              tripId,
              isActivated: false,
              numberOfPeople: product.numberOfPeopleSharing,
            }
          );
        }
        return null;
      })
    ).then(() => {
      setTimeout(() => {
        dispatch({ type: types.setGlobalLoading, payload: false });
      }, 3000);
    });
  };

  useEffect(() => {
    if (isTourRunning && storedEnableAll) {
      dispatch({ type: types.setGlobalLoading, payload: true });
    }
  }, []);

  useEffect(() => {
    if (productsToShare.length > 0) {
      if (isTourRunning && storedEnableAll && !enableAllTourActivated) {
        setEnableAllTourActivated(true);
        handleEnableAll();
        setTimeout(() => {
          dispatch(shareTransferEnableAllActions.setEnableAll(false));
        }, 5000);
      }
    }
  }, [productsToShare]);

  const cancelContext = useContext(CancelContext);

  return (
    <Checkbox
      label={t("enableAllLabel")}
      labelPosition="start"
      data-testid="enableAll-toggle"
      toggle
      checked={toggleChecked}
      disabled={
        errorEnableAll === "errorEnableAll" ||
        errorEnableAll === "errorDisableAll" ||
        errorEnableAll === "errorEnableIndividual" ||
        errorEnableAll === "errorDisableIndividual" ||
        commonError
      }
      className={
        isMobile ? Styles.enableAllToggleMobile : Styles.enableAllToggle
      }
      onClick={toggleChecked ? handleDisableAll : handleEnableAll}
      aria-hidden={cancelContext.cancelPopup}
    />
  );
}

export default EnableAllToggle;
