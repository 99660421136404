/* eslint-disable react/react-in-jsx-scope */
import { Flex, Header, Image, Text } from "@fluentui/react-northstar";
import CloseCollaboratorsStatus from "../../../../common/models/enums/ActivationStatusEnum";

import { IError } from "../../../../common/models/Error";
import closeCollaboratorErrorCodeEnum from "../../../../common/models/enums/CloseCollaboratorErrorCodeEnum";
import i18next from "../../../../i18n/i18n";
import { useAppSelector } from "../../../../store/StoreHooks";
import {
  selectCloseCollaboratorsErrorInformation,
  selectCloseCollaboratorsStatus,
} from "../../../../store/collaborators/close-collaborators/CloseCollaboratorsSelector";
import { selectReverseCollaboratorsError } from "../../../../store/collaborators/reverse-collaborators/ReverseCollaboratorSelector";
import HostSettings from "../../../../utils/host.settings";
import stylesCloseCollaborators from "../styles/close-collaborators.module.scss";

interface CardContent {
  message: string;
  subMessage: string;
  imagePath: string;
}

function generateErrorText(
  content: CardContent,
  code: number,
  theme: string
): CardContent {
  const richContent = content;
  switch (code) {
    case closeCollaboratorErrorCodeEnum.GRAPH:
      richContent.message = "closeCollaborators.list.permission.errorMessage";
      richContent.subMessage =
        "closeCollaborators.list.permission.subErrorMessage";
      richContent.imagePath = `${HostSettings.getCDNIcons}no-permissions-${theme}.svg`;
      break;
    case closeCollaboratorErrorCodeEnum.SEARCH:
    case closeCollaboratorErrorCodeEnum.COUCHBASE:
    case closeCollaboratorErrorCodeEnum.TMS:
    case closeCollaboratorErrorCodeEnum.KEYCLOAK:
    case closeCollaboratorErrorCodeEnum.BAD_INPUT:
    case closeCollaboratorErrorCodeEnum.GENERAL:
    default:
      richContent.message = "closeCollaborators.list.generic.errorMessage";
      richContent.subMessage =
        "closeCollaborators.list.generic.subErrorMessage";
      richContent.imagePath = `${HostSettings.getCDNIcons}generic-error-${theme}.svg`;
  }
  return richContent;
}

function generateStatusText(
  content: CardContent,
  status: CloseCollaboratorsStatus,
  theme: string
): CardContent {
  const richContent = content;
  switch (status) {
    case CloseCollaboratorsStatus.NO_USERS_AT_DESTINATION:
      richContent.message =
        "closeCollaborators.list.disabledCard.noUserAtDestination.errorMessage";
      richContent.subMessage =
        "closeCollaborators.list.disabledCard.noUserAtDestination.subErrorMessage";
      richContent.imagePath = `${HostSettings.getCDNIcons}no-items-desert-${theme}.svg`;
      break;
    case CloseCollaboratorsStatus.NO_COUNTRY_CODE_PROVIDED:
      richContent.message =
        "closeCollaborators.list.disabledCard.noCountryCodeProvided.errorMessage";
      richContent.subMessage =
        "closeCollaborators.list.disabledCard.noCountryCodeProvided.subErrorMessage";
      richContent.imagePath = `${HostSettings.getCDNIcons}generic-error-${theme}.svg`;
      break;
    case CloseCollaboratorsStatus.DEACTIVATED_FOR_USER_LOCATION:
      richContent.message =
        "closeCollaborators.list.disabledCard.deactivatedForUserLocation.errorMessage";
      richContent.subMessage =
        "closeCollaborators.list.disabledCard.deactivatedForUserLocation.subErrorMessage";
      richContent.imagePath = `${HostSettings.getCDNIcons}disabled-functionality-${theme}.svg`;
      break;
    case CloseCollaboratorsStatus.DEACTIVATED_FOR_DESTINATION:
      richContent.message =
        "closeCollaborators.list.disabledCard.deactivatedForDestination.errorMessage";
      richContent.subMessage =
        "closeCollaborators.list.disabledCard.deactivatedForDestination.subErrorMessage";
      richContent.imagePath = `${HostSettings.getCDNIcons}disabled-functionality-${theme}.svg`;
      break;
    default:
      richContent.message = "closeCollaborators.list.generic.errorMessage";
      richContent.subMessage =
        "closeCollaborators.list.generic.subErrorMessage";
      richContent.imagePath = `${HostSettings.getCDNIcons}generic-error-${theme}.svg`;
  }
  return richContent;
}

function generateContent(
  error: IError,
  status: CloseCollaboratorsStatus,
  theme: any
): CardContent {
  let content = {} as CardContent;
  if (error.code) {
    content = generateErrorText(content, error.code, theme);
  } else if (status) {
    content = generateStatusText(content, status, theme);
  }
  return content;
}

interface CloseCollaboratorsDisabledProps {
  themeName: string;
  isTitleVisible?: boolean;
  isReverseCollabError?: boolean;
}

export default function CollaboratorsDisabled({
  themeName,
  isTitleVisible,
  isReverseCollabError,
}: CloseCollaboratorsDisabledProps) {
  const content: CardContent = useAppSelector((state) =>
    generateContent(
      isReverseCollabError
        ? selectReverseCollaboratorsError(state)
        : selectCloseCollaboratorsErrorInformation(state),
      isReverseCollabError
        ? CloseCollaboratorsStatus.UNKNOWN
        : selectCloseCollaboratorsStatus(state),
      themeName
    )
  );

  return (
    <Flex
      data-testid="close-collaborators-disabled"
      className={[
        themeName === "light"
          ? stylesCloseCollaborators.white
          : stylesCloseCollaborators.dark,
        isTitleVisible && stylesCloseCollaborators.card,
        stylesCloseCollaborators.cardDisabled,
      ].join(" ")}
    >
      {isTitleVisible && (
        <Flex gap="gap.small" className={stylesCloseCollaborators.cardHeader}>
          <Flex column>
            <Header
              as="h3"
              content={i18next.t("closeCollaborators.list.card.title")}
              className={stylesCloseCollaborators.title}
              data-joyride-id="closeCollaborators"
            />
            <Text
              content={i18next.t("closeCollaborators.list.subtitle")}
              className={stylesCloseCollaborators.subtitle}
            />
          </Flex>
        </Flex>
      )}

      <Flex
        className={stylesCloseCollaborators.disabledCardBody}
        vAlign="center"
        column
      >
        <Flex column>
          <div>
            <Image src={content.imagePath} alt={i18next.t(content.message)} />
          </div>

          <Text
            content={i18next.t(content.message)}
            className={stylesCloseCollaborators.noCollaboratorsMsg}
          />
          <Text
            content={i18next.t(content.subMessage)}
            className={stylesCloseCollaborators.subTitle}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

CollaboratorsDisabled.defaultProps = {
  isTitleVisible: true,
  isReverseCollabError: false,
};
