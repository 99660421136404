import ColorBlock from "./colorBlockObject";

const brand = new ColorBlock(
  /* Basics */
  "#5B5FC7",
  "#4f52b2",
  "#525252",
  "#000",
  /* Hovers */
  "#7f85f5",
  "#5b5fc7",
  "#5b5fc7",
  "#000",
  /* Actives */
  "#7f85f5",
  "#7f85f5",
  "#707070",
  /* Focus */
  "#7f85f5",
  "#7f85f5",
  "#fff",
  /* Pressed */
  "#c5cbfa",
  "#444791",
  "#444791",
  /* Disabled */
  "#5c5c5c",
  "#1f1f1f",
  "#424242",
  /* Foreground */
  "#7f85f5",
  "#7f85f5",
  "#c5cbfa",
  "#fff",
  "#252423",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#2f2f4a",
  "#3d3e78",
  "#2f2f4a",
  "#0a0a0a",
  "#3d3e78",
  "#1F1F1F",
  undefined,
  /* Border */
  "#444791",
  "#444791",
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  "#fff",
  "#c5cbfa",
  /* Background Hover */
  "#3d3e78",
  "#383966",
  "#242424",
  undefined,
  /* Fore and Back Pressed */
  "#fff",
  "#3d3e78",
  "#3d3e78",
  undefined,
  /* Fore and Back Active */
  "#7f85f5",
  "#e8ebfa",
  "#7f85f5",
  undefined,
  /* Border Active */
  "#444791",
  "#444791",
  undefined,
  undefined,
  /* Foreground Focus */
  "#7f85f5",
  "#7f85f5",
  "#e8ebfa",
  "#fff",
  /* Background Focus */
  "#2f2f4a",
  "#3d3e78",
  "#2f2f4a",
  /* Border Focus */
  "#000",
  "#7f85f5",
  /* Fore and Back disabled */
  "#5c5c5c",
  "#1f1f1f",
  undefined,
  undefined
);
const defaultColorBlock = new ColorBlock(
  /* Basics */
  "#fff",
  "#292929",
  "#616161",
  "#000",
  /* Hovers */
  "#fff",
  "#3d3d3d",
  "#707070",
  "#000",
  /* Actives */
  "#fff",
  "#333",
  "#707070",
  /* Focus */
  "#fff",
  "#2D2C2C",
  "#fff",
  /* Pressed */
  "#fff",
  "#2E2E2E",
  "rgba(255,255,255,0.4)",
  /* Disabled */
  "#5c5c5c",
  "#1f1f1f",
  "#424242",
  /* Foreground */
  "#d6d6d6",
  "#adadad",
  "#fff",
  "#fff",
  "#616161",
  "#3d3d3d",
  "#d6d6d6",
  "#fff",
  "#999",
  /* Background */
  "#242424",
  "#1f1f1f",
  "#141414",
  "#3D3D3D",
  "#333",
  "#0a0a0a",
  "#1a1a1a",
  /* Border */
  "#0F0F0F",
  "#3D3D3D",
  "#3d3d3d",
  /* Border Transparent */
  "rgba(0, 0, 0, 0)",
  "rgba(0, 0, 0, 0)",
  /* Foreground Hover */
  "#fff",
  "#fff",
  /* Background Hover */
  "#242424",
  "rgba(0, 0, 0, 0)",
  "#2E2E2E",
  "#292929",
  /* Fore and Back Pressed */
  undefined,
  undefined,
  "#1a1a1a",
  "#1f1f1f",
  /* Fore and Back Active */
  "#fff",
  undefined,
  "#1F1F1F",
  "#383838",
  /* Border Active */
  "#1B1A1A",
  "#11100F",
  "#2D2C2C",
  "#666666",
  /* Foreground Focus */
  "#d6d6d6",
  "#d6d6d6",
  "#fff",
  undefined,
  /* Background Focus */
  "#292929",
  "#1f1f1f",
  "#333",
  /* Border Focus */
  "#000",
  undefined,
  /* Fore and Back disabled */
  "#5c5c5c",
  "#141414",
  "#2D2C2C",
  "#1B1A1A"
);
/// ///////////
//    RED   //
/// ///////////
const red = new ColorBlock(
  /* Basics */
  "#F9526B",
  "#E73550",
  "#1E040A",
  undefined,
  /* Hovers */
  "#fff",
  "rgba(167,32,55,0.9)",
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  "#fff",
  "rgba(142,25,46,0.9)",
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#fff",
  "#201F1F",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#3E1F25",
  "#E73550",
  "#E73550",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "#F75E75",
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  "#C4314B",
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);
/// ///////////
//  GREEN   //
/// ///////////
const green = new ColorBlock(
  /* Basics */
  "#92C353",
  "#92C353",
  "#032003",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#201F1F",
  "#92C353",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#0D2E0D",
  "#0D2E0D",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "#92C353",
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//  YELLOW  //
/// ///////////
const yellow = new ColorBlock(
  /* Basics */
  "#F9EC02",
  "#F8D22A",
  "#261A00",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#201F1F",
  "#201F1F",
  "#252423",
  "#F2E384",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#463100",
  "#FFB900",
  "#463100",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "#F2E384",
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//  ORANGE  //
/// ///////////
const orange = new ColorBlock(
  /* Basics */
  "#E97548",
  "#E97548",
  "#8A8886",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#E97548",
  "#252423",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "#E97548",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//   PINK   //
/// ///////////
const pinkDark = new ColorBlock(
  /* Basics */
  "#EC6FAE",
  "#3E2D3B",
  "#1F191D",
  undefined,
  /* Hovers */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  "#E959D9",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////
//  SILVER  //
/// ///////////
const silver = new ColorBlock(
  /* Basics */
  "#fff",
  "rgba(0,0,0,0)",
  "rgba(255,255,255,0.3)",
  undefined,
  /* Hovers */
  "#fff",
  "rgba(255,255,255,0.1)",
  "rgba(255,255,255,0.3)",
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  "#fff",
  "rgba(255,255,255,0.2)",
  "rgba(255,255,255,0.3)",
  /* Disabled */
  "rgba(255,255,255,0.3)",
  "rgba(255,255,255,0.05)",
  undefined,
  /* Foreground */
  "rgba(255,255,255,0.75)",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);
/// ///////////
//   ONYX   //
/// ///////////
const onyxDark = new ColorBlock(
  /* Basics */
  undefined,
  "rgba(41,40,40,0.9)",
  "rgba(27,26,26,0.9)",
  undefined,
  /* Hovers */
  undefined,
  "rgba(0,0,0,0.8)",
  undefined,
  undefined,
  /* Actives */
  undefined,
  undefined,
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  "rgba(0,0,0,0.8)",
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  "rgba(59,58,57,0.9)",
  "rgba(41,40,40,0.9)",
  "rgba(0,0,0,0.5)",
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  "rgba(0,0,0,0)",
  "rgba(255,255,255,0.5)",
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

/// ///////////////
//   AMETHYST   //
/// ///////////////
const amethyst = new ColorBlock(
  /* Basics */
  undefined,
  "rgba(45,44,44,0.4)",
  undefined,
  undefined,
  /* Hovers */
  undefined,
  "rgba(98,100,167,0.15)",
  undefined,
  undefined,
  /* Actives */
  undefined,
  "rgba(98,100,167,0.15)",
  undefined,
  /* Focus */
  undefined,
  undefined,
  undefined,
  /* Pressed */
  undefined,
  undefined,
  undefined,
  /* Disabled */
  undefined,
  undefined,
  undefined,
  /* Foreground */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background */
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border */
  undefined,
  undefined,
  undefined,
  /* Border Transparent */
  undefined,
  undefined,
  /* Foreground Hover */
  undefined,
  undefined,
  /* Background Hover */
  "#252423",
  undefined,
  undefined,
  undefined,
  /* Fore and Back Pressed */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Fore and Back Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Border Active */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Foreground Focus */
  undefined,
  undefined,
  undefined,
  undefined,
  /* Background Focus */
  undefined,
  undefined,
  undefined,
  /* Border Focus */
  undefined,
  undefined,
  /* Fore and Back disabled */
  undefined,
  undefined,
  undefined,
  undefined
);

const colorSchemeDark = {
  brand,
  default: defaultColorBlock,
  green,
  red,
  silver,
  yellow,
  orange,
  amethyst,
  pinkDark,
  onyxDark,
};

export default colorSchemeDark;
