/* eslint-disable react/react-in-jsx-scope */

import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import HostConfigPopup from "../card-hostConfigs/HostConfigPopup";
import HostConfigPopupDark from "../card-hostConfigs/HostConfigPopupDark";
import { RenderCard } from "../functions/CardRenderer";
import sharePopupStyles from "../styles/share-popup.module.scss";

export interface AdaptiveCardViewerProps {
  cardTemplate: any;
  cardData: any;
  cardHostConfig?: any;
}

function AdaptiveCardViewer({
  cardTemplate,
  cardData,
  cardHostConfig,
}: AdaptiveCardViewerProps) {
  const [themeName, setThemeName] = useState<any>();

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((ctx: app.Context) => setThemeName(ctx.app.theme));
    });
  }, []);

  useEffect(() => {
    if (themeName) {
      let currentHostConfig = cardHostConfig;
      if (currentHostConfig === undefined) {
        currentHostConfig =
          themeName === "default" ? HostConfigPopup : HostConfigPopupDark;
      }

      const renderedCard: any = RenderCard(
        cardTemplate,
        cardData,
        currentHostConfig
      );

      const adaptiveCardContent = document.getElementById(
        "adaptiveCardContent"
      ) as HTMLInputElement;

      if (adaptiveCardContent.hasChildNodes()) {
        adaptiveCardContent.innerHTML = "";
      }

      if (!adaptiveCardContent.hasChildNodes()) {
        adaptiveCardContent.appendChild(renderedCard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardTemplate, cardData, cardHostConfig, themeName]);

  return (
    <div className={sharePopupStyles.formBlock}>
      <div id="adaptiveCardContent" className={sharePopupStyles.adaptiveCard} />
    </div>
  );
}

export default AdaptiveCardViewer;
