export default class ColorBlock {
  // Basics
  foreground?: string;

  background?: string;

  border?: string;

  shadow?: string;

  // Hovers
  foregroundHover?: string;

  backgroundHover?: string;

  borderHover?: string;

  shadowHover?: string;

  // Actives
  foregroundActive?: string;

  backgroundActive?: string;

  borderActive?: string;

  // Focus
  foregroundFocus?: string;

  backgroundFocus?: string;

  borderFocus?: string;

  // Pressed
  foregroundPressed?: string;

  backgroundPressed?: string;

  borderPressed?: string;

  // Disabled
  foregroundDisabled?: string;

  backgroundDisabled?: string;

  borderDisabled?: string;

  // Foreground
  foreground1?: string;

  foreground2?: string;

  foreground3?: string;

  foreground4?: string;

  foreground5?: string;

  foreground6?: string;

  foreground7?: string;

  foreground8?: string;

  foreground9?: string;

  // Background
  background1?: string;

  background2?: string;

  background3?: string;

  background4?: string;

  background5?: string;

  background6?: string;

  background7?: string;

  // Border
  border1?: string;

  border2?: string;

  border3?: string;

  // Border Transparent
  borderTransparent?: string;

  borderTransparentActive?: string;

  // Foreground Hover
  foregroundHover1?: string;

  foregroundHover2?: string;

  // Background Hover
  backgroundHover1?: string;

  backgroundHover2?: string;

  backgroundHover3?: string;

  backgroundHover4?: string;

  // Fore and Back Pressed
  foregroundPressed1?: string;

  backgroundPressed1?: string;

  backgroundPressed2?: string;

  backgroundPressed3?: string;

  // Fore and Back Active
  foregroundActive1?: string;

  foregroundActive2?: string;

  backgroundActive1?: string;

  backgroundActive2?: string;

  // Border Active
  borderActive1?: string;

  borderActive2?: string;

  borderActive3?: string;

  borderActive4?: string;

  // Foreground Focus
  foregroundFocus1?: string;

  foregroundFocus2?: string;

  foregroundFocus3?: string;

  foregroundFocus4?: string;

  // Background Focus
  backgroundFocus1?: string;

  backgroundFocus2?: string;

  backgroundFocus3?: string;

  // Border Focus
  borderFocusWithin?: string;

  borderFocus1?: string;

  // Fore and Back disabled
  foregroundDisabled1?: string;

  backgroundDisabled1?: string;

  backgroundDisabled2?: string;

  backgroundDisabled3?: string;

  constructor(
    // Basics
    foreground?: string,
    background?: string,
    border?: string,
    shadow?: string,
    // Hovers
    foregroundHover?: string,
    backgroundHover?: string,
    borderHover?: string,
    shadowHover?: string,
    // Actives
    foregroundActive?: string,
    backgroundActive?: string,
    borderActive?: string,
    // Focus
    foregroundFocus?: string,
    backgroundFocus?: string,
    borderFocus?: string,
    // Pressed
    foregroundPressed?: string,
    backgroundPressed?: string,
    borderPressed?: string,
    // Disabled
    foregroundDisabled?: string,
    backgroundDisabled?: string,
    borderDisabled?: string,
    // Foreground
    foreground1?: string,
    foreground2?: string,
    foreground3?: string,
    foreground4?: string,
    foreground5?: string,
    foreground6?: string,
    foreground7?: string,
    foreground8?: string,
    foreground9?: string,
    // Background
    background1?: string,
    background2?: string,
    background3?: string,
    background4?: string,
    background5?: string,
    background6?: string,
    background7?: string,
    // Border
    border1?: string,
    border2?: string,
    border3?: string,
    // Border Transparen,
    borderTransparent?: string,
    borderTransparentActive?: string,
    // Foreground Hover
    foregroundHover1?: string,
    foregroundHover2?: string,
    // Background Hover
    backgroundHover1?: string,
    backgroundHover2?: string,
    backgroundHover3?: string,
    backgroundHover4?: string,
    // Fore and Back Pressed
    foregroundPressed1?: string,
    backgroundPressed1?: string,
    backgroundPressed2?: string,
    backgroundPressed3?: string,
    // Fore and Back Active
    foregroundActive1?: string,
    foregroundActive2?: string,
    backgroundActive1?: string,
    backgroundActive2?: string,
    // Border Active
    borderActive1?: string,
    borderActive2?: string,
    borderActive3?: string,
    borderActive4?: string,
    // Foreground Focus
    foregroundFocus1?: string,
    foregroundFocus2?: string,
    foregroundFocus3?: string,
    foregroundFocus4?: string,
    // Background Focus
    backgroundFocus1?: string,
    backgroundFocus2?: string,
    backgroundFocus3?: string,
    // Border Focus
    borderFocusWithin?: string,
    borderFocus1?: string,
    // Fore and Back disabled
    foregroundDisabled1?: string,
    backgroundDisabled1?: string,
    backgroundDisabled2?: string,
    backgroundDisabled3?: string
  ) {
    this.foreground = foreground;
    this.background = background;
    this.border = border;
    this.shadow = shadow;
    this.foregroundHover = foregroundHover;
    this.backgroundHover = backgroundHover;
    this.borderHover = borderHover;
    this.shadowHover = shadowHover;
    this.foregroundActive = foregroundActive;
    this.backgroundActive = backgroundActive;
    this.borderActive = borderActive;
    this.foregroundFocus = foregroundFocus;
    this.backgroundFocus = backgroundFocus;
    this.borderFocus = borderFocus;
    this.foregroundPressed = foregroundPressed;
    this.backgroundPressed = backgroundPressed;
    this.borderPressed = borderPressed;
    this.foregroundDisabled = foregroundDisabled;
    this.backgroundDisabled = backgroundDisabled;
    this.borderDisabled = borderDisabled;
    this.foreground1 = foreground1;
    this.foreground2 = foreground2;
    this.foreground3 = foreground3;
    this.foreground4 = foreground4;
    this.foreground5 = foreground5;
    this.foreground6 = foreground6;
    this.foreground7 = foreground7;
    this.foreground8 = foreground8;
    this.foreground9 = foreground9;
    this.background1 = background1;
    this.background2 = background2;
    this.background3 = background3;
    this.background4 = background4;
    this.background5 = background5;
    this.background6 = background6;
    this.background7 = background7;
    this.border1 = border1;
    this.border2 = border2;
    this.border3 = border3;
    this.borderTransparent = borderTransparent;
    this.borderTransparentActive = borderTransparentActive;
    this.foregroundHover1 = foregroundHover1;
    this.foregroundHover2 = foregroundHover2;
    this.backgroundHover1 = backgroundHover1;
    this.backgroundHover2 = backgroundHover2;
    this.backgroundHover3 = backgroundHover3;
    this.backgroundHover4 = backgroundHover4;
    this.foregroundPressed1 = foregroundPressed1;
    this.backgroundPressed1 = backgroundPressed1;
    this.backgroundPressed2 = backgroundPressed2;
    this.backgroundPressed3 = backgroundPressed3;
    this.foregroundActive1 = foregroundActive1;
    this.foregroundActive2 = foregroundActive2;
    this.backgroundActive1 = backgroundActive1;
    this.backgroundActive2 = backgroundActive2;
    this.borderActive1 = borderActive1;
    this.borderActive2 = borderActive2;
    this.borderActive3 = borderActive3;
    this.borderActive4 = borderActive4;
    this.foregroundFocus1 = foregroundFocus1;
    this.foregroundFocus2 = foregroundFocus2;
    this.foregroundFocus3 = foregroundFocus3;
    this.foregroundFocus4 = foregroundFocus4;
    this.backgroundFocus1 = backgroundFocus1;
    this.backgroundFocus2 = backgroundFocus2;
    this.backgroundFocus3 = backgroundFocus3;
    this.borderFocusWithin = borderFocusWithin;
    this.borderFocus1 = borderFocus1;
    this.foregroundDisabled1 = foregroundDisabled1;
    this.backgroundDisabled1 = backgroundDisabled1;
    this.backgroundDisabled2 = backgroundDisabled2;
    this.backgroundDisabled3 = backgroundDisabled3;
  }
}
