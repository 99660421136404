/* eslint-disable react/react-in-jsx-scope */
import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import { app, dialog } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import i18next from "../../../i18n/i18n";
import { getDevice } from "../../../utils/device.utils";
import HostSettings from "../../../utils/host.settings";
import stylesShareFlight from "../../styles/share-flight.module.scss";

function TaskModuleResponseSuccess(props: any) {
  const [themeName, setThemeName] = useState<any>();
  const { location } = props;
  const [origin, setOrigin] = useState("");

  const handleCancel = (): void => {
    dialog.url.submit();
  };

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((ctx: app.Context) => {
        setThemeName(ctx?.app.theme);
      });
    });
  }, []);

  useEffect(() => {
    setOrigin(location?.search.split("?")[1].split("=")[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  let isMobile = false;

  const device = getDevice();
  if (device === "mobile") {
    isMobile = true;
  }

  const getMessage = () => {
    let message = "";
    switch (origin) {
      case "events":
        message = i18next.t("moduleResponse.success.eventsMessage");
        break;
      case "classic-expenses-chat":
        message = i18next.t("moduleResponse.success.chatWithTravelerMessage");
        break;
      default:
        message = i18next.t("moduleResponse.success.primaryMessage");
    }

    return message;
  };

  return (
    <Flex
      column
      gap="gap.smaller"
      className={[
        themeName === "default"
          ? stylesShareFlight.white
          : stylesShareFlight.dark,
        stylesShareFlight.shareContentResult,
        isMobile && stylesShareFlight.tablet,
      ].join(" ")}
    >
      <Flex column className={stylesShareFlight.result}>
        <Flex
          column
          hAlign="center"
          vAlign="center"
          className={stylesShareFlight.resultMessage}
        >
          <Image src={`${HostSettings.getCDNIcons}success.svg`} alt="Success" />
          <Text
            content={getMessage()}
            weight="bold"
            className={stylesShareFlight.resultMessageText}
          />
        </Flex>

        <Flex
          hAlign="end"
          gap="gap.small"
          className={[
            themeName === "default"
              ? stylesShareFlight.white
              : stylesShareFlight.dark,
            stylesShareFlight.actions,
          ].join(" ")}
        >
          <Button
            fluid={isMobile}
            flat
            content={i18next.t("moduleResponse.success.closeButton")}
            primary
            onClick={() => handleCancel()}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TaskModuleResponseSuccess;
