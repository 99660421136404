import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Text } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/StoreHooks";
import BookingRedirections from "../../../../../BookingRedirections";
import Styles from "./NewProduct.module.scss";

interface NewProductProps {
  productType: string;
  numOfProducts: number;
}

function NewProduct({ productType, numOfProducts }: NewProductProps) {
  const { t } = useTranslation();
  const [translations, setTranslations] = useState({ text: "", button: "" });
  const trip = useAppSelector((state) => state.tripDetail.trip);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const bookElement = () => {
    const name = trip.name ?? "";
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    if (productType === "flight") {
      telemetryService.trackEvent(
        { name: "travel-booking-bookFlight" },
        {
          numOfFlights: numOfProducts,
          tripId,
        }
      );

      const redirectionInput = {
        segmentType: RedirectionTypeEnum.ADD_FLIGHT,
        tripId,
      };

      BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
    }
    if (productType === "train") {
      telemetryService.trackEvent(
        { name: "travel-booking-bookTrain" },
        {
          numOfTrains: numOfProducts,
          tripId,
        }
      );
    }
    if (productType === "hotel") {
      telemetryService.trackEvent(
        { name: "travel-booking-bookHotel" },
        {
          numOfHotels: numOfProducts,
          tripId,
        }
      );

      const redirectionInput = {
        segmentType: RedirectionTypeEnum.ADD_HOTEL,
        tripId,
      };

      BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
    }
  };

  const bookCar = () => {
    telemetryService.trackEvent(
      { name: "travel-booking-bookCar" },
      {
        numOfCars: numOfProducts,
        tripId: trip.id,
      }
    );
  };

  const bookTransfer = () => {
    telemetryService.trackEvent(
      { name: "travel-booking-bookTransfer" },
      {
        numOfCars: numOfProducts,
        tripId: trip.id,
      }
    );
  };

  useEffect(() => {
    if (productType === "flight") {
      setTranslations({
        text: `${t(
          "upcoming_trips_dashboard.tripDetails.newProduct.label.flight"
        )}`,
        button: `${t(
          "upcoming_trips_dashboard.tripDetails.newProduct.buttons.flight"
        )}`,
      });
    }
    if (productType === "train") {
      setTranslations({
        text: `${t(
          "upcoming_trips_dashboard.tripDetails.newProduct.label.train"
        )}`,
        button: `${t(
          "upcoming_trips_dashboard.tripDetails.newProduct.buttons.train"
        )}`,
      });
    }
    if (productType === "hotel") {
      setTranslations({
        text: `${t(
          "upcoming_trips_dashboard.tripDetails.newProduct.label.hotel"
        )}`,
        button: `${t(
          "upcoming_trips_dashboard.tripDetails.newProduct.buttons.hotel"
        )}`,
      });
    }
    if (productType === "car") {
      setTranslations({
        text: `${t(
          "upcoming_trips_dashboard.tripDetails.newProduct.label.car"
        )}`,
        button: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType]);

  return (
    <div className={Styles.container}>
      <div className={Styles.newProduct}>
        <Text className={Styles.newProductText} content={translations.text} />
        {productType !== "car" ? (
          <Button
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
            })}
            data-testid="productButtonId"
            className={Styles.button}
            content={`${translations.button}`}
            role="link"
            text
            primary
            onClick={() => bookElement()}
            iconOnly
          />
        ) : (
          <>
            <Button
              data-testid="carProductButtonId"
              className={Styles.button}
              content={`${t(
                "upcoming_trips_dashboard.tripDetails.newProduct.buttons.car"
              )}`}
              text
              primary
              onClick={() => bookCar()}
              iconOnly
            />
            <Text
              className={Styles.newProductTextO}
              content={t(
                "upcoming_trips_dashboard.tripDetails.newProduct.label.or"
              )}
            />
            <Button
              data-testid="transferProductButtonId"
              className={Styles.button}
              content={`${t(
                "upcoming_trips_dashboard.tripDetails.newProduct.buttons.transfer"
              )}`}
              text
              primary
              onClick={() => bookTransfer()}
              iconOnly
            />
          </>
        )}
      </div>
    </div>
  );
}

export default NewProduct;
