/* eslint-disable no-template-curly-in-string */
const checkPreview = "${isPreview != true}";
const shareCarFromCytricCard = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.3",
  body: [
    {
      type: "TextBlock",
      text: "${bookingOptionText}",
      wrap: true,
      weight: "Bolder",
      size: "Large",
      $when: checkPreview,
    },
    {
      type: "TextBlock",
      text: "${bookingDetailsText}",
      wrap: true,
      size: "Small",
      weight: "Lighter",
      spacing: "None",
      $when: checkPreview,
    },
    {
      type: "Container",
      $data: "${carTripDetails}",
      spacing: "Medium",
      separator: true,
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "Image",
                  url: "${rentalCarPictureUrl}",
                  width: "50px",
                  altText: "Car photo",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              spacing: "Default",
              items: [
                {
                  type: "TextBlock",
                  text: "${titleText}",
                  wrap: true,
                  weight: "Bolder",
                  size: "Large",
                },
                {
                  type: "TextBlock",
                  text: "${rentalCompany}",
                  wrap: true,
                  spacing: "Small",
                  size: "Small",
                },
              ],
            },
          ],
        },
        {
          type: "Container",
          items: [
            {
              type: "TextBlock",
              text: "${carName}",
              wrap: true,
              weight: "Bolder",
              size: "Medium",
            },
            {
              type: "ColumnSet",
              spacing: "None",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "TextBlock",
                      text: "${formattedPickupDateAndTime} ${$root.atTimeText} ${formatDateTime(pickupDateAndTime, 'HH:mm')}",
                      wrap: true,
                      spacing: "None",
                    },
                    {
                      type: "TextBlock",
                      text: "${$root.pickUpText} ${$root.atPlaceText} ${pickupLocationText}",
                      wrap: true,
                      size: "Small",
                      spacing: "None",
                    },
                  ],
                },
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "TextBlock",
                      text: "${formattedDropOffDateAndTime} ${$root.atTimeText} ${formatDateTime(dropOffDateAndTime, 'HH:mm')}",
                      wrap: true,
                      horizontalAlignment: "Right",
                      spacing: "None",
                    },
                    {
                      type: "TextBlock",
                      text: "${$root.dropOffText} ${$root.atPlaceText} ${dropOffLocationText}",
                      wrap: true,
                      horizontalAlignment: "Right",
                      size: "Small",
                      spacing: "None",
                    },
                  ],
                },
              ],
            },
            {
              type: "TextBlock",
              text: "${$root.capacityText} ${capacity} | ${$root.luggageCapacityText} ${suitcaseCapacity} | ${$root.carModelText} ${carModel}",
              wrap: true,
              spacing: "Padding",
              size: "Small",
            },
          ],
          style: "emphasis",
        },
      ],
    },
    {
      type: "TextBlock",
      text: "${bookingAvailabilityText}",
      wrap: true,
      separator: true,
      spacing: "Small",
      size: "Small",
      $when: checkPreview,
    },
    {
      type: "ColumnSet",
      columns: [
        {
          type: "Column",
          width: "auto",
          selectAction: {
            type: "Action.OpenUrl",
            title: "${bookingButtonText}",
            url: "${redirectionURL}",
          },
          items: [
            {
              type: "ActionSet",
              actions: [
                {
                  type: "Action.OpenUrl",
                  title: "${bookingButtonText}",
                  url: "${redirectionURL}",
                },
              ],
            },
          ],
        },
      ],
      $when: checkPreview,
    },
  ],
};

export default shareCarFromCytricCard;
