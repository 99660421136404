import { ReactNode } from "react";

export interface IGenericInfo {
  title: string;
  detail?: string | ReactNode;
  link?: {
    url: string;
    textToDisplay?: string;
  };
  subdetail?: string;
  track?: TrackError;
}

export interface IGenericInfoAction {
  text: string;
  command: () => void;
}

export enum InfoImage {
  ERROR = "ERROR",
  ERROR_REQUEST = "ERROR_REQUEST",
  SESSION_EXPIRED = "SESSION_EXPIRED",
  CYTRIC_EASY = "CYTRIC_EASY",
  WELCOME = "WELCOME",
  BIG_GLASS = "BIG_GLASS",
  NOT_ACTIVATED = "NOT_ACTIVATED",
  NOT_ACTIVATED_CUSTOMIZED = "NOT_ACTIVATED_CUSTOMIZED",
}

export enum TrackError {
  TRAVEL_WORKFLOW_ERROR_VIEW = "TRAVEL_WORKFLOW_ERROR_VIEW",
}
