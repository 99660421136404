import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CloseCollaboratorsStatus from "../../../common/models/enums/ActivationStatusEnum";
import { IError } from "../../../common/models/Error";
import { CollaboratorsModel } from "../../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import {
  enhanceCloseCollaborators,
  searchCloseCollaborators,
} from "./CloseCollabroratorsAction";

interface CloseCollaboratorsStoreModel {
  status: CloseCollaboratorsStatus;
  closeCollaborators: CollaboratorsModel[];
  countryCode: string;
  isPending: boolean;
  isError: boolean;
  error: IError;
  notificationSentConfirmation: {
    isVisible: boolean;
    isError?: boolean;
    fetchTimoutId?: NodeJS.Timeout;
  };
}

const INITIAL_STATE: CloseCollaboratorsStoreModel = {
  status: CloseCollaboratorsStatus.UNKNOWN,
  closeCollaborators: [],
  countryCode: "",
  isPending: false,
  isError: false,
  error: {} as IError,
  notificationSentConfirmation: {
    isVisible: false,
    isError: false,
    fetchTimoutId: undefined,
  },
};

export const closeCollaboratorsSlice = createSlice({
  name: "closeCollaborators",
  initialState: INITIAL_STATE,
  reducers: {
    resetCloseCollaborators: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
    setNotificationSentConfirmation: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        isError?: boolean;
        fetchTimoutId?: NodeJS.Timeout;
      }>
    ) => {
      const { isVisible, isError, fetchTimoutId } = action.payload;

      return {
        ...state,
        notificationSentConfirmation: {
          isVisible,
          isError: isError || false,
          fetchTimoutId: fetchTimoutId || undefined,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchCloseCollaborators.pending, (state) => ({
      ...state,
      ...INITIAL_STATE,
      isPending: true,
    }));

    builder.addCase(
      searchCloseCollaborators.fulfilled,
      (
        state,
        action: PayloadAction<{
          status: CloseCollaboratorsStatus;
          closeCollaborators: CollaboratorsModel[];
          countryCode: string;
        }>
      ) => ({
        ...state,
        status: action.payload.status,
        closeCollaborators: action.payload.closeCollaborators,
        countryCode: action.payload.countryCode,
        isPending: false,
        isError: false,
      })
    );

    builder.addCase(
      searchCloseCollaborators.rejected,
      (state: any, action: any) => ({
        ...state,
        ...INITIAL_STATE,
        countryCode: action.payload.countryCode,
        isPending: false,
        isError: true,
        error: action.payload.error as IError,
      })
    );

    builder.addCase(
      enhanceCloseCollaborators.fulfilled,
      (
        state,
        action: PayloadAction<{
          closeCollaborators: CollaboratorsModel[];
        }>
      ) => ({
        ...state,
        closeCollaborators: action.payload.closeCollaborators,
      })
    );
  },
});

export const { resetCloseCollaborators, setNotificationSentConfirmation } =
  closeCollaboratorsSlice.actions;

export default closeCollaboratorsSlice.reducer;
