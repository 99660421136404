import { CloseIcon, Dialog, Flex, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../../utils/host.settings";
import infoDialogStyles from "./InfoDialog.module.scss";

const termsLink = "https://amadeus.com/en/policies/cytric-for-teams#terms";
const policiesLink = "https://amadeus.com/en/policies/amadeus-policies";
const settingsLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/settings`;

export const goToLink = (e: any) => {
  if (e.which === 32 || e.which === 13) {
    e.target.click();
  }
};

function InfoDialogContent(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide.popUp",
  });

  return (
    <Flex column>
      <Text>{t("info1")}</Text>
      <Text
        styles={() => ({
          marginTop: 20,
        })}
      >
        {t("info4")}
        <Text
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.brand.foreground1,
          })}
        >
          <Text
            onClick={() => {
              app.openLink(settingsLink);
            }}
            onKeyDown={goToLink}
            className={infoDialogStyles.settingsLink}
            role="link"
            tabIndex={0}
          >
            {t("settingsLink")}
          </Text>
        </Text>
        .
      </Text>
      <Text
        styles={() => ({
          marginTop: 20,
        })}
      >
        {t("info2")}
        <Text
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.brand.foreground1,
          })}
        >
          <a
            className={infoDialogStyles.links}
            target="_blank"
            rel="noreferrer"
            href={termsLink}
            onKeyDown={goToLink}
          >
            {t("termsLink")}
          </a>
        </Text>
        {t("info3")}
        <Text
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.brand.foreground1,
          })}
        >
          <a
            className={infoDialogStyles.links}
            target="_blank"
            rel="noreferrer"
            href={policiesLink}
            onKeyDown={goToLink}
          >
            {t("policiesLink")}
          </a>
        </Text>
        .
      </Text>
    </Flex>
  );
}

function InfoDialog({
  open,
  setOpen,
}: {
  open: any;
  setOpen: any;
}): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide.popUp",
  });

  const closeButton = {
    content: t("closeTitle"),
    flat: true,
    className: "close2__btn",
  };

  return (
    <Dialog
      className={infoDialogStyles.infoContainer}
      backdrop
      data-testid="share-my-ride-header"
      cancelButton={closeButton}
      onCancel={() => setOpen(!open)}
      header={t("header")}
      headerAction={{
        icon: <CloseIcon />,
        title: t("closeTitle"),
        className: "close__btn",
        onClick: () => setOpen(!open),
      }}
      open={open}
      content={<InfoDialogContent />}
    />
  );
}

export default InfoDialog;
