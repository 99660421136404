import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";

export default class ShareMyRideService {
  private static readonly SERVER_URL =
    process.env.REACT_APP_SHARE_MY_RIDE_BASE_API;

  private static readonly ENDPOINT_URL =
    process.env.REACT_APP_SHARE_MY_RIDE_ENDPOINT;

  private static readonly ENDPOINT_URL_PREFIX = process.env
    .REACT_APP_SHARE_MY_RIDE_ENDPOINT_PREFIX
    ? process.env.REACT_APP_SHARE_MY_RIDE_ENDPOINT_PREFIX
    : "";

  public static getPeopleSharing(
    tripId: any,
    product: any,
    isPolling: string,
    locationType: string,
    callbackUrl?: string
  ): any {
    const { date, productDetails } = product;
    const { locationCode } = productDetails;
    const GET_URL = `?location=${locationCode}&date=${date}&polling=${isPolling}&locationType=${locationType}`;

    if (callbackUrl) {
      return Http.get(
        `${this.SERVER_URL}/${this.ENDPOINT_URL_PREFIX}${callbackUrl}`
      );
    }
    return Http.get(
      `${this.SERVER_URL}${this.ENDPOINT_URL}${tripId}/rides${GET_URL}`
    );
  }

  public static enableShare(
    tripId: any,
    {
      segmentType,
      date,
      reference,
      locationAdditionalInfo,
      locationType,
      locationCode,
    }: any
  ): Promise<any> {
    const data = {
      segment: {
        segmentType,
        date,
        reference,
        locationAdditionalInfo,
        locationType,
        location: locationCode,
      },
    };
    return Http.post(
      `${this.SERVER_URL}${this.ENDPOINT_URL}${tripId}/rides`,
      data
    );
  }

  public static disableShare(
    tripId: any,
    {
      segmentType,
      date,
      reference,
      locationAdditionalInfo,
      locationType,
      locationCode,
    }: any
  ): Promise<any> {
    const data = {
      segment: {
        segmentType,
        date,
        reference,
        locationAdditionalInfo,
        locationType,
        location: locationCode,
      },
    };

    return Http.delete(
      `${this.SERVER_URL}${this.ENDPOINT_URL}${tripId}/rides`,
      { data }
    );
  }
}
