/* eslint-disable react/react-in-jsx-scope */
import { Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import useGraph from "../../../../hooks/useGraph";
import Styles from "./Header.module.scss";

function Header() {
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.upcomingTrips",
  });

  const { data: graphData } = useGraph(
    async (graph) => {
      const profile = await graph.api("/me").get();
      return { profile };
    },
    { scope: ["User.Read"] }
  );

  const userName = graphData ? ` ${graphData?.profile.givenName}` : "";

  return (
    <div className={Styles.header}>
      <div
        className={Styles.header__welcome}
        role="region"
        aria-label="Introduction"
      >
        <Text
          content={`${t("header.greeting")}${userName}`}
          className={Styles["header__user-name"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.dashboardHeader?.title,
          })}
        />
        <Text
          content={t("header.message")}
          className={Styles["header__welcome-message"]}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.dashboardHeader?.title,
          })}
        />
      </div>
    </div>
  );
}

export default Header;
