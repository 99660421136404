import { app } from "@microsoft/teams-js";
import HttpData from "../../common/models/HttpData";
import { RootState } from "../StoreHooks";

export const httpDataSelector = (state: RootState): HttpData =>
  state.appSettings.httpData;

export const isHttpDataFilledSelector = (state: RootState): boolean =>
  state.appSettings.isHttpDataFilled;

export const localeSelector = (state: RootState): string =>
  state.appSettings.locale;

export const contextSelector = (state: RootState): app.Context =>
  state.appSettings.context;
