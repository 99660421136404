import { GraphService } from "@amadeus-cytric/cytric-teams-react-common-library";

export default async function getPresenceCollaborators(
  client: any,
  collabList: any[]
) {
  const distinctUsersList = collabList.filter(
    (item: any, index: number) => collabList.indexOf(item) === index
  );

  let presencesGraph: any[] = await GraphService.getGraphUsersPresence(
    client,
    distinctUsersList
  );

  if (!presencesGraph) {
    presencesGraph = [];
  }
  // In case of error or missing ids, set default unknown value
  const missingIds = distinctUsersList.filter(
    (item: string) => !presencesGraph.find((x: any) => x.id === item)
  );

  missingIds.forEach((item: string) => {
    presencesGraph.push({
      id: item,
      body: {
        availability: "Unknown",
        activity: "Unknown",
      },
    });
  });

  return { responses: presencesGraph };
}
