import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import HostSettings from "../../utils/host.settings";

export default class FlowsService {
  public static async getFlows() {
    try {
      const response = await Http.get(
        `${HostSettings.getBaseAPI}${HostSettings.getBookingRights}`
      );
      if (response) {
        return response.data.data;
      }
      return null;
    } catch {
      return null;
    }
  }
}
