/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/require-default-props */
import {
  Button,
  Flex,
  Form,
  FormButton,
  Text,
} from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MessageToShare from "../../../components/message-to-share/MessageToShare";
import SearchFlightSearchComponent from "../search-contacts/ShareFlightSearchComponent";
import UserInfo from "../user-info/UserInfo";
import { IUserInfo } from "../user-info/UserInfo.model";
import sharePopupStyles from "./styles/share-popup.module.scss";

interface SharePopupProps {
  setSelectedRecipients: (recipients: []) => void;
  setMessageToShare: (text: string) => void;
  isMessageOptional: boolean;
  setContentHeaderText: string;
  submitShareHandle: () => void;
  cancelShareHandle: () => void;
  disableShareBtnHandle: () => boolean;
  setRecipientChannelsSelected: any;
  setRecipientGroupsSelected: any;
  type?: string;
  recipient?: IUserInfo;
  children?: any;
}

function SharePopup({
  setSelectedRecipients,
  setMessageToShare,
  isMessageOptional,
  setContentHeaderText,
  submitShareHandle,
  cancelShareHandle,
  disableShareBtnHandle,
  setRecipientChannelsSelected,
  setRecipientGroupsSelected,
  type,
  recipient,
  children,
}: SharePopupProps) {
  const { t } = useTranslation();
  const [, setDisableShare] = useState(true);

  const [themeName, setThemeName] = useState<any>();

  useEffect(() => {
    app
      .getContext()
      .then((ctx: app.Context) =>
        setThemeName(
          ctx.app.theme === "default"
            ? sharePopupStyles.white
            : sharePopupStyles.dark
        )
      );
  }, []);

  const getAriaLabel = () => {
    if (disableShareBtnHandle()) {
      if (type === "chat") {
        return t("chatWithTraveler.accessibility.button.sendButtonDisabled");
      }
    } else {
      if (type === "chat") {
        return t("chatWithTraveler.button.send");
      }
      return "Share";
    }

    return "Share";
  };

  return (
    <Flex
      column
      gap="gap.smaller"
      data-testid="share-popup-container"
      className={[themeName, sharePopupStyles.shareContent].join(" ")}
    >
      <Form className={sharePopupStyles.form}>
        <div className={sharePopupStyles.formWrapper}>
          {type === "chat" ? (
            <div className={sharePopupStyles.recipient}>
              <UserInfo user={recipient} />
            </div>
          ) : (
            SearchFlightSearchComponent(
              setSelectedRecipients,
              setDisableShare,
              setRecipientGroupsSelected,
              setRecipientChannelsSelected
            )
          )}
          <MessageToShare
            setMessageText={setMessageToShare}
            isOptional={isMessageOptional}
          />
          <div className={sharePopupStyles.formBlock}>
            <Text
              content={setContentHeaderText}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground2,
              })}
            />
            {children}
          </div>
        </div>
        <Flex hAlign="end" gap="gap.small" className={sharePopupStyles.actions}>
          <Button
            content={
              type === "chat"
                ? t("chatWithTraveler.button.cancel")
                : t("shareTrip.cancelButton")
            }
            onClick={() => cancelShareHandle()}
            data-testid="closeButtonSharePopup"
          />

          <FormButton
            data-testid="shareButtonSharePopup"
            content={
              type === "chat"
                ? t("chatWithTraveler.button.send")
                : t("shareTrip.shareButtonEnabled")
            }
            aria-label={getAriaLabel()}
            disabled={disableShareBtnHandle()}
            primary
            onClick={() => submitShareHandle()}
          />
        </Flex>
      </Form>
    </Flex>
  );
}

export default SharePopup;
