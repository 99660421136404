/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMicrosoftGraphClient } from "@amadeus-cytric/cytric-teams-react-common-library";
import ClassQueryChannels from "../classes/ClassQueryChannels";
import getCallGraph from "./GetCallGraph";
import GetChannels from "./GetChannels";
import GetTeamsInfo from "./GetTeamsInfo";

export default async function ShareFlightChannel(
  searchQuery: any,
  setRecipientChannelsSelected: any
) {
  const query = new ClassQueryChannels();
  const { scope, credential } = query;
  const requestUrlTeams = `me/joinedTeams`;
  if (setRecipientChannelsSelected) {
    try {
      const graph = createMicrosoftGraphClient(credential, scope);
      const { value: teams } = await getCallGraph(graph, requestUrlTeams);
      let channels: any;

      if (teams.length) {
        const teamsInfo = GetTeamsInfo(teams);
        channels = await GetChannels(teamsInfo, graph, query, searchQuery);
      }
      return channels;
    } catch {
      return [];
    }
  }
  return [];
}
