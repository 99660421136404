/* eslint-disable react/react-in-jsx-scope */
import { createMicrosoftGraphClient } from "@amadeus-cytric/cytric-teams-react-common-library";
import { app } from "@microsoft/teams-js";
import ClassQueryHits from "./classes/ClassQueryHits";
import getCallGraph from "./utils/GetCallGraph";
import getFilteredUser from "./utils/GetFilteredUser";
import getUsersPhotos from "./utils/GetUsersPhotos";

export default async function ShareFlightTophits(
  searchQuery: string,
  context: app.Context
) {
  const query = new ClassQueryHits();
  const { scope, top, credential } = query;
  const requestUrlUser = `me/people/?$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'&$top=${top}&$select=${
    query.select
  }&$search=${searchQuery.toLowerCase()}`;

  try {
    const graph = createMicrosoftGraphClient(credential, scope);
    const { value: users } = await getCallGraph(graph, requestUrlUser);
    let finalgroupsusers: any;
    if (users.length) {
      const usersFilter = getFilteredUser(users, context, searchQuery);
      finalgroupsusers = await getUsersPhotos(
        usersFilter,
        graph,
        query,
        "hits"
      );
    }

    return finalgroupsusers;
  } catch {
    return [];
  }
}
