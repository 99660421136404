import { TeamsUserCredential } from "@amadeus-cytric/cytric-teams-react-common-library";
import { ShortQueryParamsModel } from "../../../models/QueryParamsModel";

export default class ClasQueryGroups implements ShortQueryParamsModel {
  top = 3;

  credential: any;

  scope = ["User.Read.All", "Chat.ReadBasic", "Chat.Read"];

  constructor() {
    this.credential = new TeamsUserCredential();
  }
}
