/* eslint-disable no-template-curly-in-string */
const shareJoinCardV1 = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.0",
  body: [
    {
      type: "Container",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "TextBlock",
                  text: "${$root.meta.translations.title}", // NOSONAR
                  size: "Large",
                  weight: "Bolder",
                },
                {
                  type: "TextBlock",
                  text: "${$root.meta.translations.subtitle}", // NOSONAR
                  spacing: "None",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      spacing: "ExtraLarge",
      separator: true,
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "Image",
                  url: "${trip.mediaHref}", // NOSONAR
                  altText: "${trip.title}", // NOSONAR
                  size: "Large",
                  width: "48px",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              $when: "${!trip.oneWayFlight}", // NOSONAR
              items: [
                {
                  type: "TextBlock",
                  text: "${trip.customTitle}", // NOSONAR
                  weight: "Bolder",
                  size: "Large",
                  wrap: true,
                },
                {
                  type: "TextBlock",
                  text: "${formatDateTime(trip.start.localDateTime, 'ddd, d MMM yyyy')} - ${formatDateTime(trip.end.localDateTime, 'ddd, d MMM yyyy')}", // NOSONAR
                  spacing: "None",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              $when: "${trip.oneWayFlight}", // NOSONAR
              items: [
                {
                  type: "TextBlock",
                  text: "${trip.customTitle}", // NOSONAR
                  weight: "Bolder",
                  size: "large",
                  wrap: true,
                },
                {
                  type: "TextBlock",
                  text: "${formatDateTime(trip.end.localDateTime, 'ddd, d MMM yyyy')}", // NOSONAR
                  spacing: "None",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      separator: true,
      spacing: "Medium",
      items: [
        {
          type: "Container",
          $data: "${trip.products[0].products}", // NOSONAR
          items: [
            {
              type: "Container",
              $when: "${Trip_Product_Air != null}", // NOSONAR
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Air.departure.dateTime, 'HH:mm')} ${Trip_Product_Air.departure.iataCode}", // NOSONAR
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Air.departure.dateTime, 'ddd, d MMM yyyy')}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Air.departure.airportName}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "40px",
                      items: [
                        {
                          type: "Image",
                          url: "${$root.meta.flightIcon}", // NOSONAR
                          altText: "Flight to",
                          width: "16px",
                          spacing: "None",
                          horizontalAlignment: "Center",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Air.arrival.dateTime, 'HH:mm')} ${Trip_Product_Air.arrival.iataCode}", // NOSONAR
                          wrap: true,
                          weight: "Bolder",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Air.arrival.dateTime, 'ddd, d MMM yyyy')}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Air.arrival.airportName}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "TextBlock",
                  size: "Small",
                  text: "${customInfo.numberOfStops}${customInfo.timeAndName} | ${customInfo.nameAndNumber}", // NOSONAR
                  wrap: true,
                },
              ],
            },
            {
              type: "Container",
              $when: "${Trip_Product_Hotel != null}", // NOSONAR
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: 3,
                      items: [
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Hotel.name}", // NOSONAR
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Hotel.checkInDate,'ddd, d MMM yyyy') } - ${formatDateTime(Trip_Product_Hotel.checkOutDate, 'ddd, d MMM yyyy')}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Hotel.hotelNights}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "ColumnSet",
                          width: "stretch",
                          spacing: "Small",
                          columns: [
                            // {
                            //   type: "Column",
                            //   width: "14px",
                            //   items: [
                            //     {
                            //       type: "Image",
                            //       url: "${$root.meta.coffeeIcon}",
                            //       altText:
                            //         "${Trip_Product_Hotel.breakfastInfo}",
                            //       width: "14px",
                            //     },
                            //   ],
                            // },
                            {
                              type: "Column",
                              width: "auto",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${Trip_Product_Hotel.breakfastInfo}", // NOSONAR
                                  wrap: true,
                                  size: "Small",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      verticalContentAlignment: "Center",
                    },
                  ],
                },
              ],
            },
            {
              type: "Container",
              $when: "${Trip_Product_Car != null}", // NOSONAR
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: 4,
                      items: [
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Car.serviceProviderName}", // NOSONAR
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Car.pickup.dateTime, 'ddd, d MMM yyyy')} - ${formatDateTime(Trip_Product_Car.dropoff.dateTime, 'ddd, d MMM yyyy')}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${$root.meta.translations.pickUp} ${Trip_Product_Car.pickup.location.name}", // NOSONAR
                          wrap: true,
                          size: "Small",
                        },
                        {
                          type: "TextBlock",
                          text: "${$root.meta.translations.dropOff} ${Trip_Product_Car.dropoff.location.name}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                      ],
                      verticalContentAlignment: "Center",
                    },
                  ],
                },
              ],
            },
            {
              type: "Container",
              $when: "${Trip_Product_Train != null}", // NOSONAR
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Train.departureDateTime, 'HH:mm')}", // NOSONAR
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Train.departureDateTime, 'ddd, d MMM yyyy')}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Train.departure.name}", // NOSONAR
                          wrap: true,
                          spacing: "None",
                          size: "Small",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "40px",
                      items: [
                        {
                          type: "Image",
                          url: "${$root.meta.railIcon}", // NOSONAR
                          altText: "Train to",
                          width: "14px",
                          spacing: "None",
                          horizontalAlignment: "Center",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Train.arrivalDateTime, 'HH:mm')}", // NOSONAR
                          wrap: true,
                          weight: "bolder",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Train.arrivalDateTime, 'ddd, d MMM yyyy')}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Train.arrival.name}", // NOSONAR
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "TextBlock",
                  text: "${customInfo.numberOfStops}${customInfo.timeAndName} | ${customInfo.nameAndNumber}", // NOSONAR
                  wrap: true,
                  size: "Small",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "TextBlock",
      text: "${$root.meta.translations.footer}", // NOSONAR
      wrap: true,
      size: "Small",
    },
  ],
};

export default shareJoinCardV1;
