/* eslint-disable react/react-in-jsx-scope */
import Responsive from "../../../../common/components/responsive/Responsive";
import Trip from "../../../../common/models/Trip";
import TripDestination from "../../../../common/models/TripDestination";
import TripHelper from "../../../../helpers/TripHelper";
import { useAppSelector } from "../../../../store/StoreHooks";
import returnTripProduct from "../../../../store/trips/functions/ReturnTripProduct";
import CloseCollaborators from "../../../collaborators/close-collaborators/CloseCollaborators";
import ShareMyRide from "../../../share-my-ride/ShareMyRide";
import ToDo from "../../../todo-list";
import { tripsV1Activated } from "../../data/constants";
import { hasItineraryTypes } from "../../utils/trip-utils";
import WidgetContainer from "../WidgetContainer/WidgetContainer";
import BookingDetails from "../Widgets/BookingDetails/BookingDetails";
import BookingDetailsV1 from "../Widgets/BookingDetailsV1/BookingDetails";
import { IWidgetsGridProps } from "./WidgetsGrid.model";
import Styles from "./WidgetsGrid.module.scss";

function WidgetsGrid({ trip, pastTrip }: IWidgetsGridProps) {
  const tripDetailV1 = useAppSelector(
    (state: any) => state.tripDetailV1.tripDetails
  );

  const convertedTrip: Trip = returnTripProduct(trip);

  const displayShareRide = tripsV1Activated()
    ? tripDetailV1.products?.filter(
        (e: any) =>
          e.productType === "Trip_Product_Air" ||
          e.productType === "Trip_Product_Train"
      ).length > 0
    : trip?.products[0]?.hasAir || trip?.products[0]?.hasRail;

  const tripDestination: TripDestination | undefined =
    TripHelper.computeTripDestination(convertedTrip);

  if (!pastTrip) {
    return (
      <div className={Styles["widgets-grid-container"]}>
        <WidgetContainer>
          {tripsV1Activated() ? <BookingDetailsV1 /> : <BookingDetails />}
        </WidgetContainer>
        <WidgetContainer className={Styles["widgets-grid__to-do-list"]}>
          <ToDo />
        </WidgetContainer>
        {displayShareRide && <ShareMyRide />}
        {tripDestination && !hasItineraryTypes(trip, "car") && (
          <CloseCollaborators destination={tripDestination} />
        )}
      </div>
    );
  }
  return (
    <>
      <Responsive forMaxSize="tablet">
        <div className={Styles["widgets-grid-container-past-trips-mobile"]}>
          <WidgetContainer>
            {tripsV1Activated() ? <BookingDetailsV1 /> : <BookingDetails />}
          </WidgetContainer>
        </div>
      </Responsive>
      <Responsive forMinSize="desktopSmall">
        <div className={Styles["widgets-grid-container-past-trips"]}>
          <WidgetContainer>
            {tripsV1Activated() ? <BookingDetailsV1 /> : <BookingDetails />}
          </WidgetContainer>
        </div>
      </Responsive>
    </>
  );
}

export default WidgetsGrid;
