import HostSettings from "../../../utils/host.settings";

/* eslint-disable no-template-curly-in-string */
const checkPreview = "${isPreview != true}";
const imgPlane = `${HostSettings.getCDNIcons}plane-white.png`;
const shareFlightFromCytricCard = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.3",
  body: [
    {
      type: "TextBlock",
      text: "${bookingOptionText}",
      wrap: true,
      weight: "Bolder",
      size: "Large",
      $when: checkPreview,
    },
    {
      type: "TextBlock",
      text: "${bookingDetailsText}",
      wrap: true,
      size: "Small",
      weight: "Lighter",
      spacing: "None",
      $when: checkPreview,
    },
    {
      type: "Container",
      spacing: "Medium",
      separator: true,
      items: [
        {
          type: "TextBlock",
          text: "${cityNameString}",
          wrap: true,
          weight: "Bolder",
          size: "Large",
        },
        {
          type: "TextBlock",
          text: "${tripDate}",
          wrap: true,
          spacing: "Small",
          size: "Small",
        },
      ],
    },
    {
      type: "Container",
      $data: "${itineraries}",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "TextBlock",
                  text: "${formatDateTime(departureDateTime, 'HH:mm')} ${departureLocationCode}",
                  wrap: true,
                  weight: "Bolder",
                },
                {
                  type: "TextBlock",
                  text: "${formattedDepartureDateTime}",
                  wrap: true,
                  size: "Small",
                  spacing: "None",
                  weight: "Lighter",
                },
                {
                  type: "TextBlock",
                  text: "${originAirportName}",
                  wrap: true,
                  spacing: "None",
                  size: "Small",
                },
              ],
            },
            {
              type: "Column",
              width: "16px",
              items: [
                {
                  type: "Image",
                  url: imgPlane,
                  width: "16px",
                  height: "16px",
                  horizontalAlignment: "Center",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "TextBlock",
                  text: "${formatDateTime(arrivalDateTime, 'HH:mm')} ${arrivalLocationCode}",
                  wrap: true,
                  horizontalAlignment: "Right",
                  weight: "Bolder",
                },
                {
                  type: "TextBlock",
                  text: "${formattedArrivalDateTime}",
                  wrap: true,
                  horizontalAlignment: "Right",
                  size: "Small",
                  spacing: "None",
                  weight: "Lighter",
                },
                {
                  type: "TextBlock",
                  text: "${destinationAirportName}",
                  wrap: true,
                  horizontalAlignment: "Right",
                  spacing: "None",
                  size: "Small",
                },
              ],
            },
          ],
        },
        {
          type: "TextBlock",
          text: "${numberOfStops} ${stopDuration} | ${segments[0].airlineCode},${itineraryFlightNumbers}",
          wrap: true,
          spacing: "Padding",
          size: "Small",
        },
      ],
      style: "emphasis",
    },
    {
      type: "TextBlock",
      text: "${bookingAvailabilityText}",
      wrap: true,
      separator: true,
      spacing: "Small",
      size: "Small",
      $when: checkPreview,
    },
    {
      type: "ColumnSet",
      columns: [
        {
          type: "Column",
          width: "auto",
          selectAction: {
            type: "Action.OpenUrl",
            title: "${bookingButtonText}",
            url: "${redirectionURL}",
          },
          items: [
            {
              type: "ActionSet",
              actions: [
                {
                  type: "Action.OpenUrl",
                  title: "${bookingButtonText}",
                  url: "${redirectionURL}",
                },
              ],
            },
          ],
        },
      ],
      $when: checkPreview,
    },
  ],
};

export default shareFlightFromCytricCard;
