/* eslint-disable no-template-curly-in-string */

import HostSettings from "../../../utils/host.settings";

const checkPreview = "${isPreview != true}";
const imgRail = `${HostSettings.getCDNIcons}rail.png`;
const shareRailFromCytricCard = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.3",
  body: [
    {
      type: "TextBlock",
      text: "${bookingOptionText}",
      wrap: true,
      weight: "Bolder",
      size: "Large",
      $when: checkPreview,
    },
    {
      type: "TextBlock",
      text: "${bookingDetailsText}",
      wrap: true,
      size: "Small",
      weight: "Lighter",
      spacing: "None",
      $when: checkPreview,
    },
    {
      type: "Container",
      spacing: "Medium",
      separator: true,
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              spacing: "Medium",
              items: [
                {
                  type: "TextBlock",
                  text: "${locationsString}",
                  wrap: true,
                  weight: "Bolder",
                  size: "Large",
                },
                {
                  type: "TextBlock",
                  text: "${dateTimeString}",
                  wrap: true,
                  spacing: "Small",
                  size: "Small",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      $data: "${itineraries}",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "TextBlock",
                  text: "${formatDateTime(departureDateTime, 'HH:mm')} ${departureStationName}",
                  wrap: true,
                  weight: "Bolder",
                },
                {
                  type: "TextBlock",
                  text: "${formattedDepartureDate}",
                  wrap: true,
                  size: "Small",
                  spacing: "None",
                  weight: "Lighter",
                },
                {
                  type: "TextBlock",
                  text: "${departureStationName}",
                  wrap: true,
                  spacing: "None",
                  size: "Small",
                },
              ],
            },
            {
              type: "Column",
              width: "16px",
              items: [
                {
                  type: "Image",
                  url: imgRail,
                  width: "16px",
                  height: "16px",
                  horizontalAlignment: "Center",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "TextBlock",
                  text: "${formatDateTime(arrivalDateTime, 'HH:mm')} ${arrivalStationName}",
                  wrap: true,
                  horizontalAlignment: "Right",
                  weight: "Bolder",
                },
                {
                  type: "TextBlock",
                  text: "${formattedArrivalDate}",
                  wrap: true,
                  horizontalAlignment: "Right",
                  size: "Small",
                  spacing: "None",
                  weight: "Lighter",
                },
                {
                  type: "TextBlock",
                  text: "${arrivalStationName}",
                  wrap: true,
                  horizontalAlignment: "Right",
                  spacing: "None",
                  size: "Small",
                },
              ],
            },
          ],
        },
        {
          type: "TextBlock",
          text: "${stopsText} | ${segments[0].operatedBy} ${segments[0].trainNumber}",
          wrap: true,
          spacing: "Padding",
          size: "Small",
          $when: "${segmentsNumber == '1'}",
        },
        {
          type: "TextBlock",
          text: "${stopsText} | ${totalStopDuration}",
          wrap: true,
          spacing: "Padding",
          size: "Small",
          $when: "${int(segmentsNumber) > 1}",
        },
      ],
      style: "emphasis",
    },
    {
      type: "TextBlock",
      text: "${bookingAvailabilityText}",
      wrap: true,
      separator: true,
      spacing: "Small",
      size: "Small",
      $when: checkPreview,
    },
    {
      type: "ColumnSet",
      columns: [
        {
          type: "Column",
          width: "auto",
          selectAction: {
            type: "Action.OpenUrl",
            title: "${bookingButtonText}",
            url: "${redirectionURL}",
          },
          items: [
            {
              type: "ActionSet",
              actions: [
                {
                  type: "Action.OpenUrl",
                  title: "${bookingButtonText}",
                  url: "${redirectionURL}",
                },
              ],
            },
          ],
        },
      ],
      $when: checkPreview,
    },
  ],
};

export default shareRailFromCytricCard;
