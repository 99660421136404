import Products from "../../../common/models/Product";
import ProductAir from "../../../common/models/ProductAir";
import ProductCar from "../../../common/models/ProductCar";
import ProductHotel from "../../../common/models/ProductHotel";
import ProductTrain from "../../../common/models/ProductTrain";
import Trip from "../../../common/models/Trip";
import TripProduct from "../../../common/models/TripProduct";

const getProducts = (prod: any) => {
  const typeProduct = {} as TripProduct;

  // Air section
  if (prod.productType === "Trip_Product_Air") {
    typeProduct.Trip_Product_Air = prod as ProductAir;
  }

  // Train section
  if (prod.productType === "Trip_Product_Train") {
    typeProduct.Trip_Product_Train = prod as ProductTrain;
  }
  // Hotel section
  if (prod.productType === "Trip_Product_Hotel") {
    typeProduct.Trip_Product_Hotel = prod as ProductHotel;
  }
  // Car section
  if (prod.productType === "Trip_Product_Car") {
    typeProduct.Trip_Product_Car = prod as ProductCar;
  }

  return typeProduct;
};

const returnTripProduct = (ele: any) => {
  const trip = {} as Trip;

  trip.name = ele.name;
  trip.id = ele.id;
  trip.purpose = ele.purpose;
  trip.startDate = ele.startDate;
  trip.endDate = ele.endDate;

  trip.destinationPicture = ele.destinationPicture;
  trip.destinationIata = ele.destinationIata;
  trip.destinationCities = ele.destinationCities;
  trip.destinationCountryCode = ele.destinationCountryCode;
  trip.status = ele.status;

  const productTripArray = new Array<Products>();
  if (ele.products) {
    ele.products.forEach((eleProd: any) => {
      const productTrip = {} as Products;

      productTrip.hasAir = eleProd.hasAir;
      productTrip.hasRail = eleProd.hasRail;
      productTrip.hasHotel = eleProd.hasHotel;
      productTrip.hasCar = eleProd.hasCar;

      const typeProductArray = new Array<TripProduct>();

      if (eleProd.products) {
        eleProd.products.forEach((prod: any) => {
          const typeProduct = getProducts(prod);

          typeProductArray.push(typeProduct);
        });

        productTrip.products = typeProductArray;
      }
      productTripArray.push(productTrip);
    });
    trip.products = productTripArray;
  }

  return trip;
};

export default returnTripProduct;
