import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CytricContextModel {
  lastVisitedUrl: string;
  lastVisitedPage: string;
  lastUrlDateTimeout: number;
}

const INITIAL_STATE: CytricContextModel = {
  lastVisitedUrl: localStorage.getItem("lastVisitedUrl") || "",
  lastVisitedPage: localStorage.getItem("lastVisitedPage") || "",
  lastUrlDateTimeout: Number(localStorage.getItem("lastUrlDateTimeout") || 0),
};

const IFRAME_SESSION_TIMEOUT: number = Number(
  process.env.REACT_APP_CYTRIC_IFRAME_SESSION_TIMEOUT || 600000
);

export const cytricContextSlice = createSlice({
  name: "cytricContext",
  initialState: INITIAL_STATE,
  reducers: {
    updateCytricContext: (
      state,
      action: PayloadAction<{ url: string; pageName: string }>
    ) => {
      const timeoutSession = new Date(
        new Date().getTime() + IFRAME_SESSION_TIMEOUT
      ).getTime();

      localStorage.setItem("lastVisitedUrl", action.payload.url);
      localStorage.setItem("lastVisitedPage", action.payload.pageName);
      localStorage.setItem("lastUrlDateTimeout", String(timeoutSession));

      return {
        ...state,
        lastVisitedUrl: action.payload.url,
        lastVisitedPage: action.payload.pageName,
        lastUrlDateTimeout: timeoutSession,
      };
    },

    resetCytricContext: (state) => {
      localStorage.setItem("lastVisitedUrl", "");
      localStorage.setItem("lastVisitedPage", "");
      localStorage.setItem("lastUrlDateTimeout", "0");
      return {
        ...state,
        lastVisitedUrl: "",
        lastVisitedPage: "",
        lastUrlDateTimeout: 0,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCytricContext, resetCytricContext } =
  cytricContextSlice.actions;

export default cytricContextSlice.reducer;
