/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notifyCloseCollaborators } from "./CloseCollaboratorsNotificationAction";

interface CloseCollaboratorsNotificationStoreModel {
  closeCollaboratorIds: string[];
  isPending: boolean;
}

const INITIAL_STATE: CloseCollaboratorsNotificationStoreModel = {
  closeCollaboratorIds: [],
  isPending: false,
};

export const closeCollaboratorsNotificationSlice = createSlice({
  name: "closeCollaboratorsNotification",
  initialState: INITIAL_STATE,
  reducers: {
    addCloseCollaboratorToNotify: (
      state: any,
      action: PayloadAction<{ closeCollaboratorId: string }>
    ) => {
      const { closeCollaboratorIds } = state;
      if (!closeCollaboratorIds.includes(action.payload.closeCollaboratorId)) {
        closeCollaboratorIds.push(action.payload.closeCollaboratorId);
      }
    },
    addAllCloseCollaboratorToNotify: (
      state: any,
      action: PayloadAction<{ closeCollaboratorIds: string[] }>
    ) => ({
      ...state,
      closeCollaboratorIds: action.payload.closeCollaboratorIds,
    }),
    removeCloseCollaboratorToNotify: (
      state: any,
      action: PayloadAction<{ closeCollaboratorId: string }>
    ) => {
      const { closeCollaboratorIds } = state;
      const idIndex = closeCollaboratorIds.indexOf(
        action.payload.closeCollaboratorId
      );
      if (idIndex !== -1) {
        closeCollaboratorIds.splice(idIndex, 1);
      }
    },
    resetCloseCollaboratorToNotify: (state: any) => ({
      ...state,
      ...INITIAL_STATE,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(notifyCloseCollaborators.pending, (state) => ({
      ...state,
      isPending: true,
    }));

    builder.addCase(notifyCloseCollaborators.fulfilled, (state) => ({
      ...state,
      closeCollaboratorIds: [],
      isPending: false,
    }));

    builder.addCase(notifyCloseCollaborators.rejected, (state) => ({
      ...state,
      closeCollaboratorIds: [],
      isPending: false,
    }));
  },
});

export const {
  addCloseCollaboratorToNotify,
  removeCloseCollaboratorToNotify,
  resetCloseCollaboratorToNotify,
  addAllCloseCollaboratorToNotify,
} = closeCollaboratorsNotificationSlice.actions;

export default closeCollaboratorsNotificationSlice.reducer;
