import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";

const getCurrentDate = (segment: any, language: string) => {
  const { [Object.keys(segment)[0]]: segmentType } = segment;
  let segmentStartDate;
  let segmentEndDate;
  if (segmentType) {
    switch (segmentType.productType) {
      case "Trip_Product_Train":
        segmentStartDate = segmentType.departureDateTime;
        segmentEndDate = segmentType.arrivalDateTime;
        break;
      case "Trip_Product_Hotel":
        segmentStartDate = segmentType.checkInDate;
        segmentEndDate = segmentType.checkOutDate;
        break;
      case "Trip_Product_Car":
        segmentStartDate = segmentType.pickup?.dateTime;
        segmentEndDate = segmentType.dropoff?.dateTime;
        break;
      default:
        segmentStartDate = segmentType.departure?.dateTime;
        segmentEndDate = segmentType.arrival?.dateTime;
    }
  }

  const startDate = DateUtils.getDateFormat(
    segmentStartDate,
    language,
    DateFormat.DATE_SHORT
  );
  const endDate = DateUtils.getDateFormat(
    segmentEndDate,
    language,
    DateFormat.DATE_SHORT
  );

  if (startDate === endDate) {
    return startDate;
  }

  return `${startDate} - ${endDate}`;
};

export default getCurrentDate;
