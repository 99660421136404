import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Text } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import { complexTitleNameManager } from "../../../../../../../../../helpers/TripTitleName";
import RedirectionService from "../../../../../../../../../services/redirection/RedirectionService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/StoreHooks";
import {
  getCarPermissions,
  getFlightPermissions,
  getHotelPermissions,
  getRailPermissions,
} from "../../../../../../../../../store/flows/FlowsPermissionsSelector";
import { isValidUrlAndDomain } from "../../../../../../../../../utils/validation.utils";
import { SegmentType } from "../../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";
import BookingRedirections from "../../../../../BookingRedirections";
import Styles from "./NewProduct.module.scss";
import {
  NewProductTrainIcon,
  NewProductTrainScreenReader,
} from "./NewProductTrainButton";

interface NewProductProps {
  productType: string;
  numOfProducts: number;
}
// required to avoid a sonar error

const bookElement = async (
  t: any,
  trip: any,
  productType: string,
  numOfProducts: number,
  dispatch: any,
  history: any
) => {
  const name = complexTitleNameManager(t, trip);

  const tripId = trip.id;

  localStorage.removeItem("KeepSessionCache");
  localStorage.setItem("tripDetailTab", "");

  if (productType === "flight") {
    telemetryService.trackEvent(
      { name: "travel-booking-bookFlight" },
      {
        numOfFlights: numOfProducts,
        tripId,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_FLIGHT,
      tripId,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  }
  if (productType === "train") {
    telemetryService.trackEvent(
      { name: "travel-booking-bookTrain" },
      {
        numOfTrains: numOfProducts,
        tripId,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_RAIL,
      tripId,
    };

    const urlObject = await RedirectionService.getCytricUrl(redirectionInput);
    if (urlObject?.data?.data?.cytricUrl) {
      const url = urlObject.data.data.cytricUrl as string;
      if (isValidUrlAndDomain(url)) {
        window.open(url, "_blank", "noopener noreferrer");
      }
    }
  }
  if (productType === "hotel") {
    telemetryService.trackEvent(
      { name: "travel-booking-bookHotel" },
      {
        numOfHotels: numOfProducts,
        tripId,
      }
    );
    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_HOTEL,
      tripId,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  }
};

const setData = (
  t: any,
  productType: string,
  permissions: any,
  setTranslations: any,
  setShowComponent: any
) => {
  if (productType === "flight" && permissions.flightPerm) {
    setTranslations({
      text: `${t(
        "upcoming_trips_dashboard.tripDetails.newProduct.label.flight"
      )}`,
      button: `${t(
        "upcoming_trips_dashboard.tripDetails.newProduct.buttons.flight"
      )}`,
    });
    setShowComponent(true);
  }
  if (productType === "train" && permissions.railPerm) {
    setTranslations({
      text: `${t(
        "upcoming_trips_dashboard.tripDetails.newProduct.label.train"
      )}`,
      button: `${t(
        "upcoming_trips_dashboard.tripDetails.newProduct.buttons.train"
      )}`,
    });
    setShowComponent(true);
  }
  if (productType === "hotel" && permissions.hotelPerm) {
    setTranslations({
      text: `${t(
        "upcoming_trips_dashboard.tripDetails.newProduct.label.hotel"
      )}`,
      button: `${t(
        "upcoming_trips_dashboard.tripDetails.newProduct.buttons.hotel"
      )}`,
    });
    setShowComponent(true);
  }
  if (productType === "car" && permissions.carPerm) {
    setTranslations({
      text: `${t("upcoming_trips_dashboard.tripDetails.newProduct.label.car")}`,
      button: "",
    });
    setShowComponent(true);
  }
};

function NewProduct({ productType, numOfProducts }: NewProductProps) {
  const { t } = useTranslation();
  const [translations, setTranslations] = useState({ text: "", button: "" });
  const [showComponent, setShowComponent] = useState(false);
  const trip = useAppSelector((state) => state.tripDetailV1.tripDetails);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const carPerm: SegmentType = useAppSelector((state) =>
    getCarPermissions(state)
  );
  const hotelPerm: SegmentType = useAppSelector((state) =>
    getHotelPermissions(state)
  );
  const railPerm: SegmentType = useAppSelector((state) =>
    getRailPermissions(state)
  );
  const flightPerm: SegmentType = useAppSelector((state) =>
    getFlightPermissions(state)
  );

  const bookCar = () => {
    const name = complexTitleNameManager(t, trip);
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookCar" },
      {
        numOfCars: numOfProducts,
        tripId: trip.id,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_CAR,
      tripId,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  const bookTransfer = () => {
    const name = complexTitleNameManager(t, trip);
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookTransfer" },
      {
        numOfCars: numOfProducts,
        tripId: trip.id,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_TRANSFER,
      tripId,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  useEffect(() => {
    const permissions = { flightPerm, railPerm, hotelPerm, carPerm };
    setData(t, productType, permissions, setTranslations, setShowComponent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType]);

  const carButton = (
    <Text
      data-testid="carProductButtonId"
      role="button"
      tabIndex={0}
      className={Styles.button}
      content={`${t(
        "upcoming_trips_dashboard.tripDetails.newProduct.buttons.car"
      )}`}
      styles={(theme) => ({
        color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
      })}
      onClick={() => bookCar()}
    />
  );

  function transferButton(isOnlyOption: boolean) {
    const transferText = t(
      "upcoming_trips_dashboard.tripDetails.newProduct.buttons.transfer"
    );

    const buttonContent = `${
      isOnlyOption
        ? transferText.charAt(0).toUpperCase() + transferText.slice(1)
        : transferText
    }`;

    return (
      <Text
        data-testid="transferProductButtonId"
        role="button"
        tabIndex={0}
        className={Styles.button}
        content={buttonContent}
        styles={(theme) => ({
          color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
        })}
        onClick={() => bookTransfer()}
      />
    );
  }

  const orText = (
    <Text
      className={Styles.newProductTextO}
      content={t("upcoming_trips_dashboard.tripDetails.newProduct.label.or")}
      styles={(theme) => ({
        color: theme.theme.siteVariables.colorScheme?.default.foreground2,
      })}
    />
  );

  const carButtons = () => {
    const carPermArray = carPerm.bookingTargets
      ? carPerm.bookingTargets.filter((item) => item.activated)
      : [];
    if (carPermArray.length > 1) {
      return (
        <>
          {carButton}
          {orText}
          {transferButton(false)}
        </>
      );
    }
    if (carPermArray[0].type === "CAR_BOOKING") {
      return carButton;
    }
    if (carPermArray[0].type === "CAR_BOOKING_MY_DRIVER") {
      return transferButton(true);
    }
    return null;
  };

  return showComponent ? (
    <div className={Styles.container}>
      <div>
        <Text
          className={Styles.newProductText}
          content={translations.text}
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.foreground2,
          })}
        />
        {productType !== "car" ? (
          <Text
            className={Styles.button}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
            })}
            tabIndex={0}
            data-testid="productButtonId"
            onClick={() =>
              bookElement(
                t,
                trip,
                productType,
                numOfProducts,
                dispatch,
                history
              )
            }
          >
            {translations.button}
            {NewProductTrainScreenReader(t, productType)}
            {NewProductTrainIcon(productType)}
          </Text>
        ) : (
          <>{carButtons()}</>
        )}
      </div>
    </div>
  ) : null;
}

export default NewProduct;
