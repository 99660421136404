/* eslint-disable no-plusplus */

import { IDataV1 } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";

import { RootState } from "../../StoreHooks";

export const getProductsByTypeV1 = (state: any, productType: string) => {
  const { tripDetails } = state?.tripDetailV1 || {};

  return tripDetails?.products?.filter(
    (product: { productType: string }) => product.productType === productType
  );
};

export const sortFlightProductsV1 = (segmentsV1: any) => {
  if (segmentsV1) {
    const sortedSegments = [...segmentsV1].sort((a: any, b: any) =>
      a.departure.dateTime > b.departure.dateTime ? 1 : -1
    );
    return sortedSegments;
  }
  return [];
};

export const sortTrainProductsV1 = (segmentsV1: any) => {
  if (segmentsV1) {
    const sortedSegments = [...segmentsV1].sort((a: any, b: any) =>
      a.departureDateTime > b.departureDateTime ? 1 : -1
    );
    return sortedSegments;
  }
  return [];
};

export const selectTripDetailsV1 = (state: RootState): IDataV1 =>
  state.tripDetailV1.tripDetails;
