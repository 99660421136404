import {
  Divider,
  Flex,
  Image,
  ItemLayout,
  Segment,
  Text,
} from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import i18next from "i18next";
import { useEffect, useState } from "react";
import TripHelperV1 from "../../../../../helpers/TripHelperV1";
import HostSettings from "../../../../../utils/host.settings";
import { IDataV1 } from "../../../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import Styles from "../../../ShareJoin.module.scss";
import { useDispatch, useStore } from "../../../context/ShareJoinContext";
import { types } from "../../../context/ShareJoinReducer";
import TripPanelContentV1 from "../../trip-panel-content/TripPanelContentV1";

const { t } = i18next;

function AdaptiveCardTripV1({ trip, tripList, selectedTripId }: any) {
  const [clientType, setClientType] = useState<string>("");
  const tripData: IDataV1 = trip.data;
  const tripId = trip.id;
  const dispatch = useDispatch();
  const selected = tripId === selectedTripId;
  const { range } = TripHelperV1.getStartEndDate(
    tripData.start.localDateTime,
    tripData.end.localDateTime
  );

  const { tripTemplate } = useStore();

  function getImageUrl(): string {
    if (tripData.media) {
      if (
        tripData.media.length > 1 &&
        tripData.media[1].href &&
        tripData.media[1].href !== ""
      ) {
        // check and return the second media object as it should
        // contain square image of the destination
        return tripData.media[1].href;
      }
    }
    return `${HostSettings.getCDNImages}default/square.png`;
  }

  useEffect(() => {
    dispatch({
      type: types.setSegments,
      payload: {
        products: [],
        hasAir: false,
        hasHotel: false,
        hasRail: false,
        hasCar: false,
      },
    });
    dispatch({ type: types.resetInitialSegments });

    app.initialize().then(() => {
      app.getContext().then((ctx: app.Context) => {
        setClientType(ctx.app.host.clientType);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentTitle = (
    <Text
      size="small"
      weight="regular"
      className={Styles.tripDate}
      content={range}
    />
  );

  const imgTitle = (
    <Image
      id={`city-img-${tripData.id}`}
      className={Styles.destinationPicture}
      src={getImageUrl()}
      alt-text={t("shareTrip.adaptiveCard.destinationImageAltText")}
      aria-hidden="true"
    />
  );

  const header = (
    <Text
      size="large"
      weight="bold"
      content={TripHelperV1.getTripTitleForCPFlow(trip, clientType)}
      data-testid="Trip name"
    />
  );

  const getTripBorder = (theme: any) => {
    if (tripList && tripTemplate.id !== tripId) {
      return `none`;
    }
    if (tripList && tripTemplate.id === tripId) {
      return `2px solid ${theme.theme.siteVariables.colorScheme?.brand.foregroundActive}`;
    }
    return `1px solid ${theme.theme.siteVariables.colorScheme?.default.border2}`;
  };

  return (
    <Flex column className={Styles.adaptiveCard}>
      <Segment
        key={`card-${tripData.id}`}
        className={
          tripList && tripTemplate.id !== tripId
            ? Styles.segment
            : Styles.segmentSelected
        }
        styles={(theme) => ({
          border: getTripBorder(theme),
          borderRadius: "4px",
          backgroundColor:
            theme.theme.siteVariables.colorScheme?.default.backgroundActive1,
          boxShadow: tripList
            ? "0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11);"
            : "none",
        })}
      >
        <ItemLayout
          className={Styles.tripCardHeader}
          media={imgTitle}
          endMedia={tripList}
          header={header}
          content={contentTitle}
        />

        {(selected || !tripList) && (
          <Flex column>
            <Divider className={Styles.divider} />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <TripPanelContentV1 {...tripData} />
          </Flex>
        )}
      </Segment>
    </Flex>
  );
}

export default AdaptiveCardTripV1;
