import {
  ArrowRightIcon,
  ChevronDownIcon,
  ComponentStyleFunctionParam,
  ExclamationTriangleIcon,
  Flex,
  Image,
  Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DateUtils from "../../../../../../../../../utils/date.utils";
import { handleProviderLogoError } from "../../../../../../../utils/trip-utils";
import AriaStyle from "../../../SegmentTabs.module.scss";
import { IArrowIconProps, IProvider } from "../Accordion/Accordion.model";
import StylesAcc from "../Accordion/Accordion.module.scss";
import { IBasicDetailsProps } from "./BasicDetails.model";
import Styles from "./BasicDetails.module.scss";

const STYLES_MAIN_INFO = Styles["basicDetails__main-info"];
const STYLES_INFO_DETAILS = Styles["basicDetails__info-details"];
const LIGHTER_TEXT_STYLE = (theme: ComponentStyleFunctionParam) => ({
  color: theme.theme.siteVariables
    ? theme.theme.siteVariables.bookingDetail?.lighterText
    : "",
});

function ArrowIcon({
  isOpen,
  setIsOpen,
  arrowIsHovered,
  setArrowIsHovered,
  index,
}: IArrowIconProps) {
  const getStyles = (theme: ComponentStyleFunctionParam) => {
    const color = arrowIsHovered
      ? theme.theme.siteVariables.acordion?.arrowIconHover
      : theme.theme.siteVariables.acordion?.arrowIcon;
    return {
      color,
    };
  };

  const handlekeyDown = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      const newState = [...isOpen];
      newState[index] = !isOpen[index];
      setIsOpen(newState);
    }
  };

  return (
    <div className={StylesAcc.accordion__arrow} role="row">
      <div role="gridcell">
        <div role="button">
          <ChevronDownIcon
            data-testid="chevronMoreDetails"
            aria-label="More details"
            size="large"
            styles={(theme) => getStyles(theme)}
            onMouseEnter={() => {
              setArrowIsHovered(true);
            }}
            onMouseLeave={() => {
              setArrowIsHovered(false);
            }}
            className={
              StylesAcc[
                `accordion__arrow${
                  !isOpen[index] ? "--notRotated" : "--isRotated"
                }`
              ]
            }
            onClick={() => {
              const newState = [...isOpen];
              newState[index] = !isOpen[index];
              setIsOpen(newState);
            }}
            onKeyDown={(e: any) => handlekeyDown(e)}
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  );
}

function BasicDetails({
  tripSegment,
  setIsOpen,
  isOpen,
  index,
  length,
}: // eslint-disable-next-line @typescript-eslint/no-unused-vars
IBasicDetailsProps) {
  const { t } = useTranslation();
  const {
    logo,
    departure,
    arrival,
    totalTravelDuration,
    numberOfStops,
    stopsIn,
    serviceProvidersWithNumbers,
    departureDateTime,
    arrivalDateTime,
    type,
    departureName,
    arrivalName,
    bookingCode,
  } = tripSegment;

  const [arrowIsHovered, setArrowIsHovered] = useState(false);

  const departureTime = DateUtils.getUTCTime(departureDateTime);
  const arrivalTime = DateUtils.getUTCTime(arrivalDateTime);
  const dayDifference = DateUtils.getDayDifference(
    departureDateTime,
    arrivalDateTime
  );

  const stopSingularOrPlural =
    numberOfStops > 1
      ? t("tripDetails.stopPlural")
      : t("tripDetails.stopSingular");

  const stops =
    numberOfStops > 0
      ? `${numberOfStops} ${stopSingularOrPlural}`
      : t("tripDetails.nonStop");

  const providerLogo = (
    <Text
      as="div"
      className={Styles["basicDetails__provider-logo"]}
      styles={(theme) => ({
        borderColor: theme.theme.siteVariables
          ? theme.theme.siteVariables.bookingDetail?.providerLogoBorder
          : "",
      })}
    >
      <Image
        className={Styles["basicDetails__provider-logo--image"]}
        src={logo}
        alt="provider logo"
        onError={handleProviderLogoError}
        aria-hidden="true"
      />
    </Text>
  );

  const travelTimeInterval = (
    <Flex
      title={`${t("tripDetails.flightTrain.departure")} ${departureTime} ${t(
        "tripDetails.flightTrain.arrival"
      )} ${arrivalTime}`}
    >
      <Text
        className={STYLES_MAIN_INFO}
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.mainInfo,
        })}
        aria-hidden="true"
      >
        {departureTime}
        <span className={Styles["basicDetails__main-info--dash"]}> - </span>
        {arrivalTime}&nbsp;
        <sup>{dayDifference}</sup>
      </Text>
    </Flex>
  );

  const providerInfo = serviceProvidersWithNumbers?.map(
    (provider: IProvider) => {
      const numbers = provider.numbers.join(", ");
      let titleSR = `${provider.name}. ${t(
        "tripDetails.flightNumber"
      )} ${numbers}`;
      if (type === "Trip_Product_Train") {
        titleSR = `${provider.name}. ${t(
          "tripDetails.trainNumber"
        )} ${numbers}`;
      }
      return provider.name && numbers ? (
        <Flex title={titleSR}>
          <Text
            className={STYLES_INFO_DETAILS}
            styles={LIGHTER_TEXT_STYLE}
            key={`${provider.name}_${numbers}`}
            aria-hidden="true"
          >
            {provider.name} - {numbers}
          </Text>
        </Flex>
      ) : (
        ""
      );
    }
  );

  const travelTimeDuration = (
    <Text
      className={STYLES_INFO_DETAILS}
      styles={LIGHTER_TEXT_STYLE}
      aria-hidden="true"
    >
      {totalTravelDuration}
    </Text>
  );

  const bookingCodeInfo = bookingCode ? (
    <>
      <div>
        <Text className={STYLES_MAIN_INFO} aria-hidden="true">
          {bookingCode}
        </Text>
      </div>
      <div>
        <Text
          className={STYLES_INFO_DETAILS}
          styles={LIGHTER_TEXT_STYLE}
          aria-hidden="true"
        >
          {t("tripDetails.bookingCode")}
        </Text>
      </div>
    </>
  ) : (
    <div />
  );

  const timeDurationScreenReader = (durationString: string) => {
    let travelDurationScreenReader = durationString || "";

    const timeHours = travelDurationScreenReader.includes("h")
      ? travelDurationScreenReader.split("h")[0].trim()
      : null;

    const timeMinutes = timeHours
      ? travelDurationScreenReader.split("h")[1].trim().split("m")[0].trim()
      : travelDurationScreenReader.split("m")[0].trim();

    if (travelDurationScreenReader) {
      if (timeHours === "1") {
        travelDurationScreenReader = travelDurationScreenReader.replace(
          "h",
          t("tripDetails.hour")
        );
      } else if (timeHours != null) {
        travelDurationScreenReader = travelDurationScreenReader.replace(
          "h",
          t("tripDetails.hours")
        );
      }
      if (timeMinutes === "1") {
        travelDurationScreenReader = travelDurationScreenReader.replace(
          "m",
          t("tripDetails.minute")
        );
      } else if (timeMinutes != null) {
        travelDurationScreenReader = travelDurationScreenReader.replace(
          "m",
          t("tripDetails.minutes")
        );
      }
    }
    return travelDurationScreenReader;
  };

  function travelingFromToTitleSR() {
    const departureIataCode = departure
      ? departure.split("(")[departure.split("(").length - 1].split(")")[0]
      : "";
    const arrivalIataCode = arrival
      ? arrival.split("(")[arrival.split("(").length - 1].split(")")[0]
      : "";
    let departureNameSR = departureName;
    let arrivalNameSR = arrivalName;
    if (departureNameSR === "") {
      departureNameSR = departure.replace(`(${departureIataCode})`, "");
    }
    if (arrivalNameSR === "") {
      arrivalNameSR = arrival.replace(`(${arrivalIataCode})`, "");
    }
    if (type === "Trip_Product_Train") {
      return `${t(
        "upcoming_trips_dashboard.tripcard.from"
      )} ${departureIataCode}, ${departureNameSR} ${t(
        "upcoming_trips_dashboard.tripcard.to"
      )} ${arrivalIataCode}, ${arrivalNameSR}.`;
    }
    return `${t(
      "upcoming_trips_dashboard.tripcard.from"
    )} ${departureIataCode}, ${departureName} ${t(
      "upcoming_trips_dashboard.tripcard.to"
    )} ${arrivalIataCode}, ${arrivalName}.`;
  }
  const travelingFromTo = (
    <Flex className={Styles.basicDetails__travelingFromTo}>
      <span className={AriaStyle.visuallyHidden}>
        {travelingFromToTitleSR()}
      </span>
      <div aria-hidden>
        <Text
          className={Styles["basicDetails__city-info"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.bookingDetail?.mainInfo,
          })}
        >
          {departure}
        </Text>
        <div hidden>{travelTimeDuration}</div>
      </div>
      <ArrowRightIcon
        className={Styles["basicDetails__arrow-icon"]}
        styles={LIGHTER_TEXT_STYLE}
        aria-hidden
      />
      <Text
        className={Styles["basicDetails__city-info"]}
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.mainInfo,
        })}
        aria-hidden="true"
      >
        {arrival}
      </Text>
    </Flex>
  );

  const stopDetails = (
    <>
      <Text
        aria-hidden="true"
        className={STYLES_MAIN_INFO}
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.mainInfo,
        })}
      >
        {stops}
      </Text>
      {!stopsIn?.length && (
        <Text
          className={STYLES_INFO_DETAILS}
          styles={LIGHTER_TEXT_STYLE}
          aria-hidden="true"
        >
          {" "}
        </Text>
      )}
      {stopsIn?.map((stop: any) => {
        const [stopDuration, stopInCity, isTheSameAirport] = stop;

        return (
          <Text
            aria-hidden="true"
            className={STYLES_INFO_DETAILS}
            styles={LIGHTER_TEXT_STYLE}
            key={`${stopDuration}_${stopInCity}`}
          >
            {`${stopDuration} ${t("tripDetails.detailCard.in")} ${stopInCity}`}
            &nbsp;
            {!isTheSameAirport && (
              <ExclamationTriangleIcon
                aria-hidden="true"
                className={Styles.basicDetails__alert}
                size="small"
                styles={(theme) => ({
                  color:
                    theme.theme.siteVariables.extendedDetails?.stop.colorAlert,
                })}
              />
            )}
          </Text>
        );
      })}
    </>
  );

  let stopDetailsScreenReader = stops;
  if (stopsIn && stopsIn.length > 0) {
    stopDetailsScreenReader += t("tripDetails.of");
    stopsIn.forEach((stopElement) => {
      const nameCitySR = stopElement[1]?.split("(")[0]?.trim();
      const codeCitySR = stopElement[1]?.split(",")[1]?.trim();
      stopDetailsScreenReader = `${
        stopDetailsScreenReader +
        timeDurationScreenReader(stopElement[0]?.trim())
      } ${t("tripDetails.in")} ${nameCitySR}, ${codeCitySR}. `;
    });
  }

  const screenReaderHeader = `${
    type === "Trip_Product_Train"
      ? t("upcoming_trips_dashboard.tripDetails.Train")
      : t("upcoming_trips_dashboard.tripDetails.Flight")
  } ${index + 1} ${t("tripDetails.outOf")} ${length}`;

  return (
    <div
      className={Styles.basicDetails}
      role="grid"
      aria-label={screenReaderHeader}
      aria-busy="true"
    >
      <Flex role="row" aria-busy="true">
        {providerLogo}
        <Flex column role="cell">
          {travelTimeInterval}
          {providerInfo}
        </Flex>
      </Flex>
      <div className={Styles.basicDetails__destinations}>{travelingFromTo}</div>
      <Flex
        title={stopDetailsScreenReader}
        column
        className={Styles.basicDetails__stops}
      >
        {stopDetails}
      </Flex>
      <Flex column>{bookingCodeInfo}</Flex>
      <ArrowIcon
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        arrowIsHovered={arrowIsHovered}
        setArrowIsHovered={setArrowIsHovered}
        aria-label={isOpen ? "Expanded" : "Collapsed"}
        index={index}
      />
    </div>
  );
}
export default BasicDetails;
