import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Open20Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import GetUrlCytricNewTab from "../../../utils/GetUrlCytricNewTab";
import styles from "./SeeMoreOptions.module.scss";

export default function SeeMoreOptions() {
  const { t } = useTranslation();

  return (
    <Button
      text
      role="link"
      primary
      className={styles.moreOptions}
      content={t(
        "upcoming_trips_dashboard.upcomingTrips.newBook.SeeMoreOptionsInCytric"
      )}
      styles={(theme) => ({
        color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
      })}
      tabIndex={0}
      aria-label={`${t(
        "upcoming_trips_dashboard.upcomingTrips.newBook.SeeMoreOptionsInCytric"
      )}. ${t("upcoming_trips_dashboard.upcomingTrips.newBook.externalLink")}`}
      data-testid="more-in-cytric-web"
      onClick={() => {
        telemetryService.trackEvent({ name: "travel-trip-cytricWeb" });
        GetUrlCytricNewTab();
      }}
      iconPosition="after"
      icon={<Open20Regular data-testid="external-link-go-to-cytric" />}
    />
  );
}
