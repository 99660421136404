/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-nested-ternary */
import { Image, Text } from "@fluentui/react-northstar";
import React from "react";
import { LocationData } from "../../models/Location.model";
import airportIcon from "./Airplane.png";
import "./AirportOrCity.scss";
import cityIcon from "./City.png";

function AirportOrCity(city: LocationData | any): JSX.Element {
  return city?.city?.associatedLocations ? (
    <div className="cityOption" data-testid="airportOrCityContainer">
      <Image src={cityIcon} className="cityIcon" />
      <span style={{ width: "100%", marginTop: "1", fontSize: "14" }}>
        {city?.dictionary?.name} ({city?.city?.location?.iataCode})
      </span>
      <Image
        src={city.dictionary?.countries[0].media[0].href}
        className="dropdownFlag"
      />
      <Text className="countryCode" style={{ fontSize: "10", marginTop: "2" }}>
        {city.dictionary?.countries[0].code}
      </Text>
    </div>
  ) : city?.city?.location?.iataCode ? (
    <div className="cityOption">
      <Image src={airportIcon} className="singleAirport" />
      <span className="singleAirportName">
        {city.dictionary?.name} ({city.city.location.iataCode})
      </span>

      <Image
        src={city.dictionary?.countries[0].media[0].href}
        className="dropdownFlag"
      />
      <Text className="countryCode" style={{ fontSize: "10", marginTop: "2" }}>
        {city.dictionary?.countries[0].code}
      </Text>
    </div>
  ) : (
    <div className="airportOption mmtAirportOption">
      <Image src={airportIcon} className="airportIcon" />
      <span className="airportName mmtAirportName">
        {city.dictionary?.name} ({city.city.iataCode})
      </span>
      <Image
        src={city.dictionary?.countries[0].media[0].href}
        className="dropdownFlag"
      />
      <Text className="countryCode" style={{ fontSize: "10", marginTop: "2" }}>
        {city.dictionary?.countries[0].code}
      </Text>
    </div>
  );
}

export default AirportOrCity;
