export default function getFilteredSearch(items: any, loading: any) {
  let foundContacts: any = [];

  if (items) {
    foundContacts = items;
    if (
      (foundContacts[0] &&
        Object.keys(foundContacts[0]).length === 0 &&
        !loading) ||
      !foundContacts
    ) {
      foundContacts = [];
    }
    return foundContacts;
  }

  return foundContacts;
}
