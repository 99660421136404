/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
import { FormDropdown } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import i18next from "../../../i18n/i18n";
import stylesSearch from "./styles/search-contacts.module.scss";
import getFilteredSearch from "./utils/GetFilteredSearch";
import GetSearchItems from "./utils/GetSearchItems";

export const dontClose = (e: any) => {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
};

export function countContactsGroups(
  selectedItems: any,
  setRecipientSeleted: Function,
  setDisableShare: Function,
  setRecipientGroupsSelected?: Function,
  setRecipientChannelsSelected?: Function
) {
  if (selectedItems) {
    const groupIds = new Array<string>();
    const userIds = new Array<string>();
    const channelIds = new Array<any>();
    selectedItems.forEach((user: any) => {
      if (user.type === "user") {
        userIds.push(user.key);
      } else if (user.type === "group") {
        groupIds.push(user.key);
      } else if (user.type === "channel") {
        channelIds.push({ channelId: user.key, teamId: user.teamid });
      }
    });

    setRecipientSeleted(userIds);
    if (setRecipientGroupsSelected) {
      setRecipientGroupsSelected(groupIds);
    }

    if (setRecipientChannelsSelected) {
      setRecipientChannelsSelected(channelIds);
    }

    setDisableShare(false);
  }
  return true;
}

export default function SearchFlightSearchComponent(
  setRecipientSeleted: any,
  setDisableShare: any,
  setRecipientGroupsSelected?: any,
  setRecipientChannelsSelected?: any,
  device?: string | undefined,
  themeName?: string | undefined,
  label?: string,
  placeholder?: string
) {
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [contactsGroupsChannels, setContactsGroupsChannels] = useState([{}]);

  const [context, setContext] = useState({} as app.Context);
  const labelId = "choose-friend-label";

  const classTabletDevice = device === "tablet" ? stylesSearch.tablet : null;

  const getA11ySelectionMessage = {
    onAdd: (item: any) =>
      `${item.header} selected. Press left or right arrow keys to navigate selected items.`,
    onRemove: (item: any) => `${item.header} has been removed.`,
  };

  const handleOnChange = (_e: any, data: any): void => {
    const selectedItems = data.value;
    if (setRecipientGroupsSelected) {
      countContactsGroups(
        selectedItems,
        setRecipientSeleted,
        setDisableShare,
        setRecipientGroupsSelected
      );
      if (!selectedItems.length) {
        setLoading(false);
        setDisableShare(true);
      }
    }
    if (setRecipientChannelsSelected) {
      countContactsGroups(
        selectedItems,
        setRecipientSeleted,
        setDisableShare,
        setRecipientGroupsSelected,
        setRecipientChannelsSelected
      );
      if (!selectedItems.length) {
        setLoading(false);
        setDisableShare(true);
      }
    } else {
      countContactsGroups(selectedItems, setRecipientSeleted, setDisableShare);
      if (!selectedItems.length) {
        setLoading(false);
        setDisableShare(true);
      }
    }
  };

  useEffect(() => {
    let time: any;
    let searchItems: any = [];
    setContactsGroupsChannels([]);
    if (query.length) {
      time = setTimeout(() => {
        searchItems = GetSearchItems(
          query,
          context,
          searchItems,
          setRecipientGroupsSelected,
          setRecipientChannelsSelected
        )
          .then((items) => items.filter((el) => Object.keys(el).length))
          .then((finalSearchItems) => {
            setContactsGroupsChannels(finalSearchItems);
            setLoading(false);
          });
      }, 800);
    }

    return () => {
      clearTimeout(time);
    };
  }, [query, context]);

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((ctx) => {
        setContext(ctx);
      });
    });
    const firstFocusableElement = document.querySelector(
      'input, button, a, [tabindex="0"]'
    ) as HTMLElement;
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }
  }, []);

  return (
    <FormDropdown
      label={{
        content: label || i18next.t("searchContacts.dropDownContent"),
        id: labelId,
      }}
      loading={loading}
      loadingMessage={i18next.t("searchContacts.loadingMessage")}
      noResultsMessage={i18next.t("searchContacts.noResultsMessage")}
      items={contactsGroupsChannels}
      aria-labelledby={labelId}
      multiple
      search={(items: any) => getFilteredSearch(items, loading)}
      fluid
      placeholder={placeholder || i18next.t("searchContacts.placeholder")}
      onChange={(e: any, data: any) => handleOnChange(e, data)}
      onSearchQueryChange={(_e: any, { searchQuery }: any) => {
        setLoading(true);
        setQuery(searchQuery);
      }}
      toggleIndicator=""
      getA11ySelectionMessage={getA11ySelectionMessage}
      className={[
        themeName === "default" ? stylesSearch.white : stylesSearch.dark,
        stylesSearch.formBlock,
        classTabletDevice,
      ].join(" ")}
      onKeyDown={(e: any) => dontClose(e)}
    />
  );
}
