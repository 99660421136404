import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TripDetailsService from "../../services/trip-list/TripListService";

export const fetchTripDetailsV1 = createAsyncThunk(
  "trips/fetchUserTripDetails",
  async (tripId: string, { rejectWithValue }) => {
    try {
      const id = tripId.replace(":", "");
      const tripDetailV1 = await TripDetailsService.getTripByIdV1(id);
      return tripDetailV1;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const tripDetailsV1Slice = createSlice({
  name: "tripDetailsV1",
  initialState: {
    tripDetails: {} as any,
    dictionaries: {} as any,
    tripDetailsStatus: "" as string,
    tripDetailsErrorInfo: "" as string,
    tripDetailsErrorCode: null,
  },
  reducers: {
    setTrip: (state, action) => ({
      ...state,
      tripDetails: action.payload.data,
      dictionaries: action.payload?.dictionaries,
      tripDetailsStatus: "success",
    }),
    setTripNull: (state) => ({
      ...state,
      tripDetails: null,
    }),
    setTripError: (state, action) => ({
      ...state,
      tripDetailsStatus: "error",
      tripDetailsErrorInfo: action.payload?.response?.statusText,
      tripDetailsErrorCode: action.payload?.response?.status,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTripDetailsV1.fulfilled, (state, action) => ({
      ...state,
      tripDetails: action.payload?.data?.data || action.payload?.data,
      dictionaries: action.payload?.data?.dictionaries,
      tripDetailsStatus: "success",
    }));
    builder.addCase(fetchTripDetailsV1.pending, (state) => ({
      ...state,
      tripDetailsStatus: "loading",
    }));
    builder.addCase(fetchTripDetailsV1.rejected, (state, action: any) => ({
      ...state,
      tripDetailsStatus: "error",
      tripDetailsErrorInfo: action.payload?.response?.statusText,
      tripDetailsErrorCode: action.payload?.response?.status,
    }));
  },
});

export const tripDetailV1Actions = tripDetailsV1Slice.actions;

export default tripDetailsV1Slice.reducer;
