import _ from "lodash";
import KeepSession from "../common/models/KeepSession";
import HostSettings from "./host.settings";

export enum KeepSessionActions {
  GENERIC_DETAILS = "details",
  GENERIC_DASHBOARD = "dashboard",
  DASHBOARD_SIDEBAR = "dashboard.sidebar",
  DASHBOARD_CANCELPOPUP = "dashboard.cancelPopUp",
  DASHBOARD_EVENTMANAGER = "dashboard.eventManager",
  DASHBOARD_SCROLL = "dashboard.scroll",
  DETAILS_DETAILTAB = "details.detailTab",
  DETAILS_CANCELPOPUP = "details.cancelPopUp",
  DETAILS_ADDTASK = "details.addTask",
  DETAILS_CLOSECOLLAB = "details.closeCollab",
  DETAILS_SCROLL = "details.scroll",
}

const returnKeepSession = () => {
  const keepSessionInfo = localStorage.getItem("KeepSessionCache");
  let keepSessionObject: KeepSession | null;

  if (keepSessionInfo && keepSessionInfo !== "") {
    keepSessionObject = JSON.parse(keepSessionInfo);
  } else {
    keepSessionObject = null;
  }
  return keepSessionObject;
};

export const retrieveSessionTimeOfCreation = (session: KeepSession) => {
  const lastUpdate = session.expiration;
  if (lastUpdate !== "" && lastUpdate !== null) {
    if (new Date().getTime() > JSON.parse(lastUpdate)) {
      localStorage.removeItem("KeepSessionCache");
    } else {
      return (
        JSON.parse(lastUpdate) -
        parseInt(HostSettings.getKeepSessionTimeout, 10)
      );
    }
  }
  return null;
};

export const addSessionValue = (
  session: KeepSession,
  id: string,
  value: any,
  pastTrip: boolean = false
) => {
  const copySession = _.cloneDeep(session);
  const newValue = [];
  if (copySession.value && copySession.value.length > 0) {
    const index = copySession.value.findIndex((element) => element.id === id);
    if (index > -1) {
      copySession.value[index] = { id, value, pastTrip };
    } else {
      copySession.value.push({ id, value, pastTrip });
    }
  } else {
    newValue.push({ id, value, pastTrip });
    copySession.value = newValue;
  }

  return copySession.value;
};

export const getKeepSessionValue = (session: KeepSession, id: string) => {
  if (session.value && session.value.length > 0) {
    const valueObject = session.value.find((value) => value.id === id);
    if (valueObject) {
      return valueObject.value;
    }
    return "";
  }
  return "";
};

export const removeKeepSessionValue = (session: KeepSession, id: string) => {
  if (session.value && session.value.length > 0) {
    return session.value.filter((value) => value.id !== id);
  }
  return [];
};

export default returnKeepSession;
