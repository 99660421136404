import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";

export default class TripListService {
  private static readonly SERVER_URL = process.env.REACT_APP_BASE_API;

  private static readonly API_URL = process.env.REACT_APP_TRIP_LIST;

  private static readonly API_URL_V1 = process.env.REACT_APP_TRIP_LIST_V1;

  private static readonly API_URL_V1_ARRANGER =
    process.env.REACT_APP_TRIP_LIST_ARRANGER_V1;

  public static getTrips(): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.API_URL}`);
  }

  public static getTripsV1(): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.API_URL_V1}`);
  }

  public static getTripById(tripId: string): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.API_URL}${tripId}`);
  }

  public static getTripByIdV1(tripId: string): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.API_URL_V1}${tripId}`);
  }

  public static getTripByIdV1Arranger(tripId: string): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.API_URL_V1_ARRANGER}${tripId}`);
  }
}
