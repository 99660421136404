import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  ChevronDown12Regular,
  DismissCircle16Regular,
  Edit16Regular,
  EditSettings20Regular,
} from "@fluentui/react-icons";
import { Button, Flex, MenuButton, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FeatureToggle from "../../common/models/FeatureToggle";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import { selectFeatureToggle } from "../../store/feature-toggles/FeatureTogglesSelector";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import HandleTravelCytricRedirection from "../../utils/HandleTravelCytricRedirection";
import { KeepSessionActions } from "../../utils/KeepSessionFunctions";
import ActivityClass from "../../utils/KeepUserActivityContext";
import { FeatureToggleDefinition } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";
import CancelPopUp from "./cancel/CancelPopUp";
import { useCancelPopUp } from "./cancel/context/CancelPopUpContext";

interface IConfirmationPageProps {
  tripId: string;
  tripName: string;
  showText: boolean;
}

const handleMenuClick = (e: any, cancelText: string, setDialogParams: any) => {
  e.stopPropagation();
  if (
    e.target.innerText === cancelText &&
    e.target.className.includes("ui-menu__item")
  ) {
    new ActivityClass().update(KeepSessionActions.DETAILS_CANCELPOPUP, {
      openDialog: true,
    });
    setDialogParams({ openDialog: true });
  }
};

const clickHandler = async (
  tripId: string,
  featureTravelTab: FeatureToggle | undefined,
  isTRIPPUser: boolean,
  tripName: string,
  t: any,
  dispatch: any,
  history: any
) => {
  telemetryService.trackEvent({ name: "travel-tripDetails-manageTrip" });

  const redirectionInput = {
    segmentType: RedirectionTypeEnum.CONFIRMATION_PAGE,
    tripId,
  };
  if (featureTravelTab?.isActive && !isTRIPPUser) {
    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    const breadcrumbData = [
      {
        label: "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
        url: "/travel",
        translation: true,
      },
      {
        label: tripName,
        url: `/trip:${tripId}`,
        translation: false,
      },
      {
        label: "upcoming_trips_dashboard.breadcrumb.manage",
        url: "",
        translation: true,
      },
    ];

    HandleTravelCytricRedirection({ breadcrumbData, t, dispatch });

    history.push(
      `/cytricweb?context=${JSON.stringify(redirectionInput)}&type=travel`
    );
  } else {
    const urlEncodedDeepLinkData: any = {
      subEntityId: JSON.stringify(redirectionInput),
    };
    const deepLink = `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/cytricweb?context=${encodeURI(JSON.stringify(urlEncodedDeepLinkData))}`;

    app.openLink(deepLink);
  }
};

export default function ConfirmationPageButton({
  tripId,
  tripName,
  showText,
}: IConfirmationPageProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { dialogParams, setDialogParams } = useCancelPopUp();
  const { openDialog } = dialogParams;

  const [open, setOpen] = useState(false);
  const featureTravelTab = useAppSelector((state) =>
    selectFeatureToggle(state, FeatureToggleDefinition.manageTripDetails.id)
  );

  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPUpcomingTrips.id
  );

  const cancelTripToggle = CheckFeatureToggle(
    FeatureToggleDefinition.cancelTrip.id
  );

  const userActivity = new ActivityClass();

  useEffect(() => {
    const userActivityValue = userActivity.get(
      KeepSessionActions.DETAILS_CANCELPOPUP
    );
    if (userActivityValue) {
      setDialogParams(userActivityValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const manageText = t(
    "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.manage"
  );

  const cancelText = t(
    "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.cancelTrip"
  );

  const buttonContent = t(
    "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.edit"
  );

  const getItems = () => {
    if (cancelTripToggle) {
      return [
        {
          key: "share-button",
          content: buttonContent,
          icon: <Edit16Regular />,
          "aria-label": buttonContent,
          "data-testid": "button-confirmation-page-manage-trip",
          onClick: () =>
            clickHandler(
              tripId,
              featureTravelTab,
              isTRIPPUser,
              tripName,
              t,
              dispatch,
              history
            ),
        },
        {
          key: "cancel-button",
          icon: <DismissCircle16Regular />,
          content: cancelText,
          "aria-label": cancelText,
          "data-testid": "cancel-trip-button",
        },
      ];
    }
    return [
      {
        key: "share-button",
        content: buttonContent,
        icon: <Edit16Regular />,
        "aria-label": buttonContent,
        "data-testid": "button-confirmation-page-manage-trip",
        onClick: () =>
          clickHandler(
            tripId,
            featureTravelTab,
            isTRIPPUser,
            tripName,
            t,
            dispatch,
            history
          ),
      },
    ];
  };

  const showContent = () => {
    if (showText) {
      return (
        <Flex
          gap="gap.smaller"
          hAlign="center"
          vAlign="center"
          styles={{ paddingLeft: "-5px" }}
        >
          <Text content={manageText} />
          <ChevronDown12Regular />
        </Flex>
      );
    }
    return <ChevronDown12Regular />;
  };

  const getComponent = () => {
    if (cancelTripToggle) {
      return (
        <>
          <MenuButton
            trigger={
              <Button
                icon={<EditSettings20Regular style={{ marginRight: "-5px" }} />}
                iconOnly
                text
                tabIndex={0}
                aria-label={manageText}
                content={showContent()}
                data-testid="button-manage-trip"
                title={t("contextMenuLabel")}
                styles={{ menuIcon: { paddingRight: "-5px" } }}
              />
            }
            open={open}
            onOpenChange={() => setOpen(!open)}
            onMenuItemClick={(e: any) =>
              handleMenuClick(e, cancelText, setDialogParams)
            }
            menu={{
              items: getItems(),
              trapFocus: true,
            }}
          />
          {openDialog && <CancelPopUp id={tripId} location="details" />}
        </>
      );
    }

    return (
      <Button
        aria-label={manageText}
        content={showText ? manageText : ""}
        text
        data-testid="button-confirmation-page-manage-trip"
        onClick={() =>
          clickHandler(
            tripId,
            featureTravelTab,
            isTRIPPUser,
            tripName,
            t,
            dispatch,
            history
          )
        }
        icon={<Edit16Regular />}
      />
    );
  };

  if (!isTRIPPUser && featureTravelTab?.isActive) {
    return getComponent();
  }
  return null;
}
