import { selectFeatureToggle } from "../../../../store/feature-toggles/FeatureTogglesSelector";
import { useAppSelector } from "../../../../store/StoreHooks";
import { FeatureToggleDefinition } from "../../../../utils/constants";

const CheckFeatureMMT = () => {
  const featureToogles = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.MMTArrivalTimes.id)
  );

  if (featureToogles && featureToogles.status === "true") {
    return true;
  }
  return false;
};

export default CheckFeatureMMT;
