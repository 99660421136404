import { createAsyncThunk } from "@reduxjs/toolkit";
import { IError } from "../../common/models/Error";
import RedirectionInput from "../../common/models/redirection/RedirectionInput";
import RedirectionService from "../../services/redirection/RedirectionService";

export const REDIRECTION_STORE_NAME = "redirection";
export const LOAD_REDIRECTION_URL = "loadRedirectionUrl";

const defaultError: IError = {
  code: 100,
  status: 500,
  title: "Something went wrong",
  detail: "We couldn't gather the information. Please, try again.",
};

export const loadCytricUrl = createAsyncThunk(
  `${REDIRECTION_STORE_NAME}/${LOAD_REDIRECTION_URL}`,
  async (redirectionInput: RedirectionInput, { rejectWithValue }) => {
    try {
      const response = await RedirectionService.getCytricUrl(redirectionInput);

      if (response?.data?.data?.cytricUrl) {
        return response.data.data.cytricUrl as string;
      }
      return rejectWithValue(defaultError);
    } catch (error: any) {
      if (error.response?.data?.length) {
        const errorData = error.response?.data[0];
        return rejectWithValue(errorData);
      }
      return rejectWithValue(defaultError);
    }
  }
);
