import React from "react";
import IResponseProps from "../../../components/upcoming-trips-dashboard/cancel/CancelTripModel";
import TaskModuleResponseError from "./TaskModuleResponseError";
import TaskModuleResponseSuccess from "./TaskModuleResponseSuccess";

function GetTaskResponseComponent({
  alertText,
  responseStatus,
  id,
}: IResponseProps) {
  if (alertText && responseStatus?.toString() === "200") {
    return <TaskModuleResponseSuccess alertText={alertText} />;
  }

  if (
    alertText &&
    responseStatus?.toString() !== "200" &&
    responseStatus !== null
  ) {
    return <TaskModuleResponseError alertText={alertText} id={id} />;
  }
  return null;
}
export default GetTaskResponseComponent;
