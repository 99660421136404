/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckboxProps,
  CloseIcon,
  Dialog,
  Loader,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../../../../../common/components/generic-info-component/GenericInfo";
import { InfoImage } from "../../../../../common/components/generic-info-component/GenericInfo.model";
import {
  DONT_MERGE_TYPE,
  DONT_MERGE_TYPE_EVENT,
  MERGE_ERROR_TYPE,
  MERGE_TYPE,
} from "../../../../../utils/constants";
import { DuplicatePairsInterface } from "../DuplicatesInterfaces";
import { getDuplicatesExpenses, manageDuplicates } from "../PopupRequests";
import DialogContent from "../dialog-content/DialogContent";
import DialogHeader from "../dialog-header/DialogHeader";

interface DialogPropsInterface {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMergeType: React.Dispatch<React.SetStateAction<string | null>>;
  setDidMergeAction: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogPopup({
  openDialog,
  setOpenDialog,
  setMergeType,
  setDidMergeAction,
}: DialogPropsInterface) {
  const { t } = useTranslation("translation");
  const [duplicatePairs, setDuplicatePairs] = useState<
    DuplicatePairsInterface[]
  >([]);

  const [errorOnGetList, setErrorOnGetList] = useState(false);
  const [checkboxesSelected, setCheckboxesSelected] = useState({});
  const [hasSelectedPairs, setHasSelectedPairs] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clickedMergeButtons, setClickedMergeButtons] =
    useState<boolean>(false);

  const getDuplicatesList = async () => {
    setErrorOnGetList(false);
    setIsLoading(true);
    const list = await getDuplicatesExpenses();
    if (list) {
      setDuplicatePairs(list);
    } else {
      setErrorOnGetList(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDuplicatesList();
  }, []);

  useEffect(() => {
    if (duplicatePairs) {
      const localCheckboxesSelected: any = {};
      duplicatePairs.forEach((pair) => {
        localCheckboxesSelected[pair.duplicateExpenses[0].id] = false;
      });
      setCheckboxesSelected(localCheckboxesSelected);
    }
  }, [duplicatePairs]);

  useEffect(() => {
    if (checkboxesSelected) {
      const pairsNotSelected = Object.values(checkboxesSelected).filter(
        (value) => value === false
      );
      setHasSelectedPairs(
        pairsNotSelected.length < Object.keys(checkboxesSelected).length &&
          pairsNotSelected.every((value) => value === false)
      );
    }
  }, [checkboxesSelected]);

  function closeDialog() {
    setOpenDialog(false);
  }

  const manageCheckboxes = (
    e: React.FormEvent<HTMLElement>,
    checked: CheckboxProps | undefined,
    duplicateId: number
  ) => {
    setCheckboxesSelected({
      ...checkboxesSelected,
      [duplicateId]: checked?.checked,
    });
  };

  const getSelectedPairsId = () => {
    const selectedCheckboxesArray = Object.entries(checkboxesSelected);

    const selectedDuplicateIds: string[] = [];
    selectedCheckboxesArray.forEach((pair) => {
      if (pair[1] === true || selectedCheckboxesArray.length === 1) {
        selectedDuplicateIds.push(pair[0]);
      }
    });
    return selectedDuplicateIds;
  };

  const calculateTotalExpensesMerged = (selectedIds: string[]) => {
    const totalMergedExpenses = selectedIds.length;
    localStorage.setItem("totalMergedExpenses", totalMergedExpenses.toString());
  };

  const onMergeSelectedDuplicates = async (
    mergeType: string,
    event: string
  ) => {
    const selectedIds = getSelectedPairsId();
    if (mergeType === MERGE_TYPE) {
      calculateTotalExpensesMerged(selectedIds);
    }
    const selectedIdsInNumber = selectedIds.map((stringId) => Number(stringId));
    const mergeResult = await manageDuplicates(selectedIdsInNumber, event);
    return mergeResult;
  };

  async function onMergeButtonsClick(mergeType: string, event: string) {
    setClickedMergeButtons(true);
    const result = await onMergeSelectedDuplicates(mergeType, event);
    if (result) {
      setMergeType(mergeType);
      setDidMergeAction(true);
    } else {
      setMergeType(MERGE_ERROR_TYPE);
    }
    setClickedMergeButtons(false);
    closeDialog();
  }

  const renderDialogContent = () => {
    if (errorOnGetList) {
      return (
        <GenericInfo
          infoData={{
            title: t("messages.ERR_Generic"),
            detail: t("messages.ERR_Request"),
          }}
          image={InfoImage.ERROR}
          isPopupComponent
        />
      );
    }
    if (isLoading) {
      return <Loader aria-label="Duplicates popup loading..." />;
    }
    return (
      <DialogContent
        aria-label="Duplicates popup loaded successfully"
        duplicatePairs={duplicatePairs}
        manageCheckboxes={manageCheckboxes}
        clickedMergeButtons={clickedMergeButtons}
      />
    );
  };

  return (
    <Dialog
      aria-live="polite"
      aria-labelledby={
        errorOnGetList
          ? "generic-info-header-title"
          : "popup__dialog-header__bold-title"
      }
      data-testid="expense__duplicates--dialog"
      header={<DialogHeader />}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: closeDialog,
      }}
      content={renderDialogContent()}
      cancelButton={
        errorOnGetList || isLoading
          ? {
              content: t("expenses-dashboard.popup.cancel"),
              className: "popup__dontmerge__btn",
            }
          : {
              content: t(
                "expenses-dashboard.popup.viewDuplicates.dontMergeButton"
              ),
              className: "popup__dontmerge__btn",
              disabled:
                (duplicatePairs.length > 1 && !hasSelectedPairs) ||
                clickedMergeButtons,
            }
      }
      confirmButton={
        errorOnGetList || isLoading
          ? {
              content: t("expenses-dashboard.popup.tryAgain"),
              className: "popup__merge__btn",
            }
          : {
              content: t("expenses-dashboard.popup.viewDuplicates.mergeButton"),
              disabled:
                (duplicatePairs.length > 1 && !hasSelectedPairs) ||
                clickedMergeButtons,
              className: "popup__merge__btn",
            }
      }
      open={openDialog}
      onCancel={() =>
        errorOnGetList || isLoading
          ? closeDialog()
          : onMergeButtonsClick(DONT_MERGE_TYPE, DONT_MERGE_TYPE_EVENT)
      }
      onConfirm={() =>
        errorOnGetList || isLoading
          ? getDuplicatesList()
          : onMergeButtonsClick(MERGE_TYPE, MERGE_TYPE)
      }
      closeOnOutsideClick={false}
    />
  );
}

export default DialogPopup;
