import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";

import { Loader } from "@fluentui/react-northstar";
import { app, dialog } from "@microsoft/teams-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteProps } from "react-router-dom";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import TripApprovalRedirectionInput from "../../common/models/redirection/TripApprovalRedirectionInput";
import RedirectionService from "../../services/redirection/RedirectionService";
import { getDevice } from "../../utils/device.utils";
import { isValidUrlAndDomain } from "../../utils/validation.utils";
import TripApprovalStyles from "./TripApproval.module.scss";
import ApprovalResponseContainer from "./components/approval-response/ApprovalResponseContainer";
import WithCommentsContainer from "./components/comments/WithCommentsContainer";
import NoCommentsContainer from "./components/noComments/NoCommentsContainer";
import TripApprovalPageContainer from "./components/trip-approval-page/TripApprovalPageContainer";

export enum OpenedWindowState {
  Loading,
  Opened,
  Blocked,
}

function checkDeviceType(device: string) {
  const isMobileOrTablet = device === "tablet" || device === "mobile";
  const isMobile = device === "mobile";

  return { isMobileOrTablet, isMobile };
}

function checkActionType(params: any) {
  const sourceParams = params?.get("source");
  const isApproveAction = sourceParams === "viewTripApprovalApprove";
  const isRejectAction = sourceParams === "viewTripApprovalReject";
  const isViewInCytric = sourceParams === "viewTripApprovalInCytric";

  return { isApproveAction, isRejectAction, isViewInCytric };
}

function checkApprovalReasonsSettings(params: any) {
  const settings = params?.get("approvalReasonsSettings");

  if (!settings) {
    return {};
  }
  const {
    displayApprovalComment,
    displayRejectionComment,
    displayRejectionReasons,
    rejectReasons,
  } = JSON.parse(settings);
  let defaultReason = "";
  rejectReasons?.forEach((reason: any) => {
    if (reason.default) {
      defaultReason = reason.text;
    }
  });

  return {
    displayApprovalComment,
    displayRejectionComment,
    displayRejectionReasons,
    rejectReasons,
    defaultReason,
  };
}

function getThemeSuffix(themeName: string) {
  let themeSuffix: string | undefined;
  if (themeName) {
    if (themeName === "default") {
      themeSuffix = "light";
    } else if (themeName === "dark") {
      themeSuffix = themeName;
    }
  }
  return themeSuffix;
}

function checkDefaultTheme(themeName: string): boolean {
  let defaultTheme = false;
  if (themeName && themeName === "default") {
    defaultTheme = true;
  }
  return defaultTheme;
}

function TripApproval({ location }: RouteProps) {
  const [clientType, setclientType] = useState<any>();
  const [themeName, setThemeName] = useState<any>();
  const [device, setDevice] = useState<any>();
  const [source, setSource] = useState<any>("");
  const [queryParams, setQueryParams] = useState<any>();
  const [traveler, setTraveler] = useState<any>();
  const [city, setCity] = useState<any>();
  const [tripId, setTripId] = useState<any>();
  const [openedWindow, setOpenedWindow] = useState<OpenedWindowState>(
    OpenedWindowState.Loading
  );
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<any>();
  const [isSuccess, setIsSuccess] = useState<any>();
  const [reason, setReason] = useState<any>("");
  const [comment, setComment] = useState<any>("");

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((ctx: app.Context) => {
        setclientType(ctx?.app?.host?.clientType);
        setThemeName(ctx?.app.theme);
      });
    });
    setDevice(getDevice());
  }, []);

  useEffect(() => {
    const queryParamsSearch = new URLSearchParams(location?.search);
    setQueryParams(queryParamsSearch);
    setTripId(queryParamsSearch?.get("tripId"));
    setSource(queryParamsSearch?.get("source"));
    if (queryParamsSearch?.get("source") !== "viewTripApprovalInCytric") {
      setCity(queryParamsSearch?.get("city"));
      let travelerFromParams = queryParamsSearch?.get("travelers");
      if (travelerFromParams) {
        travelerFromParams = !travelerFromParams.includes(";")
          ? travelerFromParams
          : "";
        setTraveler(travelerFromParams);
      }
    }
  }, [location?.search]);

  const handleCancel = (): void => {
    dialog.url.submit();
  };

  const approve = "approve";
  const reject = "reject";

  const { isMobileOrTablet, isMobile } = checkDeviceType(device);

  const { isApproveAction, isRejectAction, isViewInCytric } =
    checkActionType(queryParams);

  const {
    displayRejectionComment,
    displayRejectionReasons,
    rejectReasons,
    defaultReason,
  } = checkApprovalReasonsSettings(queryParams);

  const themeSuffix = getThemeSuffix(themeName);

  const defaultTheme = checkDefaultTheme(themeName);

  const themeStyle = defaultTheme
    ? TripApprovalStyles.white
    : TripApprovalStyles.dark;

  const openedNewTabRef = useRef(false);
  const redirectTripApprovalPage = useCallback(async () => {
    const tripApprovalInput: TripApprovalRedirectionInput = {
      segmentType: RedirectionTypeEnum.TRIP_APPROVAL_PAGE,
      source,
      tripId,
    };
    const urlObject = await RedirectionService.getCytricUrl(tripApprovalInput);
    const url = urlObject?.data?.data?.cytricUrl as string;
    if (isValidUrlAndDomain(url)) {
      if (openedWindow !== OpenedWindowState.Opened) {
        const result = window.open(url, "_blank");
        result?.focus();
        const isClientTypeAndResult =
          result !== null || (clientType !== "web" && clientType !== "android");
        if (isClientTypeAndResult) {
          setOpenedWindow(OpenedWindowState.Opened);
          dialog.url.submit();
        } else {
          setOpenedWindow(OpenedWindowState.Blocked);
        }
      }
      if (openedWindow !== OpenedWindowState.Loading) {
        telemetryService.trackEvent(
          { name: source },
          { tripId, openedWindow, clientType }
        );
      }
    }
  }, [tripId, source, openedWindow, clientType]);

  const handleClick = async () => {
    setIsPending(true);

    const tripApprovalInput: TripApprovalRedirectionInput = {
      segmentType: isRejectAction
        ? RedirectionTypeEnum.TRIP_REJECT_ACTION
        : RedirectionTypeEnum.TRIP_APPROVE_ACTION,
      action: isRejectAction ? "REJECT" : "APPROVE",
      source,
      tripId,
      messageId: queryParams?.get("serverMessageId"),
      message: queryParams?.get("adaptiveCardData"),
      rejectReasonCode: reason,
      approverComment: comment,
    };

    const urlObject = await RedirectionService.getCytricUrl(tripApprovalInput);
    const dataResponse = urlObject?.data?.data;
    let data = null;
    if (dataResponse) {
      data =
        typeof dataResponse === "string"
          ? JSON.parse(dataResponse)
          : dataResponse;

      if (data) {
        setIsPending(false);
        setIsConfirmed(true);
        setErrorCode(data.errorCode);
        setIsSuccess(data.isSuccess);
      }
    }

    telemetryService.trackEvent(
      { name: "travel-tripApproval-approveRejectAction" },
      {
        sourceView: source,
        reservationId: tripId,
        errorCodeResponse: data?.errorCode,
        isSuccessfull: data?.isSuccess,
        action: tripApprovalInput.action,
        rejectReasonCode: tripApprovalInput.rejectReasonCode,
        comment: tripApprovalInput.approverComment,
      }
    );
  };

  useEffect(() => {
    if (
      isViewInCytric &&
      clientType &&
      !openedNewTabRef?.current &&
      openedWindow !== OpenedWindowState.Blocked
    ) {
      redirectTripApprovalPage();
      openedNewTabRef.current = true;
    }
  }, [redirectTripApprovalPage, openedWindow, clientType, isViewInCytric]);

  const renderTripApprovalPageComponent = () => {
    switch (openedWindow) {
      case OpenedWindowState.Loading:
      case OpenedWindowState.Opened:
        return (
          <Loader
            size="large"
            className={[themeStyle, TripApprovalStyles.loader].join(" ")}
            data-testid="trip-approval-loader"
          />
        );
      case OpenedWindowState.Blocked:
      default:
        return (
          <TripApprovalPageContainer
            themeStyle={themeStyle}
            themeSuffix={themeSuffix}
            defaultTheme={defaultTheme}
            isMobile={isMobile}
            isMobileOrTablet={isMobileOrTablet}
            handleCancel={handleCancel}
            redirectTripApprovalPage={redirectTripApprovalPage}
          />
        );
    }
  };

  const renderApproveComponent = () => {
    if (isConfirmed) {
      return (
        <ApprovalResponseContainer
          isSuccess={isSuccess}
          errorCode={errorCode}
          action={approve}
          traveler={traveler}
          themeSuffix={themeSuffix}
          themeStyle={themeStyle}
        />
      );
    }
    if (isPending) {
      return (
        <Loader
          size="large"
          className={[themeStyle, TripApprovalStyles.loader].join(" ")}
          data-testid="trip-approval-loader"
        />
      );
    }
    return (
      <NoCommentsContainer
        action={approve}
        city={city}
        traveler={traveler}
        handleClick={handleClick}
      />
    );
  };

  const renderRejectComponent = () => {
    if (isConfirmed) {
      return (
        <ApprovalResponseContainer
          isSuccess={isSuccess}
          errorCode={errorCode}
          action={reject}
          traveler={traveler}
          themeSuffix={themeSuffix}
          themeStyle={themeStyle}
        />
      );
    }
    if (isPending) {
      return (
        <Loader
          size="large"
          className={[themeStyle, TripApprovalStyles.loader].join(" ")}
          data-testid="trip-approval-loader"
        />
      );
    }
    if (
      displayRejectionComment === "DONOTDISPLAY" &&
      displayRejectionReasons === "DONOTDISPLAY"
    ) {
      return (
        <NoCommentsContainer
          action={reject}
          city={city}
          traveler={traveler}
          handleClick={handleClick}
        />
      );
    }
    return (
      <WithCommentsContainer
        action={reject}
        city={city}
        traveler={traveler}
        setComment={setComment}
        setReason={setReason}
        commentsOption={displayRejectionComment}
        reasonsOption={displayRejectionReasons}
        rejectReasons={rejectReasons}
        defaultReason={defaultReason}
        handleClick={handleClick}
      />
    );
  };

  if (isViewInCytric) {
    return renderTripApprovalPageComponent();
  }
  if (isApproveAction) {
    return renderApproveComponent();
  }
  if (isRejectAction) {
    return renderRejectComponent();
  }

  return <div />;
}

export default TripApproval;
