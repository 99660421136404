/* eslint-disable no-template-curly-in-string */
const closeCollaboratorNotificationCard = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.4",
  body: [
    {
      type: "Container",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              items: [
                {
                  type: "TextBlock",
                  text: "${senderUser.text}",
                  weight: "bolder",
                  size: "large",
                  VerticalContentAlignment: "central",
                  wrap: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      style: "emphasis",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "TextBlock",
                  text: "${receiverUser.text}",
                  size: "default",
                  weight: "default",
                  wrap: true,
                  horizontalAlignment: "left",
                  style: "heading",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      items: [
        {
          type: "ActionSet",
          actions: [
            {
              type: "Action.OpenUrl",
              title: "${trip.buttonText}",
              url: "${senderUser.deeplink}",
            },
          ],
        },
      ],
    },
  ],
};
export default closeCollaboratorNotificationCard;
