import { TeamsUserCredential } from "@amadeus-cytric/cytric-teams-react-common-library";
import { ShortQueryParamsModel } from "../../../models/QueryParamsModel";

export default class ClassQueryChannels implements ShortQueryParamsModel {
  top = 3;

  credential: any;

  scope = ["Group.Read.All", "User.Read.All", "Team.ReadBasic.All"];

  constructor() {
    this.credential = new TeamsUserCredential();
  }
}
