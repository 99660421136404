import { Box, Input, SearchIcon } from "@fluentui/react-northstar";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Styles from "./SearchInput.module.scss";

export default function SearchInput({
  value,
  onChange,
  isMobileOrTablet,
}: {
  value: string;
  onChange: (e: SyntheticEvent<HTMLElement, Event>) => void;
  isMobileOrTablet: boolean;
}) {
  const { t } = useTranslation();
  const inputComponent = (
    <Input
      fluid
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables.colorScheme?.default.background,
      })}
      name="findTraveler"
      id="find-traveler"
      placeholder={
        isMobileOrTablet
          ? t("shareJoin.searchInputMobilePlaceholder")
          : t("shareJoin.searchInputPlaceholder")
      }
      showSuccessIndicator={false}
      className={[Styles.searchInput, isMobileOrTablet && Styles.mobile].join(
        " "
      )}
      onChange={onChange}
      data-testid="search-traveler-input"
      aria-label="search traveler input"
      value={value}
      icon={
        isMobileOrTablet ? null : (
          <SearchIcon
            className={Styles.searchIcon}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground,
            })}
          />
        )
      }
    />
  );

  return isMobileOrTablet ? (
    <Box className={Styles.mobileSearchContainer}>{inputComponent}</Box>
  ) : (
    <Box
      className={Styles.searchContainer}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables.colorScheme?.default.background2,
      })}
    >
      {inputComponent}
    </Box>
  );
}
