import TripProduct from "../../../../../common/models/TripProduct";
import { diffMinutes } from "../../../../../helpers/DateHelper";
import getNumberOfStopsText from "../utils/StopsText";
import GetStopsText from "./GetStopsText";

interface CustomTextProps {
  originalProducts: any;
  travelProducts: TripProduct[];
}

interface CustomInfo {
  timeAndName: string[];
  nameAndNumber: string;
  travelDuration: string;
  numberOfStops: string;
}

const getDateTimes = (date: string | undefined) => new Date(date || "");

function GetTravelDuration(originalProducts: any) {
  let departureTime = new Date();
  let arrivalTime = new Date();
  if (originalProducts.productType === "Trip_Product_Air") {
    departureTime = getDateTimes(originalProducts.arrival.dateTime);
    arrivalTime = getDateTimes(originalProducts.departure.dateTime);
  } else if (originalProducts.productType === "Trip_Product_Train") {
    departureTime = getDateTimes(originalProducts.departureDateTime);
    arrivalTime = getDateTimes(originalProducts.arrivalDateTime);
  }
  const diffInMin = diffMinutes(arrivalTime, departureTime);

  const hours =
    Math.floor(diffInMin / 60) === 0 ? "" : `${Math.floor(diffInMin / 60)}h `;

  const min = diffInMin % 60 === 0 ? "" : `${diffInMin % 60}min `;
  return `${hours} ${min}`;
}

function GetNameAndNumber(travelProducts: TripProduct[]) {
  const nameAndNumber = new Array<string>();
  let carrierName =
    travelProducts[0]?.Trip_Product_Air?.marketing?.carrier.name;
  nameAndNumber.push(`${carrierName}`);
  travelProducts.forEach((element: any) => {
    if (element.Trip_Product_Air?.marketing?.carrier.name !== carrierName) {
      carrierName = element.Trip_Product_Air?.marketing?.carrier.name;
      nameAndNumber.push(
        ` ${carrierName} ${element.Trip_Product_Air?.carrierCode}${element.Trip_Product_Air?.number}`
      );
    } else if (
      element.Trip_Product_Air?.marketing?.carrier.name &&
      element.Trip_Product_Air?.carrierCode
    ) {
      nameAndNumber.push(
        ` ${element.Trip_Product_Air?.carrierCode}${element.Trip_Product_Air?.number}`
      );
    }
  });
  return nameAndNumber;
}

function GetNumberOfStops(segment: any) {
  let numberOfStops = 0;
  segment.boundStops?.forEach((element: any) => {
    if (element.carrierCode !== "" && element.number !== "") {
      numberOfStops += 1;
    }
  });
  return getNumberOfStopsText(numberOfStops);
}

function GetCustomInfo(products: CustomTextProps) {
  const { originalProducts, travelProducts } = products;
  let timeAndName = new Array<string>();
  let nameAndNumber = new Array<string>();
  if (
    originalProducts.indexesToShare &&
    originalProducts.indexesToShare.length > 0
  ) {
    timeAndName = GetStopsText(originalProducts.indexesToShare, travelProducts);
    nameAndNumber =
      originalProducts.productType === "Trip_Product_Air"
        ? GetNameAndNumber(travelProducts)
        : originalProducts.serviceProviderName;
  }
  const travelDuration = GetTravelDuration(originalProducts);
  const numberOfStops = GetNumberOfStops(originalProducts);
  const nameAndNumberText: string = nameAndNumber.toString();
  const customInfo: CustomInfo = {
    timeAndName,
    nameAndNumber: nameAndNumberText,
    travelDuration,
    numberOfStops,
  };
  return customInfo;
}

export default GetCustomInfo;
