import { createMicrosoftGraphClient } from "@amadeus-cytric/cytric-teams-react-common-library";
import { app } from "@microsoft/teams-js";
import i18n from "../../../i18n/i18n";
import HostSettings from "../../../utils/host.settings";
import ClassQueryGroups from "./classes/ClassQueryGroups";
import getCallGraph from "./utils/GetCallGraph";
import GetGroups from "./utils/GetGroups";

export default async function ShareFlightGroup(
  searchQuery: string,
  context: app.Context
) {
  const query = new ClassQueryGroups();
  const { scope, credential } = query;
  const requestUrlUser = `me/chats?$expand=members&$filter=chatType eq 'group'`;

  try {
    const graph = createMicrosoftGraphClient(credential, scope);
    const { value: groups } = await getCallGraph(graph, requestUrlUser);

    const userGroups = GetGroups(groups);

    const userGroupsFiltered = userGroups.filter((usergroup: any) => {
      const usergrouplowercase = usergroup.name.toString().toLowerCase();
      const usergroupmemberslowercase = usergroup.members
        .toString()
        .toLowerCase();
      const usergroupmembersemaillowercase = usergroup.membersEmail
        .toString()
        .toLowerCase();

      if (
        usergroupmembersemaillowercase.includes(
          context?.user?.userPrincipalName?.toLowerCase()
        ) &&
        (usergrouplowercase.includes(searchQuery.toLowerCase()) ||
          usergroupmemberslowercase.includes(searchQuery.toLowerCase()))
      ) {
        return usergroup;
      }

      return null;
    });

    const { t } = i18n;

    const userGroupsWithImage = userGroupsFiltered.map((item: any) => {
      const compressedName = `${item.name.substring(0, 67)}...`;
      return {
        header: item.name.length < 70 ? item.name : compressedName,
        image: `${HostSettings.getCDNIcons}teams-group-avatar.svg`,
        key: item.id,
        type: "group",
        content: t("searchContacts.group"),
      };
    });
    return userGroupsWithImage;
  } catch {
    return [];
  }
}
