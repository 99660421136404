/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { SegmentType } from "../../components/upcoming-trips-dashboard/UpcomingTrips/CardBookTripButtons/model/Permissions";

interface SegmentTypeState {
  permissions: SegmentType[];
}

const INITIAL_STATE: SegmentTypeState = {
  permissions: [] as SegmentType[],
};

const flowsPermissionsSlice = createSlice({
  name: "flowsPermissions",
  initialState: INITIAL_STATE,
  reducers: {
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const flowsPermissionsActions = flowsPermissionsSlice.actions;

export default flowsPermissionsSlice.reducer;
