/* eslint-disable import/no-cycle */
import {
  createMicrosoftGraphClient,
  GraphService,
  TeamsUserCredential,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import getSharingPeople from "./getSharingPeople";

export default async function getGraphInfo(peopleSharing: any, product: any) {
  const scope = ["User.Read"];
  const credential = new TeamsUserCredential();
  const client = createMicrosoftGraphClient(credential, scope);

  let ids = peopleSharing.map((user: any) => user.user);
  if (ids.length > 1) {
    ids = ids.filter(
      (element: any, index: number) => ids.indexOf(element) === index
    );
  }
  let usersGraph;
  let usersPhotosGraph;
  let usersPresenceGraph;
  let peopleRidesObject;

  try {
    usersGraph = await GraphService.getGraphUsers(client, ids);
    usersPhotosGraph = await GraphService.getGraphUsersPhotos(client, ids);
    usersPresenceGraph = await GraphService.getGraphUsersPresence(client, ids);

    peopleRidesObject = getSharingPeople(
      peopleSharing,
      usersGraph,
      usersPhotosGraph,
      usersPresenceGraph,
      product
    );
  } catch (error) {
    peopleRidesObject = [];
  }

  return peopleRidesObject;
}
