import { Label, Text, TextArea } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import WithCommentsStyles from "./WithCommentsContainer.module.scss";

function CommentsContainer({
  action,
  commentsOption,
  setComment,
  setIsCommentEmpty,
}: any) {
  const [selectedComment, setSelectedComment] = useState<any>();
  const [count, setCount] = useState(0);
  const { t } = useTranslation("translation", {
    keyPrefix: "tripApproval.comments",
  });

  const getLabel = () => {
    let text = "";
    switch (action) {
      case "reject":
        text = t("reject.addAReason");
        break;
      case "approve":
        text = t("approve.addAComment");
        break;
      default:
        text = "";
    }

    return text;
  };

  const getPlaceholder = () => {
    let text = "";
    switch (action) {
      case "reject":
        text = t("reject.typeAReasonForRejection");
        break;
      case "approve":
        text = t("approve.typeAComment");
        break;
      default:
        text = "";
    }

    return text;
  };

  function handleOnChange(_e: any, data: any): void {
    setCount(data.value.length);
    setComment(data.value);
    setSelectedComment(data.value);
    setIsCommentEmpty(data.value.length === 0);
  }

  function recalculate(number: number) {
    return `${number}/256`;
  }

  return (
    <div className={WithCommentsStyles.commentContainer}>
      <Label
        fluid
        content={[getLabel(), t(commentsOption)].join(" ")}
        className={WithCommentsStyles.label}
        styles={(theme) => ({
          color: theme.theme.siteVariables.colorScheme?.default.foreground2,
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background,
        })}
      />
      <TextArea
        data-testid="approval-comment"
        name="comment"
        placeholder={getPlaceholder()}
        fluid
        className={WithCommentsStyles.comments}
        onChange={(e: any, data: any) => handleOnChange(e, data)}
        value={selectedComment}
        aria-label={selectedComment}
        maxLength={256}
      />
      <div className={WithCommentsStyles.commentSizeContainer}>
        <Text
          id="count"
          className={WithCommentsStyles.commentSize}
          data-testid="comment-size"
          content={recalculate(count)}
          aria-label={selectedComment}
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.color,
            backgroundColor:
              theme.theme.siteVariables?.colorScheme?.default.background,
          })}
        />
      </div>
    </div>
  );
}

export default CommentsContainer;
