import { diffMinutes } from "../../../../../helpers/DateHelper";
import i18next from "../../../../../i18n/i18n";

const { t } = i18next;

const getDateTimes = (date: string | undefined) => new Date(date || "");

function FillTimeAndName(
  timeAndName: Array<string>,
  hours: number,
  min: number,
  trainStationOrAirport: string | undefined
) {
  const finalHours = hours !== 0 ? `${hours}h ` : ``;
  const finalMin = min !== 0 ? `${min}min` : ``;

  let timeAndNameFill = "";
  timeAndNameFill = finalHours !== "" ? `${finalHours} ` : "";
  timeAndNameFill =
    finalMin !== ""
      ? ` ${timeAndNameFill}${finalMin} ${t(
          "shareTrip.adaptiveCard.in"
        )} ${trainStationOrAirport}`
      : timeAndNameFill;
  timeAndNameFill =
    finalHours !== "" && finalMin === ""
      ? ` ${timeAndNameFill} ${t(
          "shareTrip.adaptiveCard.in"
        )} ${trainStationOrAirport}`
      : timeAndNameFill;

  if (timeAndNameFill !== "") {
    timeAndName.push(timeAndNameFill);
  }
}

function GetStopsText(indexes: number[], travelProducts: any) {
  const timeAndName = new Array<string>();
  if (indexes.length && travelProducts.length) {
    for (let cont = 1; cont < indexes.length; cont += 1) {
      const prevProduct = travelProducts[cont - 1];
      const currentProduct = travelProducts[cont];
      let prevArrivalTime = new Date();
      let newDepartureTime = new Date();
      let trainStationOrAirport;

      if (currentProduct.Trip_Product_Air) {
        prevArrivalTime = getDateTimes(
          prevProduct?.Trip_Product_Air.arrival.dateTime
        );
        newDepartureTime = getDateTimes(
          currentProduct?.Trip_Product_Air.departure.dateTime
        );
        trainStationOrAirport =
          currentProduct?.Trip_Product_Air.departure?.iataCode || "";
      }
      if (currentProduct.Trip_Product_Train) {
        prevArrivalTime = getDateTimes(
          prevProduct?.Trip_Product_Train.arrivalDateTime
        );
        newDepartureTime = getDateTimes(
          currentProduct?.Trip_Product_Train.departureDateTime
        );
        trainStationOrAirport =
          currentProduct.Trip_Product_Train.departure?.iataCode ||
          currentProduct.Trip_Product_Train.departure?.name;
      }
      const diffInMin = diffMinutes(prevArrivalTime, newDepartureTime);

      const hours = Math.floor(diffInMin / 60);

      const min = diffInMin % 60;

      FillTimeAndName(timeAndName, hours, min, trainStationOrAirport);
    }
  }
  return timeAndName;
}
export default GetStopsText;
