import { Flex } from "@fluentui/react-northstar";
import i18next from "i18next";
import { useEffect } from "react";
import Trip from "../../../../common/models/Trip";
import Styles from "../../ShareJoin.module.scss";
import { useDispatch } from "../../context/ShareJoinContext";
import { types } from "../../context/ShareJoinReducer";
import getCurrentDate from "./functions/getCurrentDate";
import AirSegmentContent from "./segments/air-segment-content/AirSegmentContent";
import CarSegmentContent from "./segments/car-segment-content/CarSegmentContent";
import HotelSegmentContent from "./segments/hotel-segment-content/HotelSegmentContent";
import RailSegmentContent from "./segments/rail-segment-content/RailSegmentContent";

interface TripPanelContentProps {
  trip: Trip;
  formattedTrip: any;
}

function TripPanelContent({ trip, formattedTrip }: TripPanelContentProps) {
  const dispatch = useDispatch();

  const { t, language } = i18next;

  const segments = trip.products && trip.products[0].products;

  const getTopDate = (segment: any, index: number) => {
    const currentDate = getCurrentDate(segment, language);
    const prevDate =
      index > 0 && segments
        ? getCurrentDate(segments[index - 1], language)
        : null;

    return prevDate !== currentDate ? currentDate : null;
  };

  useEffect(() => {
    if (formattedTrip.products) {
      dispatch({
        type: types.setTrip,
        payload: formattedTrip,
      });
      dispatch({
        type: types.setSegments,
        payload: formattedTrip.products[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      gap="gap.medium"
      column
      aria-label={t("tripDetailsText")}
      role="list"
      className={Styles.shareTripDetails}
    >
      {formattedTrip?.products?.map((productTrip: any) =>
        productTrip.products?.map((segment: any, index: number) => {
          const topDate = getTopDate(segment, index);

          if (segment.Trip_Product_Air) {
            const { customInfo } = segment.Trip_Product_Air;
            return (
              <AirSegmentContent
                key={`air-segment-${index.toString()}`}
                airSegment={segment}
                customInfo={customInfo}
                topDate={topDate}
              />
            );
          }
          if (segment.Trip_Product_Hotel) {
            return (
              <HotelSegmentContent
                key={`hotel-segment-${index.toString()}`}
                hotelSegment={segment}
                topDate={topDate}
              />
            );
          }
          if (segment.Trip_Product_Train) {
            const { customInfo } = segment.Trip_Product_Train;
            return (
              <RailSegmentContent
                key={`rail-segment-${index.toString()}`}
                railSegment={segment}
                customInfo={customInfo}
                topDate={topDate}
              />
            );
          }
          if (segment.Trip_Product_Car) {
            return (
              <CarSegmentContent
                key={`car-segment-${index.toString()}`}
                carSegment={segment}
                topDate={topDate}
              />
            );
          }
          return null;
        })
      )}
    </Flex>
  );
}

export default TripPanelContent;
