import { Client } from "@microsoft/microsoft-graph-client";

export default async function countUsers(client: Client, countryCode: string) {
  const requestUrl = `users/$count?$filter=usageLocation eq '${countryCode}' and accountEnabled eq true`;
  let usersCountResponse = 0;
  try {
    usersCountResponse = parseInt(
      await client.api(requestUrl).header("ConsistencyLevel", "eventual").get(),
      10
    );
  } catch {
    usersCountResponse = 0;
  }

  return usersCountResponse;
}
