import { AddIcon, Box, Button, Header, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../common/components/responsive/useScreenSize";
import Styles from "./EmptyToDo.module.scss";

function EmptyToDo({ dispatchDialog }: any) {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const toDoImage =
    screenSize < 640 || (screenSize > 965 && screenSize < 1372)
      ? "imageMobile"
      : "image";

  const fullWidthButton =
    screenSize < 640 || (screenSize > 965 && screenSize < 1372);

  return (
    <div className={Styles["empty-to-do"]}>
      <Box
        className={Styles["empty-to-do__image"]}
        as="div"
        data-testid="to-do-list-image"
        styles={(theme) => ({
          background: theme.theme.siteVariables.toDoList?.[toDoImage]
            ? theme.theme.siteVariables.toDoList?.[toDoImage]
            : "",
        })}
      />
      <div className={Styles["empty-to-do__title-container"]}>
        <Header
          as="h3"
          className={Styles["empty-to-do__title-container--title"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.toDoList?.heading
              ? theme.theme.siteVariables.toDoList?.heading
              : "",
          })}
        >
          {t("toDo.toDoList.beReadyDontForget")}
        </Header>
      </div>
      <Text as="p" className={Styles["empty-to-do__helper-text"]}>
        {t("toDo.toDoList.createYourTasks")}
      </Text>
      <Button
        primary
        fluid={fullWidthButton}
        className={Styles["empty-to-do__button"]}
        icon={<AddIcon size="small" />}
        content={t("toDo.toDoList.addNewTask")}
        onClick={() => dispatchDialog({ type: "newTask" })}
      />
    </div>
  );
}

export default EmptyToDo;
