/* eslint-disable no-debugger */
import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  ChevronDown24Regular,
  ChevronUp24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  Divider,
  Flex,
  Image,
  ItemLayout,
  Segment,
  Text,
} from "@fluentui/react-northstar";
import i18next from "i18next";
import moment from "moment";
import Trip from "../../../../../common/models/Trip";
import { useAppSelector } from "../../../../../store/StoreHooks";
import { selectTrips } from "../../../../../store/trips/TripsSelector";
import Styles from "../../../ShareJoin.module.scss";
import { useDispatch, useStore } from "../../../context/ShareJoinContext";
import { types } from "../../../context/ShareJoinReducer";
import TripPanelContent from "../../trip-panel-content/TripPanelContent";
import formatTrip from "../../trip-panel-content/functions/formatTrip";

function AdaptiveCardTrip({
  trip,
  tripList,
  selectedTripId,
  setSelectedTripId,
}: any) {
  const tripId = trip.id;
  const dispatch = useDispatch();
  const { language, t } = i18next;
  const selected = tripId === selectedTripId;
  const storedTrips: Trip[] = useAppSelector((state) => selectTrips(state));
  const filteredTrips = storedTrips.filter(
    (storedTrip: any) => storedTrip.id === trip.id
  );
  const formattedTrip = formatTrip(trip, filteredTrips);

  const { tripTemplate } = useStore();

  const handleToggleExpand = () => {
    if (tripId === selectedTripId) {
      setSelectedTripId();
      dispatch({ type: types.resetInitialSegments });
    } else {
      setSelectedTripId(tripId);
      dispatch({
        type: types.setTrip,
        payload: {},
      });
      dispatch({
        type: types.setSegments,
        payload: [],
      });
      dispatch({ type: types.resetInitialSegments });
    }
  };

  const getStartEndDate = () => {
    const startDate = DateUtils.getDateFormat(
      trip?.startDate!,
      language,
      DateFormat.DATE_SHORT
    );

    const endDate = DateUtils.getDateFormat(
      trip?.endDate!,
      language,
      DateFormat.DATE_SHORT
    );

    if (startDate === endDate) {
      return startDate;
    }

    return `${startDate} - ${endDate}`;
  };

  const contentTitle = (
    <Text
      size="small"
      weight="regular"
      className={Styles.tripDate}
      content={getStartEndDate()}
    />
  );

  const imgTitle = (
    <Image
      id={`city-img-${trip.id}`}
      className={Styles.destinationPicture}
      src={trip.destinationPicture ? trip.destinationPicture[1] : ""}
      alt-text={t("shareTrip.adaptiveCard.destinationImageAltText")}
      aria-hidden="true"
    />
  );

  const header = (
    <Text
      size="large"
      weight="bold"
      content={trip?.name}
      data-testid="Trip name"
    />
  );
  const cardButtons = (
    <Button
      icon={selected ? <ChevronUp24Regular /> : <ChevronDown24Regular />}
      text
      iconOnly
      title={
        selected
          ? t("shareTrip.adaptiveCard.collapseButton")
          : t("shareTrip.adaptiveCard.expandButton")
      }
      onClick={handleToggleExpand}
      role="button"
      aria-label={`${t("shareTrip.adaptiveCard.showDetails")} ${
        trip.name
      }, ${moment(trip.startDate).format("ddd, DD MMM yyyy")} ${moment(
        trip.endDate
      ).format("ddd, DD MMM yyyy")}`}
    />
  );

  const getTripBorder = (theme: any) => {
    if (tripList && tripTemplate.id !== tripId) {
      return `none`;
    }
    if (tripList && tripTemplate.id === tripId) {
      return `2px solid ${theme.theme.siteVariables.colorScheme?.brand.foregroundActive}`;
    }
    return `1px solid ${theme.theme.siteVariables.colorScheme?.default.border2}`;
  };

  return (
    <Flex column className={Styles.adaptiveCard}>
      <Segment
        key={`card-${trip.id}`}
        className={
          tripList && tripTemplate.id !== tripId
            ? Styles.segment
            : Styles.segmentSelected
        }
        styles={(theme) => ({
          border: getTripBorder(theme),
          borderRadius: "4px",
          backgroundColor:
            theme.theme.siteVariables.colorScheme?.default.backgroundActive1,
          boxShadow: tripList
            ? "0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11);"
            : "none",
        })}
      >
        <ItemLayout
          className={Styles.tripCardHeader}
          media={imgTitle}
          endMedia={tripList && cardButtons}
          header={header}
          content={contentTitle}
        />

        {(selected || !tripList) && (
          <Flex column>
            <Divider className={Styles.divider} />
            <TripPanelContent trip={trip} formattedTrip={formattedTrip} />
          </Flex>
        )}
      </Segment>
    </Flex>
  );
}

export default AdaptiveCardTrip;
