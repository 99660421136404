import { Box, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DateUtils from "../../../../../../../../../utils/date.utils";
import Divider from "../../components/Divider/Divider";
import Header from "../Header/Header";
import { IHotelSegmentProps } from "./HotelSegment.model";
import Styles from "./HotelSegment.module.scss";

function HotelSegment({ details, index, totalitems }: IHotelSegmentProps) {
  const { checkInDate, checkOutDate, policiesDescription } = details;
  const [context, setContext] = useState<app.Context | undefined>();

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((value: app.Context) => setContext(value));
    });
  }, []);

  const { t } = useTranslation();
  const hotelPolicy = (
    <div className={Styles.policy}>
      <Text
        styles={(theme) => ({
          color: theme.theme.siteVariables.hotelDetails?.contactInfoText,
        })}
        title={policiesDescription}
        content={policiesDescription}
      />
    </div>
  );
  return (
    <div
      aria-busy="true"
      role="grid"
      title={
        checkOutDate
          ? `${t(
              "upcoming_trips_dashboard.tripcard.from"
            )} ${DateUtils.formatLongestDate(
              checkInDate,
              context?.app?.locale || "en-GB"
            )} ${t(
              "upcoming_trips_dashboard.tripcard.to"
            )} ${DateUtils.formatLongestDate(
              checkOutDate,
              context?.app?.locale || "en-GB"
            )}${"."} ${totalitems} ${t("tripDetails.item/s")}`
          : `${DateUtils.formatLongestDate(
              checkInDate,
              context?.app?.locale || "en-GB"
            )}${"."} ${totalitems} ${t("tripDetails.item/s")}`
      }
    >
      <Text
        role="row"
        aria-hidden
        className={Styles.date}
        as="div"
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.date,
        })}
        content={`${checkInDate} - ${checkOutDate}`}
        data-testid="date_trip_details"
      />
      <div
        role="grid"
        aria-label={`${t(
          "upcoming_trips_dashboard.tripcard.icons.Hotel"
        )} ${index} ${t("tripDetails.outOf")} ${totalitems} `}
      >
        <Box
          role="row"
          className={Styles.box}
          styles={(theme) => ({
            borderColor: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.borderColor
              : "",
            background: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.background
              : "",
          })}
        >
          <Header details={details} />
          <Divider colorTheme="bookingDetail" />
          {hotelPolicy}
        </Box>
      </div>
    </div>
  );
}
export default HotelSegment;
