import TurndownService from "turndown";

function convertListItem(content: string, node: HTMLElement): string {
  const dataList = node.getAttribute("data-list");
  if (dataList === "ordered") {
    return `1. ${content}\n`;
  }
  if (dataList === "bullet") {
    return `- ${content}\n`;
  }
  return content;
}

function isListItemNode(node: Node): boolean {
  return (
    node.nodeType === Node.ELEMENT_NODE &&
    ["ordered", "bullet"].includes(
      (node as HTMLElement).getAttribute("data-list") ?? ""
    )
  );
}

function createTurndownService(): TurndownService {
  const turndownService = new TurndownService();

  // Add custom rule to handle 'li' elements with 'data-list' attribute
  // Quill editor uses 'data-list' attributes to distinguish between list types, which are
  // not standard in HTML. This function corrects that by converting Quill-specific list
  // structures into standard HTML <ol> and <ul> lists.
  turndownService.addRule("listItem", {
    filter: (node) => isListItemNode(node),
    replacement: (content, node) =>
      convertListItem(content, node as HTMLElement),
  });

  return turndownService;
}

function htmlToMarkdown(html: string): string {
  const turndownService = createTurndownService();
  return turndownService.turndown(html);
}

export default htmlToMarkdown;
