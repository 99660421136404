/* eslint-disable @typescript-eslint/naming-convention */
import Trip from "../../../../../common/models/Trip";
import TripProduct from "../../../../../common/models/TripProduct";
import GetCustomInfo from "./GetCustomInfo";

export interface CustomTextProps {
  originalProducts: any;
  travelProducts: any;
}

export interface CustomInfo {
  timeAndName: string[];
  nameAndNumber?: string;
  travelDuration: string;
  numberOfStops: string;
}

function GetInfo(filteredTrips: any[], product: any) {
  const travelProducts: Array<TripProduct> = [];

  if (filteredTrips[0]) {
    for (let i = 0; i < product.indexesToShare.length; i += 1) {
      const element =
        filteredTrips[0].products[0].products[product.indexesToShare[i]];
      travelProducts.push(element);
    }
  }

  const customTextProd: CustomTextProps = {
    originalProducts: product,
    travelProducts,
  };
  return GetCustomInfo(customTextProd);
}

const formatTrip = (trip: Trip, filteredTrips: any) => {
  const formattedTrip = { ...trip };
  let formattedProducts;

  const formattedTripProducts = formattedTrip.products?.map((tripProduct) => {
    let id: string;
    let customInfo: CustomInfo;
    formattedProducts = tripProduct.products.map((product, index) => {
      const {
        Trip_Product_Air,
        Trip_Product_Hotel,
        Trip_Product_Car,
        Trip_Product_Train,
      } = product;

      if (Trip_Product_Air) {
        const idDeparture = `${Trip_Product_Air.departure.dateTime}#${Trip_Product_Air.departure.iataCode}`;
        const idArrival = `${Trip_Product_Air.arrival.dateTime}#${Trip_Product_Air.arrival.iataCode}`;
        const idAirlineFlight = `${Trip_Product_Air.number}#${Trip_Product_Air.carrierCode}`;

        id = `${idDeparture}#${idArrival}#${idAirlineFlight}`;
        customInfo = GetInfo(filteredTrips, Trip_Product_Air);

        return {
          ...product,
          id,
          index,
          Trip_Product_Air: { ...Trip_Product_Air, customInfo },
        };
      }
      if (Trip_Product_Hotel) {
        id = `${Trip_Product_Hotel.checkInDate}#${Trip_Product_Hotel.checkOutDate}#${Trip_Product_Hotel.name}`;
      }
      if (Trip_Product_Car) {
        id = `${Trip_Product_Car.pickup.dateTime}#${Trip_Product_Car.pickup.location?.cityName}#${Trip_Product_Car.pickup.location?.countryCode}`;
      }
      if (Trip_Product_Train) {
        const idPart1 = `${Trip_Product_Train.vehicle?.number}#${Trip_Product_Train.departureDateTime}`;
        const idPart2 = `${Trip_Product_Train.departure?.name}#${Trip_Product_Train.arrivalDateTime}`;
        const idPart3 = `${Trip_Product_Train.arrival?.name}#${Trip_Product_Train.serviceProviderName}`;
        id = `${idPart1}#${idPart2}#${idPart3}`;
        customInfo = GetInfo(filteredTrips, Trip_Product_Train);
        return {
          ...product,
          id,
          index,
          Trip_Product_Train: { ...Trip_Product_Train, customInfo },
        };
      }

      return { ...product, id, index };
    });

    return { ...tripProduct, products: formattedProducts };
  });
  return { ...formattedTrip, products: formattedTripProducts };
};

export default formatTrip;
