export default function GetPreferredLocation(userSavedLocation: any) {
  let name = "";
  let iatacode;
  const preferredLocation = userSavedLocation.data?.departureLocation;

  if (preferredLocation) {
    name = preferredLocation.iataCode
      ? `${preferredLocation.name} (${preferredLocation.iataCode})`
      : `${preferredLocation.name}`;
    iatacode = preferredLocation.iataCode;
  }
  return {
    name,
    iatacode,
  };
}
