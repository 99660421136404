/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ITrip } from "../../components/upcoming-trips-dashboard/UpcomingTrips/UpcomingTrips.model";

const initialState = {
  trip: {} as ITrip,
};

const tripDetailSlice = createSlice({
  name: "trip",
  initialState,
  reducers: {
    setTrip(state, action) {
      state.trip = action.payload;
    },
  },
});

export const tripDetailActions = tripDetailSlice.actions;

export default tripDetailSlice.reducer;
