/* eslint-disable react/react-in-jsx-scope */
import { Flex, Text } from "@fluentui/react-northstar";
import i18next from "i18next";
import stylesReverseCollaborators from "../../common/styles/close-collaborators.module.scss";

export default function ReverseCollaboratorsEmpty({
  themeName,
}: {
  themeName: string;
}) {
  return (
    <Flex
      className={[
        themeName === "light"
          ? stylesReverseCollaborators.white
          : stylesReverseCollaborators.dark,
        stylesReverseCollaborators.card,
        stylesReverseCollaborators.cardDisabled,
      ].join(" ")}
    >
      <Flex
        className={stylesReverseCollaborators.disabledCardBody}
        vAlign="center"
        column
      >
        <Flex column>
          <Text
            content={i18next.t("reverseCollaborators.emptyMessage")}
            className={stylesReverseCollaborators.noCollaboratorsMsg}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
