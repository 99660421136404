import FeatureToggle from "../../../common/models/FeatureToggle";
import KeepSession from "../../../common/models/KeepSession";
import { breadcrumbActions } from "../../../store/breadcrumb/breadcrumb-slice";
import { retrieveSessionTimeOfCreation } from "../../../utils/KeepSessionFunctions";

const HandleCytricSession = (
  keepSessionObject: KeepSession | null,
  isCytricSessionExpired: boolean,
  cytricSessionDateTimeout: number,
  dispatch: any,
  history: any,
  t: any,
  featureToggleCytricSession: FeatureToggle | undefined
) => {
  const sessionTime = keepSessionObject
    ? retrieveSessionTimeOfCreation(keepSessionObject)
    : null;

  if (featureToggleCytricSession?.isActive && !isCytricSessionExpired) {
    if (sessionTime !== null && cytricSessionDateTimeout > sessionTime) {
      return false;
    }
    const breadcrumbData = localStorage.getItem("breadCrumbInformation");
    if (breadcrumbData) {
      const dataJson = JSON.parse(breadcrumbData);

      if (dataJson[0].url !== "/travel") {
        return false;
      }

      const array: any = [];

      dataJson.forEach((data: any) => {
        array.push({
          label: data.translation ? t(data.label) : data.label,
          url: data.url,
        });
      });
      dispatch(breadcrumbActions.setTravelLevels(array));
      history.push("/cytricweb?sessionType=travel");
      return true;
    }

    return false;
  }
  return false;
};

export default HandleCytricSession;
