import { selectFeatureToggle } from "../store/feature-toggles/FeatureTogglesSelector";
import { useAppSelector } from "../store/StoreHooks";

function CheckFeatureToggle(feature: string) {
  const featureToggle = useAppSelector((state: any) =>
    selectFeatureToggle(state, feature)
  );

  if (featureToggle && featureToggle.isActive) {
    return true;
  }
  return false;
}

export default CheckFeatureToggle;
