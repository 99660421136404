import { Text } from "@fluentui/react-northstar";
import { SegmentHotel } from "../../../../../../common/models/ProductHotel";
import SegmentWrapper from "../SegmentWrapper";

interface HotelSegmentContentProps {
  hotelSegment: SegmentHotel;
  topDate: string | null;
}

function HotelSegmentContent(props: HotelSegmentContentProps) {
  const { hotelSegment, topDate } = props;

  function formatAddress(productHotel: any): string {
    if (productHotel.location) {
      return [
        productHotel.location.address.lines[0],
        productHotel.location.address.postalCode,
        productHotel.location.cityName,
        productHotel.location.countryName,
      ]
        .filter((value) => value && value !== "")
        .join(", ");
    }
    return "";
  }

  const header = (
    <Text weight="bold"> {hotelSegment.Trip_Product_Hotel?.name} </Text>
  );

  const content = <Text>{formatAddress(hotelSegment.Trip_Product_Hotel)}</Text>;
  return (
    <SegmentWrapper
      segment={hotelSegment}
      topDate={topDate}
      segmentType="hotel"
    >
      {header}
      {content}
    </SegmentWrapper>
  );
}

export default HotelSegmentContent;
