export default function getUsersRecipients(
  searchItems: any[],
  users: any,
  hits: any
) {
  let searchCopy = searchItems;
  if (users && users.length > 0) {
    let userCopy = users;
    if (hits.length > 0) {
      hits.forEach((hit: any) => {
        userCopy = userCopy.filter((user: any) => user.key !== hit.key);
      });
      searchCopy = [...searchCopy, ...userCopy.slice(0, 3)];
      return searchCopy;
    }
    searchCopy = [...searchCopy, ...users.slice(0, 3)];
  }

  return searchCopy;
}
