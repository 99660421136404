import { Flex, Table, Text } from "@fluentui/react-northstar";
import i18next from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectReverseCollaboratorsIsError,
  selectReverseCollaboratorsIsPending,
} from "../../../store/collaborators/reverse-collaborators/ReverseCollaboratorSelector";
import { ReverseCollaboratorsModel } from "../close-collaborators/models/CloseCollaboratorsModel";
import CollaboratorsDisabled from "../common/collaborators-disabled/CollaboratorsDisabled";
import stylesCloseCollaborators from "../common/styles/close-collaborators.module.scss";
import ReverseCollaboratorsEmpty from "./reverse-collaborators-empty/ReverseCollaboratorsEmpty";
import ReverseCollaboratorsLoading from "./reverse-collaborators-loading/ReverseCollaboratorsLoading";
import {
  getChatIconReverse,
  getHeader,
  getTripDates,
  getUserReverse,
} from "./utils/ReverseCollaboratorsUtils";

export default function ReverseCollaboratorsTable({
  collaborators,
  themeName,
}: {
  collaborators: ReverseCollaboratorsModel[] | undefined;
  themeName: string;
}) {
  const isLoading = useSelector(selectReverseCollaboratorsIsPending);
  const isError = useSelector(selectReverseCollaboratorsIsError);

  const reverseCollaboratorsTable = (
    reverseCollabs: ReverseCollaboratorsModel[]
  ) => (
    <Flex column data-testid="reverse-collaborators-component">
      <Table
        header={getHeader()}
        rows={reverseCollabs.map((collaborator: any, index: any) => ({
          key: `${index}-row-rc`,
          items: [
            getUserReverse(collaborator, index),
            getTripDates(collaborator, index),
            getChatIconReverse(collaborator, index),
          ],
          className: stylesCloseCollaborators.userRow,
        }))}
        className={[
          themeName === "light"
            ? stylesCloseCollaborators.white
            : stylesCloseCollaborators.dark,
          stylesCloseCollaborators.table,
          stylesCloseCollaborators.tableReverse,
        ].join(" ")}
        aria-label={i18next.t("closeCollaborators.table.tableAriaLabel", {
          numberOfRows: reverseCollabs.length + 1,
          numberOfColumns: "3",
        })}
      />
    </Flex>
  );

  const reverseCollaboratorContainer = (child: JSX.Element) => (
    <Flex
      column
      space={isLoading ? undefined : "between"}
      className={stylesCloseCollaborators.reverseCollaboratorsContainer}
    >
      <Flex column>
        <Flex
          className={
            themeName === "light"
              ? stylesCloseCollaborators.white
              : stylesCloseCollaborators.dark
          }
        >
          <p
            className={stylesCloseCollaborators.blockOfText}
            role="heading"
            aria-level={2}
          >
            <Text
              styles={(theme) => ({
                color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
              })}
              className={stylesCloseCollaborators.text}
            >
              {i18next.t("reverseCollaborators.title")}
            </Text>
            {i18next.t("reverseCollaborators.subTitle")}
          </p>
        </Flex>
        <div className={stylesCloseCollaborators.tableContainer}>
          {!isLoading &&
            !isError &&
            collaborators &&
            reverseCollaboratorsTable(collaborators)}
        </div>
      </Flex>
      {child}
      <Flex />
    </Flex>
  );

  if (isError) {
    return reverseCollaboratorContainer(
      <Flex>
        <CollaboratorsDisabled
          themeName={themeName}
          isTitleVisible={false}
          isReverseCollabError
        />
      </Flex>
    );
  }

  if (!isLoading && collaborators) {
    return reverseCollaboratorContainer(
      <Flex className={stylesCloseCollaborators.tableContainer}>
        {collaborators.length === 0 && (
          <ReverseCollaboratorsEmpty themeName={themeName} />
        )}
      </Flex>
    );
  }

  return reverseCollaboratorContainer(
    <ReverseCollaboratorsLoading themeName={themeName} />
  );
}
