import {
  Card,
  cardFocusableBehavior,
  Checkbox,
  Flex,
  FlexItem,
  hiddenComponentBehavior,
  Text,
} from "@fluentui/react-northstar";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "../../../context/ShareJoinContext";
import { types } from "../../../context/ShareJoinReducer";
import Styles from "../../../ShareJoin.module.scss";
import getSegmentImage from "../functions/getSegmentImage";
import SegmentWrapperStyles from "./SegmentWrapper.module.scss";

function SegmentWrapper({ segment, topDate, segmentType, children }: any) {
  const [checked, setChecked] = useState<boolean>(true);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { t } = i18next;

  const handleCheckAccessibility = (event: any) => {
    if (event.keyCode === 32 || event.keyCode === 13) {
      if (checked) {
        dispatch({ type: types.addSegment, payload: segment });
      } else {
        dispatch({ type: types.removeSegment, payload: segment.id });
        dispatch({ type: types.checkHasSegments });
      }
    }
  };

  const segmentImage = getSegmentImage(segmentType);

  const checkbox = (
    <Checkbox
      aria-checked={checked}
      data-testid="segment-checkbox"
      checked={checked}
      aria-label={t("shareJoin.selectFlight")}
      accessibility={hiddenComponentBehavior}
      onChange={handleCheckAccessibility}
    />
  );

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else if (checked) {
      dispatch({ type: types.addSegment, payload: segment });
      dispatch({ type: types.sortSegments });
    } else {
      dispatch({ type: types.removeSegment, payload: segment.id });
      dispatch({ type: types.checkHasSegments });
      dispatch({ type: types.sortSegments });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <Flex column role="listitem">
      {topDate !== null && (
        <Text size="small" className={Styles.topDate}>
          {topDate}
        </Text>
      )}
      <Card
        data-testid="checkable-card"
        accessibility={cardFocusableBehavior}
        selected={checked}
        fluid
        className={Styles.cardSegment}
        onClick={() => {
          setChecked(!checked);
        }}
        onKeyDown={handleCheckAccessibility}
        styles={(theme: any) => ({
          backgroundColor: checked
            ? theme.theme.siteVariables.colorScheme?.default.backgroundFocus3
            : theme.theme.siteVariables.colorScheme?.default.backgroundFocus1,
        })}
      >
        <Card.Header fitted>
          <Flex gap="gap.medium" vAlign="center">
            {segmentImage}
            <Flex column className={Styles.cardSegmentContent}>
              {children}
            </Flex>
            <FlexItem push>
              <Card.TopControls className={SegmentWrapperStyles.topControls}>
                <Flex>
                  {checkbox}
                  <div
                    aria-live="polite"
                    role="presentation"
                    className={SegmentWrapperStyles.checkedText}
                  >
                    {checked ? "checked" : "unchecked"}
                  </div>
                </Flex>
              </Card.TopControls>
            </FlexItem>
          </Flex>
        </Card.Header>
      </Card>
    </Flex>
  );
}

export default SegmentWrapper;
