import { Dialog } from "@fluentui/react-northstar";
import React from "react";
import DialogHeader from "../../../common/components/dialog-header/DialogHeader";
import { KeepSessionActions } from "../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../utils/KeepUserActivityContext";
import DialogBody from "./DialogBody";
import { IToDoDialogProps } from "./ToDoDialog.model";
import Styles from "./ToDoDialog.module.scss";

function ToDoDialog({ toDoDialog, dispatchDialog }: IToDoDialogProps) {
  const { taskHandler, headerTitle, data, confirmButtonText, isVisible } =
    toDoDialog;

  const handleClose = () => {
    const activity = new ActivityClass();
    activity.removeElementOrChildren(KeepSessionActions.DETAILS_ADDTASK);
    dispatchDialog();
  };
  return (
    <Dialog
      closeOnOutsideClick
      onCancel={handleClose}
      content={
        <>
          <DialogHeader
            dispatchDialog={dispatchDialog}
            headerTitle={headerTitle}
          />
          <DialogBody
            dispatchDialog={dispatchDialog}
            data={data}
            taskHandler={taskHandler}
            confirmButtonText={confirmButtonText}
          />
        </>
      }
      className={Styles["to-do-dialog"]}
      styles={(theme) => ({
        backgroundColor: theme.theme.siteVariables.toDoList?.dialog?.background,
      })}
      backdrop
      open={isVisible}
    />
  );
}
export default ToDoDialog;
