function CheckContextForTripId(page: string, subEntityId: any) {
  // eslint-disable-next-line no-restricted-globals
  if (subEntityId !== "" && !isNaN(subEntityId)) {
    localStorage.removeItem("KeepSessionCache");
    return `/trip:${subEntityId}`;
  }
  return page;
}

export default CheckContextForTripId;
