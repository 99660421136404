import { Flex, Text } from "@fluentui/react-northstar";
import { composeTripName } from "../../../utils/trip-utils";
import ContextMenu from "../../ContextMenu/ContextMenu";
import DateComponent from "../../Date/DateComponent";
import Styles from "./Destination.module.scss";

function Destination({ trip }: any) {
  return (
    <Flex space="between" className={Styles.itemsPlacement}>
      <Flex column className={Styles.tripNameDate}>
        <h3>
          <Text
            className={Styles.destination}
            role="document"
            styles={(theme) => ({
              color: theme.theme.siteVariables.tripCard?.title,
            })}
            data-testid="trip-name"
          >
            {composeTripName(trip)}
          </Text>
        </h3>
        <div className={Styles["trip-card__date"]}>
          <DateComponent start_date={trip.startDate} end_date={trip.endDate} />
        </div>
      </Flex>
      <ContextMenu tripName={composeTripName(trip)} trip={trip} />
    </Flex>
  );
}

export default Destination;
