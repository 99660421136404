/* eslint-disable no-debugger */
import { Button, Flex } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import ShareJoinFormStyles from "../../../../../ShareJoin.module.scss";

function ShareButtons({
  handleClick,
  closeTask,
  themeName,
  disableShare,
  loadingShare,
  segmentsLength,
  device,
  classTabletDevice,
}: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: "shareTrip",
  });

  return (
    <Flex
      hAlign="end"
      gap="gap.small"
      className={[
        themeName === "default"
          ? ShareJoinFormStyles.white
          : ShareJoinFormStyles.dark,
        ShareJoinFormStyles.actions,
        classTabletDevice,
      ].join(" ")}
    >
      <Flex gap="gap.small">
        <Button
          content={t("cancelButton")}
          flat
          onClick={() => closeTask()}
          data-testid="closeButtonShareTrip"
          tinted={device === "tablet" || device === "mobile"}
          fluid={device === "mobile"}
          className={ShareJoinFormStyles.buttons}
        />

        <Button
          data-testid="shareButtonShareTrip"
          flat
          fluid={device === "mobile"}
          disabled={disableShare}
          loading={loadingShare}
          aria-label={
            disableShare ? t("shareButtonDisabled") : t("shareButtonEnabled")
          }
          primary
          onClick={() => handleClick()}
          className={ShareJoinFormStyles.buttons}
        >
          {t("shareButtonEnabled")}{" "}
          {segmentsLength && segmentsLength > 0 ? `(${segmentsLength})` : ""}
        </Button>
      </Flex>
    </Flex>
  );
}

export default ShareButtons;
