/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-dupe-else-if */
/* eslint-disable arrow-body-style */
import { TRIP_PRODUCT_RAIL } from "../../../components/upcoming-trips-dashboard/data/constants";
import { IRailSegment } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetails/SegmentTabs/Tabs/Train/Rail.model";
import {
  composeBounds,
  getAllStopsDetails,
  getServiceProviders,
  getTotalTravelDuration,
  validateProviderLogoUrl,
} from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import DateUtils from "../../../utils/date.utils";
import { RootState } from "../../StoreHooks";
import { getProductsByType } from "./trip-detail-selectors";
import { IRailBoundDetails } from "./trip-detail-selectors.model";

const getRailBoundBasicDetails = (bound: IRailSegment[]) => {
  try {
    const logo = validateProviderLogoUrl(bound?.[0]?.vehicle?.logo[0]);
    const departure =
      bound[0]?.departure?.name ||
      bound[0].departure.iataCode ||
      bound[0].departure.cityName;
    const arrival =
      bound[bound.length - 1]?.arrival?.name ||
      bound[bound.length - 1].arrival.iataCode ||
      bound[0].arrival.cityName;
    const railDurations = bound.map(
      (boundSegment: IRailSegment) => boundSegment.duration
    );
    const totalTravelDuration = getTotalTravelDuration(railDurations);
    const numberOfStops = bound.length - 1;
    const getters = {
      getDepartureTime: (segment: IRailSegment) => segment?.departureDateTime,
      getArrivalTime: (segment: IRailSegment) => segment?.arrivalDateTime,
      getDepartureStation: (segment: IRailSegment) => segment?.departure?.name,
      getArrivalStation: (segment: IRailSegment) => segment?.arrival?.name,
      getLayoverLocation: (segment: IRailSegment) =>
        segment?.arrival?.name || segment?.arrival?.cityName,
    };
    const stopsIn = getAllStopsDetails(bound, getters);

    const serviceProvidersWithNumbers = getServiceProviders(
      bound,
      (segment: IRailSegment) => segment?.vehicle?.category,
      (segment: IRailSegment) => segment?.vehicle?.number
    );

    const { departureDateTime } = bound[0];
    const { arrivalDateTime } = bound[bound.length - 1];
    const type = bound[0].productType;
    const departureName = bound[0].departure.cityName;
    const arrivalName = bound[0].arrival.cityName;

    return {
      logo,
      departure,
      arrival,
      totalTravelDuration,
      numberOfStops,
      stopsIn,
      serviceProvidersWithNumbers,
      departureDateTime,
      arrivalDateTime,
      getters,
      type,
      departureName,
      arrivalName,
    };
  } catch {
    return {};
  }
};

const getReservationInfo = (segment: IRailSegment) => {
  const header = {
    title: `${segment?.vehicle?.category} ${segment?.vehicle?.number}`,
    subTitle: "",
  };

  const items = [];
  items.push({
    label: "Seats",
    value: segment?.seats?.[0]?.trainSeatNumber || null,
  });

  return { header, items };
};

function getRailSegmentExtendedDetails(rail: IRailSegment) {
  const { departureDateTime, arrivalDateTime } = rail;

  return {
    serviceProvider: rail?.serviceProviderName,
    departure: rail?.departure,
    departureDateTime,
    duration: rail?.duration,
    arrival: rail?.arrival,
    arrivalDateTime,
    logo: rail?.vehicle.logo[0],
    serviceClass: rail?.bookingClass,
    seats: rail?.seats?.[0]?.trainSeatNumber,
    reservationInfo: getReservationInfo(rail),
    confirmationNumber: rail?.confirmNbr,
  };
}

export const createRailDetails = (state: RootState) => {
  const segments = getProductsByType(state, TRIP_PRODUCT_RAIL);
  const bounds = composeBounds(segments);

  return bounds.reduce((acc: any, bound: IRailSegment[]) => {
    const basicDetails = getRailBoundBasicDetails(bound);
    const extendedDetails = bound.reduce((acc2: any, segment: IRailSegment) => {
      const segmentExtendedDetail = getRailSegmentExtendedDetails(segment);
      acc2.push(segmentExtendedDetail);
      return acc2;
    }, []);
    const railDetail = { basicDetails, extendedDetails };
    acc.push(railDetail);
    return acc;
  }, []);
};

export const areRailBoundsRoundTrip = (bounds: IRailBoundDetails[]) => {
  const firstStation = bounds?.[0]?.extendedDetails?.[0]?.departure?.name;

  const lastBound = bounds?.[bounds.length - 1];
  const lastExtendedDetailsLength = lastBound?.extendedDetails?.length;
  const lastStation =
    lastBound?.extendedDetails?.[lastExtendedDetailsLength - 1]?.arrival?.name;

  if (firstStation === lastStation) {
    return true;
  }
  return false;
};

export const getRoundtripRailDetails = (state: RootState) => {
  const rails = createRailDetails(state);
  const boundGroups: string[] = [];

  // Group rail bounds into roundtrips
  while (rails.length > 0) {
    const firstElem = rails?.[0];
    const secondElem = rails?.[1];

    if (areRailBoundsRoundTrip([firstElem, secondElem])) {
      boundGroups.push(rails.splice(0, 2));
    } else {
      boundGroups.push(rails.splice(0, 1));
    }
  }

  return boundGroups;
};

export const getRailDetailsGroupedByDate = (state: RootState) => {
  const boundGroups = getRoundtripRailDetails(state);

  return boundGroups.reduce((acc: any, boundGroup: any) => {
    const departureDate = DateUtils.formatLongDate(
      DateUtils.getUTCDate(
        boundGroup?.[0]?.extendedDetails?.[0]?.departureDateTime
      )
    );

    acc[departureDate] = acc[departureDate] || [];
    acc[departureDate].push(boundGroup);

    return acc;
  }, {});
};

export const getInboundDateOfRailBoundGroup = (
  boundGroup: [IRailBoundDetails[]]
) => {
  try {
    const lastBound = boundGroup?.[boundGroup?.length - 1];

    if (lastBound?.length > 1) {
      const segments = lastBound?.[lastBound?.length - 1]?.extendedDetails;
      return segments?.[0]?.departureDateTime || null;
    }
  } catch {
    return null;
  }
  return null;
};
