/* eslint-disable react/require-default-props */
import {
  ExclamationTriangleIcon,
  InfoIcon,
} from "@fluentui/react-icons-northstar";
import { Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { UPCOMING_TRIP_DETAIL } from "../../../../../../../data/constants";
import { getStopDetailsV1 } from "../../../../../../../utils/trip-utils";
import {
  IArrivalDepartureFlight,
  IArrivalDepartureRail,
} from "../Accordion/Accordion.model";
import { IStopDuration } from "./StopDuration.model";
import Styles from "./StopDuration.module.scss";

function extractMinutesFromTitle(titleString: string) {
  if (Number(titleString) || titleString === "0") {
    return titleString;
  }
  return null;
}

function titleScreenReaderHoursMinutesReplacer(inputTitle: string, t: any) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let titleSR = inputTitle;

  const titleHours =
    Number(titleSR.split("h")[0].trim()) || titleSR.split("h")[0].trim() === "0"
      ? titleSR.split("h")[0].trim()
      : null;
  const titleMinutes =
    titleHours == null
      ? extractMinutesFromTitle(inputTitle.split("m")[0].trim())
      : extractMinutesFromTitle(inputTitle.split("h")[1].split("m")[0].trim());

  if (titleSR) {
    if (titleHours === "1") {
      titleSR = titleSR.replace("h", t("tripDetails.hour"));
    } else if (titleHours != null) {
      titleSR = titleSR.replace("h", t("tripDetails.hours"));
    }
    if (titleMinutes === "1") {
      titleSR = titleSR.replace("m", t("tripDetails.minute"));
    } else if (titleMinutes != null) {
      titleSR = titleSR.replace("m", t("tripDetails.minutes"));
    }
  }

  return titleSR;
}

function titleScreenReader(
  stopDuration: any,
  stopDurationIn: any,
  isTheSameStation: any,
  changeOfStationFullText: any,
  t: any
) {
  let titleSR = "";
  if (typeof stopDuration !== "boolean") {
    titleSR = `${stopDuration} ${t(
      "tripDetails.flightTrain.layover"
    )} ${stopDurationIn}`;
    titleSR = titleScreenReaderHoursMinutesReplacer(titleSR, t);
  }
  if (!isTheSameStation) {
    titleSR = `${t(
      "tripDetails.flightTrain.warningAlert"
    )}. ${titleSR}. ${changeOfStationFullText}`;
  }
  return titleSR;
}

const getStation = (
  arrival: IArrivalDepartureRail | IArrivalDepartureFlight
): string => {
  if ("airportName" in arrival) {
    return arrival.airportName ?? "";
  }
  return arrival.name ?? "";
};

const getChangeOfStation = (
  arrival: IArrivalDepartureRail | IArrivalDepartureFlight,
  t: any
): string =>
  "airportName" in arrival
    ? t("tripDetails.flightTrain.changeOfAirport")
    : t("tripDetails.flightTrain.changeOfRailwayStation");

function StopDuration({ firstSegment, secondSegment, getters }: IStopDuration) {
  const { t } = useTranslation();

  const [stopDuration, stopDurationIn, isTheSameStation] = getStopDetailsV1(
    firstSegment,
    secondSegment,
    getters
  );

  const alertIcon = isTheSameStation ? (
    <InfoIcon outline />
  ) : (
    <ExclamationTriangleIcon />
  );

  const stopDurationText = isTheSameStation
    ? `${stopDuration} ${t(
        "tripDetails.detailCard.layoverIn"
      )} ${stopDurationIn}`
    : `${stopDuration} ${t(
        "tripDetails.detailCard.layoverIn"
      )} ${stopDurationIn} - `;

  const oldArrivalStation = firstSegment && getStation(firstSegment.arrival);
  const newDepartureStation =
    secondSegment && getStation(secondSegment.departure);

  const changeOfStation =
    firstSegment && getChangeOfStation(firstSegment?.arrival, t);

  const departureChange = `${oldArrivalStation} ${t(
    "tripDetails.flightTrain.to"
  )} ${newDepartureStation}`;
  const changeOfStationFullText = `${changeOfStation}: ${departureChange}`;

  const stopDurationElement = (
    <div
      className={Styles["duration-container"]}
      role="document"
      aria-hidden="true"
    >
      <div className={Styles.icon}>
        <div>{alertIcon}</div>
      </div>
      <div className={Styles["airport-change"]}>
        <div className={Styles.text}>{stopDurationText}</div>
        {isTheSameStation ? "" : changeOfStationFullText}
      </div>
    </div>
  );

  const backgroundColor = isTheSameStation
    ? UPCOMING_TRIP_DETAIL.stopDuration.background
    : UPCOMING_TRIP_DETAIL.stopDuration.backgroundAlert;
  const borderColor = isTheSameStation
    ? UPCOMING_TRIP_DETAIL.stopDuration.border
    : UPCOMING_TRIP_DETAIL.stopDuration.borderAlert;
  const color = isTheSameStation
    ? UPCOMING_TRIP_DETAIL.stopDuration.color
    : UPCOMING_TRIP_DETAIL.stopDuration.colorAlert;

  return stopDuration ? (
    <Text
      title={titleScreenReader(
        stopDuration,
        stopDurationIn,
        isTheSameStation,
        changeOfStationFullText,
        t
      )}
      className={Styles.stop}
      styles={(theme) => ({
        background:
          theme.theme.siteVariables.extendedDetails?.stop[backgroundColor],
        borderColor:
          theme.theme.siteVariables.extendedDetails?.stop[borderColor],
        color: theme.theme.siteVariables.extendedDetails?.stop[color],
      })}
      content={stopDurationElement}
    />
  ) : (
    <div />
  );
}
export default StopDuration;
