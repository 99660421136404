import { Open16Regular } from "@fluentui/react-icons";
import { Text } from "@fluentui/react-northstar";
import Styles from "./NewProduct.module.scss";

export function NewProductTrainScreenReader(t: any, showTrainIcon: string) {
  function externalLinkScreenReader() {
    return (
      <Text
        className={Styles.screenReaderOnly}
        content={`. ${t(
          "upcoming_trips_dashboard.upcomingTrips.newBook.externalLink"
        )}`}
      />
    );
  }

  return showTrainIcon === "train" ? externalLinkScreenReader() : null;
}

export function NewProductTrainIcon(showTrainIcon: string) {
  function externalLinkIconTrain() {
    return (
      <Open16Regular
        data-testid="external-link-icon-train"
        className={Styles.externalLinkIcon}
      />
    );
  }

  return showTrainIcon === "train" ? externalLinkIconTrain() : null;
}
