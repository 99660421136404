const types = {
  setTrip: "setTrip",
  setSegments: "setSegments",
  setInitialSegments: "setInitialSegments",
  resetInitialSegments: "resetInitialSegments",
  addSegment: "addSegment",
  removeSegment: "removeSegment",
  checkHasSegments: "checkHasSegments",
  sortSegments: "sortSegments",
};

const shareJoinReducer = (state: any, action: any) => {
  switch (action.type) {
    case types.setTrip:
      return {
        ...state,
        tripTemplate: action.payload,
      };
    case types.setSegments:
      return {
        ...state,
        segmentsToShare: action.payload.products,
        hasAir: action.payload.hasAir,
        hasHotel: action.payload.hasHotel,
        hasRail: action.payload.hasRail,
        hasCar: action.payload.hasCar,
      };
    case types.addSegment:
      return {
        ...state,
        segmentsToShare: [...state.segmentsToShare, action.payload],
        hasAir: action.payload.Trip_Product_Air ? true : state.hasAir,
        hasHotel: action.payload.Trip_Product_Hotel ? true : state.hasHotel,
        hasRail: action.payload.Trip_Product_Train ? true : state.hasRail,
        hasCar: action.payload.Trip_Product_Car ? true : state.hasCar,
      };
    case types.setInitialSegments:
      return {
        ...state,
        initialSegments: [...state.initialSegments, action.payload],
      };
    case types.resetInitialSegments:
      return {
        ...state,
        initialSegments: [],
      };
    case types.removeSegment:
      return {
        ...state,
        segmentsToShare: state.segmentsToShare.filter(
          (segment: any) => segment.id !== action.payload
        ),
      };
    case types.checkHasSegments:
      return {
        ...state,
        hasAir: !!state.segmentsToShare.find(
          (segment: any) => segment.Trip_Product_Air
        ),
        hasHotel: !!state.segmentsToShare.find(
          (segment: any) => segment.Trip_Product_Hotel
        ),
        hasRail: !!state.segmentsToShare.find(
          (segment: any) => segment.Trip_Product_Train
        ),
        hasCar: !!state.segmentsToShare.find(
          (segment: any) => segment.Trip_Product_Car
        ),
      };
    case types.sortSegments:
      return {
        ...state,
        segmentsToShare: state.segmentsToShare.sort(
          (a: any, b: any) => a.index - b.index
        ),
      };
    default:
      return state;
  }
};

export { types };
export default shareJoinReducer;
