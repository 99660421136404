import {
  DateFormat,
  DateUtils as DateUtilsLibrary,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Box, Image, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../../../../../i18n/i18n";
import DateUtils from "../../../../../../../../../utils/date.utils";
import firstLetterUppercase from "../../../../../../../utils/firstLetterUppercase";
import { handleProviderLogoError } from "../../../../../../../utils/trip-utils";
import AriaStyle from "../../../SegmentTabs.module.scss";
import Divider from "../../components/Divider/Divider";
import EquipmentsList from "../EquipmentsList/EquipmentsList";
import Header from "../Header/Header";
import { ICarSegmentProps } from "./CarSegment.model";
import Styles from "./CarSegment.module.scss";

function CarSegment({ details, index, totalitems }: ICarSegmentProps) {
  const {
    logo,
    pickUpDateTime,
    dropOffDateTime,
    associatedEquipments,
    serviceProviderName,
  } = details;

  const { t } = useTranslation();
  const accessibilityTitle = () => {
    if (
      DateUtils.getUTCDate(dropOffDateTime) !==
      DateUtils.getUTCDate(pickUpDateTime)
    ) {
      return `${t(
        "upcoming_trips_dashboard.tripcard.from"
      )} ${DateUtilsLibrary.getDateFormat(
        pickUpDateTime,
        i18n.language,
        DateFormat.DATE_LONG
      )} ${t(
        "upcoming_trips_dashboard.tripcard.to"
      )} ${DateUtilsLibrary.getDateFormat(
        dropOffDateTime,
        i18n.language,
        DateFormat.DATE_LONG
      )}${"."} ${totalitems} ${t("tripDetails.item/s")}`;
    }
    return `${DateUtilsLibrary.getDateFormat(
      pickUpDateTime,
      i18n.language,
      DateFormat.DATE_LONG
    )}${"."} ${totalitems} ${t("tripDetails.item/s")}`;
  };

  const pickUpShortDate = DateUtilsLibrary.getDateFormat(
    pickUpDateTime,
    i18n.language,
    DateFormat.DATE_SHORT
  );
  const dropOffShortDate = DateUtilsLibrary.getDateFormat(
    dropOffDateTime,
    i18n.language,
    DateFormat.DATE_SHORT
  );

  return (
    <div role="grid" aria-busy="true">
      <span className={AriaStyle.visuallyHidden}>{accessibilityTitle()}</span>
      <Text
        role="row"
        aria-hidden
        className={Styles.date}
        as="div"
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.date,
        })}
        content={
          pickUpShortDate === dropOffShortDate
            ? `${firstLetterUppercase(pickUpShortDate)}`
            : `${firstLetterUppercase(
                pickUpShortDate
              )} - ${firstLetterUppercase(dropOffShortDate)}`
        }
        data-testid="date_trip_details"
      />
      <div
        role="rowgroup"
        aria-label={`${t(
          "upcoming_trips_dashboard.tripcard.icons.Car"
        )} ${index} ${t("tripDetails.outOf")} ${totalitems} `}
      >
        <Box
          aria-busy="true"
          role="row"
          className={Styles.box}
          styles={(theme) => ({
            borderColor: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.borderColor
              : "",
            background: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.background
              : "",
          })}
        >
          <Header details={details} />
          <Divider colorTheme="bookingDetail" />
          <div className={Styles.footer}>
            <EquipmentsList equipments={associatedEquipments} />
            <div
              className={Styles["image-wrapper"]}
              title={`${serviceProviderName} ${t("tripDetails.car.company")} `}
            >
              <Image
                role="img"
                className={Styles["car-logo"]}
                src={logo}
                onError={handleProviderLogoError}
              />
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default CarSegment;
