/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { COACHMARK_FLOW } from "../../components/coachmarks/data/constants";

const initialState = {
  isJoyrideActive: false,
  flow: COACHMARK_FLOW.AUTO_TRIGGERED,
  tourDidRun: false,
  appIconYCoordinate: 0,
};

const coachmarksSlice = createSlice({
  name: "coachmarks",
  initialState,
  reducers: {
    setIsJoyrideActive(state, action) {
      state.isJoyrideActive = action.payload;
    },
    setFlow(state, action) {
      state.flow = action.payload;
    },
    setTourDidRun(state, action) {
      state.tourDidRun = action.payload;
    },
    setAppIconYCoordinate(state, action) {
      state.appIconYCoordinate = action.payload;
    },
  },
});

export const coachmarksActions = coachmarksSlice.actions;

export default coachmarksSlice.reducer;
