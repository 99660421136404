/* eslint-disable import/prefer-default-export */
/**
 * Decodes the given subEntityId if it is shared from Cytric Classic.
 * All urls coming from Cytric Classic are encoded in base64.
 *
 * @param subEntityId - The subEntityId to decode.
 * @returns The decoded subEntityId.
 */
export function decodeIfSharedFromCytric(
  subEntityId: string | undefined
): string {
  if (subEntityId == null) {
    return "";
  }
  try {
    return subEntityId ? atob(subEntityId) : subEntityId;
  } catch {
    // If subEntityId is not encoded in base64, return it as is.
    // It is most likely a link from other Cytric Easy feature, not from Cytric Classic.
    return subEntityId;
  }
}
