/* eslint-disable react-hooks/exhaustive-deps */
import { Feature } from "@paralleldrive/react-feature-toggles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Joyride, { ACTIONS, EVENTS, Step } from "react-joyride";
import { useHistory, useLocation } from "react-router-dom";
import Responsive from "../../common/components/responsive/Responsive";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import { coachmarksActions } from "../../store/coachmarks/CoachmarksSlice";
import { selectTripsSortedByDepartureDate } from "../../store/upcoming-trips-dashboard/trips-summary-selectors";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../utils/constants";
import createTooltip from "./Tooltip";
import { COACHMARK, COACHMARK_FLOW } from "./data/constants";
import {
  createJoyrideStep,
  saveCoachmarksAsShownToLocalStorage,
} from "./utils";

const COACHMARK_PLACEMENT_RIGHT_START = "right-start";
const COACHMARK_PLACEMENT_LEFT_START = "left-start";
const COACHMARK_PLACEMENT_BOTTOM_START = "bottom-start";

function Coachmarks() {
  const { t } = useTranslation("translation", {
    keyPrefix: "coachmarks",
  });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const trips = useAppSelector(selectTripsSortedByDepartureDate);
  const flow = useAppSelector((state) => state.coachmarks.flow);
  const isJoyrideActive = useAppSelector(
    (state) => state.coachmarks.isJoyrideActive
  );

  const [startFlow, setStartFlow] = useState(false);
  const appIconYCoordinate = useAppSelector(
    (state) => state.coachmarks.appIconYCoordinate
  );
  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPTakeATour.id
  );

  useEffect(() => {
    const getTimeout = () => {
      if (flow === COACHMARK_FLOW.AUTO_TRIGGERED) {
        return 3000;
      }
      return location?.pathname !== "/travel" ? 3000 : 100;
    };
    setTimeout(() => {
      setStartFlow(true);
    }, getTimeout());

    // Starting joyride at load to check automatically triggered flow
    dispatch(coachmarksActions.setIsJoyrideActive(true));
  }, []);

  const stepFlows = {
    [COACHMARK_FLOW.TAKE_A_TOUR]: useCallback(() => {
      const steps: Step[] = [];

      if (location?.pathname === "/travel") {
        steps.push(
          createJoyrideStep(
            t,
            COACHMARK.pinTheApp,
            COACHMARK.pinTheApp,
            "right",
            0,
            0
          )
        );
      }

      steps.push(
        createJoyrideStep(
          t,
          COACHMARK.planATrip,
          COACHMARK.planATrip,
          COACHMARK_PLACEMENT_RIGHT_START,
          -1
        )
      );

      if (trips.length > 0) {
        steps.push(
          createJoyrideStep(
            t,
            COACHMARK.tripCard,
            COACHMARK.tripCard,
            COACHMARK_PLACEMENT_RIGHT_START,
            0
          ),
          createJoyrideStep(
            t,
            COACHMARK.shareTransfer,
            COACHMARK.shareTransfer,
            COACHMARK_PLACEMENT_RIGHT_START,
            0
          ),
          createJoyrideStep(
            t,
            COACHMARK.closeCollaborators,
            COACHMARK.closeCollaborators,
            COACHMARK_PLACEMENT_LEFT_START
          )
        );
      }

      if (!isTRIPPUser) {
        steps.push(
          createJoyrideStep(
            t,
            COACHMARK.expenseTab,
            COACHMARK.expenseTab,
            COACHMARK_PLACEMENT_BOTTOM_START,
            -2
          )
        );
      }

      return steps;
    }, []),

    [COACHMARK_FLOW.AUTO_TRIGGERED]: useCallback(() => {
      const steps: Step[] = [];

      const COACHMARKS_TO_BE_IGNORED = JSON.parse(
        localStorage.getItem("COACHMARKS_TO_BE_IGNORED") ?? "[]"
      );

      const pushToStepsIfNotIgnored = (
        coachmarkId: string,
        stepComponent: Step
      ) => {
        if (!COACHMARKS_TO_BE_IGNORED.includes(coachmarkId)) {
          steps.push(stepComponent);
        }
      };

      if (location?.pathname === "/travel") {
        pushToStepsIfNotIgnored(
          COACHMARK.pinTheApp,
          createJoyrideStep(
            t,
            COACHMARK.pinTheApp,
            COACHMARK.pinTheApp,
            "right",
            0,
            0
          )
        );

        pushToStepsIfNotIgnored(
          COACHMARK.planATrip,
          createJoyrideStep(
            t,
            COACHMARK.planATrip,
            COACHMARK.planATrip,
            COACHMARK_PLACEMENT_RIGHT_START,
            -1
          )
        );

        if (trips.length > 0) {
          pushToStepsIfNotIgnored(
            COACHMARK.tripCard,
            createJoyrideStep(
              t,
              COACHMARK.tripCard,
              COACHMARK.tripCard,
              COACHMARK_PLACEMENT_RIGHT_START,
              0
            )
          );
        } else if (!isTRIPPUser) {
          pushToStepsIfNotIgnored(
            COACHMARK.expenseTab,
            createJoyrideStep(
              t,
              COACHMARK.expenseTab,
              COACHMARK.expenseTab,
              COACHMARK_PLACEMENT_BOTTOM_START,
              -2
            )
          );
        }
      }

      if (location?.pathname !== "/travel") {
        pushToStepsIfNotIgnored(
          COACHMARK.shareTransfer,
          createJoyrideStep(
            t,
            COACHMARK.shareTransfer,
            COACHMARK.shareTransfer,
            COACHMARK_PLACEMENT_RIGHT_START,
            0
          )
        );

        pushToStepsIfNotIgnored(
          COACHMARK.closeCollaborators,
          createJoyrideStep(
            t,
            COACHMARK.closeCollaborators,
            COACHMARK.closeCollaborators,
            "left-start"
          )
        );

        if (!isTRIPPUser) {
          pushToStepsIfNotIgnored(
            COACHMARK.expenseTab,
            createJoyrideStep(
              t,
              COACHMARK.expenseTab,
              COACHMARK.expenseTab,
              COACHMARK_PLACEMENT_BOTTOM_START,
              -2
            )
          );
        }
      }

      return steps;
    }, []),

    [COACHMARK_FLOW.CLOSE_COLLABORATORS]: useCallback(() => {
      const steps: Step[] = [
        createJoyrideStep(
          t,
          COACHMARK.closeCollaborators,
          COACHMARK.closeCollaborators,
          COACHMARK_PLACEMENT_LEFT_START
        ),
      ];
      return steps;
    }, []),

    [COACHMARK_FLOW.SHARE_TRANSFER_ENABLE_ALL]: useCallback(() => {
      const steps: Step[] = [
        createJoyrideStep(
          t,
          COACHMARK.shareTransferEnableAll,
          COACHMARK.shareTransferEnableAll,
          COACHMARK_PLACEMENT_RIGHT_START
        ),
      ];
      return steps;
    }, []),

    [COACHMARK_FLOW.NOTIFY_CLOSE_COLLABORATORS]: useCallback(() => {
      const steps: Step[] = [
        createJoyrideStep(
          t,
          COACHMARK.closeCollaborators,
          COACHMARK.notifyCloseCollaborators,
          COACHMARK_PLACEMENT_LEFT_START
        ),
      ];
      return steps;
    }, []),
  };

  const joyrideSteps: Step[] = useMemo(
    () =>
      stepFlows[flow as keyof typeof stepFlows]() ||
      stepFlows[COACHMARK_FLOW.TAKE_A_TOUR](),
    [flow]
  );

  const callbackFlows = {
    [COACHMARK_FLOW.TAKE_A_TOUR]: (data: any) => {
      const {
        action,
        type,
        step: { target },
      } = data;

      if (
        trips &&
        target === `[data-joyride-id=${COACHMARK.tripCard}]` &&
        action === ACTIONS.NEXT &&
        type === EVENTS.STEP_AFTER
      ) {
        history.push(`/trip${trips[0]?.id}`);
      }
    },
    [COACHMARK_FLOW.AUTO_TRIGGERED]: (data: any) => {
      const { action, type, step } = data;

      if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
        saveCoachmarksAsShownToLocalStorage(step.title);
      }
    },
  };

  const handleJoyrideCallback = useCallback(
    callbackFlows[flow as keyof typeof callbackFlows] ||
      callbackFlows[COACHMARK_FLOW.TAKE_A_TOUR],
    [flow]
  );

  const coachmarks = useCallback(
    () => (
      <>
        <Joyride
          steps={joyrideSteps}
          run={isJoyrideActive && startFlow && joyrideSteps.length > 0}
          tooltipComponent={createTooltip(joyrideSteps)}
          disableOverlayClose
          disableOverlay={flow === COACHMARK_FLOW.AUTO_TRIGGERED}
          continuous
          callback={(data) => handleJoyrideCallback(data)}
          styles={{
            options: {
              arrowColor: "#5B5FC7",
              overlayColor: "transparent",
            },
          }}
          floaterProps={{
            styles: {
              arrow: {
                length: 6,
                spread: 14,
              },
            },
            disableAnimation: true,
          }}
        />
        <div
          data-joyride-id="pinTheApp"
          data-testid="pinTheApp"
          style={{
            position: "fixed",
            left: "0",
            top: appIconYCoordinate,
          }}
        />
        <div
          data-joyride-id="expenseTab"
          data-testid="expenseTab"
          style={{
            position: "absolute",
            top: 0,
            left: 40,
          }}
        />
      </>
    ),
    [joyrideSteps, isJoyrideActive, startFlow, history, appIconYCoordinate]
  );

  return (
    <Responsive forMinSize="desktopSmall">
      <Feature
        activeComponent={coachmarks}
        name={FeatureToggleDefinition.coachmarks.id}
      />
    </Responsive>
  );
}

export default Coachmarks;
