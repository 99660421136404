export default async function getCallGraph(graph: any, requestUrl: string) {
  let resp;
  try {
    resp = await graph
      .api(requestUrl)
      .header("ConsistencyLevel", "eventual")
      .get();
  } catch {
    resp = "";
  }
  return resp;
}
