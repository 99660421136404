import { Feature } from "@paralleldrive/react-feature-toggles";
import { useTeams } from "msteams-react-base-component";
import CheckFeatureToggle from "../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../utils/constants";
import NewCard from "./NewCard";
import OldCard from "./OldCard";
import { SegmentType } from "./model/Permissions";

function CardBookTrip(permissions?: SegmentType[]) {
  const [{ themeString }] = useTeams();
  const themeName = themeString === "default" ? "light" : "dark";
  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPUpcomingTrips.id
  );

  const perms = permissions;
  let flightPermissions = false;
  let trainPermissions = false;
  let hotelPermissions = false;
  let carPermissions = false;

  perms?.forEach((element: { type: any }) => {
    switch (element.type) {
      case "TRANSPORTATION":
        flightPermissions = true;
        break;
      case "RAIL":
        trainPermissions = true;
        break;
      case "HOTEL":
        hotelPermissions = true;
        break;
      case "CAR":
        carPermissions = true;
        break;
      default:
        break;
    }
  });

  const hasAnyOptions: boolean =
    flightPermissions || trainPermissions || hotelPermissions || carPermissions;

  const renderCardBookTrip = () => {
    if (isTRIPPUser) {
      return OldCard(false, themeName);
    }
    return (
      <Feature
        name={FeatureToggleDefinition.planATripExperienceCard.id}
        activeComponent={() => NewCard(hasAnyOptions, themeName, permissions)}
        inactiveComponent={() => OldCard(false, themeName)}
      />
    );
  };

  return renderCardBookTrip();
}

export default CardBookTrip;
