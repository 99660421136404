export default function GetName(
  memberName: string,
  member: any,
  index: number,
  numOfMembers: number
) {
  let copyName = memberName;

  if (index === 0) {
    copyName += member.displayName;
  }
  if (index !== 0 && index === numOfMembers) {
    copyName += ` and ${member.displayName}`;
  }
  if (index !== 0 && index !== numOfMembers) {
    copyName += `, ${member.displayName}`;
  }

  return copyName;
}
