import {
  GraphService,
  TeamsUserCredential,
  createMicrosoftGraphClient,
  statusIcon,
  statusIconClass,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import stylesUserStatus from "@amadeus-cytric/cytric-teams-react-common-library/dist/components/user-status/UserStatus.module.scss";
import { Avatar, Flex, Skeleton, Text } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import CloseCollaboratorsConstant from "../../../components/collaborators/close-collaborators/close-collaborators-list/utils/CloseCollaboratorsConstant";
import stylesCloseCollaborators from "../../../components/collaborators/common/styles/close-collaborators.module.scss";
import { IUserInfo } from "./UserInfo.model";
import styles from "./UserInfo.module.scss";

interface UserInfoProps {
  user: IUserInfo | undefined;
}

function UserInfo({ user }: UserInfoProps) {
  const [photo, setPhoto] = useState<any>();
  const [presence, setPresence] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const msGraphClient = createMicrosoftGraphClient(new TeamsUserCredential());

  useEffect(() => {
    setIsLoading(true);
    GraphService.getGraphUsersPhotos(msGraphClient, [user?.id])
      .then((data: any) => {
        setPhoto(`data:image/jpeg;base64,${data[0].body}`);
      })
      .catch(() => {
        setPhoto(CloseCollaboratorsConstant.graphDefaultImage);
      });
    GraphService.getGraphUsersPresence(msGraphClient, [user?.id])
      .then((data: any) => {
        setPresence({
          activity: data[0].body.activity,
          availability: data[0].body.availability,
        });
      })
      .catch(() => {
        setPresence({
          activity: CloseCollaboratorsConstant.graphDefaultActivity,
          availability: CloseCollaboratorsConstant.graphDefaultAvailability,
        });
      });

    if (user?.id) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const userData = (
    <div className={styles.user}>
      <Avatar
        image={photo}
        name={user?.name}
        status={{
          styles: (theme) => ({
            backgroundColor: theme.theme.siteVariables.widgetContainer?.widget,
          }),
          icon: statusIcon(presence?.availability),
          title: presence?.activity,
          className: `${stylesUserStatus.statusIndicator} ${
            statusIconClass(presence?.availability)?.icon.class
          }`,
        }}
      />
      <div className={styles.user__text}>
        <Text
          as="p"
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.foreground,
          })}
        >
          {user?.name}
        </Text>
        <Text
          as="p"
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.foreground1,
          })}
        >
          {user?.jobTitle}
        </Text>
      </div>
    </div>
  );

  const skeleton = (
    <Flex className={stylesCloseCollaborators.userNameContainer}>
      <Flex
        gap="gap.small"
        vAlign="center"
        className={stylesCloseCollaborators.userText}
      >
        <Flex.Item>
          <Skeleton
            className={stylesCloseCollaborators.loaderIcon}
            animation="pulse"
          >
            <Skeleton.Shape round width="32px" height="32px" />
          </Skeleton>
        </Flex.Item>
        <Flex.Item className={stylesCloseCollaborators.userText}>
          <Flex
            column
            className={stylesCloseCollaborators.userInformationContainer}
          >
            <Skeleton animation="pulse">
              <Skeleton.Line
                width="150px"
                height="8px"
                styles={{ borderRadius: "4px" }}
              />
            </Skeleton>
          </Flex>
        </Flex.Item>
      </Flex>
    </Flex>
  );

  return isLoading ? skeleton : userData;
}

export default UserInfo;
