/* eslint-disable react/react-in-jsx-scope */
import {
  Security,
  SecurityType,
  TokenConfiguration,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { t } from "i18next";
import { useEffect, useState } from "react";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  InfoImage,
} from "../../common/components/generic-info-component/GenericInfo.model";
import SecurityComponent from "../../common/security";
import ExpenseDashboard from "./ExpenseDashboard";

interface ILink {
  url: string;
  textToDisplay?: string;
}

export default function ExpenseDashboardSecured() {
  const [title, setTitle] = useState<string>("");
  const [detail, setDetail] = useState<string>("");
  const [link, setLink] = useState<ILink | undefined>(undefined);

  useEffect(() => {
    Security.getConfiguration()
      .then((config: TokenConfiguration) => {
        if (
          config &&
          config.expenseNotActivated &&
          config.expenseNotActivated !== null
        ) {
          setTitle(config.expenseNotActivated.infoMessage!);
          setDetail("");

          const { href, hrefText } = config.expenseNotActivated;

          if (hrefText && hrefText !== null) {
            setLink({
              url: href!,
              textToDisplay: hrefText,
            });
          } else {
            const hrefSplitted = href?.split("//");
            const hrefBase = hrefSplitted![1].split("/")[0];
            setLink({
              url: href!,
              textToDisplay: hrefBase,
            });
          }
        } else {
          setTitle(t("messages.ERR_ExpenseTabActivation"));
          setDetail(t("messages.ERR_ExpenseTabActivationDetail"));
          setLink(undefined);
        }
      })
      .catch(() => {
        setTitle(t("messages.ERR_ExpenseTabActivation"));
        setDetail(t("messages.ERR_ExpenseTabActivationDetail"));
        setLink(undefined);
      });
  }, []);

  const errorInfoExpenseAccess: IGenericInfo = {
    title,
    detail,
    link,
  };

  const errorInfo = (
    <GenericInfo
      infoData={errorInfoExpenseAccess}
      image={InfoImage.NOT_ACTIVATED}
    />
  );

  return (
    <SecurityComponent
      securityRole={SecurityType.activationExpenses}
      component={errorInfo}
    >
      <ExpenseDashboard />
    </SecurityComponent>
  );
}
