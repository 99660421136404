import {
  GraphService,
  TeamsUserCredential,
  createMicrosoftGraphClient,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { app, dialog } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import { InfoImage } from "../../common/components/generic-info-component/GenericInfo.model";
import SharePopup from "../../common/components/share-popup/SharePopup";
import AdaptiveCardViewer from "../../common/components/share-popup/components/AdaptiveCardViewer";
import { ExportCardToStringJSON } from "../../common/components/share-popup/functions/CardRenderer";
import TaskModuleResponseError from "../../common/components/task-module-response/TaskModuleResponseError";
import { IUserInfo } from "../../common/components/user-info/UserInfo.model";
import { IError } from "../../common/models/Error";
import GetGraphAppCatalogId from "../../utils/GetGraphAppCatalogId";
import { getDevice } from "../../utils/device.utils";
import { getDataSegments } from "../share-from-classic/functions/ShareHelper";
import GetData from "../share-join/functions/GetData";
import AuditorCanChatWithTravelerCard from "./card-templates/AuditorCanChatWithTravelerCard";
import {
  getAuditorTravelerCardData,
  isValidAuditorTravelerData,
} from "./functions/AuditorCanChatWithTraveler";

function OpenChatTaskModule(props: any) {
  const { t } = useTranslation();
  const [, setRecipientSeleted] = useState<string[]>([]);
  const [recipient, setRecipient] = useState<IUserInfo>();
  const [messageToShare, setMessageToShare] = useState("");
  const [selectedCardTemplate, setSelectedCardTemplate] = useState({});
  const [cardData, setCardData] = useState({} as any);
  const [cardError, setCardError] = useState(false);
  const [graphError, setGraphError] = useState(false);
  const [workflow, setWorkflow] = useState("");
  const [disableShare, setDisableShare] = useState<boolean>(true);
  const [appCatalogId, setAppCatalogId] = useState("");
  const [device, setDevice] = useState<any>();
  const [recipientGroupsSelected, setRecipientGroupsSelected] = useState<any>(
    []
  );
  const [recipientChannelsSelected, setRecipientChannelsSelected] = useState<
    string[] | undefined
  >([]);

  const getCardData = (preview: boolean) => {
    switch (workflow) {
      case "auditor":
        return getAuditorTravelerCardData(cardData, preview);
      default:
        return undefined;
    }
  };

  const headerText = t("chatWithTraveler.adaptiveCard.title");

  app.initialize();

  useEffect(() => {
    if (messageToShare.length === 0 || !recipient?.id) {
      setDisableShare(true);
    } else {
      setDisableShare(false);
    }
  }, [messageToShare, recipient]);

  useEffect(() => {
    const currentProps = props;
    const queryParams = currentProps.location.search.substring(1);
    const urlData = JSON.parse(decodeURIComponent(queryParams));

    const deviceType = getDevice();
    setDevice(deviceType);

    const msGraphClient = createMicrosoftGraphClient(new TeamsUserCredential());

    if (isValidAuditorTravelerData(urlData)) {
      setSelectedCardTemplate(AuditorCanChatWithTravelerCard);
      setCardError(false);
      setCardData(urlData);
      setWorkflow("auditor");

      const travelersEmail = urlData.emailAddress;

      const getGraphData = GraphService.getGraphUsers(
        msGraphClient,
        [travelersEmail],
        ["id", "displayName", "jobTitle"]
      );

      getGraphData.then((data: any) => {
        if (data.length && !data[0].body.error) {
          setRecipient({
            name: data[0].body.displayName,
            jobTitle: data[0].body.jobTitle,
            id: data[0].body.id,
          });
        } else {
          setGraphError(true);
        }
      });
    } else {
      setCardError(true);
    }

    GetGraphAppCatalogId(setAppCatalogId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelShare = () => {
    dialog.url.submit();
  };

  const submitShare = async () => {
    const { cardToShare, cardToShareChannels } = ExportCardToStringJSON(
      selectedCardTemplate,
      getCardData(false),
      recipientChannelsSelected
    );

    setDisableShare(true);

    const getDataProps = {
      recipientSelected: [recipient?.id],
      messageToShare,
      cardToShare,
      cardToShareChannels,
      tripName: "",
      recipientGroupsSelected,
      recipientChannelsSelected,
      appCatalogId,
      segmentsData: getDataSegments(cardData),
      origin: "classic-expenses-chat",
      postOnChannel: false,
      device,
      t,
    };
    const data = await GetData(getDataProps);

    telemetryService.trackEvent({
      name: "expenses-statement-auditorChatWithTraveler",
    });

    dialog.url.submit(data);
  };

  const displayErrorMessage = () => {
    const errorInfo: IError = {
      status: 0,
      code: 0,
      title: t(`messages.ERR_Generic`),
      detail: t(`upcoming_trips_dashboard.ERR_api_call`),
    };
    return <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />;
  };

  if (cardError) {
    return displayErrorMessage();
  }

  if (graphError) {
    return (
      <TaskModuleResponseError
        props={{
          location: {
            search:
              "?origin=classic-expenses-chat?tryAgainAttempts=0?graphError=true",
          },
        }}
      />
    );
  }

  return (
    <SharePopup
      setSelectedRecipients={setRecipientSeleted}
      setMessageToShare={setMessageToShare}
      isMessageOptional={false}
      setContentHeaderText={headerText}
      submitShareHandle={submitShare}
      cancelShareHandle={cancelShare}
      disableShareBtnHandle={() => disableShare}
      setRecipientChannelsSelected={setRecipientChannelsSelected}
      setRecipientGroupsSelected={setRecipientGroupsSelected}
      type="chat"
      recipient={recipient}
    >
      <AdaptiveCardViewer
        cardTemplate={selectedCardTemplate}
        cardData={getCardData(true)}
      />
    </SharePopup>
  );
}

export default OpenChatTaskModule;
