/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subEntity: "",
};

const expenseDashboardSlice = createSlice({
  name: "expenseDashboard",
  initialState,
  reducers: {
    setSubEntity(state, action) {
      state.subEntity = action.payload;
    },
  },
});

export const expenseDashboardActions = expenseDashboardSlice.actions;

export default expenseDashboardSlice.reducer;
