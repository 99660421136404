/* eslint-disable react/react-in-jsx-scope */
import { Image } from "@fluentui/react-northstar";
import useScreenSize from "../../../../common/components/responsive/useScreenSize";
import HostSettings from "../../../../utils/host.settings";
import stylesShareMyRideTopImage from "./topImage.module.scss";

function TopImage({ theme }: any) {
  const mobile = 640;
  const screenSize = useScreenSize();
  const isMobile = screenSize <= mobile;

  return (
    <div className={stylesShareMyRideTopImage.resizedImage}>
      {isMobile ? (
        <Image
          src={`${HostSettings.getCDNIcons}share-transfer-${theme}-mobile.svg`}
          alt="Share your ride with a colleague"
          aria-hidden
          fluid
          className={stylesShareMyRideTopImage.mobileImage}
        />
      ) : (
        <Image
          src={`${HostSettings.getCDNIcons}share-transfer-${theme}.svg`}
          alt="Share your ride with a colleague"
          aria-hidden
          fluid
        />
      )}
    </div>
  );
}

export default TopImage;
