import DateUtils from "../../../utils/date.utils";

/* eslint-disable import/prefer-default-export */

import { PROVIDER_FALLBACK_LOGO } from "../../../components/upcoming-trips-dashboard/data/constants";
import { ICarSegment } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetails/SegmentTabs/Tabs/Car/Car.model";
import { validateProviderLogoUrl } from "../../../components/upcoming-trips-dashboard/utils/trip-utils";

export const getCarDetails = (car: ICarSegment) => {
  const pickUpDateTime = DateUtils.getUTCTime(car?.pickup?.dateTime);
  const dropOffDateTime = DateUtils.getUTCTime(car?.dropoff?.dateTime);

  const pickUp = `${pickUpDateTime} ${car.pickup.location.name}`;
  const dropOff = `${dropOffDateTime} ${car.dropoff.location.name}`;

  return {
    confirmationNumber: car.confirmationNumber,
    carModel: car.vehicle.carModel,
    pickUp,
    dropOff,
    pickUpDateTime: car?.pickup?.dateTime,
    dropOffDateTime: car?.dropoff?.dateTime,
    carPicture: car.vehicle.images[0].carPicture[0] || PROVIDER_FALLBACK_LOGO,
    logo: validateProviderLogoUrl(car.vehicle.images[0].logo[0]),
    associatedEquipments: car.associatedEquipments,
    serviceProviderName: car.serviceProviderName,
  };
};
