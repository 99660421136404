/* eslint-disable import/no-cycle */
/* eslint-disable react/react-in-jsx-scope */
import {
  statusIcon,
  statusIconClass,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import stylesUserStatus from "@amadeus-cytric/cytric-teams-react-common-library/dist/components/user-status/UserStatus.module.scss";
import {
  Avatar,
  Button,
  ChatIcon,
  Checkbox,
  ChevronStartIcon,
  ContactCardIcon,
  Flex,
  Label,
  Skeleton,
  Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import i18next from "../../../i18n/i18n";
import PeopleList from "../components/people-list/PeopleList";
import stylesShareMyRideContainer from "../components/share-my-ride-container/ShareMyRideContainer.module.scss";
import { createChat } from "../components/share-my-ride-container/functions/shareMyRideApi";
import { types } from "../context/ShareMyRideReducer";
import { Ride } from "../models/ShareMyRide.model";
import {
  SMRStateModel,
  SharingFlightModel,
  SharingRailModel,
} from "../models/ShareMyRideContext.model";

const { t } = i18next;

export const getStatusTitle = (statusActivity: string) => {
  const statusActivitySintax = statusActivity
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();
  return (
    statusActivitySintax &&
    statusActivitySintax.charAt(0).toUpperCase() + statusActivitySintax.slice(1)
  );
};

export const getUser = ({ user }: Ride) => (
  <>
    <Avatar
      name={user.name}
      image={user.photo}
      status={
        user.status !== null && {
          styles: (theme) => ({
            backgroundColor: theme.theme.siteVariables.widgetContainer?.widget,
          }),
          icon: statusIcon(user.status.availability),
          title: getStatusTitle(user.status.activity),
          className: `${stylesUserStatus.statusIndicator} ${
            statusIconClass(user.status.availability).icon.class
          }`,
        }
      }
    />
    <Text
      data-testid={user.mail}
      content={user.name}
      className={stylesShareMyRideContainer.name}
    />
  </>
);

export const getArrival = ({ flight }: Ride, segment: string) =>
  segment === "AIR" ? (
    <span>
      {flight.date}
      {flight.sameFlight && (
        <Label
          circular
          content={t("shareMyRide.sameFlightTag")}
          className={stylesShareMyRideContainer.sameFlightLabel}
          styles={(theme) => ({
            backgroundColor:
              theme.theme.siteVariables.colorScheme?.brand.background,
            color: theme.theme.siteVariables.colors?.white,
          })}
        />
      )}
    </span>
  ) : (
    <span>
      {flight.date}
      {flight.sameFlight && (
        <Label
          circular
          content={t("shareMyRide.sameTrainTag")}
          className={stylesShareMyRideContainer.sameFlightLabel}
          styles={(theme) => ({
            backgroundColor:
              theme.theme.siteVariables.colorScheme?.brand.background,
            color: theme.theme.siteVariables.colors?.white,
          })}
        />
      )}
    </span>
  );

export const getChatIcon = (
  { user }: Ride,
  tripId: any,
  checkedItems: any,
  productDetails: string,
  locationType: string,
  date: string,
  type: string
) => {
  const isDisabled = Object.values(checkedItems).some(
    (item: any) => item.isChecked
  );

  return (
    <Button
      data-testid={`chatWith-${user.mail}`}
      text
      iconOnly
      title={`${t("shareMyRide.chatIconTitle")}${user.name}`}
      icon={<ChatIcon outline />}
      onClick={() => {
        telemetryService.trackEvent(
          { name: "travel-shareRide-chat" },
          {
            tripId,
          }
        );
        createChat(locationType, productDetails, date, [user.id], type, tripId);
      }}
      disabled={isDisabled}
    />
  );
};

export const getHeader = (
  segment: any,
  isMixed?: any,
  dispatchCheckedItems?: any
) => ({
  key: "share-my-ride-header",
  className: stylesShareMyRideContainer.tableHeader,
  items: [
    {
      content: (
        <Checkbox
          checked={isMixed}
          onChange={(_e, { checked }: any) => {
            segment.peopleSharing.forEach((row: any) => {
              dispatchCheckedItems({
                [row.user.name]: {
                  isChecked: checked,
                  id: checked === true ? row.user.id : "",
                },
              });
            });
          }}
          aria-label="Header checkbox"
        />
      ),
      key: `share-my-ride-header-checkbox`,
    },
    {
      content: (
        <span>
          <ContactCardIcon outline /> {t("shareMyRide.tableHeaderName")}
        </span>
      ),
      key: `share-my-ride-header-user`,
      className: stylesShareMyRideContainer.tableNameCol,
    },
    {
      content:
        segment.locationType === "ARRIVAL"
          ? `${t("shareMyRide.tableHeaderArrival")}`
          : `${t("shareMyRide.tableHeaderDeparture")}`,
      key: `share-my-ride-header-arrival`,
    },
    {
      content:
        segment.type === "AIR"
          ? `${t("shareMyRide.tableHeaderFlight")}`
          : `${t("shareMyRide.tableHeaderTrain")}`,
      key: `share-my-ride-header-flight-train`,
    },
    { content: "", key: `share-my-ride-header-blank` },
  ],
});

export const getFlightTerminalOrTrain = ({ flight }: Ride) =>
  flight.terminal !== ""
    ? `${flight.flightReference} / ${flight.terminal}`
    : `${flight.flightReference}`;

export const isSameFlight = (isFlight: any) =>
  isFlight ? t("shareMyRide.sameFlightTag") : t("shareMyRide.sameTrainTag");

export const getSameSegmentType = (isFlight: boolean, ride: any) =>
  ride?.flight?.sameFlight ? isSameFlight(isFlight) : "";

export const getTerminalNumber = (isFlight: boolean) =>
  isFlight ? t("shareMyRide.terminalNumber") : "";

export const getTerminalInformation = (isFlight: boolean, ride: any) =>
  ride?.flight?.terminal
    ? getTerminalNumber(isFlight) + ride.flight.terminal
    : "";

export const getVehicleNumber = (isFlight: boolean, ride: any) =>
  isFlight
    ? `${t("shareMyRide.flightNumber")} ${ride?.flight?.flightReference}`
    : `${t("shareMyRide.trainNumber")} ${ride?.flight?.flightReference}`;

export const getRows = (
  segment: any,
  tripId: any,
  checkedItems?: any,
  dispatchCheckedItems?: any
) => {
  let rows;
  const { peopleSharing, type, date, locationType, productDetails } = segment;
  const isFlight = type === "AIR";

  if (peopleSharing && peopleSharing.length) {
    rows = peopleSharing.map((ride: Ride, index: number) => ({
      key: `${index}-row`,
      items: [
        {
          content: (
            <Checkbox
              checked={checkedItems[ride.user.name]?.isChecked}
              onChange={(_e, { checked }: any) => {
                dispatchCheckedItems({
                  [ride.user.name]: {
                    isChecked: checked,
                    id: checked === true ? ride.user.id : "",
                  },
                });
              }}
              aria-label={i18next.t("shareMyRide.tableRow", {
                availability: ride.user.status?.availability,
                name: ride.user.name,
                arrival: ride.flight.date,
                sameFlight: getSameSegmentType(isFlight, ride),
                number: getVehicleNumber(isFlight, ride),
                terminal: getTerminalInformation(isFlight, ride),
              })}
            />
          ),
          key: `${index}-userPhoto`,
        },
        {
          content: getUser(ride),
          key: `${index}-userName`,
          className: stylesShareMyRideContainer.tableNameCol,
        },
        {
          content: getArrival(ride, type),
          key: `${index}-${segment}-arrival`,
        },
        {
          content: getFlightTerminalOrTrain(ride),
          key: `${index}-${type}-reference`,
        },
        {
          content: getChatIcon(
            ride,
            tripId,
            checkedItems,
            productDetails,
            locationType,
            date,
            type
          ),
          key: `${index}-chat-icon`,
          className: stylesShareMyRideContainer.lastCol,
        },
      ],
    }));
  } else {
    rows = [];
  }

  return rows;
};

export const getFormatTime = (dateTime: string) =>
  new Date(dateTime.slice(0, -1)).toTimeString().slice(0, 5);

export const appInsightsTracker = (name: any, info: any) => {
  telemetryService.trackEvent(name, info);
};

export const getEmptyPeopleList = (isMobile: boolean) => (
  <Flex
    data-testid="firstToShare"
    column
    hAlign="center"
    className={stylesShareMyRideContainer.emptyListText}
  >
    <Text
      className={
        isMobile
          ? stylesShareMyRideContainer.noColleaguesTextMobile
          : stylesShareMyRideContainer.noColleaguesText
      }
    >
      {t("shareMyRide.emptyListPlaceholder")}
    </Text>
  </Flex>
);

export const getErrorTextContent = (isMobile: boolean) => (
  <Flex
    data-testid="errorShareText"
    column
    hAlign="center"
    className={stylesShareMyRideContainer.emptyListText}
  >
    <Text
      className={
        isMobile
          ? stylesShareMyRideContainer.errorTitleMobile
          : stylesShareMyRideContainer.errorTitle
      }
      data-testid="enable-error-title"
    >
      {t("shareMyRide.errorTitle")}
    </Text>
    <Text className={stylesShareMyRideContainer.errorSubtitle}>
      {t("shareMyRide.errorSubtitle")}
    </Text>
  </Flex>
);

export const getExpirationTextContent = () => (
  <Flex
    data-testid="expirationErrorText"
    column
    hAlign="center"
    className={stylesShareMyRideContainer.emptyListText}
  >
    <Text
      className={stylesShareMyRideContainer.errorTitle}
      data-testid="expired-title"
    >
      {t("shareMyRide.expiredTitle")}
    </Text>
  </Flex>
);

export const getDisabledTextContent = (
  numberOfPeopleSharing: number,
  isMobile: boolean,
  themeName: string,
  locationType: string
) => (
  <Flex
    data-testid="disabledShareText"
    column
    hAlign="center"
    className={stylesShareMyRideContainer.emptyListText}
  >
    <Text
      className={
        isMobile
          ? stylesShareMyRideContainer.disabledShareTextMobile
          : stylesShareMyRideContainer.disabledShareText
      }
      data-testid="disabled-title"
    >
      {locationType === "ARRIVAL"
        ? t("shareMyRide.disabledPlaceholderArrival")
        : t("shareMyRide.disabledPlaceholderDeparture")}
    </Text>
    {numberOfPeopleSharing > 0 && (
      <>
        <Text
          className={stylesShareMyRideContainer.disabledPeopleAvailable}
          data-testid="disabled-subtitle"
          aria-hidden="true"
        >
          {t("shareMyRide.peopleAvailable")}
        </Text>
        <div
          aria-label={`${numberOfPeopleSharing} ${t(
            "shareMyRide.peopleAvailableTrip"
          )}`}
        >
          <Avatar
            name={`${numberOfPeopleSharing}`}
            className={
              themeName === "dark"
                ? stylesShareMyRideContainer.disabledAvatarDark
                : stylesShareMyRideContainer.disabledAvatar
            }
            aria-hidden="true"
          />
        </div>
      </>
    )}
  </Flex>
);

export const getSkeletonRows = () => {
  const rows = [];

  for (let i = 1; i <= 4; i += 1) {
    rows.push({
      className: stylesShareMyRideContainer.skeletonRows,
      key: i,
      items: [
        {
          content: (
            <Skeleton animation="pulse" data-testid="skeleton">
              <Flex
                gap="gap.smaller"
                className={stylesShareMyRideContainer.skeletonAvatar}
              >
                <div>
                  <Skeleton.Avatar size="medium" />
                </div>
                <Skeleton.Line
                  className={stylesShareMyRideContainer.skeletonLine}
                />
              </Flex>
            </Skeleton>
          ),
          key: `avatar-${i}`,
          className: stylesShareMyRideContainer.tableNameCol,
        },
        {
          content: (
            <Skeleton animation="pulse">
              <Skeleton.Line
                className={stylesShareMyRideContainer.skeletonLine}
              />
            </Skeleton>
          ),
          key: `arrival-${i}`,
        },
        {
          content: (
            <Skeleton animation="pulse">
              <Skeleton.Line
                className={stylesShareMyRideContainer.skeletonLine}
              />
            </Skeleton>
          ),
          key: `flight-${i}`,
        },
        {
          content: (
            <Skeleton
              animation="pulse"
              className={stylesShareMyRideContainer.skeletonButton}
            >
              <Flex gap="gap.smaller">
                <Skeleton.Button circular size="small" />
              </Flex>
            </Skeleton>
          ),
          key: `chat-${i}`,
        },
      ],
    });
  }

  return rows;
};

export const formatGeoCode = (value: string) => value.replace(/\./g, "-");

export const getFormattedGeoCode = (lat: string, long: string) =>
  lat && long ? `${formatGeoCode(lat)}X${formatGeoCode(long)}` : null;

export const getListContent = (
  sharedInfo: any,
  peopleSharing: any,
  rows: any,
  header: any,
  emptyPeopleList: any,
  dispatch: any
) => (
  <div>
    {sharedInfo.length > 1 && (
      <Button
        icon={<ChevronStartIcon />}
        text
        content="Back"
        onClick={() => {
          dispatch({ type: types.setActiveSegment, payload: null });
        }}
      />
    )}

    {peopleSharing && peopleSharing.length > 0 ? (
      <PeopleList
        data-testid="listPeopleSharing"
        rows={rows}
        header={header}
        label={t("tableLabel")}
      />
    ) : (
      <Text
        content={emptyPeopleList}
        className={stylesShareMyRideContainer.emptyList}
      />
    )}
  </div>
);

export const setTripProductsToShare = (tripId: string, sharedInfo: any) => {
  const tripProductsToShare: SMRStateModel = {
    tripId,
    productsToShare: sharedInfo?.map((product: any) => {
      let currentProductDetails:
        | SharingFlightModel
        | SharingRailModel
        | undefined;

      const formattedDate = moment
        .utc(product.date)
        .format("ddd, DD MMM yyyy")
        .toString();

      if (product.type === "AIR") {
        currentProductDetails = {
          arrivalAirportName: product.arrivalAirportName,
          departureAirportName: product.departureAirportName,
          locationCode: product.airportCode,
          flightReference: product.flightReference,
          terminal: product.terminal,
          prevServiceProvider: product.prevServiceProvider,
          productTitle:
            product.locationType === "ARRIVAL"
              ? `${t("shareMyRide.from")} ${product.arrivalAirportName}`
              : `${t("shareMyRide.to")} ${product.departureAirportName}`,
          location:
            product.locationType === "ARRIVAL"
              ? product.arrivalAirportName
              : product.departureAirportName,
          arrivalIataCode: product.arrivalIataCode,
          departureIataCode: product.departureIataCode,
          arrivalDateTime: product.arrivalDateTime,
          departureDateTime: product.departureDateTime,
          serviceProviderInfo: product.serviceProviderInfo,
        };
      }

      if (product.type === "RAIL") {
        currentProductDetails = {
          arrivalStation: product.arrivalStation,
          departureStation: product.departureStation,
          serviceProvider: product.serviceProvider,
          vehicleNumber: product.vehicleNumber,
          locationCode: product.geoCode,
          productTitle:
            product.locationType === "ARRIVAL"
              ? `${t("shareMyRide.from")} ${product.arrivalStation}`
              : `${t("shareMyRide.to")} ${product.departureStation}`,
          location:
            product.locationType === "ARRIVAL"
              ? product.arrivalStation
              : product.departureStation,
          prevServiceProvider: product.prevServiceProvider,
          prevVehicleNumber: product.prevVehicleNumber,
          arrivalDateTime: product.arrivalDateTime,
          departureDateTime: product.departureDateTime,
          serviceProviderInfo: product.serviceProviderInfo,
        };
      }

      return {
        type: product.type,
        date: product.date,
        formattedDate,
        productDetails: currentProductDetails,
        locationType: product.locationType,
      };
    }),
  };
  return tripProductsToShare;
};
