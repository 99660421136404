import { Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { SegmentCar } from "../../../../../../common/models/ProductCar";
import SegmentWrapper from "../SegmentWrapper";

interface CarSegmentContentProps {
  carSegment: SegmentCar;
  topDate: string | null;
}

function CarSegmentContent(props: CarSegmentContentProps) {
  const { carSegment, topDate } = props;
  const { t } = useTranslation();

  const header = (
    <Text weight="bold">
      {carSegment.Trip_Product_Car?.serviceProviderName}
    </Text>
  );

  function GetPickUpInfo() {
    const pickUpInfo = `${t("shareTrip.adaptiveCard.pickUp")} ${
      carSegment.Trip_Product_Car?.pickup.location?.name
    }`;
    return pickUpInfo;
  }

  function GetDropOffInfo() {
    const dropOffInfo = `${t("shareTrip.adaptiveCard.dropOff")} ${
      carSegment.Trip_Product_Car?.dropoff.location?.name
    }`;
    return dropOffInfo;
  }

  const content = (
    <div>
      <Text>{GetPickUpInfo()}</Text>
      <br />
      <Text>{GetDropOffInfo()}</Text>
    </div>
  );

  return (
    <SegmentWrapper segment={carSegment} topDate={topDate} segmentType="car">
      {header}
      {content}
    </SegmentWrapper>
  );
}

export default CarSegmentContent;
