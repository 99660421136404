/* eslint-disable @typescript-eslint/no-unused-vars */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  Card,
  Grid,
  Image,
  Text,
  cardSelectableBehavior,
  cardsContainerBehavior,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../common/models/redirection/RedirectionTypeEnum";
import { useAppDispatch } from "../../../../store/StoreHooks";
import HostSettings from "../../../../utils/host.settings";
import BookRedirection from "../CardBookTripButtons/Components/BookRedirection";
import Styles from "./TravelArranger.module.scss";

function TravelArranger(themeName: string, isExpandedSidebarApplied: boolean) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const text = t("upcoming_trips_dashboard.upcomingTrips.travelArranger.body");
  const redirectTravelArranger = () => {
    telemetryService.trackEvent({
      name: "travel-arranger-dashboard",
    });
    const redirectionInput = {
      segmentType: RedirectionTypeEnum.TRAVEL_ARRANGER_DASHBOARD,
    };
    BookRedirection(t, dispatch, history, redirectionInput, true);
  };

  return (
    <div className={Styles.cardComponent}>
      <Grid
        accessibility={cardsContainerBehavior}
        className={`${
          !isExpandedSidebarApplied
            ? Styles.grid_container_sidebar_expanded
            : Styles.grid_container
        }`}
      >
        <Card
          accessibility={cardSelectableBehavior}
          centered
          fluid
          horizontal
          className={
            themeName === "dark"
              ? Styles.cardElement__dark
              : Styles.cardElement__default
          }
          onClick={() => redirectTravelArranger()}
          styles={(theme) => ({
            background: theme.theme.siteVariables.tripCard?.background,
          })}
          style={{ height: "120px" }}
          data-testid="card-travel-arranger"
        >
          <Card.Preview horizontal>
            <Image
              style={{
                height: "100px",
                width: "100px",
              }}
              src={`${HostSettings.getCDNIcons}travel-arranger-dashboard-${themeName}.svg`}
            />
          </Card.Preview>
          <Card.Column className={Styles.cardBody}>
            <Text className={Styles.textContent} content={text} />
          </Card.Column>
        </Card>
      </Grid>
    </div>
  );
}

export default TravelArranger;
