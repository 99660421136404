import CloseCollaboratorsStatus from "../../../common/models/enums/ActivationStatusEnum";
import { IError } from "../../../common/models/Error";
import { CollaboratorsModel } from "../../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import { RootState } from "../../StoreHooks";

export const selectCloseCollaborators = (
  state: RootState
): CollaboratorsModel[] => state.closeCollaborators.closeCollaborators;

export const selectCloseCollaboratorsSize = (
    state: RootState
): number => state.closeCollaborators.closeCollaborators.length;

export const selectCloseCollaboratorsStatus = (
  state: RootState
): CloseCollaboratorsStatus => state.closeCollaborators.status;

export const selectCloseCollaboratorsIsPending = (state: RootState): boolean =>
  state.closeCollaborators.isPending;

export const selectCloseCollaboratorsIsError = (state: RootState): boolean =>
  state.closeCollaborators.isError;

export const selectCloseCollaboratorsErrorInformation = (
  state: RootState
): IError => state.closeCollaborators.error;
