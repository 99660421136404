/* eslint-disable react/react-in-jsx-scope */
import { createMicrosoftGraphClient } from "@amadeus-cytric/cytric-teams-react-common-library";
import { app } from "@microsoft/teams-js";
import ClassFindShareOptions from "./classes/ClassFindShareOptions";
import getCallGraph from "./utils/GetCallGraph";
import getFilteredUser from "./utils/GetFilteredUser";
import getUsersPhotos from "./utils/GetUsersPhotos";

export default async function ShareFlightSearch(
  searchQuery: string,
  context: app.Context
) {
  const query = new ClassFindShareOptions();
  const { scope, top, credential } = query;
  const requestUrlUser = `users?$top=${top}&$select=${
    query.select
  }&$search="displayName:${searchQuery.toLowerCase()}" OR "userPrincipalName:${searchQuery.toLowerCase()}"`;

  try {
    const graph = createMicrosoftGraphClient(credential, scope);
    const { value: users } = await getCallGraph(graph, requestUrlUser);
    let finalgroupsusers: any;

    if (users.length) {
      const usersFilter = getFilteredUser(users, context, searchQuery);
      finalgroupsusers = await getUsersPhotos(usersFilter, graph, query, "");
    }
    return finalgroupsusers;
  } catch {
    return [];
  }
}
