import SearchFlightSearchComponent from "../../../../../common/components/search-contacts/ShareFlightSearchComponent";
import MessageToShare from "../../../../message-to-share/MessageToShare";
import { ShareJoinFormModel } from "../../../models/ShareJoinFormModel";
import ShareJoinFormStyles from "../../../ShareJoin.module.scss";

function ShareJoinForm({
  setRecipientSeleted,
  setDisableShare,
  setRecipientGroupsSelected,
  setRecipientChannelsSelected,
  setMessageToShare,
  device,
  themeName,
}: ShareJoinFormModel) {
  return (
    <div className={ShareJoinFormStyles.formWrapper}>
      {SearchFlightSearchComponent(
        setRecipientSeleted,
        setDisableShare,
        setRecipientGroupsSelected,
        setRecipientChannelsSelected,
        device,
        themeName
      )}
      <MessageToShare setMessageText={setMessageToShare} isOptional />
    </div>
  );
}

export default ShareJoinForm;
