/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-array-index-key */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/StoreHooks";
import { getProductsByType } from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors";
import {
  getFlightDetailsGroupedByDate,
  getInboundDateOfFlightBoundGroup,
} from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors-flight";
import DateUtils from "../../../../../../../../utils/date.utils";
import { TRIP_PRODUCT_AIR } from "../../../../../../data/constants";
import BookingRedirections from "../../../../BookingRedirections";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import Accordion from "../components/Accordion/Accordion";
import { IBound } from "../components/Accordion/Accordion.model";
import NewProduct from "../components/NewProduct/NewProduct";
import Styles from "./Flight.module.scss";

function Flight({ pastTrip }: any) {
  const trip = useAppSelector((state) => state.tripDetail.trip);
  const { t } = useTranslation();
  const [context, setContext] = useState<app.Context | undefined>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((value: app.Context) => setContext(value));
    });
  }, []);

  const bookFlight = () => {
    const name = trip.name ?? "";
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookFlight" },
      {
        numOfFlights: 0,
        tripId: trip.id,
      }
    );
    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_FLIGHT,
      tripId: trip.id,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  if (trip?.products?.[0]?.hasAir) {
    const flightDetails = useAppSelector(getFlightDetailsGroupedByDate);
    const flightSegments = useAppSelector((state) =>
      getProductsByType(state, TRIP_PRODUCT_AIR)
    );
    const content = Object.keys(flightDetails).map((key: string, i: number) => {
      const endDate = getInboundDateOfFlightBoundGroup(flightDetails?.[key]);

      const titleScreenReader = endDate
        ? `${t(
            "upcoming_trips_dashboard.tripcard.from"
          )} ${DateUtils.formatLongestDate(
            key,
            context?.app?.locale || "en-GB"
          )} ${t(
            "upcoming_trips_dashboard.tripcard.to"
          )} ${DateUtils.formatLongestDate(
            endDate,
            context?.app?.locale || "en-GB"
          )} ${flightDetails?.[key]?.[0].length} ${t("tripDetails.item/s")}`
        : `${DateUtils.formatLongestDate(
            key,
            context?.app?.locale || "en-GB"
          )} ${flightDetails?.[key]?.[0].length} ${t("tripDetails.item/s")}`;

      return (
        <div
          key={`${key}-${i}-date-section`}
          role="grid"
          aria-label={titleScreenReader}
          title={titleScreenReader}
        >
          <Text
            styles={(theme) => ({
              color: theme.theme.siteVariables.bookingDetail?.date,
            })}
          >
            <Text
              aria-hidden="true"
              className={Styles.date}
              data-testid="date_trip_details"
              role="row"
            >
              {key}
              {endDate ? ` - ${DateUtils.formatLongDate(endDate)}` : null}
            </Text>
          </Text>

          {flightDetails[key].map((boundGroup: IBound[], index: number) => (
            <div
              role="row"
              key={`${key}-${index}-accordion`}
              className={Styles.accordion}
            >
              <Accordion boundGroup={boundGroup} type="Flight" />
            </div>
          ))}
        </div>
      );
    });

    return (
      <>
        {content}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({
            productType: "flight",
            numOfProducts: flightSegments.length,
          })}
      </>
    );
  }

  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.flight")}
      />
      {CheckFeatureEnabled() && (
        <Button
          aria-label={t(
            "upcoming_trips_dashboard.tripDetails.newProduct.buttons.flight"
          )}
          content={t(
            "upcoming_trips_dashboard.tripDetails.newProduct.buttons.flight"
          )}
          onClick={() => bookFlight()}
          flat
        />
      )}
    </Flex>
  );
}

export default Flight;
