import i18next from "i18next";
import HostSettings from "../../../../utils/host.settings";

export default function channelsCardFooter() {
  const { t } = i18next;
  const imgDivider = `${HostSettings.getCDNImages}divider.png`;
  const imgCollaboration = `${HostSettings.getCDNImages}colaboration.png`;

  return {
    type: "ColumnSet",
    spacing: "Large",
    columns: [
      {
        type: "Column",
        width: "20px",
        backgroundImage: {
          fillMode: "RepeatVertically",
          url: imgDivider,
        },
        separator: true,
        verticalContentAlignment: "Top",
      },
      {
        type: "Column",
        width: "auto",
        items: [
          {
            type: "TextBlock",
            text: t("shareTrip.adaptiveCard.collaborativePlan.title"),
            wrap: true,
            fontType: "Default",
            size: "Medium",
            weight: "Bolder",
            spacing: "Medium",
          },
          {
            type: "TextBlock",
            text: t("shareTrip.adaptiveCard.collaborativePlan.detail"),
            wrap: true,
            size: "Small",
          },
        ],
        height: "stretch",
        spacing: "Small",
        verticalContentAlignment: "Center",
      },
      {
        type: "Column",
        width: "150px",
        items: [
          {
            type: "Image",
            url: imgCollaboration,
            horizontalAlignment: "Right",
          },
        ],
        spacing: "Small",
      },
    ],
    separator: true,
  };
}
