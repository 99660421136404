import { RootState } from "../StoreHooks";
import { SegmentObj } from "./SegmentSelectedSlice";

export const isSegmentSelected = (
  state: RootState,
  segmentId: SegmentObj
): boolean =>
  state.segmentSelected.selectedSegments.some(
    (item) =>
      item.id === segmentId.id && item.productType === segmentId.productType
  );

export const getSelectedTripId = (state: RootState): string =>
  state.segmentSelected.selectedTripId;

export const getSelectedSegments = (state: RootState): SegmentObj[] =>
  state.segmentSelected.selectedSegments;

export const hasAtLeastOneSegmentSelected = (state: RootState): boolean =>
  !!state.segmentSelected.selectedSegments?.length;

export const hasTripSelected = (state: RootState): boolean =>
  state.segmentSelected.selectedTripId !== "";
