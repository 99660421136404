import { Button, Flex, Image, Layout, Text } from "@fluentui/react-northstar";
import { dialog } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../../utils/host.settings";

import TripApprovalStyles from "../../TripApproval.module.scss";

function ApprovalResponseContainer({
  isSuccess,
  errorCode,
  action,
  traveler,
  themeSuffix,
  themeStyle,
}: any) {
  const SOMETHING_WENT_WRONG_ERROR_CODES = [
    "APPROVAL_CHANNEL_INTERNAL",
    "SSO_GENERIC_MISSING_APPROVER_COMMENT",
    "SSO_GENERIC_MISSING_REJECT_REASON_CODE",
    "SSO_GENERIC_INVALID_REJECT_REASON_CODE",
  ];

  const DISABLED_ERROR_CODES = [
    "APPROVAL_ITINERARY_ALREADY_APPROVED",
    "APPROVAL_ITINERARY_REJECTED",
    "GENERAL_ITINERARY_CANCELED",
    "GENERAL_ITINERARY_PAST",
    "LOGIN_USER_WRONG_APPROVER",
  ];

  const handleCancel = (): void => {
    dialog.url.submit();
  };

  const { t } = useTranslation("translation", {
    keyPrefix: `tripApproval`,
  });

  function isDisabledError() {
    return DISABLED_ERROR_CODES.includes(errorCode);
  }

  function isSomethingWentWrongError() {
    return SOMETHING_WENT_WRONG_ERROR_CODES.includes(errorCode);
  }

  const getTitle = () => {
    let title = "";
    if (isSuccess) {
      switch (action) {
        case "approve":
          title =
            traveler !== ""
              ? t("confirmation.approve.title", { traveler })
              : t("confirmation.approve.titleNoName");
          break;
        case "reject":
          title =
            traveler !== ""
              ? t("confirmation.reject.title", { traveler })
              : t("confirmation.reject.titleNoName");
          break;
        default:
          title = "";
      }
    } else if (isDisabledError()) {
      switch (action) {
        case "approve":
          title = t("errors.disabled.approve.title");
          break;
        case "reject":
          title = t("errors.disabled.reject.title");
          break;
        default:
          title = "";
      }
    } else {
      title = t("errors.wrongSetup.title");
    }
    return title;
  };

  const getDescription = () => {
    let description = "";
    if (isSomethingWentWrongError()) {
      switch (action) {
        case "approve":
          description = t("errors.wrongSetup.approve.description");
          break;
        case "reject":
          description = t("errors.wrongSetup.reject.description");
          break;
        default:
          description = "";
      }
    } else if (isDisabledError()) {
      description = t(`errors.disabled.${errorCode}`);
    } else {
      description = t("errors.wrongSetup.description");
    }
    return description;
  };

  const getImageURL = () => {
    let imageURL = "";
    if (isSuccess) {
      imageURL = `${HostSettings.getCDNIcons}success.svg`;
    } else if (isDisabledError()) {
      imageURL = `${HostSettings.getCDNIcons}request-error-${themeSuffix}.svg`;
    } else {
      imageURL = `${HostSettings.getCDNIcons}generic-error-${themeSuffix}.svg`;
    }
    return imageURL;
  };

  const getAltText = () => {
    let altText = "";
    if (isSuccess) {
      altText = "Success";
    } else {
      altText = "Error";
    }
    return altText;
  };

  return (
    <div className={[themeStyle, TripApprovalStyles.content].join(" ")}>
      <Flex
        className={TripApprovalStyles.centerContainer}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background,
        })}
      >
        <Layout
          className={TripApprovalStyles.layout}
          renderMainArea={() => (
            <Image
              className={TripApprovalStyles.img}
              src={getImageURL()}
              alt={getAltText()}
            />
          )}
        />
        <Flex
          className={[themeStyle, TripApprovalStyles.textContainer].join(" ")}
        >
          <Text
            data-testid="approval-response-title"
            className={TripApprovalStyles.title}
            content={getTitle()}
            aria-label={getTitle()}
          />
          {!isSuccess && (
            <Text
              data-testid="approval-response-description"
              className={TripApprovalStyles.description}
              content={getDescription()}
              aria-label={getDescription()}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        className={TripApprovalStyles.footer}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background,
        })}
      >
        <Flex className={TripApprovalStyles.buttons}>
          <Button
            className={[themeStyle, TripApprovalStyles.continue].join(" ")}
            data-testid="approval-response-close-button"
            content={t("button.close")}
            size="medium"
            primary
            onClick={() => {
              handleCancel();
            }}
            aria-label={t("button.close")}
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default ApprovalResponseContainer;
