/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-dupe-else-if */
/* eslint-disable arrow-body-style */
import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { IRailV1 } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/Train/Rail.model";
import { TRIP_PRODUCT_RAIL } from "../../../components/upcoming-trips-dashboard/data/constants";
import {
  composeRailBoundsV1,
  getAllStopsDetailsV1,
  getServiceProvidersV1,
  getTotalTravelDurationV1,
  validateProviderLogoUrl,
} from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import i18n from "../../../i18n/i18n";
import { RootState } from "../../StoreHooks";
import {
  getProductsByTypeV1,
  sortTrainProductsV1,
} from "./trip-detail-v1-selectors";
import { IRailBoundDetails } from "./trip-detail-v1-selectors.model";

const getRailBoundBasicDetails = (bound: IRailV1[]) => {
  try {
    const selectedLogo = bound[0].media.filter(
      (content: any) => content.category === "RAIL_COMPANY_LOGO"
    )[0];
    const logo = validateProviderLogoUrl(selectedLogo?.href);
    const departure =
      bound[0]?.departure?.name ||
      bound[0].departure.iataCode ||
      bound[0].departure.address?.cityName;
    const arrival =
      bound[bound.length - 1]?.arrival?.name ||
      bound[bound.length - 1].arrival.iataCode ||
      bound[0].arrival.address?.cityName;
    const railDurations = bound.map(
      (boundSegment: IRailV1) => boundSegment.duration
    );
    const totalTravelDuration = getTotalTravelDurationV1(railDurations);
    const numberOfStops = bound.length - 1;
    const getters = {
      getDepartureTime: (segment: IRailV1) => segment?.departureDateTime,
      getArrivalTime: (segment: IRailV1) => segment?.arrivalDateTime,
      getDepartureStation: (segment: IRailV1) => segment?.departure?.name,
      getArrivalStation: (segment: IRailV1) => segment?.arrival?.name,
      getLayoverLocation: (segment: IRailV1) =>
        segment?.arrival?.name || segment?.arrival?.address?.cityName,
    };
    const stopsIn = getAllStopsDetailsV1(bound, getters);

    const serviceProvidersWithNumbers = getServiceProvidersV1(
      bound,
      (segment: IRailV1) => segment?.vehicle?.category,
      (segment: IRailV1) => segment?.vehicle?.number
    );

    const { departureDateTime } = bound[0];
    const { arrivalDateTime } = bound[bound.length - 1];
    const type = bound[0].productType;
    const departureName = bound[0].departure.address?.cityName;
    const arrivalName = bound[0].arrival.address?.cityName;

    return {
      logo,
      departure,
      arrival,
      totalTravelDuration,
      numberOfStops,
      stopsIn,
      serviceProvidersWithNumbers,
      departureDateTime,
      arrivalDateTime,
      getters,
      type,
      departureName,
      arrivalName,
    };
  } catch {
    return {};
  }
};

const getReservationInfo = (segment: IRailV1) => {
  const header = {
    title: `${segment?.vehicle?.category} ${segment?.vehicle?.number}`,
    subTitle: "",
  };

  const items = [];
  items.push({
    label: "Seats",
    value: segment?.seats?.[0]?.number || null,
  });

  return { header, items };
};

function getRailSegmentExtendedDetails(rail: IRailV1) {
  const { departureDateTime, arrivalDateTime } = rail;
  const selectedLogo = rail.media.filter(
    (content: any) => content.category === "RAIL_COMPANY_LOGO"
  )[0];

  return {
    serviceProvider: rail?.serviceProviderName,
    departure: rail?.departure,
    departureDateTime,
    duration: rail?.duration,
    arrival: rail?.arrival,
    arrivalDateTime,
    logo: selectedLogo,
    serviceClass: rail?.bookingClass,
    seats: rail?.seats?.[0]?.number,
    reservationInfo: getReservationInfo(rail),
    confirmationNumber: rail?.tripReference?.providerConfirmationNumber,
  };
}

export const createRailDetailsV1 = (state: any) => {
  const segmentsV1 = getProductsByTypeV1(state, TRIP_PRODUCT_RAIL);
  const sortedSegmentsV1 = sortTrainProductsV1(segmentsV1);
  const bounds = composeRailBoundsV1(sortedSegmentsV1);

  return bounds.reduce((acc: any, bound: IRailV1[]) => {
    const basicDetails = getRailBoundBasicDetails(bound);
    const extendedDetails = bound.reduce((acc2: any, segment: IRailV1) => {
      const segmentExtendedDetail = getRailSegmentExtendedDetails(segment);
      acc2.push(segmentExtendedDetail);
      return acc2;
    }, []);
    const railDetail = { basicDetails, extendedDetails };
    acc.push(railDetail);
    return acc;
  }, []);
};

export const areRailBoundsRoundTrip = (bounds: IRailBoundDetails[]) => {
  const firstStation = bounds?.[0]?.extendedDetails?.[0]?.departure?.name;

  const lastBound = bounds?.[bounds.length - 1];
  const lastExtendedDetailsLength = lastBound?.extendedDetails?.length;
  const lastStation =
    lastBound?.extendedDetails?.[lastExtendedDetailsLength - 1]?.arrival?.name;

  if (firstStation === lastStation) {
    return true;
  }
  return false;
};

export const getRoundtripRailDetailsV1 = (state: RootState) => {
  const rails = createRailDetailsV1(state);
  const boundGroups: string[] = [];

  // Group rail bounds into roundtrips
  while (rails.length > 0) {
    const firstElem = rails?.[0];
    const secondElem = rails?.[1];

    if (areRailBoundsRoundTrip([firstElem, secondElem])) {
      boundGroups.push(rails.splice(0, 2));
    } else {
      boundGroups.push(rails.splice(0, 1));
    }
  }

  return boundGroups;
};

export const getRailDetailsGroupedByDateV1 = (state: RootState) => {
  const boundGroups = getRoundtripRailDetailsV1(state);

  return boundGroups.reduce((acc: any, boundGroup: any) => {
    const departureDate = DateUtils.getDateFormat(
      boundGroup?.[0]?.extendedDetails?.[0]?.departureDateTime,
      i18n.language,
      DateFormat.DATE_SHORT
    );
    acc[departureDate] = acc[departureDate] || [];
    acc[departureDate].push(boundGroup);

    return acc;
  }, {});
};

export const getInboundDateOfRailBoundGroup = (
  boundGroup: [IRailBoundDetails[]]
) => {
  try {
    const lastBound = boundGroup?.[boundGroup?.length - 1];

    if (lastBound?.length > 1) {
      const segments = lastBound?.[lastBound?.length - 1]?.extendedDetails;
      return segments?.[0]?.departureDateTime || null;
    }
  } catch {
    return null;
  }
  return null;
};
