/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Box, Button, CloseIcon } from "@fluentui/react-northstar";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AppDispatch,
  useAppDispatch,
  useAppSelector,
} from "../../store/StoreHooks";
import { coachmarksActions } from "../../store/coachmarks/CoachmarksSlice";
import Styles from "./Tooltip.module.scss";
import { COACHMARK_FLOW } from "./data/constants";
import { saveCoachmarksAsShownToLocalStorage } from "./utils";

export const quitTheTourHandler = (dispatch: AppDispatch) => {
  dispatch(coachmarksActions.setIsJoyrideActive(false));
  dispatch(coachmarksActions.setFlow(COACHMARK_FLOW.AUTO_TRIGGERED));
};

const trackSkipTheTourEvent = (step: any) => {
  telemetryService.trackEvent(
    { name: "app-takeATourCoachmarks-skip" },
    {
      title: step?.content?.props?.title,
      step: step?.title,
    }
  );
};

export const trackEndTheTourEvent = () => {
  telemetryService.trackEvent({ name: "app-takeATourCoachmarks-end" });
};

function TakeATourActionButtons({ isLastStep, primaryProps, step }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const BUTTON_LABEL_GOT_IT = t("coachmarks.buttons.gotIt");
  const BUTTON_LABEL_NEXT = t("coachmarks.buttons.next");
  const BUTTON_LABEL_SKIP = t("coachmarks.buttons.skip");
  const BUTTON_LABEL_CLOSE = t("coachmarks.buttons.close");

  return (
    <>
      <div className={Styles["tooltip__close-button"]}>
        {isLastStep && (
          <Button
            onClick={() => {
              quitTheTourHandler(dispatch);
              trackEndTheTourEvent();
            }}
            icon={
              <CloseIcon
                styles={(theme) => ({
                  color: theme.theme.siteVariables.coachmarks?.text,
                })}
                aria-hidden
              />
            }
            data-testid="close-button"
            text
            iconOnly
            aria-label={BUTTON_LABEL_CLOSE}
          />
        )}
        {!isLastStep && (
          <Button
            {...primaryProps}
            icon={
              <CloseIcon
                styles={(theme) => ({
                  color: theme.theme.siteVariables.coachmarks?.text,
                })}
                aria-hidden
              />
            }
            text
            iconOnly
            data-testid="close-button"
            aria-label={BUTTON_LABEL_CLOSE}
          />
        )}
      </div>
      <div className={Styles["action-buttons"]}>
        {isLastStep && (
          <Button
            onClick={() => {
              quitTheTourHandler(dispatch);
              trackEndTheTourEvent();
            }}
            fluid
            id="next"
            style={{ backgroundColor: "#FFFFFF", color: "#5B5FC7" }}
            aria-label={BUTTON_LABEL_GOT_IT}
          >
            {BUTTON_LABEL_GOT_IT}
          </Button>
        )}
        {!isLastStep && (
          <Button
            {...primaryProps}
            fluid
            id="next"
            style={{ backgroundColor: "#FFFFFF", color: "#5B5FC7" }}
            aria-label={BUTTON_LABEL_NEXT}
          >
            {BUTTON_LABEL_NEXT}
          </Button>
        )}
        {!isLastStep && (
          <Button
            fluid
            id="close"
            inverted
            onClick={() => {
              quitTheTourHandler(dispatch);
              trackSkipTheTourEvent(step);
            }}
            aria-label={BUTTON_LABEL_SKIP}
          >
            {BUTTON_LABEL_SKIP}
          </Button>
        )}
      </div>
    </>
  );
}

export function AutoTriggeredActionButtons({
  isLastStep,
  primaryProps,
  step,
  steps,
}: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const BUTTON_LABEL_GOT_IT = t("coachmarks.buttons.gotIt");
  const BUTTON_LABEL_NEXT = t("coachmarks.buttons.next");
  const BUTTON_LABEL_SKIP = t("coachmarks.buttons.skip");
  const BUTTON_LABEL_CLOSE = t("coachmarks.buttons.close");

  return (
    <>
      <div className={Styles["tooltip__close-button"]}>
        {isLastStep && (
          <Button
            onClick={() => {
              quitTheTourHandler(dispatch);
              trackEndTheTourEvent();
              saveCoachmarksAsShownToLocalStorage(step.title);
            }}
            icon={
              <CloseIcon
                styles={(theme) => ({
                  color: theme.theme.siteVariables.coachmarks?.text,
                })}
                aria-hidden
              />
            }
            data-testid="close-button"
            text
            iconOnly
            aria-label={BUTTON_LABEL_CLOSE}
          />
        )}
        {!isLastStep && (
          <Button
            {...primaryProps}
            icon={
              <CloseIcon
                styles={(theme) => ({
                  color: theme.theme.siteVariables.coachmarks?.text,
                })}
                aria-hidden
              />
            }
            text
            iconOnly
            data-testid="close-button"
            aria-label={BUTTON_LABEL_CLOSE}
          />
        )}
      </div>
      <div className={Styles["action-buttons"]}>
        {isLastStep && (
          <Button
            onClick={() => {
              quitTheTourHandler(dispatch);
              trackEndTheTourEvent();
              saveCoachmarksAsShownToLocalStorage(step.title);
            }}
            fluid
            id="next"
            style={{ backgroundColor: "#FFFFFF", color: "#5B5FC7" }}
            aria-label={BUTTON_LABEL_GOT_IT}
          >
            {BUTTON_LABEL_GOT_IT}
          </Button>
        )}

        {!isLastStep && (
          <Button
            {...primaryProps}
            fluid
            id="next"
            style={{ backgroundColor: "#FFFFFF", color: "#5B5FC7" }}
            aria-label={BUTTON_LABEL_NEXT}
          >
            {BUTTON_LABEL_NEXT}
          </Button>
        )}
        {!isLastStep && (
          <Button
            fluid
            id="close"
            inverted
            onClick={() => {
              quitTheTourHandler(dispatch);
              trackSkipTheTourEvent(step);
              const allStepsTitles = steps.map((s: any) => s.title);
              saveCoachmarksAsShownToLocalStorage(...allStepsTitles);
            }}
            aria-label={BUTTON_LABEL_SKIP}
          >
            {BUTTON_LABEL_SKIP}
          </Button>
        )}
      </div>
    </>
  );
}

const createTooltip = (steps: any) =>
  function Tooltip({
    isLastStep,
    step,
    tooltipProps,
    index,
    primaryProps,
  }: any) {
    const appIconYCoordinate = useAppSelector(
      (state) => state.coachmarks.appIconYCoordinate
    );
    const flow = useAppSelector((state) => state.coachmarks.flow);

    useEffect(() => {
      if (!(index === 0 && appIconYCoordinate === 0)) {
        telemetryService.trackEvent(
          { name: "app-takeATourCoachmarks-display" },
          {
            title: step?.content?.props?.title,
            step: step?.title,
          }
        );
      }
    }, []);

    const actionButtonFlows = useMemo(
      () => ({
        [COACHMARK_FLOW.TAKE_A_TOUR]: (
          <TakeATourActionButtons
            isLastStep={isLastStep}
            primaryProps={{ ...primaryProps }}
            step={step}
          />
        ),
        [COACHMARK_FLOW.AUTO_TRIGGERED]: (
          <AutoTriggeredActionButtons
            isLastStep={isLastStep}
            primaryProps={{ ...primaryProps }}
            step={step}
            steps={steps}
          />
        ),
      }),
      []
    );

    const getActionButtons = useCallback(
      () =>
        actionButtonFlows[flow as keyof typeof actionButtonFlows] ||
        actionButtonFlows[COACHMARK_FLOW.TAKE_A_TOUR],
      [flow]
    );

    return (
      <Box
        {...tooltipProps}
        className={Styles.tooltip}
        styles={(theme) => ({
          background: theme.theme.siteVariables?.coachmarks?.background,
          color: theme.theme.siteVariables?.coachmarks?.text,
        })}
      >
        {step.content}
        {getActionButtons()}
      </Box>
    );
  };

export default createTooltip;
