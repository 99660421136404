import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import HostSettings from "../../../../utils/host.settings";

export const getDuplicatesExpenses = async () => {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}${HostSettings.getDuplicateExpensesList}`
    );
    if (response.status === 200) {
      const duplicatesList =
        (await response.data) || (await response.data.data);
      return duplicatesList.duplicatePairs;
    }
  } catch (error) {
    return null;
  }
  return null;
};

export const manageDuplicates = async (
  selectedIds: number[],
  event: string
) => {
  try {
    const response = await Http.post(
      `${HostSettings.getBaseAPI}${HostSettings.manageDuplicates}&event=${event}`,
      { duplicatePairIds: selectedIds }
    );
    if (response.status === 204) {
      return "Duplicates managing succeeded";
    }
  } catch (error: any) {
    return null;
  }
  return null;
};
