import lodash from "lodash";
import Products from "../common/models/Product";
import ProductAir, { FlightSegmentEndpoint } from "../common/models/ProductAir";
import { GeoCode, Location } from "../common/models/ProductCar";
import ProductHotel from "../common/models/ProductHotel";
import ProductTrain from "../common/models/ProductTrain";
import Trip from "../common/models/Trip";
import TripDestination from "../common/models/TripDestination";
import TripProduct from "../common/models/TripProduct";

export interface ITripTitleTranslations {
  tripTo: string;
  tripByCar: string;
  tripString: string;
}

const titleDelimiter: string = " \u00B7 ";
export default class TripHelper {
  public static formatTripProductsWithBound(
    tripProducts: Products[]
  ): Products[] {
    return tripProducts.map((product) => {
      // example: products = [flight1-1, flight1-2, hotel, flight2]
      const products = [...product.products];

      // products: [flight1, hotel, flight2]
      return {
        ...product,
        products: this.reduceProductByBound(products),
      };
    });
  }

  public static updateArrival(
    AirOrRailProduct: ProductAir | ProductTrain,
    lastAirOrRailProduct: ProductAir | ProductTrain
  ) {
    const newLastAirOrRailProduct = lastAirOrRailProduct;
    newLastAirOrRailProduct.arrival = AirOrRailProduct.arrival;
    if (
      "arrivalDateTime" in AirOrRailProduct &&
      newLastAirOrRailProduct &&
      "arrivalDateTime" in newLastAirOrRailProduct
    ) {
      newLastAirOrRailProduct.arrivalDateTime =
        AirOrRailProduct.arrivalDateTime;
    }

    return newLastAirOrRailProduct;
  }

  private static reduceProductByBound(products: TripProduct[]) {
    let lastAirOrRailProduct: ProductAir | ProductTrain;

    return products.reduce(
      (filteredProducts: TripProduct[], currentProduct, index) => {
        if (
          currentProduct.Trip_Product_Air ||
          currentProduct.Trip_Product_Train
        ) {
          const AirOrRailProduct = currentProduct.Trip_Product_Air
            ? currentProduct.Trip_Product_Air
            : currentProduct.Trip_Product_Train!;

          const isTheSameBound =
            lastAirOrRailProduct &&
            lastAirOrRailProduct.productType === AirOrRailProduct.productType &&
            AirOrRailProduct.id !== "0" &&
            AirOrRailProduct.direction === lastAirOrRailProduct.direction;

          // if we should add a stop in the last air or rail bound
          if (isTheSameBound) {
            // update of the last air or rail bound to support new stop and a new arrival
            this.pushStopInfoInAirOrRailSegment(
              lastAirOrRailProduct,
              AirOrRailProduct,
              index
            );

            lastAirOrRailProduct = this.updateArrival(
              AirOrRailProduct,
              lastAirOrRailProduct
            );

            // product volontary not push in filteredProducts in order to remove it from the list
          } else {
            // otherwise we should create a new bound
            const lastProduct = lodash.cloneDeep(currentProduct);
            lastAirOrRailProduct = lastProduct.Trip_Product_Air
              ? lastProduct.Trip_Product_Air
              : lastProduct.Trip_Product_Train!;

            lastAirOrRailProduct.boundStops = [];
            lastAirOrRailProduct.indexesToShare = [index];

            // new air bound added to filteredProducts
            filteredProducts.push(lastProduct);
          }
        } else {
          // if not air or rail we keep the product like it is
          filteredProducts.push(currentProduct);
        }
        return filteredProducts;
      },
      []
    );
  }

  private static pushStopInfoInAirOrRailSegment(
    airOrRailBoundProduct: ProductAir | ProductTrain,
    airOrRailStopProduct: ProductAir | ProductTrain,
    index: number
  ) {
    airOrRailBoundProduct.indexesToShare?.push(index);

    if (airOrRailStopProduct.productType === "Trip_Product_Air") {
      const airStopProduct = airOrRailStopProduct as ProductAir;
      airOrRailBoundProduct.boundStops?.push({
        carrierCode: airStopProduct.carrierCode,
        number: airStopProduct.number,
      });
    } else {
      const railStopProduct = airOrRailStopProduct as ProductTrain;
      airOrRailBoundProduct.boundStops?.push({
        category: railStopProduct.vehicle?.category,
        number: railStopProduct.vehicle?.number,
      });
    }
  }

  /**
   * The Trip title is set in the following way (in descending priority):
   * 1. Trip name; if it exists
   * 2. Trip to (comma separated Hotel destinations); if there are hotel bookings
   * 3. Trip to (comma separated Flight destinations); if there are flight bookings and no hotel
   * 4. Trip to (comma separated Train destinations); if there are train bookings and no air or hotel
   * 4. "Trip by car" litteral; if there is a Car booking and no rail, air or hotel
   *
   * Note: the return trip case (air and rail) is partially handled, meaning that the return
   * trip's return destination is not taken into account
   *
   * @param trip the Trip objects with bounds already created
   * @returns a ready to use Trip Title
   */

  public static computeTripTitle(
    trip: Trip,
    translations: ITripTitleTranslations
  ) {
    if (trip.name) {
      return trip.name;
    }

    let tripTitle;
    const { tripTo, tripByCar, tripString } = translations;

    trip.products?.forEach((tripProduct) => {
      // go through all the products
      if (tripProduct.hasHotel) {
        const hotelDestinations = this.getHotelDestinations(
          tripProduct.products
        )
          .map((hotel) => hotel.location?.cityName)
          .join(titleDelimiter);
        tripTitle = `${tripTo} ${hotelDestinations}`;
      } else if (tripProduct.hasAir) {
        const flightDestinations = this.getFlightDestinations(
          tripProduct.products
        )
          .map((flight) => flight.cityName)
          .join(titleDelimiter);
        tripTitle = `${tripTo} ${flightDestinations}`;
      } else if (tripProduct.hasRail) {
        // compute trip title with rail station cityName or with name
        const trainDestinations = this.getTrainDestinations(
          tripProduct.products
        )
          .map((train) => train.arrival?.cityName || train.arrival?.name)
          .join(titleDelimiter);
        tripTitle = `${tripTo} ${trainDestinations}`;
      } else if (tripProduct.hasCar) {
        tripTitle = tripByCar;
      } else {
        tripTitle = tripString;
      }
    });
    return tripTitle;
  }

  public static computeTripDestination(
    trip: Trip
  ): TripDestination | undefined {
    // Transform products into bounds to remove potential stops
    const productsAsBounds = TripHelper.formatTripProductsWithBound(
      trip.products || []
    );

    // last products list
    const tripProduct = productsAsBounds[productsAsBounds.length - 1];
    let tripDestination: Location | FlightSegmentEndpoint | undefined;
    let tripGeocode: GeoCode | undefined;
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (tripProduct?.hasHotel) {
      const hotelDestination = this.getHotelDestinations(
        tripProduct.products
      )[0];
      tripDestination = hotelDestination.location;
      tripGeocode = tripDestination?.geoCode;
      startDate = hotelDestination?.checkInDate;
      endDate =
        hotelDestination?.checkInDate === hotelDestination?.checkOutDate
          ? undefined
          : hotelDestination?.checkOutDate;
    } else if (tripProduct?.hasAir) {
      // no geoCode for flight
      const flightDestinations = this.getFlightDestinations(
        tripProduct.products
      );
      tripDestination = flightDestinations[0];
      startDate = tripDestination?.dateTime;

      // if there are only other flights, we can determine the end date at destination
      if (
        tripProduct.products.length > 1 &&
        !tripProduct.hasRail &&
        !tripProduct.hasCar
      ) {
        endDate = tripProduct.products[1].Trip_Product_Air?.departure?.dateTime;
      }
    } else if (tripProduct?.hasRail) {
      const trainDestination = this.getTrainDestinations(
        tripProduct.products
      )[0];
      tripDestination = trainDestination?.arrival;
      tripGeocode = tripDestination?.geoCode;
      startDate = trainDestination?.arrivalDateTime;

      // if there are only other trains, we can determine the end date at destination
      if (tripProduct.products.length > 1 && !tripProduct.hasCar) {
        endDate = tripProduct.products[1].Trip_Product_Train?.departureDateTime;
      }
    }

    return {
      city: tripDestination?.cityName,
      countryCode: tripDestination?.countryCode,
      latitude: tripGeocode?.latitude,
      longitude: tripGeocode?.longitude,
      iataCode: tripDestination?.iataCode,
      startDate,
      endDate,
    };
  }

  private static getHotelDestinations(products: TripProduct[]): ProductHotel[] {
    return products.reduce((destinations, product) => {
      if (product.Trip_Product_Hotel?.location?.cityName) {
        destinations.push(product.Trip_Product_Hotel);
      }
      return destinations;
    }, [] as ProductHotel[]);
  }

  private static getFlightDestinations(
    products: TripProduct[]
  ): FlightSegmentEndpoint[] {
    let firstOrigin: FlightSegmentEndpoint | undefined;
    const allFlightDestinations = products.reduce((destinations, product) => {
      const arrivalCity = product.Trip_Product_Air?.arrival?.cityName;
      if (
        product.Trip_Product_Air?.arrival &&
        arrivalCity &&
        !destinations.some((dest) => dest.cityName === arrivalCity)
      ) {
        destinations.push(product.Trip_Product_Air.arrival);
      }
      if (!firstOrigin && product.Trip_Product_Air?.id === "0") {
        firstOrigin = product.Trip_Product_Air.departure;
      }
      return destinations;
    }, [] as FlightSegmentEndpoint[]);

    return allFlightDestinations.filter(
      (destination) => destination.cityName !== firstOrigin?.cityName
    );
  }

  private static getTrainDestinations(products: TripProduct[]): ProductTrain[] {
    let firstOriginCityName: string;
    const allTrainDestinations = products.reduce((destinations, product) => {
      // taking the city or the name of the arrival because the city can be empty for some rail station
      const arrivalCityName =
        product.Trip_Product_Train?.arrival?.cityName ||
        product.Trip_Product_Train?.arrival?.name;
      if (
        product.Trip_Product_Train?.arrival &&
        arrivalCityName &&
        !destinations.some(
          (dest) =>
            dest.arrival?.cityName === arrivalCityName ||
            dest.arrival?.name === arrivalCityName
        )
      ) {
        destinations.push(product.Trip_Product_Train);
      }
      const originCityName =
        product.Trip_Product_Train?.departure?.cityName ||
        product.Trip_Product_Train?.departure?.name;
      if (
        !firstOriginCityName &&
        product.Trip_Product_Train?.id === "0" &&
        originCityName
      ) {
        firstOriginCityName = originCityName;
      }
      return destinations;
    }, [] as ProductTrain[]);

    return allTrainDestinations.filter(
      (destination) =>
        destination.arrival?.cityName !== firstOriginCityName &&
        destination.arrival?.name !== firstOriginCityName
    );
  }
}
