/* eslint-disable react/react-in-jsx-scope */
import { Flex, Text } from "@fluentui/react-northstar";
import i18next from "../../../../../i18n/i18n";
import stylesCloseCollaborators from "../../../common/styles/close-collaborators.module.scss";

export default function CloseCollaboratorsNoItems() {
  return (
    <Flex
      data-testid="collaborators-collaborators-no-items"
      hAlign="center"
      vAlign="center"
      className={stylesCloseCollaborators.noCollaboratorsMsg}
    >
      <Text content={i18next.t("closeCollaborators.noItems")} />
    </Flex>
  );
}
