import {
  Box,
  CheckmarkCircleIcon,
  ChevronDownIcon,
  Grid,
  Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../../../../../../common/components/responsive/Responsive";
import DateUtils from "../../../../../../../../../utils/date.utils";
import { UPCOMING_TRIPS } from "../../../../../../../data/constants";
import { getTotalTravelDuration } from "../../../../../../../utils/trip-utils";
import Divider from "../Divider/Divider";
import {
  IDestinationDetails,
  IExtendedDetailsProps,
  IServicesProps,
} from "./ExtendedDetails.model";
import Styles from "./ExtendedDetails.module.scss";
import ReservationInfo from "./ReservationInfo/ReservationInfo";

const extendedDetailsClass = Styles["extended-details"];

function TimeLine() {
  return (
    <div className={Styles.timeline}>
      <Box
        className={Styles["timeline__circle--departure"]}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables.extendedDetails?.circleColor,
        })}
      />
      <Box
        className={Styles.timeline__line}
        styles={(theme) => ({
          background: theme.theme.siteVariables.extendedDetails?.circleColor,
        })}
      />
      <Box
        className={Styles["timeline__circle--arrival"]}
        styles={(theme) => ({
          borderColor: theme.theme.siteVariables.extendedDetails?.circleColor,
        })}
      />
    </div>
  );
}

function TravelTime({ duration }: { duration: string }) {
  const travelDuration = getTotalTravelDuration([duration]);
  const { t } = useTranslation();

  return (
    <Box
      hidden
      aria-hidden="true"
      className={Styles["extended-details__destination--duration"]}
      styles={(theme) => ({
        color: theme.theme.siteVariables.extendedDetails?.duration,
      })}
    >
      {`${t("tripDetails.detailCard.duration")} ${travelDuration}`}
    </Box>
  );
}

function DestinationDetails({ destination, time }: IDestinationDetails) {
  const { t } = useTranslation();
  try {
    const { terminal, cityName, countryCode } = destination;
    const stationName = destination?.airportName || destination?.name;

    const isFlight = "airportName" in destination;

    const destinationTime = DateUtils.getUTCTime(time);
    const city = isFlight ? `${cityName}, ${countryCode}, ` : "";
    const terminalNo = terminal
      ? `, ${t("tripDetails.detailCard.terminal")}${terminal}`
      : "";

    return (
      <div className={Styles["details-destination"]} aria-hidden="true">
        <div>{destinationTime}</div>
        <div>{`${city}${stationName}${terminalNo}`}</div>
      </div>
    );
  } catch {
    return null;
  }
}

function titleScreenReaderTime({
  tripSegment,
  orderInTripDetails,
}: IExtendedDetailsProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  const isFlight = "airportName" in tripSegment.departure;

  const departureDateTimeSR = DateUtils.getUTCTime(
    tripSegment?.departure.dateTime || tripSegment?.departureDateTime
  );
  const arrivalDateTimeSR = DateUtils.getUTCTime(
    tripSegment?.arrival.dateTime || tripSegment?.arrivalDateTime
  );

  const getOrdinalText = () => {
    if (orderInTripDetails) {
      return t(`ordinalNumbers.${orderInTripDetails}`);
    }
    return "";
  };

  const ordinalSR = getOrdinalText();
  const departureLocationSR = isFlight
    ? `${tripSegment?.departure.cityName}, ${
        tripSegment?.departure.countryCode
      }, ${tripSegment?.departure.airportName}, ${t(
        "tripDetails.flightTrain.Terminal"
      )} ${tripSegment?.departure.terminal}`
    : `${tripSegment?.departure.countryCode}, ${tripSegment?.departure.name}, ${tripSegment?.departure.iataCode}`;
  const arrivalLocationSR = isFlight
    ? `${tripSegment?.arrival.cityName}, ${tripSegment?.arrival.countryCode}, ${
        tripSegment?.arrival.airportName
      }, ${t("tripDetails.flightTrain.Terminal")} ${
        tripSegment?.arrival.terminal
      }`
    : `${tripSegment?.arrival.countryCode}, ${tripSegment?.arrival.name}, ${tripSegment?.arrival.iataCode}`;

  const typeTravelSR = isFlight
    ? t(`upcoming_trips_dashboard.tripDetails.Flight`).toLowerCase()
    : t(`upcoming_trips_dashboard.tripDetails.Train`).toLowerCase();

  return `${ordinalSR} ${typeTravelSR} ${t(
    "tripDetails.flightTrain.departingAt"
  )} ${departureDateTimeSR} ${t(
    "tripDetails.flightTrain.from"
  )} ${departureLocationSR}\n${ordinalSR} ${typeTravelSR} ${t(
    "tripDetails.flightTrain.arrivingAt"
  )} ${arrivalDateTimeSR} ${t(
    "tripDetails.flightTrain.from"
  )} ${arrivalLocationSR}`;
}

function Destination({
  tripSegment,
  orderInTripDetails,
}: IExtendedDetailsProps) {
  return (
    <Box
      title={titleScreenReaderTime({ tripSegment, orderInTripDetails })}
      className={Styles["extended-details__destination"]}
      styles={(theme) => ({
        borderRightColor:
          theme.theme.siteVariables.extendedDetails?.destinationBorder,
      })}
    >
      <DestinationDetails
        destination={tripSegment.departure}
        time={tripSegment?.departure.dateTime || tripSegment?.departureDateTime}
      />
      <TravelTime duration={tripSegment.duration} />
      <DestinationDetails
        destination={tripSegment.arrival}
        time={tripSegment?.arrival.dateTime || tripSegment?.arrivalDateTime}
      />
    </Box>
  );
}

function Services({ serviceClass, baggage, meal }: IServicesProps) {
  const baggageInfo =
    baggage?.filter(Boolean)?.length > 0
      ? `${baggage
          ?.filter(Boolean)
          ?.join(UPCOMING_TRIPS.delimiterForDestinations)}`
      : "";

  const composeServices = () => {
    if (!meal && !baggageInfo) {
      return "";
    }
    if (!baggageInfo) {
      return `${meal}`;
    }
    if (!meal) {
      return `${baggageInfo}`;
    }
    return `${baggageInfo} ${UPCOMING_TRIPS.delimiterForDestinations} ${meal}`;
  };

  let servicearraySR: string[] = [];
  composeServices()
    .split("·")
    .forEach((elmnt) => {
      servicearraySR.push(` ${elmnt.trim()}`);
    });
  if (servicearraySR[0] === " ") {
    servicearraySR = [""];
  } else {
    servicearraySR = [""].concat(servicearraySR);
  }
  const serviceClassSR = serviceClass
    ? serviceClass.charAt(0).toUpperCase() + serviceClass.toLowerCase().slice(1)
    : "";
  const serviceTitleSR = `${serviceClassSR}${servicearraySR}.`;

  return (
    <div
      className={Styles["extended-details__services-container"]}
      title={serviceTitleSR}
    >
      <div
        className={Styles["extended-details__service-class"]}
        aria-hidden="true"
      >
        <CheckmarkCircleIcon
          outline
          size="small"
          styles={(theme) => ({
            color: theme.theme.siteVariables.extendedDetails?.serviceClass,
          })}
        />
        <Text
          className={Styles["extended-details__class"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.extendedDetails?.serviceClass,
          })}
          content={serviceClass}
        />
      </div>
      <Text
        aria-hidden="true"
        className={Styles["extended-details__services"]}
        styles={(theme) => ({
          color: theme.theme.siteVariables.extendedDetails?.services,
        })}
        content={composeServices()}
      />
    </div>
  );
}

function ExtendedDetailsForDesktop({
  tripSegment,
  orderInTripDetails,
}: IExtendedDetailsProps) {
  return (
    <Responsive forMinSize="tablet">
      <Grid className={extendedDetailsClass} columns="1.3% 63.7% 35%">
        <TimeLine />
        <Destination
          tripSegment={tripSegment}
          orderInTripDetails={orderInTripDetails}
        />
        <div className={Styles["extended-details__reservation-info"]}>
          <ReservationInfo tripSegment={tripSegment} />
        </div>
      </Grid>
      <Services
        serviceClass={tripSegment?.serviceClass}
        baggage={tripSegment?.baggage}
        meal={tripSegment?.meal}
      />
    </Responsive>
  );
}

function ExtendedDetailsForMobile(
  { tripSegment }: IExtendedDetailsProps,
  orderInTripDetails: number
) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Responsive forMaxSize="mobile">
      <Grid columns="1.3% 98.7%" className={extendedDetailsClass}>
        <TimeLine />
        <Destination
          tripSegment={tripSegment}
          orderInTripDetails={orderInTripDetails}
        />
      </Grid>
      <Grid columns={1} className={extendedDetailsClass}>
        <div
          className={Styles["extended-details__info-button"]}
          role="button"
          tabIndex={0}
          onKeyDown={() => setIsOpen(!isOpen)}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Text
            content={
              isOpen
                ? t("tripDetails.detailCard.lessInfo")
                : t("tripDetails.detailCard.moreInfo")
            }
            styles={(theme) => ({
              color: theme.theme.siteVariables.extendedDetails?.moreInfoButton,
            })}
          />
          <ChevronDownIcon
            size="small"
            styles={(theme) => ({
              color: theme.theme.siteVariables.extendedDetails?.moreInfoButton,
            })}
            className={
              Styles[
                `extended-details__icon${
                  !isOpen ? "--notRotated" : "--isRotated"
                }`
              ]
            }
          />
        </div>
        {isOpen ? (
          <Box
            className={Styles["extended-details__box"]}
            styles={(theme) => ({
              background:
                theme.theme.siteVariables.extendedDetails?.moreInfoBox
                  .background,
            })}
          >
            <ReservationInfo tripSegment={tripSegment} />
            <div className={Styles["extended-details__box--divider"]}>
              <Divider colorTheme="moreInfoBox" />
            </div>
            <Services
              serviceClass={tripSegment?.serviceClass}
              baggage={tripSegment?.baggage}
              meal={tripSegment?.meal}
            />
          </Box>
        ) : null}
      </Grid>
    </Responsive>
  );
}

function ExtendedDetails({
  tripSegment,
  orderInTripDetails,
}: IExtendedDetailsProps) {
  return (
    <div className={Styles.container} role="document">
      <ExtendedDetailsForDesktop
        tripSegment={tripSegment}
        orderInTripDetails={orderInTripDetails}
      />
      <ExtendedDetailsForMobile
        tripSegment={tripSegment}
        orderInTripDetails={orderInTripDetails}
      />
    </div>
  );
}

export default ExtendedDetails;
