import HostSettings from "../../../../utils/host.settings";
import GetBatchPhotos from "./GetBatchPhotos";
import getFinalObjet from "./GetFinalObjet";
import getImageObject from "./GetImageObject";

export function GetFinalObject(users: any, usersImages: any, type: any) {
  return getFinalObjet(users, usersImages, type);
}

export default async function getUsersPhotos(
  users: any,
  graph: any,
  query: any,
  type: string
) {
  let usersImages = [{}];
  let finalgroupsusers = [{}];
  if (users.length) {
    const usersIds = users.map((user: any) => user.id);
    const imagesUsers = await GetBatchPhotos(usersIds, graph, query.users);
    const photoError = `${HostSettings.getCDNImages}default-avatar.png`;
    usersImages = getImageObject(imagesUsers, photoError);
    finalgroupsusers = GetFinalObject(users, usersImages, type);
  }
  return finalgroupsusers;
}
