import { Box, Text } from "@fluentui/react-northstar";
import React from "react";
import Responsive from "../../../../../../../common/components/responsive/Responsive";
import { ISegmentProps } from "./Placeholder.model";
import Styles from "./Placeholder.module.scss";

function Placeholder({ text }: ISegmentProps) {
  return (
    <>
      <Responsive forRange="mobile">
        <Box
          className={Styles.placeholder__imageMobile}
          as="image"
          data-testid="HotAirBalloonImage"
          styles={(theme) => ({
            background: theme.theme.siteVariables.bookingDetail
              ?.placeholderImage
              ? theme.theme.siteVariables.bookingDetail.placeholderImage
              : "",
            backgroundSize: "100px",
          })}
        />
      </Responsive>
      <Responsive forMinSize="tablet">
        <Box
          className={Styles.placeholder__image}
          as="image"
          data-testid="HotAirBalloonImage"
          styles={(theme) => ({
            background: theme.theme.siteVariables.bookingDetail
              ?.placeholderImage
              ? theme.theme.siteVariables.bookingDetail.placeholderImage
              : "",
            backgroundSize: "100px",
          })}
        />
      </Responsive>

      <Text
        content={text}
        className={Styles.placeholder__title}
        data-testid="title"
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.placeholderText
            ? theme.theme.siteVariables.bookingDetail.placeholderText
            : "",
        })}
      />
    </>
  );
}

export default Placeholder;
