/* eslint-disable no-param-reassign */
import CloseCollaboratorsConstant from "./CloseCollaboratorsConstant";

export default function mapCollabImages(
  collaborators: any,
  images: any,
  presencesGraph: any
) {
  const enhancedCollaborators: any[] = [];
  collaborators.forEach((collaborator: any) => {
    const enhancedCollaborator = { ...collaborator };

    const ccImage = images.responses.find(
      (im: any) => im.id === collaborator.id
    );
    const ccPresence = presencesGraph.responses.find(
      (pr: any) => pr.id === enhancedCollaborator.id
    );

    enhancedCollaborator.image =
      ccImage.status === 200
        ? `data:image/jpeg;base64,${ccImage.body}`
        : CloseCollaboratorsConstant.graphDefaultImage;

    const parseActivity =
      ccPresence?.body.activity === "OffWork"
        ? "Offline"
        : ccPresence?.body.activity;

    const statusActivitySintax =
      parseActivity &&
      parseActivity
        .replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase();

    const statusActivity =
      statusActivitySintax &&
      statusActivitySintax.charAt(0).toUpperCase() +
        statusActivitySintax.slice(1);

    enhancedCollaborator.availability =
      ccPresence.status === 200
        ? ccPresence?.body.availability
        : CloseCollaboratorsConstant.graphDefaultAvailability;
    enhancedCollaborator.activity =
      ccPresence.status === 200
        ? statusActivity
        : CloseCollaboratorsConstant.graphDefaultActivity;

    enhancedCollaborators.push(enhancedCollaborator);
  });
  return enhancedCollaborators;
}
