import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import HostSettings from "../../../../../utils/host.settings";

const getPastTrips = async () => {
  const url = `${HostSettings.getBaseAPI}${HostSettings.getPastTripListV1}`;
  const response = await Http.get(url);

  return (await response.data.data) || (await response.data);
};

export default getPastTrips;
