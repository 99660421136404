/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment";

import { forEach } from "lodash";
import RailRedirectionInput from "../../../common/models/redirection/RailRedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import i18n from "../../../i18n/i18n";
import {
  ShareRailCardData,
  ShareRailItinerary,
  ShareRailParamsModel,
} from "../models/ShareRailModel";
import {
  ShareRailItineraryShort,
  ShareRailParamsShortModel,
} from "../models/ShareRailShortModel";
import {
  getFormattedDuration,
  getSearchDeeplink,
  getformattedDateTime,
} from "./ShareHelper";

/**
 * Creates string stating initial and final destination station names for adaptive card.
 *
 * @param firstItinerary first itinerary from rail data
 * @returns string containing intial and final station destinations
 */
function getLocationsString(firstItinerary: any): string {
  return `${firstItinerary?.departureStationName} ${i18n.t(
    "shareTrip.adaptiveCard.to"
  )} ${firstItinerary?.arrivalStationName}`;
}

/**
 * Creates string stating departure and arrival dates for adaptive card.
 *
 * @param railData rail data
 * @returns datetime string of trip duration
 */
function getTripDatesString(railData: any): string {
  const departure = getformattedDateTime(
    railData?.itineraries[0]?.departureDateTime
  );
  const arrival = getformattedDateTime(
    railData?.itineraries[railData.itineraries.length - 1]?.arrivalDateTime
  );
  return `${departure} - ${arrival}`;
}

/**
 * Validates deeplink data for ShareRail.
 *
 * @param data deeplink data to be validated
 * @returns true if data is of type ShareRailParamsModel otherwise returns false
 */
export function isValidRailData(data: any): data is ShareRailParamsModel {
  return (
    data.itineraries != null &&
    data.itineraries[0].departureDateTime &&
    data.itineraries[0].arrivalDateTime &&
    data.itineraries[0].departureStationName &&
    data.itineraries[0].arrivalStationName &&
    data.itineraries[0].segments[0].trainNumber
  );
}

export function getShareRailCardData(
  rail: ShareRailParamsModel,
  isPreview: boolean
) {
  calculateTotalStopDuration(rail);
  setFormattedItineraryDates(rail);

  const shareRailCardData: ShareRailCardData = {
    locationsString: getLocationsString(rail.itineraries[0]),
    dateTimeString: getTripDatesString(rail),
    isPreview,
    redirectionURL: getRailDeeplinkURL(rail),
    itineraries: rail.itineraries,
    bookingOptionText: i18n.t("shareTrip.adaptiveCard.bookingOption"),
    bookingDetailsText: i18n.t("shareTrip.adaptiveCard.bookingOptionDetails"),
    bookingAvailabilityText: i18n.t(
      "shareTrip.adaptiveCard.bookingAvailability"
    ),
    bookingButtonText: i18n.t("shareTrip.railDeepLink"),
  };

  return shareRailCardData;
}

/**
 * Sets formatted departure and arrival dates for each itinerary as per current locale.
 *
 * @param rail rail data
 */
function setFormattedItineraryDates(rail: ShareRailParamsModel) {
  rail.itineraries.forEach((itinerary) => {
    itinerary.formattedDepartureDate = getformattedDateTime(
      itinerary.departureDateTime
    );
    itinerary.formattedArrivalDate = getformattedDateTime(
      itinerary.arrivalDateTime
    );
  });
}

/**
 * Creates string stating number of stops and their total duration for adaptive card.
 *
 * @param rail rail data
 */
function calculateTotalStopDuration(rail: ShareRailParamsModel) {
  forEach(rail.itineraries, (itinerary) => {
    // case: there is at least one stop
    if (itinerary.segments.length > 1) {
      const totalDuration = moment.duration(0);

      for (let i = 0; i < itinerary.segments.length - 1; i += 1) {
        const startDate = moment(itinerary.segments[i].arrivalDate);
        const endDate = moment(itinerary.segments[i + 1].departureDate);
        totalDuration.add(moment.duration(endDate.diff(startDate)));
      }

      const formattedDuration = getFormattedDuration(totalDuration);

      // case: there is only one stop
      if (itinerary.segments.length === 2) {
        itinerary.totalStopDuration = `${formattedDuration} ${itinerary.segments[0].arrivalStationName}`;
        itinerary.stopsText = `1 ${i18n.t(
          "shareTrip.adaptiveCard.stopSingular"
        )}`;
      }
      // case: there are at least two stops
      else if (itinerary.segments.length > 2) {
        itinerary.totalStopDuration = `${formattedDuration} ${i18n.t(
          "shareTrip.adaptiveCard.inTotal"
        )}`;
        itinerary.stopsText = `${itinerary.segments.length - 1} ${i18n.t(
          "shareTrip.adaptiveCard.stopPlural"
        )}`;
      }

      // case: total duration has invalid value
      if (totalDuration.asMilliseconds() < 0) {
        itinerary.totalStopDuration = formattedDuration;
      }
    }
    // case: there is no stop
    else {
      itinerary.stopsText = `${i18n.t("shareTrip.adaptiveCard.nonStop")}`;
    }
  });
}

function isDirectTrainsSearch(itineraries: any[]) {
  return itineraries.every((itinerary: any) => !!itinerary.directTrains);
}

/**
 * Creates search rail deeplink with given booking data.
 *
 * @param rail rail booking data
 * @returns deeplink url for search rail
 */
function getRailDeeplinkURL(rail: ShareRailParamsModel): string {
  const dateTimes: string[] = [];
  const departures: string[] = [];
  const arrivals: string[] = [];
  rail.itineraries.forEach((itinerary) => {
    const lastSegment = itinerary.segments.length - 1;
    dateTimes.push(itinerary.departureDateTime);

    // NOTE: All stations should have UFI code, stationName is used only as backup
    const departure = itinerary.segments[0].departureStationUfi
      ? itinerary.segments[0].departureStationUfi
      : itinerary.segments[0].departureStationName;
    const arrival = itinerary.segments[lastSegment].arrivalStationUfi
      ? itinerary.segments[lastSegment].arrivalStationUfi
      : itinerary.segments[lastSegment].arrivalStationName;
    departures.push(departure);
    arrivals.push(arrival);
  });

  const deeplinkData: RailRedirectionInput = {
    segmentType: RedirectionTypeEnum.RAIL,
    dateTime: dateTimes,
    stopOnStartPage: false,
    directTrains: isDirectTrainsSearch(rail.itineraries),
    departure: departures,
    arrival: arrivals,
    buttonType: "bookTrain",
  };

  return getSearchDeeplink(deeplinkData);
}

function mapToShareRailItinerary(
  data: ShareRailItineraryShort
): ShareRailItinerary {
  return {
    departureDateTime: data.ddt,
    arrivalDateTime: data.adt,
    departureCityName: data.dcn,
    arrivalCityName: data.acn,
    departureStationName: data.dsn,
    arrivalStationName: data.asn,
    segmentsNumber: data.snu,
    directTrips: data.dtr,
    segments: data.seg.map((segment) => ({
      departureDate: segment.dda,
      arrivalDate: segment.ada,
      departureStationName: segment.dsn,
      departureStationUfi: segment.dsu,
      arrivalStationName: segment.asn,
      arrivalStationUfi: segment.asu,
      trainNumber: segment.tno,
      operatedBy: segment.opb,
    })),
  };
}

/**
 * Ensures correct mapping from data shared from Cytric Classic to Cytric Easy.
 * Links shared from Cytric Classic have limitation of 1900 characters, so all keys were shortened to only 3 characters.
 *
 * Example: trainNumber -> tno
 * One exception: segmentType key is not shortened.
 *
 * @param data from Cytric Classic
 * @returns The mapped ShareRailParamsModel or null if an error occurs.
 */
export function mapToShareRailParamsModel(
  data: ShareRailParamsShortModel
): ShareRailParamsModel | null {
  try {
    return {
      segmentType: RedirectionTypeEnum.SHARE_RAIL,
      tripPurposeId: data.tpi,
      itineraries: data.its.map((it) => mapToShareRailItinerary(it)),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in mapToShareRailParamsModel: ", error);
    return null;
  }
}
