import { RootState } from "../../../StoreHooks";

export const selectCloseCollaboratorsIdsToNotify = (
  state: RootState
): string[] => state.closeCollaboratorsNotification.closeCollaboratorIds;

export const selectCloseCollaboratorsToNotifySize = (
  state: RootState
): number => state.closeCollaboratorsNotification.closeCollaboratorIds.length;

export const selectIsPendingCloseCollaboratorsToNotify = (
  state: RootState
): boolean => state.closeCollaboratorsNotification.isPending;
