import { selectFeatureToggle } from "../../../../store/feature-toggles/FeatureTogglesSelector";
import { useAppSelector } from "../../../../store/StoreHooks";
import CheckFeatureToggle from "../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../utils/constants";

const CheckFeatureEnabled = () => {
  const featureToogles = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.tripDetailAddElements.id)
  );
  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPUpcomingTrips.id
  );

  if (featureToogles && featureToogles.status === "true" && !isTRIPPUser) {
    return true;
  }
  return false;
};

export default CheckFeatureEnabled;
