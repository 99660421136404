/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Http,
  TeamsUserCredential,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import moment from "moment";
import HostSettings from "../../../../../utils/host.settings";
import { types } from "../../../context/ShareMyRideReducer";
import ShareMyRideService from "../../../services/shareMyRideService";
import getGraphInfo from "../../../utils/getGraphInfo";
import openChat from "../../../utils/OpenChat";
import { appInsightsTracker } from "../../../utils/shareMyRideUtils";

export const SERVER_URL = HostSettings.getBaseAPI;
export const ENDPOINT_URL = HostSettings.getShareMyRideNotification;

export const updateProduct = async (
  numberUsersSharing: number,
  userIsSharing: boolean,
  peopleSharing: any,
  product: any,
  dispatch: any
) => {
  const productCopy = { ...product, numberUsersSharing, userIsSharing };
  dispatch({ type: types.updateTrip, payload: productCopy });

  const peopleSharingData = {
    date: product?.date,
    peopleSharing,
  };
  dispatch({ type: types.setPeopleSharing, payload: peopleSharingData });
};

export const setActiveSegment = (
  product: any,
  numberUsersSharing: number,
  userIsSharing: boolean,
  peopleSharing: any,
  dispatch: any
) => {
  const productCopy = {
    ...product,
    numberOfPeopleSharing: numberUsersSharing,
    sharingEnabled: userIsSharing,
    peopleSharing,
    error: false,
    loading: false,
  };
  dispatch({ type: types.setActiveSegment, payload: productCopy });
  dispatch({ type: types.setGlobalLoading, payload: false });
};

const getSharedProducts = async (
  tripId: string,
  product: any,
  isPolling: string,
  locationType: string,
  dispatch: any,
  active: boolean,
  callbackUrl?: string
) => {
  try {
    const response = await ShareMyRideService.getPeopleSharing(
      tripId,
      product,
      isPolling,
      locationType,
      callbackUrl
    );

    const {
      sharingUsersCount: numberUsersSharing,
      isCurrentUserSharing: userIsSharing,
      users,
    } = response.data.data;
    const peopleSharing = await getGraphInfo(users, product);
    updateProduct(
      numberUsersSharing,
      userIsSharing,
      peopleSharing,
      product,
      dispatch
    );
    if (active) {
      setActiveSegment(
        product,
        numberUsersSharing,
        userIsSharing,
        peopleSharing,
        dispatch
      );
    }
  } catch (err: any) {
    dispatch({ type: types.setCommonError, payload: true });
  } finally {
    appInsightsTracker(
      { name: "travel-shareRide-display" },
      {
        tripId,
        isActivated: true,
      }
    );
  }
};

export const enableShare = async (
  tripId: string,
  enableParams: any,
  dispatch: any,
  isPolling: string,
  product: any,
  enableAll: boolean = false
) => {
  dispatch({ type: types.setGlobalLoading, payload: true });
  try {
    const response = await ShareMyRideService.enableShare(tripId, enableParams);
    const { callbackUrl } = response.data.data;
    const active = !enableAll;
    getSharedProducts(
      tripId,
      product,
      isPolling,
      product.locationType,
      dispatch,
      active,
      callbackUrl
    );
  } catch {
    dispatch({ type: types.setGlobalLoading, payload: false });
    if (enableAll) {
      dispatch({
        type: types.setErrorEnableAll,
        payload: "errorEnableAll",
      });
    } else {
      dispatch({
        type: types.setErrorEnableAll,
        payload: "errorEnableIndividual",
      });
    }
  }
};

export const disableShare = async (
  tripId: string,
  disableParams: any,
  dispatch: any,
  isPolling: string,
  enableAll: boolean,
  product: any
) => {
  try {
    const response = await ShareMyRideService.disableShare(
      tripId,
      disableParams
    );
    const { callbackUrl } = response.data.data;
    const active = false;
    getSharedProducts(
      tripId,
      product,
      isPolling,
      product.locationType,
      dispatch,
      active,
      callbackUrl
    );
  } catch {
    dispatch({ type: types.setGlobalLoading, payload: false });
    if (enableAll) {
      dispatch({
        type: types.setErrorEnableAll,
        payload: "errorDisableAll",
      });
    } else {
      dispatch({
        type: types.setErrorEnableAll,
        payload: "errorDisableIndividual",
      });
    }
  } finally {
    appInsightsTracker(
      { name: "travel-shareRide-display" },
      {
        tripId,
        isActivated: false,
      }
    );
  }
};

export const getGraphToken = async () => {
  const credential = new TeamsUserCredential();
  const graphToken = await credential.getToken("Chat.ReadWrite");

  return graphToken?.token;
};

export const adaptiveCardData = (
  segmentType: string,
  productDetails: any,
  date: string,
  locationType: string,
  recipientIds: string[]
) => {
  const departureLocation =
    segmentType === "AIR"
      ? productDetails?.departureIataCode
      : productDetails?.departureStation;

  const arrivalLocation =
    segmentType === "AIR"
      ? productDetails?.arrivalIataCode
      : productDetails?.arrivalStation;

  return {
    recipientIds,
    date: moment.utc(date).format("YYYY-MM-DD"),
    location: productDetails?.location,
    locationType,
    segmentType,
    segmentInfo: `${productDetails?.departureDateTime} ${departureLocation} - ${productDetails?.arrivalDateTime} ${arrivalLocation}`,
    transportationInfo: productDetails?.serviceProviderInfo,
  };
};

export const createChat = async (
  locationType: string,
  productDetails: any,
  date: string,
  recipientIds: string[],
  segmentType: string,
  tripId: string
) => {
  await Http.post(
    `${SERVER_URL}${ENDPOINT_URL}${tripId}/rides/notification`,
    adaptiveCardData(
      segmentType,
      productDetails,
      date,
      locationType,
      recipientIds
    ),
    {
      headers: {
        GraphToken: await getGraphToken(),
      },
    }
  ).then((response: any) => {
    openChat(response.data.data);
  });
};

export default getSharedProducts;
