export default function getFinalConstructObjet(users: any, photoError: any) {
  const collaboratorsContact = users.map((item: any) => ({
    header: item.displayName,
    image:
      item.image.status === 200
        ? `data:image/jpeg;base64,${item.image.body}`
        : photoError,
    content: `(${item.userPrincipalName?.split("@")[0]}) ${
      item.jobTitle || ""
    }`,
    className: item.userPrincipalName,
    id: item.id,
  }));

  return collaboratorsContact;
}
