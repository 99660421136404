/* eslint-disable import/prefer-default-export */

import DateUtils from "../../utils/date.utils";
import { RootState } from "../StoreHooks";

export const selectTripsSortedByDepartureDate = (state: RootState) => {
  const { trips } = state.tripsSummary || {};

  return [...trips].sort(
    (a, b): number =>
      DateUtils.getDateInMiliseconds(a.startDate) -
      DateUtils.getDateInMiliseconds(b.startDate)
  );
};
