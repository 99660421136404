import {
  TeamsUserCredential,
  createMicrosoftGraphClient,
} from "@amadeus-cytric/cytric-teams-react-common-library";

import getCallGraph from "../../../common/components/search-contacts/utils/GetCallGraph";

async function GetAppCatalogId(teamsAppId: any) {
  const requestUrl = `/appCatalogs/teamsApps?$filter=externalId eq '${teamsAppId}'`;

  const credential = new TeamsUserCredential();
  const scope = ["AppCatalog.Read.All"];

  try {
    const graph = createMicrosoftGraphClient(credential, scope);
    const { value: appCatalog } = await getCallGraph(graph, requestUrl);

    return appCatalog[0].id;
  } catch {
    return [];
  }
}

export default GetAppCatalogId;
