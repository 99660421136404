import HostSettings from "./host.settings";

const ValidationUtils = {
  isValidHttpUrl: (string: string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  },
};

export const isValidUrlAndDomain = (urlString: string) => {
  try {
    const url = new URL(urlString);
    const trustedOrigins = HostSettings.iFrameTrustedOrigins;
    return (
      url.protocol === "https:" &&
      trustedOrigins.length > 0 &&
      trustedOrigins.some((pattern) => url.origin.match(pattern) != null)
    );
  } catch (_) {
    return false;
  }
};

export default ValidationUtils;
