class HostSettings {
  public static get getAmadeusEnvironment(): string {
    return process.env.REACT_APP_AMADEUS_ENVIRONMENT || "";
  }

  public static get clientId(): string {
    return process.env.REACT_APP_CLIENT_ID || "";
  }

  public static get teamsAppId(): string {
    return process.env.REACT_APP_TEAMS_APP_ID || "";
  }

  public static get teamsAppCatalogId(): string {
    return process.env.REACT_APP_CATALOG_ID || "";
  }

  public static get teamsTrippAppCatalogId(): string {
    return process.env.REACT_APP_TRIPP_CATALOG_ID || "";
  }

  public static get teamsAppStoreId(): string {
    return process.env.REACT_APP_STORE_ID || "";
  }

  public static get teamsfxEndpoint(): string {
    return process.env.REACT_APP_TEAMSFX_ENDPOINT || "";
  }

  public static get startLoginPageUrl(): string {
    return process.env.REACT_APP_START_LOGIN_PAGE_URL || "";
  }

  public static get getBaseAPI(): string {
    return process.env.REACT_APP_BASE_API || "";
  }

  public static get getApiKey(): string {
    return process.env.REACT_APP_API_KEY || "";
  }

  public static get getInstrumentationKey(): string {
    return process.env.REACT_APP_INSTRUMENTATION_KEY || "";
  }

  public static get isTelemetryEnabled(): boolean {
    return process.env.REACT_APP_IS_TELEMETRY_ENABLED === "1";
  }

  public static get getKeycloakUri(): string {
    return process.env.REACT_APP_KEYCLOAK_URI || "";
  }

  public static get isCytricLoginEnabled(): boolean {
    return process.env.REACT_APP_IS_CYTRIC_LOGIN_ENABLED === "1";
  }

  public static get tokenScopes(): string[] {
    const scopes = process.env.REACT_APP_TOKEN_SCOPES || "";
    if (scopes === "") {
      return [];
    }
    return scopes.split(",");
  }

  public static get getKeycloakAuthUri(): string {
    return process.env.REACT_APP_KEYCLOAK_AUTH_URI || "";
  }

  public static get iFrameTrustedOrigins(): string[] {
    const iframeTrustedOrigins =
      process.env.REACT_APP_CYTRIC_IFRAME_TRUSTED_ORIGINS || "";
    if (iframeTrustedOrigins === "") {
      return [];
    }
    return iframeTrustedOrigins.split(",");
  }

  public static get getUrlLearningPortal(): string {
    return process.env.REACT_APP_URL_LEARNING_PORTAL || "";
  }

  // expenses dashboard
  public static get getDashboardInfo(): string {
    return process.env.REACT_APP_GET_DASHBOARD_INFO || "";
  }

  public static get getExpensesToApproveCount(): string {
    return process.env.REACT_APP_GET_COUNT_EXPENSES_TOAPPROVE || "";
  }

  public static get getDuplicateExpensesList(): string {
    return process.env.REACT_APP_GET_DUPLICATE_EXPENSES_LIST || "";
  }

  public static get getCurrencyCode(): string {
    return process.env.REACT_APP_GET_DASHBOARD_CURRENCY || "";
  }

  public static get getExpenseStatements(): string {
    return process.env.REACT_APP_GET_EXPENSE_STATEMENTS || "";
  }

  public static get getChartSpendings(): string {
    return process.env.REACT_APP_GET_CHART_SPENDINGS || "";
  }

  public static get manageDuplicates(): string {
    return process.env.REACT_APP_MANAGE_DUPLICATES || "";
  }

  public static get getTripList(): string {
    return process.env.REACT_APP_TRIP_LIST || "";
  }

  public static get getTripListV1(): string {
    return process.env.REACT_APP_TRIP_LIST_V1 || "";
  }

  public static get getPastTripList(): string {
    return process.env.REACT_APP_PAST_TRIP_LIST || "";
  }

  public static get getPastTripListV1(): string {
    return process.env.REACT_APP_PAST_TRIP_LIST_V1 || "";
  }

  public static get getTodoList(): string {
    return process.env.REACT_APP_TO_DO_ENDPOINT || "";
  }

  public static get getBotId(): string {
    return process.env.REACT_APP_BOT_ID || "";
  }

  public static get getCloseCollaboratorsPath(): string {
    return process.env.REACT_APP_GET_CLOSE_COLLABORATORS || "";
  }

  public static get getCloseCollaboratorsNotificationPath(): string {
    return process.env.REACT_APP_CLOSE_COLLABORATORS_NOTIFICATION || "";
  }

  public static get reverseCollaboratorsPath(): string {
    return process.env.REACT_APP_REVERSE_COLLABORATORS || "";
  }

  public static get getKeepSessionTimeout(): string {
    return process.env.REACT_APP_KEEP_SESSION_TIMEOUT || "";
  }

  public static get getFeatureTogglesEndpoint(): string {
    return process.env.REACT_APP_FEATURE_TOGGLES_ENDPOINT || "";
  }

  public static get getAllowedTranslationLanguages(): string[] {
    const languages = process.env.REACT_APP_ALLOWED_TRANSLATION_LANGUAGES || "";
    let result: string[] = [];
    if (languages && languages.length > 0) {
      result = languages.split(",");
    }
    if (!result.includes("en")) {
      result.push("en");
    }
    return result;
  }

  public static get getAcceptedLanguages(): string {
    return process.env.REACT_APP_ACCEPTED_LANGUAGES || "";
  }

  public static get getDefaultLanguage(): string {
    return process.env.REACT_APP_DEFAULT_LANGUAGE || "";
  }

  public static get getAppReleaseVersionNumber(): string {
    return process.env.REACT_APP_RELEASE_VERSION_NUMBER || "";
  }

  public static get getBookingRights(): string {
    return process.env.REACT_APP_BOOKING_RIGHTS || "";
  }

  public static get getRedirectionRail(): string {
    return process.env.REACT_APP_REDIRECTION_RAIL || "";
  }

  public static get getShareMyRideNotification(): string {
    return process.env.REACT_APP_SHARE_MY_RIDE_NOTIFICATION_ENDPOINT || "";
  }

  public static get getCDNIcons(): string {
    return process.env.REACT_APP_ICONS_CDN || "";
  }

  public static get getCDNImages(): string {
    return process.env.REACT_APP_IMAGES_CDN || "";
  }

  public static get msTeamsUrl(): string {
    return process.env.REACT_APP_MSTEAMS_URL || "";
  }
}

export default HostSettings;
