/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */

import { Box } from "@fluentui/react-northstar";
import React, { useState } from "react";
import BasicDetails from "../BasicDetails/BasicDetails";
import ExtendedDetails from "../ExtendedDetails/ExtendedDetails";
import StopDuration from "../StopDuration/StopDuration";
import {
  IBound,
  IBoundProps,
  IChildrenProps,
  ISegment,
} from "./Accordion.model";
import Styles from "./Accordion.module.scss";

function Divider({ isOpen }: { isOpen: boolean }) {
  return (
    <div
      style={{
        paddingLeft: isOpen ? "0px" : "16px",
        paddingRight: isOpen ? "0px" : "48px",
      }}
    >
      <Box
        className={Styles.divider}
        styles={(theme) => ({
          color: theme.theme.siteVariables.divider
            ? theme.theme.siteVariables.divider.bookingDetail
            : "",
        })}
      />
    </div>
  );
}

function AccordionCardBox({ children }: IChildrenProps) {
  return (
    <Box
      role="cell"
      className={Styles.accordion}
      styles={(theme) => ({
        borderColor: theme.theme.siteVariables.bookingDetail
          ? theme.theme.siteVariables.bookingDetail.borderColor
          : "",
        background: theme.theme.siteVariables.bookingDetail
          ? theme.theme.siteVariables.bookingDetail.background
          : "",
      })}
    >
      {children}
    </Box>
  );
}

const composeLayout = (
  boundGroup: IBound[],
  isOpen: boolean[],
  setIsOpen: any
) => {
  const isRoundtrip = boundGroup?.length > 1;
  return boundGroup.reduce(
    (acc: JSX.Element[], bound: IBound, index: number) => {
      acc.push(
        <BasicDetails
          tripSegment={bound.basicDetails}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          index={index}
          length={boundGroup.length}
          isRoundtrip={isRoundtrip}
          key={`${index}--BasicDetail`}
        />
      );

      if (
        (!isOpen[index] && index + 1 !== boundGroup?.length && isRoundtrip) ||
        isOpen[index]
      ) {
        acc.push(<Divider isOpen={false} key={`${index}--Divider-inside`} />);
      }

      bound.extendedDetails.forEach(
        (segment: ISegment, i: number, elements: ISegment[]) => {
          if (isOpen[index]) {
            acc.push(
              <ExtendedDetails
                tripSegment={segment}
                key={`${index}--ExtendedDetail--${i}`}
                orderInTripDetails={i + 1}
              />
            );
          }
          if (i + 1 !== elements.length) {
            if (isOpen[index]) {
              acc.push(
                <StopDuration
                  firstSegment={segment}
                  secondSegment={elements?.[i + 1]}
                  getters={bound.basicDetails.getters}
                  key={`${index}--StopDuration--${i}`}
                />
              );
            }
          }
        }
      );

      if (isOpen[index] && index + 1 !== boundGroup?.length) {
        acc.push(<Divider isOpen key={`${index}--Divider-top`} />);
      }

      return acc;
    },
    []
  );
};

function Accordion({ boundGroup }: IBoundProps) {
  const [isOpen, setIsOpen] = useState(
    new Array(boundGroup.length).fill(false)
  );

  const components = composeLayout(boundGroup, isOpen, setIsOpen);
  return (
    <AccordionCardBox>
      {components.map((component: any) => {
        if (
          component?.key?.indexOf("BasicDetail") < 0 && // component?.type?.name !== "BasicDetails" &&
          component?.key?.indexOf("Divider") < 0 && // component?.type?.name !== "Divider" &&
          !isOpen
        ) {
          return null;
        }
        return component;
      })}
    </AccordionCardBox>
  );
}

export default Accordion;
