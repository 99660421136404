import {
  createMicrosoftGraphClient,
  GraphService,
  TeamsUserCredential,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import CloseCollaboratorsConstant from "../../close-collaborators-list/utils/CloseCollaboratorsConstant";
import {
  TransformedImageData,
  TransformedUserData,
} from "../../models/CloseCollaboratorsModel";

// Transforms raw user data from Microsoft Graph API into a structured format.
const transformUsersGraphData = (data: any[]): TransformedUserData[] => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  return data.reduce((acc: TransformedUserData[], item: any) => {
    if (item.body && !item.body.error) {
      const { "@odata.context": dataContext, ...graphBody } = item.body;
      const transformedItem: TransformedUserData = {
        key: graphBody.id,
        header: graphBody.displayName,
        content: graphBody.jobTitle,
        mail: graphBody.mail,
      };
      acc.push(transformedItem);
    }
    return acc;
  }, []);
};

// Transforms raw image data from Microsoft Graph API into a structured format.
const transformImageGraphData = (data: any[]): TransformedImageData[] => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  return data.reduce((acc: TransformedImageData[], item: any) => {
    if (item.body && !item.body.error) {
      const imageData = { ...item };

      if (imageData.status !== 200) {
        imageData.body = CloseCollaboratorsConstant.graphDefaultImage;
      } else {
        imageData.body = `data:image/jpeg;base64,${imageData.body}`;
      }

      const transformedItem: TransformedImageData = {
        image: imageData.body,
        key: imageData.id,
      };
      acc.push(transformedItem);
    }
    return acc;
  }, []);
};

// Merges user data and image data into a single array of objects.
const mergeResults = (
  usersData: TransformedUserData[],
  imageData: TransformedImageData[]
) => {
  if (
    !Array.isArray(usersData) ||
    usersData.length === 0 ||
    !Array.isArray(imageData) ||
    imageData.length === 0
  ) {
    return [];
  }
  const userWithImage: any[] = [];
  const tempDataMap = new Map();
  usersData.forEach((item: any) => tempDataMap.set(item.key, { ...item }));
  imageData.forEach((item: any) => {
    if (tempDataMap.has(item.key)) {
      tempDataMap.set(item.key, { ...tempDataMap.get(item.key), ...item });
    } else {
      tempDataMap.set(item.key, { ...item });
    }
  });
  tempDataMap.forEach((value) => userWithImage.push(value));
  return userWithImage;
};

// Fetches user data and their images from Microsoft Graph API and merges them.
const getUsersWithImages = async (closeCollaboratorIds: string[]) => {
  if (
    !Array.isArray(closeCollaboratorIds) ||
    closeCollaboratorIds.length === 0
  ) {
    return [];
  }

  try {
    const msGraphClient = createMicrosoftGraphClient(new TeamsUserCredential());
    const [usersGraphData, usersImageGraphData] = await Promise.all([
      GraphService.getGraphUsers(msGraphClient, closeCollaboratorIds, [
        "id",
        "displayName",
        "jobTitle",
        "mail",
      ]),
      GraphService.getGraphUsersPhotos(
        msGraphClient,
        closeCollaboratorIds,
        "48x48"
      ),
    ]);

    if (!Array.isArray(usersGraphData) || !Array.isArray(usersImageGraphData)) {
      throw new Error("Invalid data format received from Graph API");
    }

    const usersWithImage = mergeResults(
      transformUsersGraphData(usersGraphData),
      transformImageGraphData(usersImageGraphData)
    );

    return usersWithImage;
  } catch (error) {
    return [];
  }
};

export default getUsersWithImages;
