import { Flex, Text } from "@fluentui/react-northstar";
import moment from "moment";
import { SegmentTrain } from "../../../../../../common/models/ProductTrain";
import SegmentWrapper from "../SegmentWrapper";

interface CustomInfo {
  timeAndName: string[];
  nameAndNumber: string;
  travelDuration: string;
  numberOfStops: string;
}

interface RailSegmentContentProps {
  railSegment: SegmentTrain;
  customInfo: CustomInfo;
  topDate: string | null;
}

function RailSegmentContent(props: RailSegmentContentProps) {
  const { railSegment, customInfo, topDate } = props;
  const boundStopsTrain = customInfo?.timeAndName;
  const numberStops = customInfo?.numberOfStops;
  const trainInfo = customInfo?.nameAndNumber;

  function GetBoundStopsTrainText() {
    const boundStopsTrainText =
      boundStopsTrain && boundStopsTrain.toString().replaceAll(",", ", ");
    return boundStopsTrainText;
  }

  const header = (
    <Flex column>
      <Text size="small">
        <Text weight="bold">
          {moment
            .utc(railSegment.Trip_Product_Train?.departureDateTime)
            .format("HH:mm")}
          &nbsp;
          {railSegment.Trip_Product_Train?.departure?.name}
          &nbsp; - &nbsp;
          {moment
            .utc(railSegment.Trip_Product_Train?.arrivalDateTime)
            .format("HH:mm")}
          &nbsp;
          {railSegment.Trip_Product_Train?.arrival?.name}
        </Text>
        &nbsp; | &nbsp;
        {`${numberStops} ${GetBoundStopsTrainText()}`}
      </Text>
    </Flex>
  );

  const content = <Text>{`${trainInfo}`}</Text>;

  return (
    <SegmentWrapper segment={railSegment} topDate={topDate} segmentType="train">
      {header}
      {content}
    </SegmentWrapper>
  );
}

export default RailSegmentContent;
