import { Checkbox, Flex, Text } from "@fluentui/react-northstar";

function SettingsOption({
  optionTitle,
  optionCheck,
  onChangeType,
  optionLabel,
  setSettingsOnClick,
}: any) {
  return (
    <Flex
      className="sectionItem"
      space="between"
      style={{ backgroundColor: "transparent" }}
    >
      <Text>{optionTitle}</Text>

      <Checkbox
        checked={optionCheck}
        className="justifySelfRight"
        labelPosition="start"
        toggle
        onChange={(e, checked) => setSettingsOnClick(e, checked, onChangeType)}
        aria-label={optionLabel}
      />
    </Flex>
  );
}

export default SettingsOption;
