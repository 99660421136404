/* eslint-disable react/react-in-jsx-scope */
import { Flex, Image } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../../../utils/host.settings";

function DialogHeader() {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.popup",
  });
  return (
    <Flex id="popup__dialog-header__container" vAlign="center">
      <Image
        id="popup__dialog-header__image-logo"
        src={`${HostSettings.getCDNIcons}cytric-easy-app-logo.svg`}
        alt="Cytric logo"
        styles={{ marginRight: "0.75rem" }}
      />
      <Flex id="popup__dialog-header__title-container" column>
        <b
          id="popup__dialog-header__bold-title"
          data-testid="popup__dialog-header__bold-title"
          style={{ fontSize: "0.875rem" }}
        >
          {t("viewDuplicates.mergeExpenses")}
        </b>
        <span
          id="popup__dialog-header__span-title"
          data-testid="popup__dialog-header__span-title"
          style={{ color: "#616161", fontSize: "0.75rem", fontWeight: "400" }}
        >
          Cytric Easy
        </span>
      </Flex>
    </Flex>
  );
}

export default DialogHeader;
