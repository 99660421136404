import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Dialog, Loader } from "@fluentui/react-northstar";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { KeepSessionActions } from "../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../utils/KeepUserActivityContext";
import CancelContext from "./CancelContext";
import Styles from "./CancelPopUp.module.scss";
import CancelTrip from "./CancelTrip";
import { useCancelPopUp } from "./context/CancelPopUpContext";

interface CancelPopUpProps {
  id: string;
  location: string;
}

function CancelPopUp({ id, location }: CancelPopUpProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { dialogParams, setDialogParams } = useCancelPopUp();
  const { openDialog, id: dialogId } = dialogParams;

  if (location === "dashboard") {
    telemetryService.trackEvent({ name: "travel-tripDashboard-cancelPopup" });
  } else {
    telemetryService.trackEvent({ name: "travel-tripDetails-cancelPopup" });
  }

  const handleConfirm = async (e: SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    setLoading(true);
    const cancelConfirmation = await CancelTrip(id);
    telemetryService.trackEvent({ name: "travel-trip-cancel" });
    const activity = new ActivityClass();
    activity.removeElementOrChildren(KeepSessionActions.DASHBOARD_CANCELPOPUP);
    activity.removeElementOrChildren(KeepSessionActions.DETAILS_CANCELPOPUP);

    if (cancelConfirmation) {
      localStorage.removeItem("KeepSessionCache");
      localStorage.setItem("tripDetailTab", "");
      setLoading(false);
      setOpen(false);
      if (location === "dashboard") {
        window.location.reload();
      } else {
        history.push("/travel");
      }
    } else {
      setLoading(false);
      setOpen(false);
      window.location.reload();
    }
  };

  const cancelContext = useContext(CancelContext);
  const travelDashboardChecker =
    location === "dashboard" ? dialogId === id : true;

  useEffect(() => {
    if (openDialog && travelDashboardChecker) {
      cancelContext.setCancelPopup(true);
      setOpen(true);
    }
  }, [cancelContext, openDialog, travelDashboardChecker]);

  const handleTrigger = (e: SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const buttonText = t("upcoming_trips_dashboard.cancel.cancelButton");
  const cancelText = t("moduleResponse.error.closeButton");

  const handleCancel = (e: any) => {
    e.stopPropagation();
    const activity = new ActivityClass();
    if (location === "dashboard") {
      activity.removeElementOrChildren(
        KeepSessionActions.DASHBOARD_CANCELPOPUP
      );
    } else {
      activity.removeElementOrChildren(KeepSessionActions.DETAILS_CANCELPOPUP);
    }

    setDialogParams({ openDialog: false, id: "" });
    setOpen(false);
    cancelContext.setCancelPopup(false);
  };

  return (
    <Dialog
      open={open}
      onConfirm={handleConfirm}
      className={Styles.cancelDialog}
      confirmButton={loading ? null : buttonText}
      cancelButton={loading ? null : cancelText}
      onCancel={(e) => handleCancel(e)}
      trigger={
        <Button
          text
          content={t(
            "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.cancelTrip"
          )}
          className={Styles.cancelTripButton}
          data-testid="cancel-trip-dialog-button"
          onKeyDown={handleTrigger}
          onClick={handleTrigger}
          hidden
        />
      }
      content={
        loading ? (
          <Loader size="large" />
        ) : (
          t("upcoming_trips_dashboard.cancel.confirmationText")
        )
      }
      header={`${t("upcoming_trips_dashboard.cancel.cancelTitle")}`}
      data-testid="cancel-trip-dialog"
    />
  );
}

export default CancelPopUp;
