import {
  ProductsToShareModel,
  SMRStateModel,
} from "../models/ShareMyRideContext.model";

export const updateProductsToShare = (
  state: SMRStateModel,
  mapFn: (product: ProductsToShareModel) => void
) => ({
  ...state,
  productsToShare: state.productsToShare.map(mapFn),
});

export const matchArrivalDate = (
  product: ProductsToShareModel,
  payloadDate: string,
  result: any
) => (product.date === payloadDate ? result : product);
