export const getDevice = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/gi.test(
      ua
    ) ||
    window.screen.width < 480
  ) {
    return "mobile";
  }

  return "desktop";
};

// TODO use them in rest of code if possible
export const isMobile = () => getDevice() === "mobile";
const isTablet = () => getDevice() === "tablet";
export const isMobileOrTablet = () => isMobile() || isTablet();
