import { useTranslation } from "react-i18next";
import GenericInfo from "../../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfoAction,
  InfoImage,
} from "../../../common/components/generic-info-component/GenericInfo.model";
import { IError } from "../../../common/models/Error";

interface IErrorProps {
  type: string;
}

function Error(data: IErrorProps) {
  const { type } = data;
  const { t } = useTranslation();
  let title = "";
  let detail = "";

  if (type === "many") {
    title = t(`messages.ERR_Generic`);
    detail = t(`messages.ERR_TooManyRequest`);
  } else {
    title = t(`messages.ERR_Generic`);
    detail = t(`upcoming_trips_dashboard.ERR_api_call`);
  }

  const errorInfo: IError = {
    status: 0,
    code: 0,
    title,
    detail,
  };
  const action: IGenericInfoAction = {
    text: t(`upcoming_trips_dashboard.Button_try_again`),
    command: () => {
      window.location.reload();
    },
  };

  return (
    <GenericInfo infoData={errorInfo} action={action} image={InfoImage.ERROR} />
  );
}
export default Error;
