import { Flex, Loader } from "@fluentui/react-northstar";
import Styles from "../../ShareMyRide.module.scss";

function LoadingSpinner({ themeName }: any) {
  return (
    <>
      <Flex
        className={[
          themeName === "light" ? Styles.whiteOverlay : Styles.darkOverlay,
          Styles.overlay,
        ].join(" ")}
      />
      <Loader className={Styles.spinner} />
    </>
  );
}

export default LoadingSpinner;
