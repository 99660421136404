import { IError } from "../../../common/models/Error";
import { ReverseCollaboratorsModel } from "../../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import { RootState } from "../../StoreHooks";

export const selectReverseCollaborators = (
  state: RootState
): ReverseCollaboratorsModel[] | undefined =>
  state.reverseCollaborators.reverseCollaborators;

export const selectReverseCollaboratorsIsPending = (
  state: RootState
): boolean => state.reverseCollaborators.isPending;

export const selectReverseCollaboratorsIsError = (state: RootState): boolean =>
  state.reverseCollaborators.isError;

export const selectReverseCollaboratorsError = (state: RootState): IError =>
  state.reverseCollaborators.error;
