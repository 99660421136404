import { Open16Regular } from "@fluentui/react-icons";
import {
  Button,
  CloseIcon,
  Dialog,
  Dropdown,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/StoreHooks";
import { selectFeatureToggle } from "../../../../../store/feature-toggles/FeatureTogglesSelector";
import { FeatureToggleDefinition } from "../../../../../utils/constants";
import { BookingTargets, SegmentType } from "../model/Permissions";
import styles from "./BookButtons.module.scss";
import BookTrain from "./BookTrainAction";
import DialogFooter from "./DialogFooter";
import DialogOptions from "./DialogOptions";

interface IBookTrainProps {
  permissions?: SegmentType[];
}

function BookTrainButton({ permissions }: IBookTrainProps) {
  const { t } = useTranslation();

  const trainButton = t("upcoming_trips_dashboard.upcomingTrips.newBook.Rail");

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [trainPermissions, setTrainPermissions] = useState<BookingTargets[]>(
    []
  );

  const [showValue, setShowValue] = useState(trainButton);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const featureTogglePunchOuts = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.DashboardPunchOut.id)
  );

  const featureTravelTab = useAppSelector((state) =>
    selectFeatureToggle(state, FeatureToggleDefinition.deprecateCytricTab.id)
  );

  useEffect(() => {
    if (permissions) {
      const permis = permissions.filter(
        (elements: SegmentType) => elements.type === "RAIL"
      )[0].bookingTargets;

      const perm = permis
        ? permis.filter(
            (elements: BookingTargets) => elements.activated === true
          )
        : [];

      const trainItems: string[] = [];

      perm.forEach((element: BookingTargets) => {
        trainItems.push(element.title);
      });

      setTrainPermissions(perm);
    }
  }, [permissions]);

  const getTrainValue = (_e: any, val: any) => {
    if (val.searchQuery !== "") {
      const trainValue = trainPermissions.filter(
        (element: BookingTargets) => element.title === val.value.key
      )[0].type;
      BookTrain(
        value,
        trainPermissions,
        featureTogglePunchOuts,
        dispatch,
        history,
        t,
        featureTravelTab,
        trainValue
      );
    }
    setShowValue(trainButton);
  };

  const addExternalIconToDropdownList = (perms: BookingTargets[]) => {
    const modifiedWithExternalIconList: any[] = [];
    perms.forEach((element) => {
      const dropdownOption = {
        key: element.title,
        content: element.external ? (
          <Flex gap="gap.small" vAlign="center">
            <Open16Regular data-testid="external-link-icon-train" />
            <Text
              size="medium"
              content={element.title}
              aria-label={`${element.title}. ${t(
                "upcoming_trips_dashboard.upcomingTrips.newBook.externalLink"
              )}`}
            />
          </Flex>
        ) : (
          <Text size="medium" content={element.title} />
        ),
      };
      modifiedWithExternalIconList.push(dropdownOption);
    });
    return modifiedWithExternalIconList;
  };

  const closePopUp = () => {
    setOpen(false);
    setValue("");
  };

  const showCorrectElement = () => {
    if (trainPermissions.length === 1) {
      return (
        <Button
          aria-label={
            trainPermissions[0].external
              ? `${trainButton}. ${t(
                  "upcoming_trips_dashboard.upcomingTrips.newBook.externalLink"
                )}`
              : trainButton
          }
          className={styles.buttons}
          content={trainButton}
          data-testid="button-card-book-train-single"
          flat
          name={trainButton}
          onClick={async () =>
            BookTrain(
              value,
              trainPermissions,
              featureTogglePunchOuts,
              dispatch,
              history,
              t,
              featureTravelTab
            )
          }
          role="button"
          tabIndex={0}
          icon={
            trainPermissions[0].external ? (
              <Open16Regular data-testid="external-link-icon-train" />
            ) : null
          }
          iconPosition="before"
        />
      );
    }
    if (trainPermissions.length >= 2 && trainPermissions.length <= 3) {
      return (
        <Dropdown
          inverted
          styles={(theme) => ({
            borderColor: theme.theme.siteVariables?.colorScheme?.default.border,
            backgroundColor:
              theme.theme.siteVariables?.colorScheme?.default.background2,
          })}
          className={styles.dropDown}
          items={addExternalIconToDropdownList(trainPermissions)}
          data-testid="button-card-book-train-dropdown"
          placeholder={t("upcoming_trips_dashboard.tripDetails.Train")}
          onSearchQueryChange={getTrainValue}
          value={showValue}
        />
      );
    }
    if (trainPermissions.length > 3) {
      return (
        <Dialog
          open={open}
          data-testid="book-train-task-module-dialog"
          headerAction={{
            icon: <CloseIcon />,
            title: "Close",
            onClick: () => closePopUp(),
          }}
          content={
            <DialogOptions
              type="Train"
              permissions={trainPermissions}
              setValue={setValue}
              punchOutFeature={featureTogglePunchOuts}
            />
          }
          footer={
            <DialogFooter
              setOpen={setOpen}
              bookTrain={() =>
                BookTrain(
                  value,
                  trainPermissions,
                  featureTogglePunchOuts,
                  dispatch,
                  history,
                  t,
                  featureTravelTab
                )
              }
              value={value}
              setValue={setValue}
              toggle={featureTogglePunchOuts}
            />
          }
          trigger={
            <Button
              aria-label={trainButton}
              className={styles.buttons}
              content={trainButton}
              data-testid="button-card-book-train-taskm"
              flat
              name={trainButton}
              onClick={() => setOpen(true)}
              role="button"
              tabIndex={0}
            />
          }
        />
      );
    }
    return null;
  };

  return showCorrectElement();
}

export default BookTrainButton;
