/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-debugger */
import { ProviderConsumer } from "@fluentui/react-northstar";
import { useAppSelector } from "../../store/StoreHooks";
import { getFlightDetailsGroupedByDateV1 } from "../../store/trip-details-v1/selectors/trip-detail-v1-selectors-flight";
import { getRailDetailsGroupedByDateV1 } from "../../store/trip-details-v1/selectors/trip-detail-v1-selectors-rail";
import { getFlightDetailsGroupedByDate } from "../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors-flight";
import { getRailDetailsGroupedByDate } from "../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors-rail";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../utils/constants";
import WidgetContainer from "../upcoming-trips-dashboard/UpcomingTripDetail/WidgetContainer/WidgetContainer";
import stylesShareMyRide from "./ShareMyRide.module.scss";
import ShareMyRideContainer from "./components/share-my-ride-container/ShareMyRideContainer";
import TopImage from "./components/top-image/topImage";
import ShareMyRideProvider from "./context/ShareMyRideContext";
import filterAllSegments from "./utils/filterAllSegments";

function ShareMyRide() {
  const trip = useAppSelector((state) => state.tripDetail.trip);
  const tripV1 = useAppSelector((state) => state.tripDetailV1.tripDetails);
  const railDetails = useAppSelector(getRailDetailsGroupedByDate);
  const flightDetails = useAppSelector(getFlightDetailsGroupedByDate);
  const railDetailsV1 = useAppSelector(getRailDetailsGroupedByDateV1);
  const flightDetailsV1 = useAppSelector(getFlightDetailsGroupedByDateV1);
  const isEnableAllActivated = CheckFeatureToggle(
    FeatureToggleDefinition.shareMyRideEnableAll.id
  );
  const extractThemeName = (theme: any): string =>
    theme.siteVariables.bodyBackground === "#fff" ? "light" : "dark";

  const renderShareMyRideContainer = (themeName: string) => {
    const originFeatureEnabled = CheckFeatureToggle(
      FeatureToggleDefinition.shareMyRideOrigin.id
    );
    let tripId;
    let segments;
    if (CheckFeatureToggle(FeatureToggleDefinition.tripsV1ShareMyRide.id)) {
      tripId = tripV1.id;
      segments = filterAllSegments(
        tripV1,
        railDetailsV1,
        flightDetailsV1,
        originFeatureEnabled
      );
    } else {
      tripId = trip.id;
      segments = filterAllSegments(
        trip,
        railDetails,
        flightDetails,
        originFeatureEnabled
      );
    }
    return (
      <ShareMyRideContainer
        tripId={tripId}
        sharedInfo={segments}
        themeName={themeName}
        isEnableAllActivated={isEnableAllActivated}
      />
    );
  };

  const renderShareMyRide = (themeName: string) => (
    <div data-testid="smr-widget-container">
      <ShareMyRideProvider>
        <WidgetContainer
          className={stylesShareMyRide.shareMyRideWidgetContainer}
        >
          <TopImage theme={themeName} />

          <ProviderConsumer
            render={(globalTheme) =>
              renderShareMyRideContainer(extractThemeName(globalTheme))
            }
          />
        </WidgetContainer>
      </ShareMyRideProvider>
    </div>
  );

  return (
    <ProviderConsumer
      render={(globalTheme) => renderShareMyRide(extractThemeName(globalTheme))}
    />
  );
}

export default ShareMyRide;
