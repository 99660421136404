/* eslint-disable react/react-in-jsx-scope */
import { ComponentStyleFunctionParam, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../common/components/responsive/Responsive";
import { useAppSelector } from "../../../../store/StoreHooks";
import DateComponent from "../../UpcomingTrips/Date/DateComponent";
import { composeTripName } from "../../utils/trip-utils";
import Styles from "./Header.module.scss";

function Header() {
  const { t } = useTranslation();
  const trip = useAppSelector((state) => state.tripDetail.trip);

  const statusList = [
    "to-be-approved",
    "preapproved",
    "approved",
    "rejected",
    "rejected-not-cancelled",
    "rejected-not-canceled",
    "",
  ];

  const statusStyleNameWithDash = trip ? trip.status?.replace(/ /g, "-") : "";
  const statusStyleName = statusList.includes(statusStyleNameWithDash)
    ? statusStyleNameWithDash
    : "unknown";

  const STATUS_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.color
      : "",
    borderColor: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.borderColor
      : "",
    background: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.background
      : "",
  });

  const status =
    trip?.status === "rejected not canceled" ||
    trip?.status === "rejected not cancelled"
      ? "rejected"
      : trip?.status;

  const tripName = composeTripName(trip, t("tripDetails.tripTo"));

  const getTitle = () => {
    const tripTitle = t("tripDetails.trip");
    const tripStatus = t(`tripDetails.${status}`);
    return `${tripTitle} ${tripStatus}`;
  };

  const getStatus = () => {
    if (status !== null && status !== "") {
      return (
        <Text
          className={Styles[`header--status-${statusStyleName}`]}
          content={t(`tripDetails.${status}`)}
          aria-hidden
          styles={STATUS_STYLE}
        />
      );
    }
    return null;
  };

  return (
    <div className={Styles.header} data-testid="trip-details-header">
      <Responsive forRange="mobile">
        <Text
          className={Styles.header__titleMobile}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.upcomingTrips?.header.title,
          })}
          title={tripName}
        >
          {tripName}
        </Text>
      </Responsive>
      <Responsive forMinSize="tablet">
        <Text
          className={Styles.header__title}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.upcomingTrips?.header.title,
          })}
          title={tripName}
        >
          <h2>{tripName}</h2>
        </Text>
      </Responsive>
      <div className={Styles["header__date-and-status-container"]}>
        <div className={Styles.header__date}>
          <DateComponent start_date={trip.startDate} end_date={trip.endDate} />
        </div>
        <div title={getTitle()}>{getStatus()}</div>
      </div>
    </div>
  );
}

export default Header;
