/* eslint-disable import/no-cycle */
import { getFormatTime } from "./shareMyRideUtils";

export function mapPeopleSharing(
  peopleSharing: any,
  usersGraph: any,
  usersPhotosGraph: any,
  usersPresenceGraph: any
) {
  return peopleSharing.map((personSharing: any) => {
    const graphUser = usersGraph.find(
      (element: any) => element.id === personSharing.user
    );
    const graphPhoto = usersPhotosGraph.find(
      (element: any) => element.id === personSharing.user
    );
    const graphPresence = usersPresenceGraph.find(
      (element: any) => element.id === personSharing.user
    );

    return {
      ...personSharing,
      graphUser,
      graphPhoto,
      graphPresence,
    };
  });
}

function getSharingPeople(
  peopleSharing: any,
  usersGraph: any,
  usersPhotosGraph: any,
  usersPresenceGraph: any,
  product: any
) {
  const allPeopleSharingData = mapPeopleSharing(
    peopleSharing,
    usersGraph,
    usersPhotosGraph,
    usersPresenceGraph
  );

  return allPeopleSharingData.map((userData: any) => ({
    user: {
      id: userData.graphUser.body.id,
      name: userData.graphUser.body.displayName,
      photo:
        userData.graphPhoto.status === 200
          ? `data:image/jpeg;base64,${userData.graphPhoto.body}`
          : "",
      status:
        userData.graphPresence.status === 200
          ? {
              activity:
                userData.graphPresence.body.activity === "OffWork"
                  ? "Offline"
                  : userData.graphPresence.body.activity,
              availability: userData.graphPresence.body.availability,
            }
          : null,
      mail: userData.graphUser.body.mail,
    },
    flight: {
      flightReference: userData.segment.reference,
      terminal: userData.segment.locationAdditionalInfo,
      date: getFormatTime(userData.segment.date),
      sameFlight: product.productDetails.flightReference
        ? userData.segment.reference ===
            product.productDetails.flightReference &&
          userData.segment.date === product.date
        : userData.segment.reference ===
            `${product.productDetails.serviceProvider} ${product.productDetails.vehicleNumber}` &&
          userData.segment.date === product.date,
    },
  }));
}

export default getSharingPeople;
