import lodash from "lodash";
import AirCityModel from "../models/AirSearch";
import SearchModel from "../models/SearchModel";

function GetNewAirValues(
  airState: SearchModel | undefined,
  state: AirCityModel
) {
  let newState;
  if (state.iatacode && state.iatacode !== "") {
    const copyState = lodash.cloneDeep(airState);
    newState = airState as SearchModel;
    if (copyState?.departure && copyState?.arrival) {
      const departures = copyState?.departure;
      const arrivals = copyState?.arrival;
      if (departures[0] === arrivals[arrivals.length - 1]) {
        departures[0] = state.iatacode;

        arrivals[arrivals.length - 1] = state.iatacode;
      } else {
        departures[0] = state.iatacode;
      }

      newState = {
        ...airState,
        ...copyState,
        departure: departures,
        arrival: arrivals,
      };
    }
  }
  return newState;
}

export default GetNewAirValues;
