import { Flex, List, Text } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../../../store/StoreHooks";
import { selectFeatureToggle } from "../../../../../../store/feature-toggles/FeatureTogglesSelector";
import { getProductsByType } from "../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors";
import returnKeepSession from "../../../../../../utils/KeepSessionFunctions";
import { FeatureToggleDefinition } from "../../../../../../utils/constants";
import {
  TRIP_PRODUCT_CAR,
  TRIP_PRODUCT_HOTEL,
} from "../../../../data/constants";
import CheckFeatureEnabled from "../../CheckFeatureEnabled";
import { ITabContentProps } from "./SegmentTabs.model";
import Styles from "./SegmentTabs.module.scss";
import Car from "./Tabs/Car/Car";
import Flight from "./Tabs/Flight/Flight";
import Hotel from "./Tabs/Hotel/Hotel";
import Rail from "./Tabs/Train/Rail";

function TabContent({ id, activeTab, children }: ITabContentProps) {
  return activeTab === id ? (
    <Flex column className={Styles.gapSegments}>
      {children}
    </Flex>
  ) : null;
}

function SegmentTabs() {
  const trip = useAppSelector((state) => state.tripDetail.trip);
  const hotels = useAppSelector((state) =>
    getProductsByType(state, TRIP_PRODUCT_HOTEL)
  );
  const cars = useAppSelector((state) =>
    getProductsByType(state, TRIP_PRODUCT_CAR)
  );
  const history = useHistory();
  const param = history.location.state;
  const previousSessionObject = returnKeepSession();
  const pastTrip: any =
    previousSessionObject && previousSessionObject.value
      ? previousSessionObject.value[0].pastTrip
      : param === "past";

  const featureToggleTrain = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.addRailElementTrip.id)
  );
  const featureToggleCar = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.addCarElementTrip.id)
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.tripDetails",
  });

  const getItemsByTime = () => {
    const products = trip?.products?.[0]?.products as any;
    let arrayOfProducts: { type: string; date: any }[] = [];
    if (products && products.length > 0) {
      products.forEach((element: any) => {
        if (element.productType === "Trip_Product_Air") {
          const data = { type: "flight", date: element.departure?.dateTime };
          arrayOfProducts.push(data);
        }
        if (element.productType === "Trip_Product_Train") {
          const data = { type: "train", date: element.departureDateTime };
          arrayOfProducts.push(data);
        }
        if (element.productType === "Trip_Product_Hotel") {
          const data = { type: "hotel", date: element.checkInDate };
          arrayOfProducts.push(data);
        }
        if (element.productType === "Trip_Product_Car") {
          const data = { type: "car", date: element.pickup?.dateTime };
          arrayOfProducts.push(data);
        }
      });
      arrayOfProducts.sort(
        (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
      );
      arrayOfProducts = arrayOfProducts.filter(
        (obj, pos, arr) =>
          arr.map((mapObj) => mapObj.type).indexOf(obj.type) === pos
      );
    }
    return arrayOfProducts;
  };

  const addElementsToList = (items: any[]) => {
    const listOfProductTabs: {
      id: string;
      title: string;
      content: JSX.Element;
    }[] = [];

    if (items.length > 0) {
      items.forEach((item: any) => {
        if (item.type === "flight") {
          listOfProductTabs.push({
            id: "tab1",
            title: t("Flight"),
            content: <Flight pastTrip={pastTrip} />,
          });
        }
        if (item.type === "train") {
          listOfProductTabs.push({
            id: "tab2",
            title: t("Train"),
            content: <Rail pastTrip={pastTrip} />,
          });
        }
        if (item.type === "hotel") {
          listOfProductTabs.push({
            id: "tab3",
            title: t("Hotel"),
            content: <Hotel pastTrip={pastTrip} />,
          });
        }
        if (item.type === "car") {
          listOfProductTabs.push({
            id: "tab4",
            title: t("Car"),
            content: <Car pastTrip={pastTrip} />,
          });
        }
      });
    }

    return listOfProductTabs;
  };

  const getListOfProductsWithoutFeature = () => {
    const listOfProductTabs = [];
    if (trip?.products?.[0]?.hasAir) {
      listOfProductTabs.push({
        id: "tab1",
        title: t("Flight"),
        content: <Flight pastTrip={pastTrip} />,
      });
    }
    if (trip?.products?.[0]?.hasRail) {
      listOfProductTabs.push({
        id: "tab2",
        title: t("Train"),
        content: <Rail pastTrip={pastTrip} />,
      });
    }
    if (hotels?.length) {
      listOfProductTabs.push({
        id: "tab3",
        title: t("Hotel"),
        content: <Hotel pastTrip={pastTrip} />,
      });
    }
    if (cars?.length) {
      listOfProductTabs.push({
        id: "tab4",
        title: t("Car"),
        content: <Car pastTrip={pastTrip} />,
      });
    }
    return listOfProductTabs;
  };

  const tabListnotEmpty = () => {
    let listOfProductTabs = [];
    if (CheckFeatureEnabled() === true && !pastTrip) {
      const items = getItemsByTime();

      listOfProductTabs = addElementsToList(items);

      if (!trip?.products?.[0]?.hasAir) {
        listOfProductTabs.push({
          id: "tab1",
          title: t("Flight"),
          content: <Flight pastTrip={pastTrip} />,
        });
      }
      if (!trip?.products?.[0]?.hasRail && featureToggleTrain?.isActive) {
        listOfProductTabs.push({
          id: "tab2",
          title: t("Train"),
          content: <Rail pastTrip={pastTrip} />,
        });
      }
      if (!hotels?.length) {
        listOfProductTabs.push({
          id: "tab3",
          title: t("Hotel"),
          content: <Hotel pastTrip={pastTrip} />,
        });
      }
      if (!cars?.length && featureToggleCar?.isActive) {
        listOfProductTabs.push({
          id: "tab4",
          title: t("Car"),
          content: <Car pastTrip={pastTrip} />,
        });
      }
    } else {
      listOfProductTabs = getListOfProductsWithoutFeature();
    }

    return listOfProductTabs;
  };

  const tabList = tabListnotEmpty();

  const getActiveTab = () => {
    const active = localStorage.getItem("tripDetailTab");
    if (active !== null && active !== "") {
      return active;
    }
    return tabList[0].id;
  };
  const getSelectedTab = () => {
    const active = localStorage.getItem("tripDetailTab");
    if (active !== null && active !== "") {
      return tabList.findIndex((element: any) => element.id === active);
    }
    return 0;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab);
  const [selectedIndex, setSelectedIndex] = useState(getSelectedTab);

  useEffect(() => {
    localStorage.setItem("tripDetailTab", activeTab);
  }, [activeTab]);

  const getColorTheme = (tabNavItem: any, id: string) => {
    if (tabNavItem) {
      return tabNavItem[activeTab === id ? "tabActive" : "tabInactive"];
    }
    return "";
  };

  const TabNavItem = () => {
    const items: { key: string; content: JSX.Element }[] = [];
    tabList.forEach(({ id, title }) => {
      const element = {
        key: id,
        content: (
          <Text
            className={
              Styles[
                `tab-nav__button${activeTab === id ? "--active" : "--inactive"}`
              ]
            }
            styles={(theme) => ({
              color: getColorTheme(theme.theme.siteVariables.tabNavItem, id),
              borderBottomColor: getColorTheme(
                theme.theme.siteVariables.tabNavItem,
                id
              ),
            })}
            content={title}
          />
        ),
      };
      items.push(element);
    });
    return items;
  };

  return (
    <>
      <List
        selectable
        selectedIndex={selectedIndex}
        onSelectedIndexChange={(_e, newProps) => {
          if (newProps?.selectedIndex !== undefined) {
            setActiveTab(tabList[newProps?.selectedIndex].id);
            setSelectedIndex(newProps?.selectedIndex);
          }
        }}
        data-testid="nav"
        aria-label="segment list"
        items={TabNavItem()}
        horizontal
        className={Styles[`tab-nav-background`]}
      />

      {tabList.map(({ id, content }) => (
        <TabContent key={id} id={id} activeTab={activeTab}>
          {content}
        </TabContent>
      ))}
    </>
  );
}
export default SegmentTabs;
