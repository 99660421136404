import { createAsyncThunk } from "@reduxjs/toolkit";
import { IError } from "../../common/models/Error";
import Trip from "../../common/models/Trip";
import TripListService from "../../services/trip-list/TripListService";
import returnTripProduct from "./functions/ReturnTripProduct";

/*
  Actions to apply logic and dispatch to the correct reducer
  The Middleware and the actions are useful if there is some asynchronous process , like loading data
 */
export const TRIPS_STORE_NAME = "trips";
export const FETCH_TRIPS = "fetchUserTrips";

export const fetchUserTrips = createAsyncThunk(
  `${TRIPS_STORE_NAME}/${FETCH_TRIPS}`,
  async (data, { rejectWithValue }) => {
    const defaultError: IError = {
      code: 100,
      status: 500,
      title: "Something went wrong",
      detail: "We couldn't gather the information. Please, try again.",
    };

    try {
      const response = await TripListService.getTrips();
      const tripArray = new Array<Trip>();

      if (response) {
        const responseData = response.data.data || response.data;

        if (Array.isArray(responseData)) {
          responseData.forEach((ele: any) => {
            const trip = returnTripProduct(ele);
            tripArray.push(trip);
          });
        } else {
          const trip = returnTripProduct(responseData);
          tripArray.push(trip);
        }

        return tripArray;
      }
      return tripArray;
    } catch (error: any) {
      return rejectWithValue(defaultError);
    }
  }
);
