/* eslint-disable react/require-default-props */
import { Flex, RadioGroup, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import FeatureToggle from "../../../../../common/models/FeatureToggle";
import { isPunchOut } from "../PunchOutLogic";
import { BookingTargets } from "../model/Permissions";
import Styles from "./DialogOptions.module.scss";

interface IOptions {
  type: string;
  permissions?: BookingTargets[];
  setValue: (text: string) => void;
  punchOutFeature: FeatureToggle | undefined;
}

const GetItemsFromType = (
  type: string,
  punchOutFeature: FeatureToggle | undefined,
  permissions?: BookingTargets[]
) => {
  const radioObjects = [] as any;
  const { t } = useTranslation();

  if (type === "Train") {
    if (permissions) {
      permissions.forEach((element: BookingTargets) => {
        radioObjects.push({
          name: "Rail",
          key: element.title,
          label: isPunchOut(element.type, punchOutFeature) ? (
            <div>
              <Text content={element.title} />
              <Text
                className={Styles.screenReaderOnly}
                content={`. ${t(
                  "upcoming_trips_dashboard.upcomingTrips.newBook.externalLink"
                )}`}
              />
            </div>
          ) : (
            element.title
          ),
          value: element.type,
        });
      });
    }
  }

  return radioObjects;
};

function DialogOptions({
  type,
  permissions,
  setValue,
  punchOutFeature,
}: IOptions) {
  const { t } = useTranslation();

  return (
    <Flex column gap="gap.medium">
      <Text
        content={t(
          "upcoming_trips_dashboard.upcomingTrips.newBook.dialog.typeSelected"
        )}
      />
      <Flex
        className={Styles.mainOptions}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background2,
          borderColor: theme.theme.siteVariables?.colorScheme?.default.border3,
        })}
      >
        <Text content={type} />
      </Flex>
      <Flex>
        <Text
          content={t(
            "upcoming_trips_dashboard.upcomingTrips.newBook.dialog.selectOptionRail"
          )}
        />
      </Flex>
      <Flex>
        <RadioGroup
          vertical
          className={Styles.RadioGroup}
          items={GetItemsFromType(type, punchOutFeature, permissions)}
          onCheckedValueChange={(_e: any, val: any) => setValue(val.value)}
        />
      </Flex>
    </Flex>
  );
}

export default DialogOptions;
