import GetChannelsRecipients from "./GetChannelsRecipients";
import GetGroupsRecipients from "./GetGroupsRecipients";
import getHitsRecipients from "./GetHitsRecipients";
import getUsersRecipients from "./GetUsersRecipients";

export default function getAllItems(
  searchItems: any[],
  hits: any,
  users: any,
  groups: any,
  channels: any,
  setRecipientGroupsSelected?: any,
  setRecipientChannelsSelected?: any
) {
  let copyItems = searchItems;

  copyItems = getHitsRecipients(copyItems, hits);
  copyItems = getUsersRecipients(copyItems, users, hits || []);

  if (setRecipientGroupsSelected) {
    copyItems = GetGroupsRecipients(copyItems, groups);
  }

  if (setRecipientChannelsSelected) {
    copyItems = GetChannelsRecipients(copyItems, channels);
  }

  return copyItems;
}
