/* eslint-disable import/prefer-default-export */

import DateUtils from "../../../utils/date.utils";
import { RootState } from "../../StoreHooks";

export const selectTripsV1SortedByDepartureDate = (state: RootState) => {
  const { trips } = state.tripsV1 || {};

  return [...trips].sort(
    (a, b): number =>
      DateUtils.getDateInMiliseconds(a.start.localDateTime) -
      DateUtils.getDateInMiliseconds(b.start.localDateTime)
  );
};
