/* eslint-disable no-template-curly-in-string */
const shareJoinCard = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.0",
  body: [
    {
      type: "Container",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "TextBlock",
                  text: "${$root.meta.translations.title}",
                  size: "Large",
                  weight: "Bolder",
                },
                {
                  type: "TextBlock",
                  text: "${$root.meta.translations.subtitle}",
                  spacing: "None",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      spacing: "ExtraLarge",
      separator: true,
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "Image",
                  url: "${trip.destinationPicture[1]}",
                  altText: "${trip.name}",
                  size: "Large",
                  width: "48px",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              $when: "${!trip.oneWayFlight}",
              items: [
                {
                  type: "TextBlock",
                  text: "${trip.name}",
                  weight: "Bolder",
                  size: "Large",
                  wrap: true,
                },
                {
                  type: "TextBlock",
                  text: "${trip.startDate} - ${trip.endDate}",
                  spacing: "None",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              $when: "${trip.oneWayFlight}",
              items: [
                {
                  type: "TextBlock",
                  text: "${trip.name}",
                  weight: "Bolder",
                  size: "large",
                  wrap: true,
                },
                {
                  type: "TextBlock",
                  text: "${trip.endDate}",
                  spacing: "None",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Container",
      separator: true,
      spacing: "Medium",
      items: [
        {
          type: "Container",
          $data: "${trip.products[0].products}",
          items: [
            {
              type: "Container",
              $when: "${Trip_Product_Air != null}",
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Air.departure.dateTime, 'HH:mm')} ${Trip_Product_Air.departure.iataCode}",
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Air.departureDateFormatted}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Air.departure.airportName}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "40px",
                      items: [
                        {
                          type: "Image",
                          url: "${$root.meta.flightIcon}",
                          altText: "Flight to",
                          width: "16px",
                          spacing: "None",
                          horizontalAlignment: "Center",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Air.arrival.dateTime, 'HH:mm')} ${Trip_Product_Air.arrival.iataCode}",
                          wrap: true,
                          weight: "Bolder",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Air.arrivalDateFormatted}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Air.arrival.airportName}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "TextBlock",
                  size: "Small",
                  text: "${Trip_Product_Air.customInfo.numberOfStops}${Trip_Product_Air.customInfo.timeAndName} | ${Trip_Product_Air.customInfo.nameAndNumber}",
                  wrap: true,
                },
              ],
            },
            {
              type: "Container",
              $when: "${Trip_Product_Hotel != null}",
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: 3,
                      items: [
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Hotel.name}",
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Hotel.checkInDateFormatted } - ${Trip_Product_Hotel.checkOutDateFormatted}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Hotel.hotelNights}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "ColumnSet",
                          width: "stretch",
                          spacing: "Small",
                          columns: [
                            // {
                            //   type: "Column",
                            //   width: "14px",
                            //   items: [
                            //     {
                            //       type: "Image",
                            //       url: "${$root.meta.coffeeIcon}",
                            //       altText:
                            //         "${Trip_Product_Hotel.breakfastInfo}",
                            //       width: "14px",
                            //     },
                            //   ],
                            // },
                            {
                              type: "Column",
                              width: "auto",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${Trip_Product_Hotel.breakfastInfo}",
                                  wrap: true,
                                  size: "Small",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      verticalContentAlignment: "Center",
                    },
                  ],
                },
              ],
            },
            {
              type: "Container",
              $when: "${Trip_Product_Car != null}",
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: 4,
                      items: [
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Car.serviceProviderName}",
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Car.pickupDateFormatted} - ${Trip_Product_Car.dropoffDateFormatted}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${$root.meta.translations.pickUp} ${Trip_Product_Car.pickup.location.name}",
                          wrap: true,
                          size: "Small",
                        },
                        {
                          type: "TextBlock",
                          text: "${$root.meta.translations.dropOff} ${Trip_Product_Car.dropoff.location.name}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                      ],
                      verticalContentAlignment: "Center",
                    },
                  ],
                },
              ],
            },
            {
              type: "Container",
              $when: "${Trip_Product_Train != null}",
              style: "emphasis",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Train.departureDateTime, 'HH:mm')}",
                          wrap: true,
                          weight: "Bolder",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Train.departureDateFormatted}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Train.departure.name}",
                          wrap: true,
                          spacing: "None",
                          size: "Small",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "40px",
                      items: [
                        {
                          type: "Image",
                          url: "${$root.meta.railIcon}",
                          altText: "Train to",
                          width: "14px",
                          spacing: "None",
                          horizontalAlignment: "Center",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${formatDateTime(Trip_Product_Train.arrivalDateTime, 'HH:mm')}",
                          wrap: true,
                          weight: "bolder",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Train.arrivalDateFormatted}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                        {
                          type: "TextBlock",
                          text: "${Trip_Product_Train.arrival.name}",
                          wrap: true,
                          size: "Small",
                          spacing: "None",
                          horizontalAlignment: "Right",
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "TextBlock",
                  text: "${Trip_Product_Train.customInfo.numberOfStops}${Trip_Product_Train.customInfo.timeAndName} | ${Trip_Product_Train.customInfo.nameAndNumber}",
                  wrap: true,
                  size: "Small",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "TextBlock",
      text: "${$root.meta.translations.footer}",
      wrap: true,
      size: "Small",
    },
  ],
};

export default shareJoinCard;
