/* eslint-disable react/require-default-props */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Dropdown } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../common/models/redirection/RedirectionTypeEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/StoreHooks";
import { selectFeatureToggle } from "../../../../../store/feature-toggles/FeatureTogglesSelector";
import { FeatureToggleDefinition } from "../../../../../utils/constants";
import HostSettings from "../../../../../utils/host.settings";
import { BookingTargets, SegmentType } from "../model/Permissions";
import styles from "./BookButtons.module.scss";
import BookRedirection from "./BookRedirection";

interface IBookCarProps {
  permissions?: SegmentType[];
}

function BookCarButton({ permissions }: IBookCarProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const featureTravelTab = useAppSelector((state) =>
    selectFeatureToggle(state, FeatureToggleDefinition.deprecateCytricTab.id)
  );

  const carButton = t(
    "upcoming_trips_dashboard.upcomingTrips.newBook.CarTransfer"
  );

  const [value] = useState("");
  const [carItems, setCarItems] = useState<string[]>([]);
  const [carTypes, setCarTypes] = useState<string[]>([]);
  const [carPermissions, setCarPermissions] = useState<BookingTargets[]>([]);
  const [carName, setCarName] = useState("");
  const [transferName, setTransferName] = useState("");
  const [showValue, setShowValue] = useState(carButton);

  const carTraduction = t("upcoming_trips_dashboard.upcomingTrips.newBook.Car");
  const transferTraduction = t(
    "upcoming_trips_dashboard.upcomingTrips.newBook.Transfer"
  );

  useEffect(() => {
    if (permissions) {
      const getCarPermissions = permissions.filter(
        (elements: SegmentType) => elements.type === "CAR"
      )[0].bookingTargets;

      const activatedCarPermissions = getCarPermissions?.filter(
        (elements: BookingTargets) =>
          elements.activated === true &&
          (elements.type === "CAR_BOOKING" ||
            elements.type === "CAR_BOOKING_MY_DRIVER")
      );

      const carItemss: string[] = [];
      const carTypess: string[] = [];

      activatedCarPermissions?.forEach((element: BookingTargets) => {
        carItemss.push(element.title);
        carTypess.push(element.type);
        if (element.type === "CAR_BOOKING") {
          setCarName(element.title);
        }
        if (element.type === "CAR_BOOKING_MY_DRIVER") {
          setTransferName(element.title);
        }
      });

      if (activatedCarPermissions) {
        setCarPermissions(activatedCarPermissions);
      }
      setCarItems(carItemss);
      setCarTypes(carTypess);
    }
  }, [permissions]);

  const bookCar = async (val?: string) => {
    telemetryService.trackEvent({ name: "travel-trip-bookCar" });
    let param = "";
    if (val) {
      param = val;
    }
    if (value !== "") {
      param = value;
    } else if (carPermissions.length === 1) {
      const company = carPermissions[0].type;
      param = company;
    }

    const deeplinkData = {
      segmentType: RedirectionTypeEnum.CAR_CARD,
      bookingService: param,
      stopOnStartPage: true,
    };

    if (featureTravelTab?.isActive) {
      BookRedirection(t, dispatch, history, deeplinkData);
    } else {
      const urlEncodedDeepLinkData = {
        subEntityId: JSON.stringify(deeplinkData),
      };

      const deepLink = `https://teams.microsoft.com/l/entity/${
        HostSettings.teamsAppId
      }/cytricweb?context=${encodeURI(JSON.stringify(urlEncodedDeepLinkData))}`;

      app.openLink(deepLink);
    }
  };

  const getCarValue = (_e: any, val: any) => {
    if (val.searchQuery !== "") {
      const realTitle =
        val.searchQuery === carTraduction ? carName : transferName;
      const carValue = carPermissions.filter(
        (element: BookingTargets) => element.title === realTitle
      )[0].type;
      bookCar(carValue);
    }
    setShowValue(carButton);
  };

  const titleTraduction = (title: string) => {
    if (title === carName) {
      return carTraduction;
    }
    if (title === transferName) {
      return transferTraduction;
    }
    return title;
  };

  const carButtonDisplay = () => {
    if (
      carTypes.includes("CAR_BOOKING") &&
      !carTypes.includes("CAR_BOOKING_MY_DRIVER")
    ) {
      return carTraduction;
    }
    if (
      carTypes.includes("CAR_BOOKING_MY_DRIVER") &&
      !carTypes.includes("CAR_BOOKING")
    ) {
      return transferTraduction;
    }
    return carButton;
  };

  const showCorrectElement = () => {
    if (carPermissions.length === 1) {
      return (
        <Button
          aria-label={carButtonDisplay()}
          className={styles.buttons}
          content={carButtonDisplay()}
          data-showValueid="button-card-book-car"
          data-testid="button-card-book-car"
          flat
          name={carButtonDisplay()}
          onClick={async () => bookCar()}
          role="button"
          tabIndex={0}
        />
      );
    }
    if (carPermissions.length >= 2) {
      return (
        <Dropdown
          inverted
          styles={(theme) => ({
            borderColor: theme.theme.siteVariables?.colorScheme?.default.border,
            backgroundColor:
              theme.theme.siteVariables?.colorScheme?.default.background2,
          })}
          className={styles.dropDown}
          items={carItems.map(titleTraduction)}
          data-showValueid="button-card-book-car"
          data-testid="button-card-book-car"
          placeholder={carButtonDisplay()}
          onSearchQueryChange={getCarValue}
          value={showValue}
        />
      );
    }
    return null;
  };

  return showCorrectElement();
}

export default BookCarButton;
