import { Open16Regular } from "@fluentui/react-icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Layout,
  Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../../utils/host.settings";

import TripApprovalStyles from "../../TripApproval.module.scss";

function TripApprovalPageContainer({
  themeStyle,
  themeSuffix,
  defaultTheme,
  isMobile,
  isMobileOrTablet,
  handleCancel,
  redirectTripApprovalPage,
}: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: "tripApproval",
  });
  return (
    <div className={[themeStyle, TripApprovalStyles.content].join(" ")}>
      <Flex className={TripApprovalStyles.centerContainer}>
        <Layout
          className={TripApprovalStyles.layout}
          renderMainArea={() => (
            <Image
              src={`${HostSettings.getCDNIcons}paper-airplane-${themeSuffix}.png`}
              className={TripApprovalStyles.img}
            />
          )}
        />
        <Flex
          className={[themeStyle, TripApprovalStyles.textContainer].join(" ")}
        >
          <Text
            data-testid="trip-approval-title"
            className={TripApprovalStyles.title}
            content={t("title")}
          />
          <Text
            className={TripApprovalStyles.description}
            content={t("description")}
          />
        </Flex>
      </Flex>
      {defaultTheme && !(isMobile || isMobileOrTablet) && (
        <Box className={TripApprovalStyles.divider} />
      )}
      <Flex className={TripApprovalStyles.footer}>
        <Flex className={TripApprovalStyles.buttons}>
          <Button
            className={TripApprovalStyles.cancel}
            data-testid="trip-approval-cancel-button"
            content={t("button.cancel")}
            onClick={() => {
              handleCancel();
            }}
          />
          <Button
            className={[themeStyle, TripApprovalStyles.continue].join(" ")}
            data-testid="trip-approval-continue-button"
            content={t("button.continue")}
            iconPosition="before"
            icon={<Open16Regular />}
            size="medium"
            primary
            onClick={() => {
              redirectTripApprovalPage();
            }}
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default TripApprovalPageContainer;
