/* eslint-disable no-unneeded-ternary */
import {
  Box,
  Button,
  ComponentStyleFunctionParam,
  Flex,
  FormDatepicker,
  Input,
  Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../common/components/responsive/useScreenSize";
import Styles from "./DialogBody.module.scss";

const MAX_TITLE_LENGTH = 75;

function DialogBody({
  dispatchDialog,
  data,
  taskHandler,
  confirmButtonText,
}: any) {
  const { t } = useTranslation();

  const [taskTitle, setTaskTitle] = useState("");
  const [taskDueDate, setTaskDueDate] = useState<Date>();
  const [calendarIsOpened, setCalendarIsOpened] = useState(false);

  useEffect(() => {
    setTaskTitle(data?.title || "");
    setTaskDueDate(data?.dueDate || "");
  }, [data]);

  const taskTitleInputHandler = (e: any) => {
    setTaskTitle(e.target.value);
  };

  const datePickerHandler = (value: any) => {
    if (value) {
      const formattedDueDate = new Date(moment(value).format("YYYY-MM-DD"));
      setTaskDueDate(formattedDueDate);
    } else {
      setTaskDueDate(undefined);
    }
  };

  const screenSize = useScreenSize();
  const backgroundColorResponsive =
    screenSize < 640 ? "greyBackground" : "transparent";

  const INPUT_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables.toDoList?.dialog?.label
      ? theme.theme.siteVariables.toDoList?.dialog?.label
      : "",
    borderColor: theme.theme.siteVariables.toDoList?.dialog?.borderColor
      ? theme.theme.siteVariables.toDoList?.dialog?.borderColor
      : "",
  });

  const handleSpaceForCalendar = () => {
    setCalendarIsOpened(!calendarIsOpened);
  };

  const contentWrapperClasses = calendarIsOpened
    ? `${Styles["content-wrapper"]} ${Styles["content-wrapper--space-for-calendar"]}`
    : Styles["content-wrapper"];

  const dateStrings: any = t("datePicker", { returnObjects: true });

  return (
    <>
      <Box
        data-testid="content-wrapper--to-do-dialog"
        as="div"
        className={contentWrapperClasses}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables?.toDoList?.dialog[
              backgroundColorResponsive
            ],
        })}
        aria-hidden
        onClick={() => {
          if (calendarIsOpened) {
            setCalendarIsOpened(false);
          }
        }}
      >
        <div className={Styles["dialog-body"]}>
          <Box
            as="div"
            data-testid="to-do-dialog-body-image"
            className={Styles["dialog-body__image"]}
            styles={(theme) => ({
              background: theme.theme.siteVariables.toDoList?.imageMobile
                ? theme.theme.siteVariables.toDoList?.imageMobile
                : "",
            })}
            aria-hidden
          />
          <div className={Styles["dialog-body__form"]}>
            <Input
              fluid
              data-testid="to-do-dialog-input-task-name"
              label={t("toDo.dialog.taskName")}
              name="taskName"
              id="task-name"
              placeholder={t("toDo.dialog.addNameForYourTask")}
              showSuccessIndicator={false}
              className={Styles["dialog-body__form--input"]}
              styles={INPUT_STYLE}
              onChange={(e) => taskTitleInputHandler(e)}
              maxLength={MAX_TITLE_LENGTH}
              aria-label={`${t(
                "toDo.dialog.accessibleTaskNamePart1"
              )} ${MAX_TITLE_LENGTH} ${t(
                "toDo.dialog.accessibleTaskNamePart2"
              )}`}
              value={taskTitle}
            />
            <Text
              as="p"
              className={Styles["dialog-body__form--max-input-length"]}
              styles={INPUT_STYLE}
            >
              {taskTitle.length}/{MAX_TITLE_LENGTH}
            </Text>
            <Flex className={Styles.flex}>
              <div className={Styles["flex--item"]}>
                <Flex column>
                  <FormDatepicker
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dateStrings}
                    label={t("toDo.dialog.setDueDate")}
                    className={Styles["dialog-body__form--date-picker"]}
                    inputPlaceholder={t("toDo.dialog.selectADate")}
                    aria-label={t("toDo.dialog.selectADate")}
                    styles={INPUT_STYLE}
                    onDateChange={(_, value) => {
                      datePickerHandler(value?.value);
                    }}
                    onClick={handleSpaceForCalendar}
                    selectedDate={
                      taskDueDate ? new Date(taskDueDate) : undefined
                    }
                    minDate={new Date()}
                  />
                </Flex>
              </div>
            </Flex>
          </div>
        </div>
      </Box>
      <div className={Styles["dialog-button-container"]}>
        <Button
          className={Styles["dialog-button-container__button"]}
          content={t("toDo.dialog.cancel")}
          onClick={() => dispatchDialog()}
        />
        <Button
          className={Styles["dialog-button-container__button"]}
          content={confirmButtonText}
          data-testid="confirm-button"
          primary
          onClick={() => taskHandler(taskTitle, taskDueDate, data)}
          disabled={taskTitle === ""}
          aria-label={
            taskTitle === ""
              ? t("toDo.dialog.accessibleNewTask")
              : confirmButtonText
          }
        />
      </div>
    </>
  );
}

export default DialogBody;
