/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enableAll: false,
};

const shareTransferEnableAllSlice = createSlice({
  name: "shareTransferEnableAll",
  initialState,
  reducers: {
    setEnableAll(state, action) {
      state.enableAll = action.payload;
    },
  },
});

export const shareTransferEnableAllActions =
  shareTransferEnableAllSlice.actions;

export default shareTransferEnableAllSlice.reducer;
