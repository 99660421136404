import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TravelTripIdModel {
  tripId: string;
}

const INITIAL_STATE: TravelTripIdModel = {
  tripId: "",
};

export const travelTripIdSlice = createSlice({
  name: "travelTripId",
  initialState: INITIAL_STATE,
  reducers: {
    updateTravelTripId: (
      state,
      action: PayloadAction<{
        tripId: string;
      }>
    ) => ({
      ...state,
      tripId: action.payload.tripId,
    }),

    resetTravelTripId: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { updateTravelTripId, resetTravelTripId } =
  travelTripIdSlice.actions;

export default travelTripIdSlice.reducer;
