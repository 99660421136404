import FeatureToggle from "../../../../common/models/FeatureToggle";
import { BookingTargets, SegmentType } from "./model/Permissions";

const types = ["RAIL_BOOKING_RENFE"];

function checkPunchOuts(booking: BookingTargets) {
  return types.includes(booking.type)
    ? { ...booking, external: true }
    : booking;
}

function pushToArray(array: BookingTargets[], booking: BookingTargets) {
  if (booking) {
    array.push(checkPunchOuts(booking));
  }
  return array;
}

function rearrangRailData(
  bookingSegment: BookingTargets[],
  segment: SegmentType
) {
  segment.bookingTargets?.forEach((booking: BookingTargets) => {
    pushToArray(bookingSegment, booking);
  });
  return bookingSegment;
}

function rearrangeData(data: SegmentType[]): SegmentType[] {
  const result: SegmentType[] = [];
  data.forEach((segment: SegmentType) => {
    let bookingSegment: BookingTargets[] = [];
    if (segment.type === "RAIL") {
      bookingSegment = rearrangRailData(bookingSegment, segment);
    } else if (segment.bookingTargets) {
      bookingSegment = segment.bookingTargets;
    }
    result.push({ type: segment.type, bookingTargets: bookingSegment });
  });
  return result;
}

const flowsPermissionFixer = (
  originalSegments: SegmentType[],
  featureTogglePunchOuts: FeatureToggle | undefined
) => {
  if (featureTogglePunchOuts?.isActive) {
    return rearrangeData(originalSegments);
  }
  return originalSegments;
};

export default flowsPermissionFixer;

export const isPunchOut = (type: string, toggle: FeatureToggle | undefined) => {
  if (toggle?.isActive) {
    return types.includes(type);
  }
  return false;
};
