import HandleTravelCytricRedirection from "../../../../utils/HandleTravelCytricRedirection";

export default function BookingRedirections(
  t: any,
  dispatch: any,
  history: any,
  redirectionInput: any,
  tripId: string,
  name: string
) {
  const breadcrumbData = [
    {
      label: "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
      url: "/travel",
      translation: true,
    },
    {
      label: name,
      url: `/trip:${tripId}`,
      translation: false,
    },
    {
      label: "upcoming_trips_dashboard.breadcrumb.addBooking",
      url: "",
      translation: true,
    },
  ];
  HandleTravelCytricRedirection({ breadcrumbData, t, dispatch });

  history.push(
    `/cytricweb?context=${JSON.stringify(redirectionInput)}&type=travel`
  );
}
