import {
  Card,
  CardBody,
  cardSelectableBehavior,
  Flex,
  Image,
  Text,
} from "@fluentui/react-northstar";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { useTranslation } from "react-i18next";
import { FeatureToggleDefinition } from "../../../../utils/constants";
import HostSettings from "../../../../utils/host.settings";
import Styles from "./CardBookTripButtons.module.scss";
import CheckPermissions from "./CheckPermissions";
import BookCarButton from "./Components/BookCarButton";
import BookFlightButton from "./Components/BookFlightButton";
import BookHotelButton from "./Components/BookHotelButton";
import BookTrainButton from "./Components/BookTrainButton";
import SeeMoreOptions from "./Components/SeeMoreOptions";
import { SegmentType } from "./model/Permissions";
import OldCard from "./OldCard";

function NewCard(
  hasAnyOptions: boolean,
  themeName: string,
  permissions?: SegmentType[]
) {
  const { t } = useTranslation();

  if (hasAnyOptions) {
    return (
      <Card
        accessibility={cardSelectableBehavior}
        className={Styles.cardButtons}
        compact
        data-testid="newcard"
        fluid
        styles={(theme: any) => ({
          background: theme.theme.siteVariables.tripCard?.background,
          border: `2px dashed`,
        })}
        data-joyride-id="planATrip"
      >
        <CardBody className={Styles.cardBodyButtons}>
          <Image
            alt="upcoming_trips_dashboard.upcomingTrips.newBook.suitcaseImage"
            aria-hidden="true"
            className={Styles.imgActivedFT}
            data-testid="new-trip-card__img"
            id="new-trip-card__img"
            src={`${HostSettings.getCDNIcons}suitcase-${themeName}.svg`}
          />
          <Text
            className={Styles.title}
            content={t(
              "upcoming_trips_dashboard.upcomingTrips.newBook.planNextTrip"
            )}
          />
          <Text
            className={Styles.subtitle}
            content={t(
              "upcoming_trips_dashboard.upcomingTrips.newBook.selectBookingType"
            )}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground2,
            })}
          />
          <Flex hAlign="center" className={Styles.buttonContainer}>
            {CheckPermissions(permissions, "TRANSPORTATION") && (
              <BookFlightButton />
            )}
            {CheckPermissions(permissions, "RAIL") && (
              <Feature
                name={FeatureToggleDefinition.planATripExperienceTrainCar.id}
                activeComponent={() => BookTrainButton({ permissions })}
              />
            )}
            {CheckPermissions(permissions, "HOTEL") && <BookHotelButton />}
            {CheckPermissions(permissions, "CAR") && (
              <Feature
                name={FeatureToggleDefinition.planATripExperienceCar.id}
                activeComponent={() => BookCarButton({ permissions })}
              />
            )}
          </Flex>
          <SeeMoreOptions />
        </CardBody>
      </Card>
    );
  }
  return OldCard(true, themeName);
}
export default NewCard;
