import { breadcrumbActions } from "../store/breadcrumb/breadcrumb-slice";

export interface BreadcrumbData {
  label: string;
  url: string;
  translation: boolean;
}

interface HandleTravelCytricRedirectionProps {
  breadcrumbData: BreadcrumbData[];
  t: any;
  dispatch: any;
}

const HandleTravelCytricRedirection = ({
  breadcrumbData,
  t,
  dispatch,
}: HandleTravelCytricRedirectionProps) => {
  const array: any = [];

  breadcrumbData.forEach((data: any) => {
    array.push({
      label: data.translation ? t(data.label) : data.label,
      url: data.url,
    });
  });

  dispatch(breadcrumbActions.setTravelLevels(array));
  localStorage.setItem("breadCrumbInformation", JSON.stringify(breadcrumbData));
};

export default HandleTravelCytricRedirection;
