import { Flex, Text } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TripApprovalFooter from "../footer/TripApprovalFooter";
import CommentsContainer from "./CommentsContainer";
import ReasonsContainer from "./ReasonsContainer";
import WithCommentsStyles from "./WithCommentsContainer.module.scss";

function WithCommentsContainer({
  action,
  city,
  traveler,
  setComment,
  setReason,
  commentsOption,
  reasonsOption,
  rejectReasons,
  defaultReason,
  handleClick,
}: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: `tripApproval.comments`,
  });

  const [isCommentEmpty, setIsCommentEmpty] = useState(true);
  const [isReasonEmpty, setIsReasonEmpty] = useState(true);
  const [isDisable, setIsDisable] = useState(false);

  const getOption = (optionParam: string) => {
    let option = "";
    switch (optionParam) {
      case "OPTIONAL":
        option = "optional";
        break;
      case "MANDATORY":
        option = "required";
        break;
      default:
        option = "";
    }

    return option;
  };

  const getTitle = () => {
    let title = "";
    const travelerFirstName =
      traveler !== "" && traveler !== undefined ? traveler.split(" ")[0] : "";
    switch (action) {
      case "approve":
        title =
          travelerFirstName !== ""
            ? t("approve.title", { traveler: travelerFirstName, city })
            : t("approve.titleNoName", { city });
        break;
      case "reject":
        title =
          traveler !== "" && traveler !== undefined
            ? t("reject.title", { traveler: travelerFirstName, city })
            : t("reject.titleNoName", { city });
        break;
      default:
        title = "";
    }

    return title;
  };

  useEffect(() => {
    if (
      (getOption(commentsOption) === "required" && isCommentEmpty) ||
      (getOption(reasonsOption) === "required" && isReasonEmpty)
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [commentsOption, reasonsOption, isCommentEmpty, isReasonEmpty]);

  return (
    <div className={WithCommentsStyles.content}>
      <Flex
        column
        className={WithCommentsStyles.container}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background,
        })}
        id="display-comments"
      >
        <Text
          className={WithCommentsStyles.title}
          data-testid="comments-title"
          content={getTitle()}
          aria-label={getTitle()}
        />

        {action !== "approve" && reasonsOption !== "DONOTDISPLAY" && (
          <ReasonsContainer
            reasonsOption={getOption(reasonsOption)}
            rejectReasons={rejectReasons}
            setReason={setReason}
            setIsReasonEmpty={setIsReasonEmpty}
            defaultReason={defaultReason}
          />
        )}

        {commentsOption !== "DONOTDISPLAY" && (
          <CommentsContainer
            action={action}
            commentsOption={getOption(commentsOption)}
            setComment={setComment}
            setIsCommentEmpty={setIsCommentEmpty}
          />
        )}
      </Flex>
      <TripApprovalFooter
        action={action}
        isDisable={isDisable}
        handleClick={handleClick}
      />
    </div>
  );
}

export default WithCommentsContainer;
