export const COACHMARK = {
  pinTheApp: "pinTheApp",
  planATrip: "planATrip",
  tripCard: "tripCard",
  shareTransfer: "shareTransfer",
  shareTransferEnableAll: "shareTransferEnableAll",
  closeCollaborators: "closeCollaborators",
  notifyCloseCollaborators: "notifyCloseCollaborators",
  expenseTab: "expenseTab",
};

export const COACHMARK_FLOW = {
  AUTO_TRIGGERED: "autoTriggeredFlow",
  TAKE_A_TOUR: "takeATourFlow",
  CLOSE_COLLABORATORS: "closeCollaboratorsFlow",
  SHARE_TRANSFER_ENABLE_ALL: "shareTransferEnableAllFlow",
  NOTIFY_CLOSE_COLLABORATORS: "notifyCloseCollaboratorsFlow",
};

export default {};
