/* eslint-disable no-plusplus */
import {
  TRIP_PRODUCT_AIR,
  TRIP_PRODUCT_HOTEL,
  TRIP_PRODUCT_RAIL,
} from "../../../components/upcoming-trips-dashboard/data/constants";
import { composeBounds } from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import { RootState } from "../../StoreHooks";

export const getProductsByType = (state: any, productType: string) => {
  const { trip } = state?.tripDetail || {};

  return trip?.products?.[0]?.products?.filter(
    (product: { productType: string }) => product.productType === productType
  );
};

export const getAllDestinationsOfATrip = (state: RootState) => {
  const flights = composeBounds(getProductsByType(state, TRIP_PRODUCT_AIR));
  const flightDestinationSegments = flights.reduce((acc, bound) => {
    acc.push(bound.pop());
    return acc;
  }, []);

  const rails = composeBounds(getProductsByType(state, TRIP_PRODUCT_RAIL));
  const railDestinationSegments = rails.reduce((acc, bound) => {
    acc.push(bound.pop());
    return acc;
  }, []);

  const hotels = getProductsByType(state, TRIP_PRODUCT_HOTEL);

  return [
    ...flightDestinationSegments,
    ...railDestinationSegments,
    ...hotels,
  ].filter(Boolean);
};

export const getAllDestinationCountryCode = (state: RootState) => {
  try {
    const destinationSegments = getAllDestinationsOfATrip(state);

    destinationSegments.sort((a: any, b: any) =>
      new Date(
        a.arrival?.dateTime || a.arrivalDateTime || a.checkInDate
      ).getTime() >
      new Date(
        b.arrival?.dateTime || b.arrivalDateTime || b.checkInDate
      ).getTime()
        ? 1
        : -1
    );

    const departureCountryCode =
      state?.tripDetail?.trip?.products?.[0]?.products?.[0]?.departure
        ?.countryCode || "";

    const covidAreaCodes = destinationSegments.reduce(
      (acc: any, segment: any) => {
        const countryCode = segment?.arrival || segment?.location || "";

        if (
          !acc.some((e: any) => e?.countryCode === countryCode.countryCode) &&
          departureCountryCode !== countryCode.countryCode
        ) {
          if (countryCode.countryCode) {
            acc.push(countryCode);
          }
        }
        return acc;
      },
      []
    );

    return Array.from(new Set(covidAreaCodes));
  } catch {
    return [];
  }
};
