import { Avatar } from "@fluentui/react-northstar";
import i18next from "i18next";
import Styles from "./PeopleAvailable.module.scss";

function PeopleAvailable({
  peopleList,
  peopleCount,
  listEnabled = true,
  themeName,
}: any) {
  const { t } = i18next;
  const peopleWithPhoto = (
    <div aria-hidden="true" className={Styles.avatarsPosition}>
      {peopleList && peopleList.length > 0 ? (
        peopleList.map((element: any, index: any) => {
          if (index < 3) {
            return (
              <Avatar
                name={element.user.name}
                image={element.user.photo}
                key={element.user.name + element.user.photo}
                className={
                  themeName === "dark"
                    ? Styles.imageOverlapDark
                    : Styles.imageOverlap
                }
              />
            );
          }
          if (index === 3) {
            const aux = (peopleCount - 3).toString();
            return (
              <Avatar
                name={`+ ${aux}`}
                key={peopleCount - 3}
                className={
                  themeName === "dark"
                    ? Styles.imageOverlapDark
                    : Styles.imageOverlap
                }
              />
            );
          }
          return null;
        })
      ) : (
        <Avatar
          name="0"
          className={
            themeName === "dark" ? Styles.imageOverlapDark : Styles.imageOverlap
          }
        />
      )}
    </div>
  );

  const peopleWithoutPhoto = (
    <div aria-hidden="true" className={Styles.avatarsPosition}>
      <Avatar
        name={peopleCount?.toString()}
        className={
          themeName === "dark" ? Styles.imageOverlapDark : Styles.imageOverlap
        }
      />
    </div>
  );

  return (
    <div
      aria-label={`${peopleCount} ${t("shareMyRide.peopleAvailableTrip")}`}
      role="img"
      className={Styles.peopleAvailableStyles}
    >
      {listEnabled ? peopleWithPhoto : peopleWithoutPhoto}
    </div>
  );
}

export default PeopleAvailable;
