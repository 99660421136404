import { CheckboxProps } from "@fluentui/react-northstar";
import lodash from "lodash";
import NotificationSettings, {
  ScheduleExpenseStatements,
  ScheduleShareMyTransfer,
} from "../../../common/models/NotificationSettings";

function setSettings(
  requestSettingsObject: NotificationSettings,
  checked: CheckboxProps | undefined,
  setting: string
) {
  const settingsObject = lodash.cloneDeep(requestSettingsObject);
  if (setting === "status") {
    if (checked?.checked === true) {
      settingsObject.scheduleExpenseStatements =
        ScheduleExpenseStatements.Always;
    } else {
      settingsObject.scheduleExpenseStatements =
        ScheduleExpenseStatements.Never;
    }
  } else if (setting === "sharemyride") {
    if (checked?.checked === true) {
      settingsObject.scheduleShareMyTransfer = ScheduleShareMyTransfer.Always;
    } else {
      settingsObject.scheduleShareMyTransfer = ScheduleShareMyTransfer.Never;
    }
  } else if (setting === "enablechatassistance") {
    settingsObject.isChatAssistantEnabled = checked?.checked === true;
  }
  return settingsObject;
}

export default setSettings;
