import { TeamsUserCredential } from "@amadeus-cytric/cytric-teams-react-common-library";
import HostSettings from "../../../../utils/host.settings";
import { QueryParamsModel } from "../../../models/QueryParamsModel";

export default class ClassFindShareOptions implements QueryParamsModel {
  top = 6;

  select = "id, displayName, jobTitle, userPrincipalName";

  photoError = `${HostSettings.getCDNImages}default-avatar.png`;

  credential: any;

  users = "users";

  scope = ["User.Read.All"];

  constructor() {
    this.credential = new TeamsUserCredential();
  }
}
