/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expenseLevel2: { label: "", url: "" },
  expenseLevel3: { label: "", url: "" },
  travelLevels: [{ label: "", url: "" }],
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setExpenseLevel2(state, action) {
      state.expenseLevel2 = action.payload;
    },
    setExpenseLevel3(state, action) {
      state.expenseLevel3 = action.payload;
    },
    setTravelLevels(state, action) {
      state.travelLevels = action.payload;
    },
  },
});

export const breadcrumbActions = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
