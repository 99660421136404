import { Alert, CheckmarkCircleIcon, Text } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import store from "../../../store/Store";
import {
  didUserLogOut,
  login,
  loginForcePinSec,
} from "../../../store/authentication/AuthenticationAction";
import GenericInfo from "../generic-info-component/GenericInfo";
import { InfoImage } from "../generic-info-component/GenericInfo.model";

import {
  CYTRIC_EASY_USER_DID_LOGOUT_TO_SWITCH_ACCOUNT,
  USER_LOG_OUT_FLAG,
} from "../../../utils/constants";
import Styles from "./login-component.module.scss";

const dispatchLogin = () => {
  store.dispatch(login());
};

const dispatchLoginForcePinSec = () => {
  store.dispatch(loginForcePinSec());
};

function LoginComponent() {
  const { t } = useTranslation();
  const [userDidLogOut, setUserDidLogOut] = useState(false);

  useEffect(() => {
    const userDidLogOutSwitchAccountFlag =
      sessionStorage.getItem(USER_LOG_OUT_FLAG) ===
        CYTRIC_EASY_USER_DID_LOGOUT_TO_SWITCH_ACCOUNT &&
      window.location.hash.includes("settings");

    if (userDidLogOutSwitchAccountFlag) {
      dispatchLoginForcePinSec();
    }
  }, []);

  useEffect(() => {
    const userDidLogOutFlag =
      didUserLogOut() && window.location.hash.includes("settings");
    setUserDidLogOut(userDidLogOutFlag);
  }, []);

  return (
    <div className={Styles.container}>
      {userDidLogOut && (
        <Alert
          success
          icon={<CheckmarkCircleIcon />}
          header={t("LoginComponent.youLoggedOut")}
          dismissible
          dismissAction={{
            "aria-label": t("LoginComponent.closeAlert"),
          }}
          className={Styles.alert}
        />
      )}
      <GenericInfo
        infoData={{
          title: t("LoginComponent.welcome"),
          detail: <Text>{t("LoginComponent.start")} </Text>,
        }}
        action={{
          text: t("LoginComponent.loginButton"),
          command: () => dispatchLogin(),
        }}
        image={InfoImage.WELCOME}
        isLoginComponent
      />
    </div>
  );
}

export default LoginComponent;
