/* eslint-disable react/require-default-props */
import { EventTemplate } from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-events";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../utils/host.settings";
import Styles from "../UpcomingTrips/UpcomingTrips.module.scss";

function EventSection({ themeString }: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.upcomingTrips",
  });

  const themeName = themeString === "dark" ? themeString : "light";

  return (
    <Flex
      className={Styles.background}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables?.colorScheme?.default.background2,
      })}
      gap="gap.small"
    >
      <Flex.Item size="size.half">
        <Flex gap="gap.small" column className={Styles.eventManagerSection}>
          <Flex className={Styles.sectionTitle}>
            <p>
              <Text
                styles={(theme) => ({
                  color:
                    theme.theme.siteVariables.colorScheme?.brand.foreground2,
                })}
              >
                {t("eventSection.titleStyled")}
              </Text>
              {t("eventSection.title")}
            </p>
          </Flex>
          <EventTemplate />
        </Flex>
      </Flex.Item>
      <Flex.Item size="size.half">
        <Image
          alt="Image of two people talking in the part of the page dedicated to organizing events"
          aria-hidden="true"
          className={Styles.imgEvent}
          data-testid="trips_events_image__img"
          id="trips_events_image__img"
          src={`${HostSettings.getCDNIcons}collaboration-${themeName}.svg`}
        />
      </Flex.Item>
    </Flex>
  );
}

export default EventSection;
