import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HostSettings from "../utils/host.settings";
import timezonesDe from "./i18n.timezones.de-de.json";
import timezonesEn from "./i18n.timezones.en-us.json";
import timezonesEs from "./i18n.timezones.es-es.json";
import timezonesFr from "./i18n.timezones.fr-fr.json";
import resourceDe from "./i18n.translation.de-de.json";
import resourceEn from "./i18n.translation.en-us.json";
import resourceEs from "./i18n.translation.es-es.json";
import resourceFr from "./i18n.translation.fr-fr.json";

i18n.use(initReactI18next).init({
  supportedLngs: HostSettings.getAllowedTranslationLanguages,
  lowerCaseLng: true,
  resources: {
    en: {
      translation: resourceEn,
      timezones: timezonesEn,
    },
    de: {
      translation: resourceDe,
      timezones: timezonesDe,
    },
    es: {
      translation: resourceEs,
      timezones: timezonesEs,
    },
    fr: {
      translation: resourceFr,
      timezones: timezonesFr,
    },
  },
  fallbackLng: (code) => {
    // punt and just 'en' for undefined
    if (!code) {
      return ["en"];
    }

    // primary as-is
    const fallbacks = [code];
    // secondary fallback without geography, e.g 'de' for 'de-li'
    const langPart = code.split("-")[0];
    if (langPart !== code) {
      fallbacks.push(langPart);
    }

    // final fallback to 'en'
    fallbacks.push("en");

    return fallbacks;
  },
});

export default i18n;
