import { IError } from "../../common/models/Error";
import { RootState } from "../StoreHooks";

export const selectCytricUrl = (state: RootState): string =>
  state.redirection.cytricUrl;

export const selectRedirectionIsPending = (state: RootState): boolean =>
  state.redirection.isPending;

export const selectRedirectionIsError = (state: RootState): boolean =>
  state.redirection.isError;

export const errorInformation = (state: RootState): IError =>
  state.redirection.error;
