import { Card, CardBody, Image } from "@fluentui/react-northstar";
import HostSettings from "../../../../utils/host.settings";
import PlanATripButton from "../../utils/PlanATripButton";
import Styles from "./CardBookTripButtons.module.scss";

function OldCard(openCytricInNewTab: boolean, themeName: string) {
  return (
    <Card
      className={Styles.cardButtons}
      compact
      data-testid="oldcard"
      fluid
      styles={(theme: any) => ({
        background: theme.theme.siteVariables.tripCard?.background,
        border: `2px dashed`,
      })}
      data-joyride-id="planATrip"
    >
      <CardBody className={Styles.cardBodyButtons}>
        <Image
          alt="Suitcase image in a Card to Plan a new trip"
          aria-hidden="true"
          className={Styles.imgDeactivedFT}
          data-testid="new-trip-card__img"
          id="new-trip-card__img"
          src={`${HostSettings.getCDNIcons}suitcase-${themeName}.svg`}
        />
        <div className="buttonDiv">
          <PlanATripButton isExternal={openCytricInNewTab} />
        </div>
      </CardBody>
    </Card>
  );
}

export default OldCard;
