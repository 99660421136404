/* eslint-disable react/react-in-jsx-scope */
import { Flex, Header } from "@fluentui/react-northstar";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../common/components/responsive/useScreenSize";
import { KeepSessionActions } from "../../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../../utils/KeepUserActivityContext";
import { useDispatch, useStore } from "../../context/ShareMyRideContext";
import { types } from "../../context/ShareMyRideReducer";
import {
  appInsightsTracker,
  getHeader,
  getRows,
  setTripProductsToShare,
} from "../../utils/shareMyRideUtils";
import CommonError from "../common-error/CommonError";
import DestinationCity from "../destination-city/DestinationCity";
import ListContent from "../list-content/ListContent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import SelectiveSharing from "../selective-sharing/SelectiveSharing";
import ShareMyRideError from "../share-my-ride-error/ShareMyRideError";
import ShareMyRideFooter from "../share-my-ride-footer/ShareMyRideFooter";
import stylesShareMyRideContainer from "./ShareMyRideContainer.module.scss";
import getSharedProducts from "./functions/shareMyRideApi";

export const defaultSelectedUsers = (peopleSharingList: any) =>
  peopleSharingList?.reduce((acc: any, row: any) => {
    acc[row.user.name] = {
      isChecked: false,
      id: "",
    };
    return acc;
  }, {});

export const isMixed = (checkedItems: any) =>
  Object.values(checkedItems).every((user: any) => Boolean(user.isChecked)) ||
  (Object.values(checkedItems).some((user: any) => Boolean(user.isChecked))
    ? "mixed"
    : false);

function ShareMyRideContainer({
  tripId,
  sharedInfo,
  themeName,
  isEnableAllActivated,
}: any) {
  const {
    activeSegment,
    globalLoading,
    errorEnableAll,
    productsToShare,
    commonError,
  } = useStore();
  const dispatch = useDispatch();
  const tablet = 640;
  const screenSize = useScreenSize();
  const isMobile = screenSize < tablet;
  const [header, setHeader] = useState({});
  const [rows, setRows] = useState({});

  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide",
  });
  const [toggleChecked, setToggleChecked] = useState(false);

  const listConentParams = {
    tripId,
    sharedInfo,
    header,
    rows,
    dispatch,
    isMobile,
    themeName,
    isEnableAllActivated,
  };

  const tripProductsToShare = setTripProductsToShare(tripId, sharedInfo);

  const [checkedItems, dispatchCheckedItems] = useReducer(
    (state: any, action: any) => ({ ...state, ...action }),
    {}
  );

  useEffect(() => {
    dispatchCheckedItems(defaultSelectedUsers(activeSegment?.peopleSharing));

    // this is the last component to load on trip details, so we can use it's render to load the right scroll position
    const container = document.querySelector("#main_container_tripDetails");
    if (container) {
      const activity = new ActivityClass();
      const value = activity.get(KeepSessionActions.DETAILS_SCROLL, 0);
      container.scrollTop = value;
    }
  }, [activeSegment]);

  useEffect(() => {
    if (activeSegment) {
      setHeader(
        getHeader(activeSegment, isMixed(checkedItems), dispatchCheckedItems)
      );
      setRows(
        getRows(activeSegment, tripId, checkedItems, dispatchCheckedItems)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSegment, checkedItems]);

  useEffect(() => {
    dispatch({ type: types.setTrip, payload: tripProductsToShare });
    const productsLength: number = tripProductsToShare.productsToShare?.length;
    const active: boolean = productsLength === 1;
    if (tripProductsToShare.productsToShare && productsLength > 0) {
      Promise.all(
        tripProductsToShare.productsToShare.map(async (product: any) => {
          const { productDetails } = product;
          if (productDetails?.locationCode) {
            const sharedProducts = await getSharedProducts(
              tripId,
              product,
              "NONE",
              product.locationType,
              dispatch,
              active
            );
            return sharedProducts;
          }
          dispatch({ type: types.setGlobalLoading, payload: false });
          return null;
        })
      ).then(() => {
        dispatch({ type: types.setGlobalLoading, payload: false });
      });
    } else {
      dispatch({ type: types.setGlobalLoading, payload: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeSegment) {
      const numPeopleSharing = activeSegment.numberOfPeopleSharing
        ? activeSegment.numberOfPeopleSharing
        : 0;
      setHeader(
        getHeader(activeSegment, isMixed(checkedItems), dispatchCheckedItems)
      );
      setRows(
        getRows(activeSegment, tripId, checkedItems, dispatchCheckedItems)
      );
      appInsightsTracker(
        { name: "travel-shareRide-segmentDetails" },
        {
          tripId,
          numOfItems: numPeopleSharing,
          typeOfTransport: activeSegment.type,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSegment]);

  useEffect(() => {
    if (
      activeSegment === null &&
      tripProductsToShare.productsToShare?.length === 1
    ) {
      dispatch({
        type: types.setActiveSegment,
        payload: tripProductsToShare.productsToShare[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripProductsToShare]);

  useEffect(() => {
    if (productsToShare.length > 0) {
      const unActivatedProducts = productsToShare.filter(
        (product: any) => !product.sharingEnabled
      );
      if (unActivatedProducts.length > 0) {
        setToggleChecked(false);
      } else {
        setToggleChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsToShare]);

  const showSubtitle = !activeSegment || activeSegment.sharingEnabled === false;

  const selectedUsers = Object.values(checkedItems).map((user: any) => user.id);
  const showFooter = selectedUsers.some((user: string) => user !== "");

  const showList = () =>
    activeSegment || sharedInfo?.length === 0 ? (
      <ListContent
        params={listConentParams}
        isEnableAllActivated={isEnableAllActivated}
      />
    ) : (
      <SelectiveSharing
        themeName={themeName}
        isEnableAllActivated={isEnableAllActivated}
      />
    );

  const showContent = () =>
    commonError ? <CommonError themeName={themeName} /> : showList();

  return (
    <>
      <div
        className={stylesShareMyRideContainer.shareMyRideContainer}
        data-joyride-id="shareTransfer"
        id="shareMyRide"
        style={{ paddingBottom: showFooter ? "0px" : "20px" }}
      >
        <div className={stylesShareMyRideContainer.shareMyRideHeader}>
          <Flex column gap="gap.large">
            <Header
              as="h3"
              content={t("infoTitle")}
              className={
                isMobile
                  ? stylesShareMyRideContainer.shareMyRideTitleMobile
                  : stylesShareMyRideContainer.shareMyRideTitle
              }
            />
            {showSubtitle && (
              <div
                className={
                  isMobile
                    ? stylesShareMyRideContainer.shareMyRideSubTitleMobile
                    : stylesShareMyRideContainer.shareMyRideSubTitle
                }
              >
                <DestinationCity
                  isEnableAllActivated={isEnableAllActivated}
                  activeSegment={activeSegment}
                  segmentsLength={sharedInfo.length}
                  toggleChecked={toggleChecked}
                />
              </div>
            )}
          </Flex>
        </div>
        {errorEnableAll !== "errorEnableAll" &&
        errorEnableAll !== "errorDisableAll" &&
        errorEnableAll !== "errorEnableIndividual" &&
        errorEnableAll !== "errorDisableIndividual" ? (
          showContent()
        ) : (
          <ShareMyRideError themeName={themeName} />
        )}
        {globalLoading && <LoadingSpinner themeName={themeName} />}
      </div>
      {showFooter && (activeSegment || sharedInfo?.length === 0) && (
        <ShareMyRideFooter
          themeName={themeName}
          selectedUsers={selectedUsers}
          isMobile={isMobile}
          tripId={tripId}
        />
      )}
    </>
  );
}

export default ShareMyRideContainer;
