import { Box, Image, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DateUtils from "../../../../../../../../../utils/date.utils";
import { handleProviderLogoError } from "../../../../../../../utils/trip-utils";
import Divider from "../../components/Divider/Divider";
import EquipmentsList from "../EquipmentsList/EquipmentsList";
import Header from "../Header/Header";
import { ICarSegmentProps } from "./CarSegment.model";
import Styles from "./CarSegment.module.scss";

function CarSegment({ details, index, totalitems }: ICarSegmentProps) {
  const {
    logo,
    pickUpDateTime,
    dropOffDateTime,
    associatedEquipments,
    serviceProviderName,
  } = details;
  const [context, setContext] = useState<app.Context | undefined>();

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((value: app.Context) => setContext(value));
    });
  }, []);

  const { t } = useTranslation();
  const accessibilityTitle = () => {
    if (
      DateUtils.getUTCDate(dropOffDateTime) !==
      DateUtils.getUTCDate(pickUpDateTime)
    ) {
      return `${t(
        "upcoming_trips_dashboard.tripcard.from"
      )} ${DateUtils.formatLongestDate(
        pickUpDateTime,
        context?.app?.locale || "en-GB"
      )} ${t(
        "upcoming_trips_dashboard.tripcard.to"
      )} ${DateUtils.formatLongestDate(
        dropOffDateTime,
        context?.app?.locale || "en-GB"
      )}${"."} ${totalitems} ${t("tripDetails.item/s")}`;
    }
    return `${DateUtils.formatLongestDate(
      pickUpDateTime,
      context?.app?.locale || "en-GB"
    )}${"."} ${totalitems} ${t("tripDetails.item/s")}`;
  };
  return (
    <div role="grid" title={accessibilityTitle()} aria-busy="true">
      <Text
        role="row"
        aria-hidden
        className={Styles.date}
        as="div"
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.date,
        })}
        content={
          DateUtils.formatLongDate(pickUpDateTime) ===
          DateUtils.formatLongDate(dropOffDateTime)
            ? `${DateUtils.formatLongDate(pickUpDateTime)}`
            : `${DateUtils.formatLongDate(
                pickUpDateTime
              )} - ${DateUtils.formatLongDate(dropOffDateTime)}`
        }
        data-testid="date_trip_details"
      />
      <div
        role="grid"
        aria-label={`${t(
          "upcoming_trips_dashboard.tripcard.icons.Car"
        )} ${index} ${t("tripDetails.outOf")} ${totalitems} `}
      >
        <Box
          aria-busy="true"
          role="row"
          className={Styles.box}
          styles={(theme) => ({
            borderColor: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.borderColor
              : "",
            background: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.background
              : "",
          })}
        >
          <Header details={details} />
          <Divider colorTheme="bookingDetail" />
          <div className={Styles.footer}>
            <EquipmentsList equipments={associatedEquipments} />
            <div
              className={Styles["image-wrapper"]}
              title={`${serviceProviderName} ${t("tripDetails.car.company")} `}
            >
              <Image
                role="img"
                className={Styles["car-logo"]}
                src={logo}
                onError={handleProviderLogoError}
              />
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default CarSegment;
