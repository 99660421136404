/* eslint-disable array-callback-return */
import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { ReactComponent as AirCondition } from "../../../../../images/air-condition.svg";
import { ReactComponent as AutomaticTransmission } from "../../../../../images/automatic-transmission.svg";
import { ReactComponent as ManualTransmission } from "../../../../../images/manual-transmission.svg";
import { IEquipmentsListProps } from "./EquipmentsList.model";
import Styles from "./EquipmentsList.module.scss";

function EquipmentsList({ equipments }: IEquipmentsListProps) {
  const iconWithText = equipments?.map((equipment: string) => {
    const hasManualTransmission = equipment.toLowerCase().includes("manual");
    const hasAutomaticTransmission = equipment
      .toLowerCase()
      .includes("automatic");
    const hasAirCondition = equipment.toLowerCase().includes("a/c");

    const equipmentIcon = () => {
      if (hasManualTransmission) {
        return <ManualTransmission />;
      }
      if (hasAutomaticTransmission) {
        return <AutomaticTransmission />;
      }
      if (hasAirCondition) {
        return <AirCondition />;
      }
      return null;
    };

    return (
      <div className={Styles.equipment} key={equipment}>
        <Flex
          styles={(theme) => ({
            color: theme.theme.siteVariables.carDetails?.iconColor,
            width: "16px",
            height: "16px",
          })}
        >
          {equipmentIcon()}
        </Flex>

        <Text
          styles={(theme) => ({
            color: theme.theme.siteVariables.carDetails?.textColor,
          })}
          className={Styles["equipment--name"]}
          title={equipment}
          content={equipment}
        />
      </div>
    );
  });

  return <div className={Styles["equipments-wrapper"]}>{iconWithText}</div>;
}

export default EquipmentsList;
