/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

interface DialogParams {
  openDialog: boolean;
  id: string;
}

interface CancelPopUpContextType {
  dialogParams: DialogParams;
  setDialogParams: Dispatch<SetStateAction<DialogParams>>;
}

export const CancelPopUpContext = createContext<
  CancelPopUpContextType | undefined
>(undefined);

export function CancelPopUpProvider({ children }: { children: ReactNode }) {
  const [dialogParams, setDialogParams] = useState<DialogParams>({
    openDialog: false,
    id: "",
  });

  const value = useMemo(
    () => ({ dialogParams, setDialogParams }),
    [dialogParams]
  );

  return (
    <CancelPopUpContext.Provider value={value}>
      {children}
    </CancelPopUpContext.Provider>
  );
}

export const useCancelPopUp = (): CancelPopUpContextType => {
  const context = useContext(CancelPopUpContext);

  if (!context) {
    throw new Error("Context is not enable");
  }

  return context;
};
