import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { app, dialog } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import { InfoImage } from "../../common/components/generic-info-component/GenericInfo.model";
import SharePopup from "../../common/components/share-popup/SharePopup";
import AdaptiveCardViewer from "../../common/components/share-popup/components/AdaptiveCardViewer";
import { ExportCardToStringJSON } from "../../common/components/share-popup/functions/CardRenderer";
import { IError } from "../../common/models/Error";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import { useAppSelector } from "../../store/StoreHooks";
import { selectFeatureToggle } from "../../store/feature-toggles/FeatureTogglesSelector";
import GetGraphAppCatalogId from "../../utils/GetGraphAppCatalogId";
import { FeatureToggleDefinition } from "../../utils/constants";
import { getDevice } from "../../utils/device.utils";
import GetData from "../share-join/functions/GetData";
import shareCarFromCytricCard from "./card-template/ShareCarFromCytricCard";
import shareFlightFromCytricCard from "./card-template/ShareFlightFromCytricCard";
import shareHotelFromCytricCard from "./card-template/ShareHotelFromCytricCard";
import shareRailFromCytricCard from "./card-template/ShareRailFromCytricCard";
import {
  getShareCarCardData,
  isValidCarData,
  mapToShareCarParamsModel,
} from "./functions/ShareCarHelper";
import {
  getShareFlightCardData,
  isValidFlightData,
  mapToShareFlightParamsModel,
} from "./functions/ShareFlightHelper";
import { getDataSegments } from "./functions/ShareHelper";
import {
  getShareHotelCardData,
  isValidHotelData,
  mapToShareHotelParamsModel,
} from "./functions/ShareHotelHelper";
import {
  getShareRailCardData,
  isValidRailData,
  mapToShareRailParamsModel,
} from "./functions/ShareRailHelper";

function ShareFromClassicTaskModule(props: any) {
  const [recipientSelected, setRecipientSeleted] = useState<[]>([]);
  const [messageToShare, setMessageToShare] = useState("");
  const [selectedCardTemplate, setSelectedCardTemplate] = useState({});
  const [cardData, setCardData] = useState({} as any);
  const [cardError, setCardError] = useState(false);
  const [workflow, setWorkflow] = useState("");
  const featureJoinHotel = useAppSelector((state) =>
    selectFeatureToggle(state, FeatureToggleDefinition.JoinHotel.id)
  );
  const [recipientGroupsSelected, setRecipientGroupsSelected] = useState<any>(
    []
  );
  const [recipientChannelsSelected, setRecipientChannelsSelected] = useState<
    string[] | undefined
  >([]);
  const [disableShare, setDisableShare] = useState<boolean>(true);
  const [appCatalogId, setAppCatalogId] = useState("");
  const [device, setDevice] = useState<any>();

  const getCardData = (preview: boolean) => {
    switch (workflow) {
      case "hotel":
        return getShareHotelCardData(
          cardData,
          featureJoinHotel?.isActive,
          preview
        );

      case "flight":
        return getShareFlightCardData(cardData, preview);

      case "rail":
        return getShareRailCardData(cardData, preview);

      case "car":
        return getShareCarCardData(cardData, preview);

      default:
        return undefined;
    }
  };

  const { t } = useTranslation();
  app.initialize();

  const headerText = t(`shareTrip.${workflow}Share`);

  function setShareSegmentType(
    urlData: any,
    segmentCard: any,
    segmentType: string
  ) {
    setCardError(false);
    setSelectedCardTemplate(segmentCard);
    setCardData(urlData);
    setWorkflow(segmentType);
  }

  function getWorkflowWithCapital(): string {
    return workflow.charAt(0).toUpperCase() + workflow.slice(1);
  }

  function getTelemetryData(data: any) {
    return {
      numOfPeople: data?.recipientIDs?.length,
      numOfGroups: data?.recipientGroupIDs?.length,
      numOfChannels: data?.recipientChannelIDs?.length,
    };
  }

  useEffect(() => {
    if (
      recipientSelected.length === 0 &&
      recipientChannelsSelected?.length === 0 &&
      recipientGroupsSelected?.length === 0
    ) {
      setDisableShare(true);
    } else {
      setDisableShare(false);
    }
  }, [recipientSelected, recipientChannelsSelected, recipientGroupsSelected]);

  useEffect(() => {
    const currentProps = props;
    const queryParams = currentProps.location.search.substring(1);
    const urlData = JSON.parse(decodeURIComponent(queryParams));
    const deviceType = getDevice();

    setDevice(deviceType);
    const segmentType = urlData?.segmentType;

    const segmentDataMap: {
      [key: string]: {
        needsMapping: boolean;
        mapper: (data: any) => any;
        validator: (data: any) => boolean;
        setter: any;
        type: string;
      };
    } = {
      [RedirectionTypeEnum.SHARE_FLIGHT]: {
        needsMapping: !urlData?.itineraries,
        mapper: mapToShareFlightParamsModel,
        validator: isValidFlightData,
        setter: shareFlightFromCytricCard,
        type: "flight",
      },
      [RedirectionTypeEnum.SHARE_HOTEL]: {
        needsMapping: !urlData?.checkinDate,
        mapper: mapToShareHotelParamsModel,
        validator: isValidHotelData,
        setter: shareHotelFromCytricCard,
        type: "hotel",
      },
      [RedirectionTypeEnum.SHARE_RAIL]: {
        needsMapping: !urlData?.itineraries,
        mapper: mapToShareRailParamsModel,
        validator: isValidRailData,
        setter: shareRailFromCytricCard,
        type: "rail",
      },
      [RedirectionTypeEnum.SHARE_CAR]: {
        needsMapping: !urlData?.carTripDetails,
        mapper: mapToShareCarParamsModel,
        validator: isValidCarData,
        setter: shareCarFromCytricCard,
        type: "car",
      },
    };

    // `needsMapping` is true if data shared from Cytric has shortened keys to 3 characters
    // e.g. itinearies -> its, checkinDate -> cid, carTripDetails -> ctd
    const segmentData = segmentDataMap[segmentType];
    if (segmentData) {
      const data = segmentData.needsMapping
        ? segmentData.mapper(urlData)
        : urlData;
      if (data && segmentData.validator(data)) {
        setShareSegmentType(data, segmentData.setter, segmentData.type);
      } else {
        setCardError(true);
      }
    } else {
      setCardError(true);
    }

    GetGraphAppCatalogId(setAppCatalogId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCardTemplate]);

  const cancelShare = () => {
    dialog.url.submit();
  };

  const submitShare = async () => {
    const { cardToShare, cardToShareChannels } = ExportCardToStringJSON(
      selectedCardTemplate,
      getCardData(false),
      recipientChannelsSelected
    );

    setDisableShare(true);

    const getDataProps = {
      recipientSelected,
      messageToShare,
      cardToShare,
      cardToShareChannels,
      tripName: "",
      recipientGroupsSelected,
      recipientChannelsSelected,
      appCatalogId,
      segmentsData: getDataSegments(cardData),
      origin: "classic",
      postOnChannel: false,
      device,
      t,
    };
    const data = await GetData(getDataProps);

    telemetryService.trackEvent(
      { name: `travel-CBP${getWorkflowWithCapital()}-share` },
      getTelemetryData(data)
    );

    dialog.url.submit(data);
  };

  const displayErrorMessage = () => {
    const errorInfo: IError = {
      status: 0,
      code: 0,
      title: t(`messages.ERR_Generic`),
      detail: t(`upcoming_trips_dashboard.ERR_api_call`),
    };
    return <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />;
  };

  if (cardError) {
    return displayErrorMessage();
  }

  return (
    <SharePopup
      setSelectedRecipients={setRecipientSeleted}
      setMessageToShare={setMessageToShare}
      isMessageOptional
      setContentHeaderText={headerText}
      submitShareHandle={submitShare}
      cancelShareHandle={cancelShare}
      disableShareBtnHandle={() => disableShare}
      setRecipientChannelsSelected={setRecipientChannelsSelected}
      setRecipientGroupsSelected={setRecipientGroupsSelected}
    >
      <AdaptiveCardViewer
        cardTemplate={selectedCardTemplate}
        cardData={getCardData(true)}
      />
    </SharePopup>
  );
}

export default ShareFromClassicTaskModule;
