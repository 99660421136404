import { app } from "@microsoft/teams-js";

function getFilteredUser(
  dataCollaborators: any,
  context: app.Context,
  searchQuery: string
) {
  const currentUserMail = context?.user?.loginHint;
  const dataCollaboratorsFilter = dataCollaborators
    .filter((element: any) => element.userPrincipalName !== currentUserMail)
    .filter((element: any) =>
      element.displayName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  return dataCollaboratorsFilter;
}

export default getFilteredUser;
