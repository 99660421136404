/* eslint-disable react/react-in-jsx-scope */
import {
  Airplane20Regular,
  Airplane24Regular,
  Bed20Regular,
  Bed24Regular,
  VehicleCar20Regular,
  VehicleCar24Regular,
  VehicleSubway20Regular,
  VehicleSubway24Regular,
} from "@fluentui/react-icons";
import {
  ComponentStyleFunctionParam,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../../common/components/responsive/Responsive";
import { ISegmentIconsProps } from "./SegmentIcons.model";
import Styles from "./SegmentIcons.module.scss";

function SegmentIcons({ trip, title }: ISegmentIconsProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.upcomingTrips",
  });
  const ICON_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.segmentIcons?.icons
      : "",
  });

  let textTitle = "";
  if (title !== false) {
    textTitle = t("segmentsText");
  }

  const iconsForMobile = (
    <Responsive forMaxSize="tablet">
      {trip?.products[0]?.hasAir && (
        <Flex styles={ICON_STYLE} role="img">
          <Airplane24Regular />
        </Flex>
      )}
      {trip?.products[0]?.hasRail && (
        <Flex styles={ICON_STYLE} role="img">
          <VehicleSubway24Regular />
        </Flex>
      )}
      {trip?.products[0]?.hasHotel && (
        <Flex styles={ICON_STYLE} role="img">
          <Bed24Regular />
        </Flex>
      )}
      {trip?.products[0]?.hasCar && (
        <Flex styles={ICON_STYLE} role="img">
          <VehicleCar24Regular />
        </Flex>
      )}
    </Responsive>
  );
  const iconsForDesktop = (
    <Responsive forMinSize="desktopSmall">
      {trip?.products[0]?.hasAir && (
        <Flex styles={ICON_STYLE} role="img">
          <Airplane20Regular />
        </Flex>
      )}
      {trip?.products[0]?.hasRail && (
        <Flex styles={ICON_STYLE} role="img">
          <VehicleSubway20Regular />
        </Flex>
      )}
      {trip?.products[0]?.hasHotel && (
        <Flex styles={ICON_STYLE} role="img">
          <Bed20Regular />
        </Flex>
      )}
      {trip?.products[0]?.hasCar && (
        <Flex styles={ICON_STYLE} role="img">
          <VehicleCar20Regular />
        </Flex>
      )}
    </Responsive>
  );
  return (
    <div className={Styles.segments}>
      <Text
        content={textTitle}
        styles={(theme) => ({
          color: theme.theme.siteVariables?.segmentIcons?.text,
        })}
      />
      <div className={Styles.segments__icons} data-testid="segment-icons">
        {iconsForMobile}
        {iconsForDesktop}
      </div>
    </div>
  );
}
export default SegmentIcons;
