export default function getBatchPhoto(value: any, url: string) {
  return value.map((item: any) => ({
    id: item,
    request: new Request(
      url === "teams"
        ? `/${url}/${item}/photo/$value`
        : `/${url}/${item}/photos/64x64/$value`,
      {
        method: "GET",
      }
    ),
  }));
}
