import {
  Configuration,
  loadConfiguration,
  LogLevel,
  setLogFunction,
  setLogLevel,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { useTeams } from "msteams-react-base-component";
import HostSettings from "../utils/host.settings";
import useData from "./useData";

const { clientId, teamsfxEndpoint, startLoginPageUrl } = HostSettings;

// TODO fix this when the SDK stops hiding global state!
// eslint-disable-next-line import/no-mutable-exports
let initialized = false;
export function setInitialized(val: boolean) {
  initialized = val;
}

function useTeamsFx() {
  const configuration: Configuration = {
    authentication: {
      initiateLoginEndpoint: startLoginPageUrl,
      simpleAuthEndpoint: teamsfxEndpoint !== "" ? teamsfxEndpoint : undefined,
      clientId,
    },
  };

  const [result] = useTeams({});
  const { error, loading } = useData(async () => {
    if (!initialized) {
      if (process.env.NODE_ENV === "development") {
        setLogLevel(LogLevel.Verbose);

        setLogFunction((level: LogLevel, message: string) => {
          if (level === LogLevel.Error) {
            telemetryService.trackException({
              error: new Error(message),
              severityLevel: LogLevel.Error,
            });
          }

          if (HostSettings.getAmadeusEnvironment === "LOCAL") {
            // eslint-disable-next-line no-console
            console.log(message);
          }
        });
      }

      loadConfiguration(configuration);
      initialized = true;
    }
  });
  const isInTeams = true;
  return { error, loading, isInTeams, ...result };
}

export default useTeamsFx;
