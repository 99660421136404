import { Step } from "react-joyride";
import StepContent from "./StepContent/StepContent";

export const saveCoachmarksAsShownToLocalStorage = (
  ...coachmarks: string[]
) => {
  const COACHMARKS_TO_BE_IGNORED_FROM_LOCAL_STORAGE = JSON.parse(
    localStorage.getItem("COACHMARKS_TO_BE_IGNORED") ?? "[]"
  );

  coachmarks.forEach((coachmark) => {
    if (!COACHMARKS_TO_BE_IGNORED_FROM_LOCAL_STORAGE.includes(coachmark)) {
      COACHMARKS_TO_BE_IGNORED_FROM_LOCAL_STORAGE.push(coachmark);
    }
  });

  localStorage.setItem(
    "COACHMARKS_TO_BE_IGNORED",
    JSON.stringify(COACHMARKS_TO_BE_IGNORED_FROM_LOCAL_STORAGE)
  );
};

export const createJoyrideStep = (
  t: any,
  coachmarkSelector: string,
  coachmarkContentKey: string,
  placement: any = "bottom",
  offset: number = 10,
  spotlightPadding: number = 10
): Step => ({
  target: `[data-joyride-id=${coachmarkSelector}]`,
  title: coachmarkContentKey,
  content: (
    <StepContent
      title={t(`${coachmarkContentKey}.title`)}
      content={t(`${coachmarkContentKey}.content`)}
    />
  ),
  disableBeacon: true,
  placement,
  offset,
  spotlightPadding,
});
