import { createAsyncThunk } from "@reduxjs/toolkit";
import FeatureToggle from "../../common/models/FeatureToggle";
import FeatureToggleData from "../../common/models/FeatureToggleData";
import FeatureTogglesService from "../../services/feature-toggles/feature-toggles.service";
import {
  FeatureToggleDefinition,
  FeatureToggleType,
} from "../../utils/constants";

export const FEATURE_TOGGLES_STORE_NAME = "featureToggles";
export const UPDATE_FEATURE_TOGGLES = "updateFeatureToggles";

export const getFeatureToggles = createAsyncThunk(
  `${FEATURE_TOGGLES_STORE_NAME}/${UPDATE_FEATURE_TOGGLES}`,
  async (data: FeatureToggleData, { rejectWithValue }) => {
    try {
      const response = await FeatureTogglesService.getFeatureToggles();
      const featureToggles: FeatureToggle[] = response.toggles
        ? response.toggles
        : [];
      const result: FeatureToggle[] = [];
      const toggleDefinitions = Object.values(FeatureToggleDefinition);

      // Validate each toggle (apply custom logic to each of them)
      featureToggles.forEach((toggle) => {
        const myToggle: FeatureToggle = { ...toggle };

        // search for toggle type
        const item = toggleDefinitions.find((x) => x.id === toggle.feature);
        // TenantId list
        if (item && item.type === FeatureToggleType.TenantId) {
          const list = toggle.status?.split(",");
          myToggle.isActive = list?.includes(data.tenantId);
        } else {
          // by default boolean value
          myToggle.isActive = toggle.status === "true";
        }
        result.push(myToggle);
      });

      return result;
    } catch (error: any) {
      return rejectWithValue([]);
    }
  }
);
