import { Dispatch, SetStateAction } from "react";

function GetTaskResponseStorage(
  t: any,
  setMessageResponse: Dispatch<
    SetStateAction<{
      alertText: string | null;
      responseStatus: string | null;
      id: string | null;
    }>
  >
) {
  const deleteTripCancellationResponseValue = localStorage.getItem(
    "deleteTripCancellationResponse"
  );

  const setResponse = (
    alertText: string,
    responseStatus: string,
    id: string | null
  ) => {
    setMessageResponse({
      alertText,
      responseStatus,
      id,
    });

    localStorage.removeItem("deleteTripCancellationResponse");
  };

  if (deleteTripCancellationResponseValue) {
    const deleteTripCancellationResponse = JSON.parse(
      deleteTripCancellationResponseValue
    );

    if (deleteTripCancellationResponse.status?.toString() === "200") {
      const textOk = t("upcoming_trips_dashboard.cancel.cancelOk");
      setResponse(textOk, deleteTripCancellationResponse.status, null);
    } else {
      // only return trip when we plan to show the user the option to go to manage my trip
      const textKo = t("upcoming_trips_dashboard.cancel.errorCancel");
      setResponse(
        textKo,
        deleteTripCancellationResponse.status,
        deleteTripCancellationResponse.trip
      );
    }
  }
}

export default GetTaskResponseStorage;
