import React from "react";
import AirportOrCity from "../../../common/components/airport-or-city/AirportOrCity";
import { LocationData } from "../../../common/models/Location.model";

function CreateOptions(data: any) {
  const options: any = [];
  data?.data.forEach((city: LocationData, index: number) => {
    const locationSubType =
      city.location.subType === "city" ? "city" : "airport";

    if (locationSubType === "city" || locationSubType === "airport") {
      options.push({
        name: `${data.dictionaries?.[locationSubType][city.location.iataCode]
          .name} (${city.location.iataCode})`,
        iatacode: city.location.iataCode,
        countrycode:
          data.dictionaries?.[locationSubType][city.location.iataCode]
            ?.countries[0]?.code,
        key: index,
        city: city?.associatedLocations,
        content: (
          <AirportOrCity
            city={city}
            dictionary={
              data.dictionaries?.[locationSubType][city.location.iataCode]
            }
          />
        ),
      });
    }

    if (city?.associatedLocations && data.dictionaries?.airport) {
      const airportCity = data.dictionaries?.city[city.location.iataCode].name;
      city?.associatedLocations.forEach((airport: any) => {
        if (airportCity !== "") {
          options.push({
            name: `${data.dictionaries?.airport[airport.iataCode].name} (${
              airport.iataCode
            })`,
            iatacode: airport.iataCode,
            countrycode:
              data.dictionaries?.airport[airport.iataCode].countries[0].code,
            key: `${index} ${data.dictionaries?.airport[airport.iataCode]
              .name}`,
            city: false,
            content: (
              <AirportOrCity
                city={airport}
                dictionary={data.dictionaries?.airport[airport.iataCode]}
              />
            ),
          });
        }
      });
    }
  });
  return options;
}

export default CreateOptions;
