enum CloseCollaboratorsStatus {
  DEACTIVATED_FOR_USER_LOCATION = "DEACTIVATED_FOR_USER_LOCATION",
  DEACTIVATED_FOR_DESTINATION = "DEACTIVATED_FOR_DESTINATION",
  NO_COUNTRY_CODE_PROVIDED = "NO_COUNTRY_CODE_PROVIDED",
  NO_USERS_AT_DESTINATION = "NO_USERS_AT_DESTINATION",
  ACTIVATED = "ACTIVATED",
  UNKNOWN = "UNKNOWN", // Not coming from the backend, only use for internal purpose
}

export default CloseCollaboratorsStatus;
