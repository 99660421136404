import { Call16Regular, Mail16Regular } from "@fluentui/react-icons";
import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../../../../../../common/components/responsive/Responsive";
import { IContactInformationProps } from "./ContactInformation.model";
import Styles from "./ContactInformation.module.scss";

const displayContacts = (
  className: string,
  largeIcon: any,
  mediumIcon: any,
  contactType: string,
  title: string
) => (
  <div className={className}>
    <Responsive forMaxSize="mobile">{largeIcon}</Responsive>
    <Responsive forMinSize="tablet">{mediumIcon}</Responsive>
    <Text
      styles={(theme) => ({
        color: theme.theme.siteVariables.hotelDetails?.contactColor,
      })}
      title={`${title} ${contactType}`}
    >
      <Text className={Styles["sr-only"]} content={title} /> {contactType}
    </Text>
  </div>
);

function ContactInformation({ email, phone }: IContactInformationProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "tripDetails.hotel",
  });

  return (
    <div className={Styles["contact-information"]}>
      {phone
        ? displayContacts(
            Styles["contact-information__phone"],
            <Flex
              styles={(theme) => ({
                color: theme.theme.siteVariables?.hotelDetails?.contactInfoText,
              })}
            >
              <Call16Regular />
            </Flex>,
            <Flex
              styles={(theme) => ({
                color: theme.theme.siteVariables?.hotelDetails?.contactInfoText,
              })}
            >
              <Call16Regular />
            </Flex>,
            phone,
            t("phoneNumber")
          )
        : ""}
      {email
        ? displayContacts(
            Styles["contact-information__email"],
            <Flex
              styles={(theme) => ({
                color: theme.theme.siteVariables?.hotelDetails?.contactInfoText,
              })}
            >
              <Mail16Regular />
            </Flex>,
            <Flex
              styles={(theme) => ({
                color: theme.theme.siteVariables?.hotelDetails?.contactInfoText,
              })}
            >
              <Mail16Regular />
            </Flex>,
            email,
            t("emailContact")
          )
        : ""}
    </div>
  );
}

export default ContactInformation;
