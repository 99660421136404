function getEnableParams(segment: any) {
  return {
    segmentType: segment.type,
    date: segment.date,
    reference:
      segment.type === "AIR"
        ? segment.productDetails.flightReference
        : `${segment.productDetails.serviceProvider} ${segment.productDetails.vehicleNumber}`,
    locationAdditionalInfo:
      segment.type === "AIR" ? segment.productDetails.terminal : "",

    locationType: segment.locationType,
    locationCode: segment.productDetails.locationCode,
    prevReference:
      segment.type === "AIR"
        ? segment.productDetails.prevFlightReference
        : `${segment.productDetails.prevServiceProvider} ${segment.productDetails.prevVehicleNumber}`,
  };
}

export default getEnableParams;
