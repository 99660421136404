/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Breadcrumb,
  ChevronDownIcon,
  ChevronEndIcon,
  MenuButton,
  Text,
} from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Responsive from "../../../common/components/responsive/Responsive";
import Store from "../../../store/Store";
import { useAppSelector } from "../../../store/StoreHooks";
import { breadcrumbActions } from "../../../store/breadcrumb/breadcrumb-slice";
import { resetCytricContext } from "../../../store/cytric-context/CytricContextSlice";
import { loadCytricUrl } from "../../../store/redirection/RedirectionActions";
import { EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY } from "../../../utils/constants";
import HostSettings from "../../../utils/host.settings";
import Styles from "./Breadcrumb.module.scss";
import NO_LABEL_NO_URL from "./constants";

interface IBreadcrumbNavigationProps {
  type: string;
}

function BreadcrumbNavigation({ type }: IBreadcrumbNavigationProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const [navig, setNav] = useState<Array<{ label: string; url: string }>>([]);
  const [breadcrumbMobileText, setBreadcrumbMobileText] = useState<string>();

  const expenseLevel1 = { label: t("breadcrumbs.expense"), url: "/expenses" };
  const expenseLevel2 = useAppSelector(
    (state) => state.breadcrumb.expenseLevel2
  );
  const expenseLevel3 = useAppSelector(
    (state) => state.breadcrumb.expenseLevel3
  );

  const travelLeves = useAppSelector((state) => state.breadcrumb.travelLevels);

  const breadcrumbClickHandler = async (_index: number, url: string) => {
    if (_index === 0 && url === "/expenses") {
      localStorage.removeItem(EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY);
    }
    let subEntity = "";
    const context: app.Context = await app.getContext();
    subEntity = context?.page?.subPageId ?? "";
    if (url) {
      const urlParams = new URLSearchParams(url.split("?")[1]);
      if (urlParams.size > 0) {
        const params = Object.fromEntries(urlParams.entries());

        Store.dispatch(loadCytricUrl(params as any));
        Store.dispatch(breadcrumbActions.setExpenseLevel3(NO_LABEL_NO_URL));
      } else if (url === "/travel" && subEntity?.includes("segmentType")) {
        localStorage.removeItem("breadCrumbInformation");
        Store.dispatch(resetCytricContext());

        const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel`;
        app.openLink(deepLink);
      } else {
        localStorage.removeItem("breadCrumbInformation");
        Store.dispatch(resetCytricContext());
        history.push(url);
      }
    }
  };

  const getBreadcrumbs = React.useCallback(() => {
    if (type === "expense") {
      return [expenseLevel1, expenseLevel2, expenseLevel3];
    }
    if (type === "travel") {
      return travelLeves;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, expenseLevel3]);

  useEffect(() => {
    const navItems = getBreadcrumbs();
    setNav(navItems);
  }, [getBreadcrumbs, expenseLevel3]);

  useEffect(() => {
    if (navig.length) {
      let labelValue = "";

      if (navig[navig.length - 1].label !== "") {
        labelValue = navig[navig.length - 1].label;
      } else if (navig.length > 1) {
        labelValue = navig[navig.length - 2].label;
      }

      if (labelValue) {
        setBreadcrumbMobileText(labelValue);
      } else {
        setBreadcrumbMobileText("");
      }
    }
  }, [navig]);

  useEffect(() => {
    /// this is a workaround test to do the redirection to the travel page when the user is in mobile and the session has expired
    const timeOut = Number(localStorage.getItem("lastUrlDateTimeout"));
    if (timeOut && timeOut !== 0) {
      const sessionTimeOut = timeOut < new Date().getTime();
      if ((!type || type === "travel") && sessionTimeOut) {
        localStorage.setItem("lastVisitedUrl", "");
        localStorage.setItem("lastVisitedPage", "");
        localStorage.setItem("lastUrlDateTimeout", "0");

        history.push("/travel");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navItemsWithLabel = navig.filter((item) => item.label);

  const mobileMenuItems = navItemsWithLabel.map((item, index) => ({
    content: item.label,
    onClick: () => breadcrumbClickHandler(index, item.url),
    key: item.label,
  }));

  const clickAction = (index: number, item: { label?: string; url: any }) => {
    breadcrumbClickHandler(index, navig[index + 1]?.label ? item.url : "");
  };

  const breadcrumbMobileButton = (
    <div className={Styles.breadcrumb__mobileMenuWrapper}>
      <Text
        content={breadcrumbMobileText}
        aria-label={breadcrumbMobileText}
        className={Styles.breadcrumb__mobileMenu}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.currentPage,
        })}
      />
      <ChevronDownIcon
        size="small"
        className={Styles.breadcrumb__divider}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.path,
        })}
      />
    </div>
  );

  return (
    <Box
      className={Styles.breadcrumb}
      styles={(theme) => ({
        background: theme.theme.siteVariables.tripBreadcrumb?.background,
        borderBottomColor:
          theme.theme.siteVariables.tripBreadcrumb?.mobileBorderBottom,
      })}
      data-testid="breadcrumb"
    >
      <Responsive forMinSize="desktopSmall">
        <div className={Styles.desktop}>
          <Breadcrumb aria-label="breadcrumb">
            {navig.map(
              (item, index) =>
                item.label && (
                  <React.Fragment key={item.label}>
                    <Breadcrumb.Item
                      onClick={() => {
                        clickAction(index, item);
                      }}
                      onKeyDown={(e: { key: string }) => {
                        if (e.key === "Enter" || e.key === " ") {
                          clickAction(index, item);
                        }
                      }}
                      className={Styles.breadcrumb__item}
                      styles={(theme) => ({
                        color: theme.theme.siteVariables.tripBreadcrumb?.path,
                      })}
                      data-testid={`breadcrumb-button-n${index}`}
                      tabIndex={0}
                    >
                      <Breadcrumb.Link className={Styles.link}>
                        {item.label}
                      </Breadcrumb.Link>
                    </Breadcrumb.Item>

                    {navig[index + 1]?.label && (
                      <Breadcrumb.Divider>
                        <ChevronEndIcon
                          size="small"
                          className={Styles.breadcrumb__divider}
                          styles={(theme) => ({
                            color:
                              theme.theme.siteVariables.tripBreadcrumb?.path,
                          })}
                        />
                      </Breadcrumb.Divider>
                    )}
                  </React.Fragment>
                )
            )}
          </Breadcrumb>
        </div>
      </Responsive>
      <Responsive forMaxSize="tablet">
        <MenuButton
          className={Styles.mobile}
          trigger={breadcrumbMobileButton}
          menu={mobileMenuItems}
          on="click"
        />
      </Responsive>
    </Box>
  );
}

export default BreadcrumbNavigation;
