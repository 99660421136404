import {
  createMicrosoftGraphClient,
  TeamsUserCredential,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { createAsyncThunk } from "@reduxjs/toolkit";
import CloseCollaboratorsStatus from "../../../common/models/enums/ActivationStatusEnum";
import { IError } from "../../../common/models/Error";
import CloseCollaboratorsConstant from "../../../components/collaborators/close-collaborators/close-collaborators-list/utils/CloseCollaboratorsConstant";
import countUsers from "../../../components/collaborators/close-collaborators/close-collaborators-list/utils/CountUsers";
import mapCollabImages from "../../../components/collaborators/close-collaborators/close-collaborators-list/utils/FunctionsCloseCollaborators";
import getImages from "../../../components/collaborators/close-collaborators/close-collaborators-list/utils/GetImages";
import { CollaboratorsModel } from "../../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import getPresenceCollaborators from "../../../components/collaborators/close-collaborators/utils/getPresenceCollaborators";
import CloseCollaboratorService from "../../../services/close-collaborators/close-collaborators.service";

export const CLOSE_COLLABORATORS_STORE_NAME = "closeCollaborators";
export const SEARCH_CLOSE_COLLABORATORS = "searchCloseCollaborators";
export const ENHANCE_CLOSE_COLLABORATORS = "enhanceCloseCollaborators";

const defaultError: IError = {
  code: 100,
  status: 500,
  title: "Something went wrong",
  detail: "Please try again later or contact your system administrator",
};

const rejectionValue = (countryCode: string, error: IError) => ({
  countryCode,
  error,
});

const trackCCEvent = (numOfItems: number, status: string, tripId: string) => {
  telemetryService.trackEvent(
    { name: "travel-closeCollaborators-display" },
    {
      numOfItems,
      status,
      tripId,
    }
  );
};

const countUsersInCountry = async (countryCode: string) => {
  if (countryCode === "") {
    return 0;
  }

  const graph = createMicrosoftGraphClient(new TeamsUserCredential(), [
    "User.Read",
  ]);

  return countUsers(graph, countryCode);
};

const enhanceCloseCollaboratorsData = async (
  closeCollaborators: CollaboratorsModel[]
) => {
  if (closeCollaborators.length === 0) {
    return closeCollaborators;
  }

  const graph = createMicrosoftGraphClient(new TeamsUserCredential(), [
    "User.Read",
  ]);

  const collabList = closeCollaborators.map(({ id }: any) => id);
  const images = await getImages(graph, collabList);
  const presencesGraph = await getPresenceCollaborators(graph, collabList);

  return mapCollabImages(closeCollaborators, images, presencesGraph);
};

export const enhanceCloseCollaborators = createAsyncThunk(
  `${CLOSE_COLLABORATORS_STORE_NAME}/${ENHANCE_CLOSE_COLLABORATORS}`,
  async (
    data: {
      closeCollaborators: CollaboratorsModel[];
    },
    { rejectWithValue }
  ) => {
    try {
      const closeCollaborators = await enhanceCloseCollaboratorsData(
        data.closeCollaborators
      );
      return { closeCollaborators };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const searchCloseCollaborators = createAsyncThunk(
  `${CLOSE_COLLABORATORS_STORE_NAME}/${SEARCH_CLOSE_COLLABORATORS}`,
  async (
    data: {
      countryCode: string;
      latitude?: number;
      longitude?: number;
      iataCode?: string;
      tripId: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    if (!data.countryCode) {
      trackCCEvent(
        0,
        CloseCollaboratorsStatus.NO_COUNTRY_CODE_PROVIDED,
        data.tripId
      );
      return {
        status: CloseCollaboratorsStatus.NO_COUNTRY_CODE_PROVIDED,
        closeCollaborators: [],
        countryCode: data.countryCode,
      };
    }

    try {
      // get close collaborators and count number of users in parallel
      const [response, numberOfUsers] = await Promise.all([
        CloseCollaboratorService.getCloseCollaborators(
          data.countryCode,
          data.latitude,
          data.longitude,
          data.iataCode,
          data.tripId
        ),
        countUsersInCountry(data.countryCode),
      ]);

      if (response.status) {
        let status: CloseCollaboratorsStatus =
          CloseCollaboratorsStatus[
            response.status as keyof typeof CloseCollaboratorsStatus
          ];

        if (
          status === CloseCollaboratorsStatus.ACTIVATED &&
          numberOfUsers === 0
        ) {
          status = CloseCollaboratorsStatus.NO_USERS_AT_DESTINATION;
        }

        let closeCollaborators = response.closeCollaborators
          ? response.closeCollaborators
          : [];

        if (closeCollaborators.length) {
          closeCollaborators = closeCollaborators.map(
            (collaborator: CollaboratorsModel) => ({
              ...collaborator,
              availability: CloseCollaboratorsConstant.graphDefaultAvailability,
              activity: CloseCollaboratorsConstant.graphDefaultActivity,
              image: CloseCollaboratorsConstant.graphDefaultImage,
            })
          );

          dispatch(enhanceCloseCollaborators({ closeCollaborators }));
        }

        trackCCEvent(closeCollaborators.length, status, data.tripId);
        return {
          status,
          closeCollaborators,
          countryCode: data.countryCode,
        };
      }

      trackCCEvent(0, "SOMETHING_WENT_WRONG", data.tripId);
      return rejectWithValue(rejectionValue(data.countryCode, defaultError));
    } catch (error: any) {
      trackCCEvent(0, "SOMETHING_WENT_WRONG", data.tripId);
      if (error.response?.data?.length) {
        const errorData = error.response?.data[0];
        return rejectWithValue(
          rejectionValue(data.countryCode, errorData as IError)
        );
      }
      return rejectWithValue(rejectionValue(data.countryCode, defaultError));
    }
  }
);
