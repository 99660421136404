import { app, pages } from "@microsoft/teams-js";
import HostSettings from "../../../utils/host.settings";

export default function navigateTo(tab: string) {
  if (pages.currentApp?.isSupported()) {
    // This is the case for New Teams version
    pages.currentApp.navigateTo({
      pageId: tab,
    });
  } else {
    const url = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/${tab}`;
    app.openLink(url);
  }
}
