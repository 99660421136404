import { SegmentType } from "../../components/upcoming-trips-dashboard/UpcomingTrips/CardBookTripButtons/model/Permissions";
import { RootState } from "../StoreHooks";

// eslint-disable-next-line import/prefer-default-export
export const getFlowsPermissions = (state: RootState): SegmentType[] =>
  state.flowPermissions.permissions;

export const getCarPermissions = (state: RootState): SegmentType =>
  state.flowPermissions.permissions.filter(
    (permission) => permission.type === "CAR"
  )[0];

export const getHotelPermissions = (state: RootState): SegmentType =>
  state.flowPermissions.permissions.filter(
    (permission) => permission.type === "HOTEL"
  )[0];

export const getRailPermissions = (state: RootState): SegmentType =>
  state.flowPermissions.permissions.filter(
    (permission) => permission.type === "RAIL"
  )[0];

export const getFlightPermissions = (state: RootState): SegmentType =>
  state.flowPermissions.permissions.filter(
    (permission) => permission.type === "TRANSPORTATION"
  )[0];
