import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import lodash from "lodash";

export default function createFormattedDates(trip: any, language: string) {
  const myTrip = lodash.cloneDeep(trip);
  if (myTrip.products) {
    myTrip.products[0].products.forEach((product: any) => {
      if (product.Trip_Product_Air) {
        const departureDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Air.departure.dateTime,
          language,
          DateFormat.DATE_SHORT
        );
        const arrivalDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Air.arrival.dateTime,
          language,
          DateFormat.DATE_SHORT
        );
        // eslint-disable-next-line no-param-reassign
        product.Trip_Product_Air = {
          ...product.Trip_Product_Air,
          departureDateFormatted,
          arrivalDateFormatted,
        };
      } else if (product.Trip_Product_Train) {
        const departureDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Train.departureDateTime,
          language,
          DateFormat.DATE_SHORT
        );
        const arrivalDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Train.arrivalDateTime,
          language,
          DateFormat.DATE_SHORT
        );
        // eslint-disable-next-line no-param-reassign
        product.Trip_Product_Train = {
          ...product.Trip_Product_Train,
          departureDateFormatted,
          arrivalDateFormatted,
        };
      } else if (product.Trip_Product_Hotel) {
        const checkInDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Hotel.checkInDate,
          language,
          DateFormat.DATE_SHORT
        );
        const checkOutDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Hotel.checkOutDate,
          language,
          DateFormat.DATE_SHORT
        );
        // eslint-disable-next-line no-param-reassign
        product.Trip_Product_Hotel = {
          ...product.Trip_Product_Hotel,
          checkInDateFormatted,
          checkOutDateFormatted,
        };
      } else if (product.Trip_Product_Car) {
        const pickupDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Car.pickup.dateTime,
          language,
          DateFormat.DATE_SHORT
        );
        const dropoffDateFormatted = DateUtils.getDateFormat(
          product.Trip_Product_Car.dropoff.dateTime,
          language,
          DateFormat.DATE_SHORT
        );
        // eslint-disable-next-line no-param-reassign
        product.Trip_Product_Car = {
          ...product.Trip_Product_Car,
          pickupDateFormatted,
          dropoffDateFormatted,
        };
      }
    });
  }
  return myTrip;
}
