/* eslint-disable no-useless-return */
/* eslint-disable react/react-in-jsx-scope */
import {
  GenericInfo,
  InfoImage,
  KEYCLOACK_ERROR_CODE,
  Security,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Alert, ExclamationCircleIcon } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import store from "../store/Store";
import { useAppSelector } from "../store/StoreHooks";
import { didUserLogOut } from "../store/authentication/AuthenticationAction";
import {
  authErrorCodeSelector,
  isLoggedInSelector,
} from "../store/authentication/AuthenticationSelector";
import {
  resetAuthentication,
  setAuthErrorCode,
  setIsLoggedIn,
} from "../store/authentication/AuthenticationSlice";
import {
  DOCUMENT_NOT_FOUND_ERROR_CODE,
  KEYCLOAK_MICROSOFT_TOKEN_ERROR_CODE,
  KEYCLOAK_MISSING_MICROSOFT_LINK_ERROR_CODE,
} from "../utils/constants";
import HostSettings from "../utils/host.settings";
import LoginComponent from "./components/login-component/login-component";
import { IErrorResponse } from "./models/ErrorResponse";

export interface ISecurity {
  securityRole?: string;
  enabled?: boolean;
  component?: any;
  children?: any;
}

function SecurityComponent(props: ISecurity) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorDocument, setIsErrorDocument] = useState(false);
  const [isErrorToken, setIsErrorToken] = useState(false);
  const [userDidLogout, setUserDidLogout] = useState(false);
  const isEnabled = (enabled: boolean = true) => {
    let result = true;
    if (enabled !== undefined) {
      result = enabled;
    }
    return result;
  };
  const isLoggedIn: boolean = useAppSelector((state: any) =>
    isLoggedInSelector(state)
  );

  const authErrorCode: number | undefined = useAppSelector((state: any) =>
    authErrorCodeSelector(state)
  );
  const { t } = useTranslation();

  const resetAuthInCaseOfError = (error: IErrorResponse) => {
    // Manage document not found exception
    if (error?.data?.errors?.[0]?.code === DOCUMENT_NOT_FOUND_ERROR_CODE) {
      setIsErrorDocument(true);
      store.dispatch(setAuthErrorCode(DOCUMENT_NOT_FOUND_ERROR_CODE));
    }
    // Manage keycloak errors
    else if (
      error.status === KEYCLOACK_ERROR_CODE &&
      (error?.data?.errors?.[0]?.code ===
        KEYCLOAK_MISSING_MICROSOFT_LINK_ERROR_CODE ||
        error?.data?.errors?.[0]?.code === KEYCLOAK_MICROSOFT_TOKEN_ERROR_CODE)
    ) {
      setIsErrorToken(true);
      store.dispatch(setAuthErrorCode(error?.data?.errors?.[0]?.code));
    } else {
      // Manage unhandled error
      setIsError(true);
    }

    store.dispatch(resetAuthentication());
    sessionStorage.clear();
    return;
  };

  useEffect(() => {
    const enabledCall = isEnabled(props.enabled);
    const isSecurityEnabled = process.env.REACT_APP_IS_SECURITY_ENABLED === "1";
    const isSecurityDisabled =
      process.env.REACT_APP_IS_SECURITY_ENABLED === "0";

    if (isSecurityDisabled) {
      // Explicitly disable login only for local development
      store.dispatch(setIsLoggedIn(true));
      setHasAccess(enabledCall);
      setIsLoaded(true);
      return;
    }

    if (
      isSecurityEnabled &&
      props.securityRole !== undefined &&
      !didUserLogOut()
    ) {
      Security.isSecured(props.securityRole)
        .then((isSecured: boolean) => {
          setHasAccess(enabledCall && isSecured);
          setIsLoaded(true);
          setIsErrorDocument(false);
          setIsErrorToken(false);
          setIsError(false);
          // handling case when app is secured, but isLoggedInSelector is false
          // due to initial state and call to feature toggles doesn't happen,
          // resulting in showing loader indefinitely
          if (!isLoggedIn) {
            store.dispatch(setIsLoggedIn(true));
          }
        })
        .catch((error: IErrorResponse) => {
          // Manage logout
          if (didUserLogOut()) {
            setUserDidLogout(true);
            return;
          }

          // Manage auth errors
          resetAuthInCaseOfError(error);
        });
    } else if (
      isSecurityEnabled &&
      props.securityRole !== undefined &&
      didUserLogOut()
    ) {
      setUserDidLogout(true);
    } else {
      setHasAccess(enabledCall);
      setIsLoaded(true);
    }
  }, [props.enabled, props.securityRole, isLoggedIn]);

  const redirectChatTab = () => {
    const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/conversations`;
    app.openLink(deepLink);
  };

  const renderErrorDocument = () => (
    <>
      {authErrorCode === DOCUMENT_NOT_FOUND_ERROR_CODE && (
        <Alert
          danger
          icon={<ExclamationCircleIcon />}
          actions={[
            {
              content: t("Security.DocumentNotFound_button"),
              onClick: () => redirectChatTab(),
            },
          ]}
          header={t("Security.DocumentNotFound_message")}
        />
      )}
      <LoginComponent />
    </>
  );

  const renderComponent = () => {
    if (userDidLogout && !isLoggedIn) {
      return <LoginComponent />;
    }
    if (isErrorDocument) {
      return renderErrorDocument();
    }
    if (isErrorToken) {
      return <LoginComponent />;
    }
    if (isError) {
      return (
        <GenericInfo
          infoData={{
            title: t(`messages.ERR_Generic`),
            detail: t(`messages.ERR_AccessCytricEasySubdetail`),
          }}
          image={InfoImage.ERROR}
          action={{
            text: t("App.Try_again"),
            command: () => window.location.reload(),
          }}
        />
      );
    }
    if (!isLoaded) {
      return null;
    }
    if (!hasAccess) {
      if (props.component) {
        return props.component;
      }
      return null;
    }
    return props.children;
  };

  return renderComponent();
}

export default SecurityComponent;
