/* eslint-disable @typescript-eslint/no-use-before-define */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Loader } from "@fluentui/react-northstar";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import { todosActions } from "../../store/todos/todos-slice";
import { KeepSessionActions } from "../../utils/KeepSessionFunctions";
import ActivityClass from "../../utils/KeepUserActivityContext";
import ToDoDialog from "./Dialog/ToDoDialog";
import EmptyToDo from "./ToDoList/EmptyToDo/EmptyToDo";
import ToDoList from "./ToDoList/ToDoList";
import { IToDo } from "./index.model";
import useTodoRequest from "./useTodoRequest";

function ToDo() {
  const { t } = useTranslation();
  const todos = useAppSelector((state) => state.todos.todos);
  const isLoading = useAppSelector((state) => state.todos.isLoading);
  const dispatch = useAppDispatch();
  const { getTodos, createTodo, updateTodo } = useTodoRequest();

  const newTaskHandler = async (taskTitle: string, taskDueDate: Date) => {
    dispatch(todosActions.setIsLoading(true));

    createTodo({
      title: taskTitle,
      dueDate: taskDueDate,
      status: "UNCHECKED",
    }).then(() => {
      getTodos().then((resData) => {
        dispatch(todosActions.setIsLoading(false));
        dispatch(todosActions.setTodos(resData));
      });
      dispatchDialog({ type: "close" });
    });
    const activity = new ActivityClass();
    activity.removeElementOrChildren(KeepSessionActions.DETAILS_ADDTASK);

    telemetryService.trackEvent({ name: "travel-todo-create" });
  };

  const editTaskHandler = async (
    taskTitle: string,
    taskDueDate: Date,
    data: IToDo
  ) => {
    dispatch(todosActions.setIsLoading(true));
    const { id } = data;
    const payload = {
      ...data,
      title: taskTitle,
      dueDate: taskDueDate,
    };

    updateTodo(id, payload).then(() => {
      dispatch(todosActions.setIsLoading(false));
      getTodos().then((resData) => {
        dispatch(todosActions.setTodos(resData));
      });
      dispatchDialog({ type: "close" });
    });

    const activity = new ActivityClass();
    activity.removeElementOrChildren(KeepSessionActions.DETAILS_ADDTASK);

    telemetryService.trackEvent({ name: "travel-todo-update" });
  };

  const dialogReducer = (_state: any, action: any) => {
    if (action?.type === "newTask") {
      return {
        isVisible: true,
        data: null,
        taskHandler: newTaskHandler,
        headerTitle: t("toDo.dialog.newTask"),
        confirmButtonText: t("toDo.dialog.newTask"),
      };
    }

    if (action?.type === "editTask") {
      return {
        isVisible: true,
        data: action.payload,
        taskHandler: editTaskHandler,
        headerTitle: t("toDo.dialog.editTask"),
        confirmButtonText: t("toDo.dialog.saveChanges"),
      };
    }

    if (action?.type === "close") {
      return {
        isVisible: false,
        data: null,
        taskHandler: null,
        headerTitle: "",
        confirmButtonText: "",
      };
    }

    const activity = new ActivityClass();
    activity.removeElementOrChildren(KeepSessionActions.DETAILS_ADDTASK);
    return {
      isVisible: false,
      data: null,
      taskHandler: null,
      headerTitle: "",
      confirmButtonText: "",
    };
  };

  const [toDoDialog, dispatchDialog] = useReducer(dialogReducer, {
    isVisible: false,
    data: null,
    taskHandler: null,
    headerTitle: "",
    confirmButtonText: "",
  });

  useEffect(() => {
    dispatch(todosActions.setIsLoading(true));
    getTodos().then((data) => {
      dispatch(todosActions.setTodos(data));
      dispatch(todosActions.setIsLoading(false));

      telemetryService.trackEvent(
        { name: "travel-todo-display" },
        {
          numOfItems: data.length,
        }
      );
    });
  }, [dispatch, getTodos]);

  const ToDoComponent = todos?.length ? (
    <ToDoList dispatchDialog={dispatchDialog} todos={todos} />
  ) : (
    <EmptyToDo dispatchDialog={dispatchDialog} />
  );

  const userActivity = new ActivityClass();

  useEffect(() => {
    const userActivityValue = userActivity.get(
      KeepSessionActions.DETAILS_ADDTASK
    );
    if (userActivityValue) {
      dispatchDialog(userActivityValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loader style={{ margin: 100 }} />}
      {!isLoading && (
        <>
          {ToDoComponent}
          <ToDoDialog toDoDialog={toDoDialog} dispatchDialog={dispatchDialog} />
        </>
      )}
    </>
  );
}

export default ToDo;
