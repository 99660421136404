import { useTranslation } from "react-i18next";
import GenericInfo from "../../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfoAction,
  InfoImage,
} from "../../../common/components/generic-info-component/GenericInfo.model";
import { IError } from "../../../common/models/Error";

export default function ErrorPage() {
  const { t } = useTranslation();
  const errorInfo: IError = {
    status: 0,
    code: 0,
    title: t(`messages.ERR_Generic`),
    detail: t(`upcoming_trips_dashboard.ERR_api_call`),
  };
  const action: IGenericInfoAction = {
    text: t(`upcoming_trips_dashboard.Button_try_again`),
    command: () => {
      window.location.reload();
    },
  };
  return (
    <GenericInfo infoData={errorInfo} action={action} image={InfoImage.ERROR} />
  );
}
