/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  CheckboxProps,
  Flex,
  List,
  Loader,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../../../../common/components/divider/Divider";
import {
  DuplicateExpensesInterface,
  DuplicatePairsInterface,
} from "../DuplicatesInterfaces";
import styles from "./DialogContent.module.scss";

interface DialogContentPropsInterface {
  duplicatePairs: DuplicatePairsInterface[];
  manageCheckboxes: (
    e: React.FormEvent<HTMLElement>,
    checked: CheckboxProps | undefined,
    pairId: number
  ) => void;
  clickedMergeButtons: boolean;
}

export function ListItemHeader(props: {
  expense: DuplicateExpensesInterface;
}): JSX.Element {
  const { expense } = props;
  return (
    <span
      id={`popup__dialog-content__item-title-${expense.id}`}
      data-testid="popup__dialog-content__item-title"
      style={{ fontWeight: "bold" }}
    >
      {expense.sourceType}
    </span>
  );
}

export function ListItemContent(props: {
  expense: DuplicateExpensesInterface;
}): JSX.Element {
  const { expense } = props;
  return (
    <Box
      id="popup__dialog-content__item-content"
      data-testid="popup__dialog-content__item-content"
      styles={{ fontSize: "0.8rem" }}
    >
      <Box
        id="popup__dialog-content__item-details"
        data-testid="popup__dialog-content__item-details"
        styles={{ margin: "0.3rem 0" }}
      >
        {expense.description ?? "No description"} / Atts:{" "}
        {expense.attachmentCount} / Date: {expense.period} / Location:{" "}
        {expense.location}
      </Box>
      <Box
        id="popup__dialog-content__item-amount"
        data-testid="popup__dialog-content__item-amount"
      >
        <b>{expense.amount}</b>
      </Box>
    </Box>
  );
}

function DialogContent({
  duplicatePairs,
  manageCheckboxes,
  clickedMergeButtons,
}: DialogContentPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.popup.viewDuplicates",
  });
  return (
    <Box id="popup__dialog-content" data-testid="popup__dialog-content">
      <Box
        id="popup__dialog-content__merge-question"
        data-testid="popup__dialog-content__merge-question"
        styles={{ margin: "0.5rem 0 0.3rem" }}
      >
        <Box aria-label={`${t("mergeQuestion")}`}>{t("mergeQuestion")}</Box>
        <Box
          aria-label={`${t("mergeInfo")}`}
          data-testid="popup__dialog-content__merge-info-title"
        >
          {t("mergeInfo")}
        </Box>
        <div className={styles.divider}>
          <Divider />
        </div>
      </Box>

      <Box role="list">
        {duplicatePairs?.map((pair) => (
          <Box
            aria-label={`Pair ${pair.id} out of ${duplicatePairs.length}`}
            role="listitem"
            key={pair.id}
            id={`popup__dialog-content__item-container-${pair.id}`}
            data-testid={`popup__dialog-content__item-container-${pair.id}`}
          >
            <Flex
              id="popup__dialog-content__list-container"
              data-testid="popup__dialog-content__list-container"
              hAlign="center"
              vAlign="start"
            >
              {duplicatePairs?.length > 1 && (
                <Checkbox
                  aria-label={`Pair ${pair.id} out of ${duplicatePairs.length} for ${pair.duplicateExpenses[0].sourceType} expenses`}
                  id={`popup__dialog-content__item-checkbox-${pair.id}`}
                  data-testid="popup__dialog-content__item-checkbox"
                  onChange={(e, checked) =>
                    manageCheckboxes(e, checked, pair.duplicateExpenses[0].id)
                  }
                />
              )}

              <List
                aria-label={`Statements of ${pair.duplicateExpenses[0].sourceType} possible duplicate expenses`}
                id="popup__dialog-content__list"
                data-testid="popup__dialog-content__list"
                styles={{ width: "100%" }}
              >
                {pair.duplicateExpenses.map((expense, index) => (
                  <div
                    key={expense.id}
                    data-testid={`popup__dialog-content__list-item-container-${expense.id}`}
                  >
                    <List.Item
                      id={`popup__dialog-content__list-item-${expense.id}`}
                      styles={{ paddingLeft: "0" }}
                      header={<ListItemHeader expense={expense} />}
                      content={<ListItemContent expense={expense} />}
                      index={0}
                    />
                    {index !== pair.duplicateExpenses.length - 1 && (
                      <div className={styles.divider}>
                        <Divider />
                      </div>
                    )}
                  </div>
                ))}
              </List>
            </Flex>
            <div className={styles.divider}>
              <Divider />
            </div>
          </Box>
        ))}
      </Box>
      {clickedMergeButtons && <Loader />}
    </Box>
  );
}

export default DialogContent;
