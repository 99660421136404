import { createAsyncThunk } from "@reduxjs/toolkit";
import NotificationSettings from "../../common/models/NotificationSettings";
import NotificationSettingsService from "../../services/notification-settings/NotificationSettingsService";

export const NOTIFICATION_SETTINGS_STORE_NAME = "notificationSettings";
export const LOAD_NOTIFICATION_SETTINGS = "loadNotificationSettings";
export const SET_NOTIFICATION_SETTINGS = "setNotificationSettings";
export const RESET_NOTIFICATION_SETTINGS = "resetNotificationSettings";

const defaultErrorMessage = "Something went wrong";

export const loadNotificationSettings = createAsyncThunk(
  `${NOTIFICATION_SETTINGS_STORE_NAME}/${LOAD_NOTIFICATION_SETTINGS}`,
  async (_, { rejectWithValue }) => {
    try {
      const response =
        await NotificationSettingsService.getUserNotificationSettings();
      if (response?.data?.data) {
        return response.data.data as NotificationSettings;
      }
      return rejectWithValue(defaultErrorMessage);
    } catch (error: any) {
      if (error.response?.data?.length) {
        const errorData = error.response?.data[0];
        return rejectWithValue(errorData);
      }
      return rejectWithValue(defaultErrorMessage);
    }
  }
);

export const setNotificationSettings = createAsyncThunk(
  `${NOTIFICATION_SETTINGS_STORE_NAME}/${SET_NOTIFICATION_SETTINGS}`,
  async (notificationSettings: NotificationSettings, { rejectWithValue }) => {
    try {
      const response =
        await NotificationSettingsService.setUserNotificationSettings(
          notificationSettings
        );

      if (response?.data?.data) {
        return response.data.data as NotificationSettings;
      }
      return rejectWithValue(defaultErrorMessage);
    } catch (error: any) {
      if (error.response?.data?.length) {
        const errorData = error.response?.data[0];
        return rejectWithValue(errorData);
      }
      return rejectWithValue(defaultErrorMessage);
    }
  }
);
