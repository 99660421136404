import * as AdaptiveCards from "adaptivecards";
import * as ACData from "adaptivecards-templating";
import channelsCardFooter from "../../../../components/share-join/functions/ac-utils/channelCardFooter";

function RenderCard(cardTemplate: any, cardData: any, cardThemeConfig: any) {
  const adaptiveCardTemplate = new ACData.Template(cardTemplate);
  const adaptiveContext: ACData.IEvaluationContext = {
    $root: {
      ...cardData,
    },
  };
  const cardPayload = adaptiveCardTemplate.expand(adaptiveContext);
  const adaptiveCard = new AdaptiveCards.AdaptiveCard();
  adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(cardThemeConfig);

  adaptiveCard.parse(cardPayload);
  return adaptiveCard.render();
}

function ExportCardToStringJSON(
  cardTemplate: any,
  cardData: any,
  recipientChannelsSelected: string[] | undefined,
  cardElementsToAppend?: any
) {
  let cardToShareChannels = "";

  const adaptiveCardTemplate = new ACData.Template(cardTemplate);
  const adaptiveContext: ACData.IEvaluationContext = {
    $root: {
      ...cardData,
    },
  };
  const cardPayload = adaptiveCardTemplate.expand(adaptiveContext);
  if (cardElementsToAppend) {
    cardPayload.body.push(cardElementsToAppend);
  }

  const cardToShare = JSON.stringify(cardPayload);

  if (recipientChannelsSelected && recipientChannelsSelected.length) {
    cardPayload?.body.push(channelsCardFooter());
    cardToShareChannels = JSON.stringify(cardPayload);
  }

  return { cardToShare, cardToShareChannels };
}

export { ExportCardToStringJSON, RenderCard };
