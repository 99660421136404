import { Flex, Skeleton, Table } from "@fluentui/react-northstar";
import i18next from "i18next";

import stylesCloseCollaborators from "../../common/styles/close-collaborators.module.scss";

export default function ReverseCollaboratorsLoading({
  themeName,
}: {
  themeName: string;
}) {
  const loadingItems = Array.from(Array(5).keys());

  const getHeaderLoading = () => ({
    key: "header",
    className: stylesCloseCollaborators.tableHeaderReverse,
    items: [
      {
        content: (
          <Flex gap="gap.small" vAlign="center" hAlign="center">
            <Skeleton
              className={stylesCloseCollaborators.loaderHeaderIcon}
              animation="pulse"
            >
              <Skeleton.Shape round width="16px" height="16px" />
            </Skeleton>
            <Skeleton animation="pulse">
              <Skeleton.Line width="100px" height="8px" />
            </Skeleton>
          </Flex>
        ),
        key: "header-user",
        className: [
          stylesCloseCollaborators.firstCol,
          stylesCloseCollaborators.firstColReverse,
        ].join(" "),
      },
      {
        content: (
          <Flex vAlign="center" hAlign="center">
            <Skeleton animation="pulse">
              <Skeleton.Line width="75px" height="8px" />
            </Skeleton>
          </Flex>
        ),
        key: "header-trip-dates",
        className: stylesCloseCollaborators.tripDatesCol,
      },
      {
        content: "",
        key: "header-menu",
        className: stylesCloseCollaborators.menuColReverse,
      },
    ],
  });

  const getUserReverseLoading = (index: number) => ({
    content: (
      <Flex className={stylesCloseCollaborators.userNameContainer}>
        <Flex
          gap="gap.small"
          vAlign="center"
          className={stylesCloseCollaborators.userText}
        >
          <Flex.Item>
            <Skeleton
              className={stylesCloseCollaborators.loaderIcon}
              animation="pulse"
            >
              <Skeleton.Shape round width="32px" height="32px" />
            </Skeleton>
          </Flex.Item>
          <Flex.Item className={stylesCloseCollaborators.userText}>
            <Flex
              column
              className={stylesCloseCollaborators.userInformationContainer}
            >
              <Skeleton animation="pulse">
                <Skeleton.Line width="100px" height="8px" />
              </Skeleton>
            </Flex>
          </Flex.Item>
        </Flex>
      </Flex>
    ),
    key: `${index}-user-rc`,
    className: [
      stylesCloseCollaborators.firstCol,
      stylesCloseCollaborators.firstColReverse,
    ].join(" "),
  });

  const getTripDatesLoader = (index: number) => ({
    content: (
      <Flex vAlign="center" hAlign="center">
        <Skeleton animation="pulse">
          <Skeleton.Line width="75px" height="8px" />
        </Skeleton>
      </Flex>
    ),
    key: `${index}-trip-dates-rc`,
    className: stylesCloseCollaborators.tripDatesCol,
  });

  const getChatIconReverseLoader = (index: number) => ({
    content: (
      <Flex vAlign="center" hAlign="center">
        <Skeleton
          className={stylesCloseCollaborators.loaderChatIcon}
          animation="pulse"
        >
          <Skeleton.Shape round width="16px" height="16px" />
        </Skeleton>
      </Flex>
    ),
    key: `${index}-options-rc`,
    className: [
      stylesCloseCollaborators.options,
      stylesCloseCollaborators.menuColReverse,
    ].join(" "),
  });

  return (
    <Flex column>
      <Table
        header={getHeaderLoading()}
        rows={loadingItems.map((index) => ({
          key: `${index}-row-rc`,
          items: [
            getUserReverseLoading(index),
            getTripDatesLoader(index),
            getChatIconReverseLoader(index),
          ],
          className: stylesCloseCollaborators.userRow,
        }))}
        className={[
          themeName === "light"
            ? stylesCloseCollaborators.white
            : stylesCloseCollaborators.dark,
          stylesCloseCollaborators.table,
          stylesCloseCollaborators.tableReverse,
        ].join(" ")}
        aria-label={i18next.t("closeCollaborators.table.tableAriaLabel", {
          numberOfRows: loadingItems.length + 1,
          numberOfColumns: "3",
        })}
      />
    </Flex>
  );
}
