import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Open20Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import { useAppDispatch } from "../../../store/StoreHooks";
import BookRedirection from "../UpcomingTrips/CardBookTripButtons/Components/BookRedirection";
import GetUrlCytricNewTab from "./GetUrlCytricNewTab";
import styles from "./planATripButton.module.scss";

export default function PlanATripButton({
  isExternal,
}: {
  isExternal: boolean;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const redirectTravelDashboard = () => {
    telemetryService.trackEvent({ name: "travel-trip-newTrip" });
    const redirectionInput = {
      segmentType: RedirectionTypeEnum.DASHBOARD_WITHOUT_CONTEXT,
      stopOnStartPage: true,
    };
    BookRedirection(t, dispatch, history, redirectionInput);
  };

  return (
    <Button
      aria-label={`${t("upcoming_trips_dashboard.upcomingTrips.buttonText")}`}
      className={styles["plan-a-trip-button"]}
      content={{
        content: t("upcoming_trips_dashboard.upcomingTrips.buttonText"),
        as: "div",
        className: "text-button-beta",
      }}
      data-testid="button-go-to-cytric"
      disabled={false}
      flat
      fluid
      name="Plan a new trip"
      onClick={
        isExternal
          ? () => {
              telemetryService.trackEvent({ name: "travel-trip-cytricWeb" });
              GetUrlCytricNewTab();
            }
          : () => {
              redirectTravelDashboard();
            }
      }
      primary
      role="button"
      tabIndex={0}
      iconPosition="before"
      icon={isExternal ? <Open20Regular /> : null}
    />
  );
}
