import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import RedirectionInput from "../common/models/redirection/RedirectionInput";

export default function trackRedirection(
  redirectionInput: RedirectionInput
) {
  if (redirectionInput.buttonType) {
    if (redirectionInput.trackParams) {
      telemetryService.trackEvent(
        {
          name: `chat-notification-${redirectionInput.buttonType}`,
        },
        redirectionInput.trackParams
      );
    } else {
      telemetryService.trackEvent(
        {
          name: `chat-notification-${redirectionInput.buttonType}`,
        }
      );
    }
  }
}
