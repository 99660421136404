import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Box, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../../../../../i18n/i18n";
import firstLetterUppercase from "../../../../../../../utils/firstLetterUppercase";
import AriaStyle from "../../../SegmentTabs.module.scss";
import Divider from "../../components/Divider/Divider";
import Header from "../Header/Header";
import { IHotelSegmentProps } from "./HotelSegment.model";
import Styles from "./HotelSegment.module.scss";

function HotelSegment({ details, index, totalitems }: IHotelSegmentProps) {
  const { checkInDate, checkOutDate, policiesDescription } = details;

  const { t } = useTranslation();
  const hotelPolicy = (
    <div className={Styles.policy}>
      <Text
        styles={(theme) => ({
          color: theme.theme.siteVariables.hotelDetails?.contactInfoText,
        })}
        title={policiesDescription}
        content={policiesDescription}
      />
    </div>
  );
  return (
    <div aria-busy="true" role="grid">
      <span className={AriaStyle.visuallyHidden}>
        {checkOutDate
          ? `${t(
              "upcoming_trips_dashboard.tripcard.from"
            )} ${DateUtils.getDateFormat(
              checkInDate,
              i18n.language,
              DateFormat.DATE_LONG
            )} ${t(
              "upcoming_trips_dashboard.tripcard.to"
            )} ${DateUtils.getDateFormat(
              checkOutDate,
              i18n.language,
              DateFormat.DATE_LONG
            )}${"."} ${totalitems} ${t("tripDetails.item/s")}`
          : `${DateUtils.getDateFormat(
              checkInDate,
              i18n.language,
              DateFormat.DATE_LONG
            )}${"."} ${totalitems} ${t("tripDetails.item/s")}`}
      </span>
      <Text
        role="row"
        aria-hidden
        className={Styles.date}
        as="div"
        styles={(theme) => ({
          color: theme.theme.siteVariables.bookingDetail?.date,
        })}
        content={`${firstLetterUppercase(
          DateUtils.getDateFormat(
            checkInDate,
            i18n.language,
            DateFormat.DATE_SHORT
          )
        )} - ${firstLetterUppercase(
          DateUtils.getDateFormat(
            checkOutDate,
            i18n.language,
            DateFormat.DATE_SHORT
          )
        )}`}
        data-testid="date_trip_details"
      />
      <div
        role="rowgroup"
        aria-label={`${t(
          "upcoming_trips_dashboard.tripcard.icons.Hotel"
        )} ${index} ${t("tripDetails.outOf")} ${totalitems} `}
      >
        <Box
          role="row"
          className={Styles.box}
          styles={(theme) => ({
            borderColor: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.borderColor
              : "",
            background: theme.theme.siteVariables.bookingDetail
              ? theme.theme.siteVariables.bookingDetail.background
              : "",
          })}
        >
          <Header details={details} />
          <Divider colorTheme="bookingDetail" />
          {hotelPolicy}
        </Box>
      </div>
    </div>
  );
}
export default HotelSegment;
