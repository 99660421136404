import {
  DateFormat,
  DateUtils,
  statusIcon,
  statusIconClass,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import stylesUserStatus from "@amadeus-cytric/cytric-teams-react-common-library/dist/components/user-status/UserStatus.module.scss";
import {
  Avatar,
  ChatIcon,
  ContactCardIcon,
  Flex,
  Menu,
  Text,
} from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import i18next from "i18next";
import React from "react";
import i18n from "../../../../i18n/i18n";
import { getDevice } from "../../../../utils/device.utils";
import { ReverseCollaboratorsModel } from "../../close-collaborators/models/CloseCollaboratorsModel";
import stylesCloseCollaborators from "../../common/styles/close-collaborators.module.scss";

export const openChatFromSidebar = (email: string, tripId: string) => {
  const chatUrl = `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
  telemetryService.trackEvent(
    { name: "travel-reverseCollaborators-chat" },
    { tripId }
  );
  app.openLink(chatUrl);
};

export const getHeader = () => ({
  key: "header",
  className: stylesCloseCollaborators.tableHeaderReverse,
  items: [
    {
      content: (
        <Flex gap="gap.small" vAlign="center" hAlign="center">
          <ContactCardIcon outline />
          <Text
            content={i18next.t("reverseCollaborators.table.headerName")}
            size="small"
          />
        </Flex>
      ),
      key: "header-user",
      className: [
        stylesCloseCollaborators.firstCol,
        stylesCloseCollaborators.firstColReverse,
      ].join(" "),
    },
    {
      content: (
        <Flex gap="gap.small" vAlign="center" hAlign="center">
          <Text
            content={i18next.t("reverseCollaborators.table.tripDates")}
            size="small"
          />
        </Flex>
      ),
      key: "header-trip-dates",
      className: stylesCloseCollaborators.tripDatesCol,
    },
    {
      content: "",
      key: "header-menu",
      className: stylesCloseCollaborators.menuColReverse,
    },
  ],
});

export const getUserReverse = (
  collaborator: ReverseCollaboratorsModel,
  index: number
) => ({
  content: (
    <Flex className={stylesCloseCollaborators.userNameContainer}>
      <Flex
        gap="gap.small"
        vAlign="center"
        className={stylesCloseCollaborators.userText}
      >
        <Flex.Item>
          <Avatar
            image={{ src: collaborator.image }}
            name={collaborator.displayName}
            className={stylesCloseCollaborators.avatar}
            status={{
              styles: (theme) => ({
                backgroundColor:
                  theme.theme.siteVariables.widgetContainer?.widget,
              }),
              icon: statusIcon(collaborator.availability || ""),
              title: collaborator.activity || "",
              className: `${stylesUserStatus.statusIndicator} ${
                statusIconClass(collaborator.availability || "").icon.class
              }`,
            }}
          />
        </Flex.Item>
        <Flex.Item className={stylesCloseCollaborators.userText}>
          <Flex
            column
            className={stylesCloseCollaborators.userInformationContainer}
          >
            <Text
              content={collaborator.displayName}
              className={stylesCloseCollaborators.userNameText}
              truncated={getDevice() === "desktop"}
            />
            <Text
              content={collaborator.jobTitle}
              weight="semilight"
              className={stylesCloseCollaborators.roleText}
              title={collaborator.jobTitle}
              truncated={getDevice() === "desktop"}
            />
          </Flex>
        </Flex.Item>
      </Flex>
    </Flex>
  ),
  key: `${index}-user-rc`,
  className: [
    stylesCloseCollaborators.firstCol,
    stylesCloseCollaborators.firstColReverse,
  ].join(" "),
});

export const getFormattedDate = (date: any) =>
  DateUtils.getDateFormat(date, i18n.language, DateFormat.DATE_SHORT_DAY_MONTH);

export const getTripDates = (
  collaborator: ReverseCollaboratorsModel,
  index: number
) => ({
  content: (
    <Flex column>
      <Text>{getFormattedDate(collaborator.trip.startDate)}</Text>
      {collaborator.trip.endDate && (
        <Text>{getFormattedDate(collaborator.trip.endDate)}</Text>
      )}
    </Flex>
  ),
  key: `${index}-trip-dates-rc`,
  className: stylesCloseCollaborators.tripDatesCol,
});

export const getChatIconReverse = (
  collaborator: ReverseCollaboratorsModel,
  index: number
) => {
  const chatItem = {
    icon: <ChatIcon outline />,
    key: "chat",
    active: false,
    className: stylesCloseCollaborators.iconDisabledHover,
    "data-testid": `rcChatIcon-${index}`,
    title: i18next.t("closeCollaborators.table.chatWith", {
      user: `${collaborator.displayName}`,
    }),
    onClick: () => {
      openChatFromSidebar(
        collaborator.userPrincipalName || "",
        collaborator.trip.tripId
      );
    },
  };

  return {
    content: <Menu iconOnly items={[chatItem]} />,
    key: `${index}-options-rc`,
    className: [
      stylesCloseCollaborators.options,
      stylesCloseCollaborators.menuColReverse,
    ].join(" "),
  };
};
