/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
import DateUtils from "../../../utils/date.utils";
import getExpiration from "../components/share-my-ride-container/functions/getExpiration";
import { getFormattedGeoCode } from "./shareMyRideUtils";

export function getSegmentDetails(detail: any) {
  return Object.values(detail).flatMap((innerArray) =>
    (innerArray as any[]).flat()
  );
}

function setSharedInfoObject(
  sharedInfoAllSegments: any,
  arrivalObject: any,
  departureObject: any,
  originFeatureEnabled: boolean
) {
  if (originFeatureEnabled) {
    sharedInfoAllSegments.push(departureObject);
  }
  sharedInfoAllSegments.push(arrivalObject);
  return sharedInfoAllSegments;
}

function getFlightServiceProviderInfo(flightDetails: any) {
  const airlineNumbersObj = flightDetails.reduce(
    (
      acc: { [key: string]: string[] },
      flight: { serviceProvider: string; number: string }
    ) => {
      acc[flight.serviceProvider] = acc[flight.serviceProvider] || [];
      acc[flight.serviceProvider].push(flight.number);
      return acc;
    },
    {}
  );

  return Object.entries(airlineNumbersObj)
    .map(
      ([serviceProvider, numbers]: [string, any]) =>
        `${serviceProvider}, ${numbers.join(", ")}`
    )
    .join(", ");
}

function getRailServiceProviderInfo(railDetails: any) {
  return railDetails.basicDetails?.serviceProvidersWithNumbers
    .map(
      (info: { name: string; numbers: string[] }) =>
        `${info.name}, ${info.numbers.join(", ")}`
    )
    .join(", ");
}

export default function filterAllSegments(
  trip: any,
  railDetails: any,
  flightDetails: any,
  originFeatureEnabled: boolean
) {
  let sharedInfoAllSegments: any[] = [];
  if (
    !railDetails["Invalid Date"] ||
    (trip.products && trip.products[0].hasRail)
  ) {
    const railsArray = getSegmentDetails(railDetails);

    railsArray.forEach((rail: any) => {
      const railObjectArrival = {
        arrivalStation: rail.basicDetails.arrival,
        departureStation: rail.basicDetails.departure,
        date: rail.basicDetails.arrivalDateTime,
        serviceProvider:
          rail.extendedDetails[rail.extendedDetails.length - 1].serviceProvider,
        vehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[
            rail.basicDetails.serviceProvidersWithNumbers.length - 1
          ].numbers[
            rail.basicDetails.serviceProvidersWithNumbers[0].numbers.length - 1
          ],
        geoCode: getFormattedGeoCode(
          rail.extendedDetails[
            rail.extendedDetails.length - 1
          ].arrival.geoCode?.latitude?.toString(),
          rail.extendedDetails[
            rail.extendedDetails.length - 1
          ].arrival.geoCode?.longitude?.toString()
        ),
        prevServiceProvider: rail.extendedDetails[0].serviceProvider,
        prevVehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[0].numbers[0],
        type: "RAIL",
        locationType: "ARRIVAL",
        arrivalDateTime: DateUtils.getUTCTime(
          rail.basicDetails.arrivalDateTime
        ),
        departureDateTime: DateUtils.getUTCTime(
          rail.basicDetails.departureDateTime
        ),
        serviceProviderInfo: getRailServiceProviderInfo(rail),
      };

      const railObjectDeparture = {
        arrivalStation: rail.basicDetails.arrival,
        departureStation: rail.basicDetails.departure,
        date: rail.basicDetails.departureDateTime,
        serviceProvider: rail.extendedDetails[0].serviceProvider,
        vehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[0].numbers[
            rail.basicDetails.serviceProvidersWithNumbers[0].numbers.length - 1
          ],
        geoCode: getFormattedGeoCode(
          rail.extendedDetails[0].departure.geoCode?.latitude?.toString(),
          rail.extendedDetails[0].departure.geoCode?.longitude?.toString()
        ),
        prevServiceProvider: rail.extendedDetails[0].serviceProvider,
        prevVehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[0].numbers[0],
        type: "RAIL",
        locationType: "DEPARTURE",
        arrivalDateTime: DateUtils.getUTCTime(
          rail.basicDetails.arrivalDateTime
        ),
        departureDateTime: DateUtils.getUTCTime(
          rail.basicDetails.departureDateTime
        ),
        serviceProviderInfo: getRailServiceProviderInfo(rail),
      };

      const notExpired = getExpiration(rail.basicDetails.arrivalDateTime);
      if (notExpired) {
        sharedInfoAllSegments = setSharedInfoObject(
          sharedInfoAllSegments,
          railObjectArrival,
          railObjectDeparture,
          originFeatureEnabled
        );
      }
    });
  }

  if (
    !flightDetails["Invalid Date"] ||
    (trip.products && trip.products[0].hasAir)
  ) {
    const airArray = getSegmentDetails(flightDetails);
    airArray.forEach((flight: any) => {
      const firstFlight = flight?.extendedDetails[0];
      const lastFlight =
        flight?.extendedDetails[flight.extendedDetails.length - 1];

      const flightObjectArrival = {
        arrivalAirportName: lastFlight?.arrival.airportName,
        airportCode: lastFlight?.arrival.iataCode,
        date: lastFlight?.arrival.dateTime,
        flightReference: `${lastFlight?.carrierCode}${lastFlight?.number}`,
        prevFlightReference: `${firstFlight?.carrierCode}${firstFlight?.number}`,
        terminal: lastFlight?.arrival.terminal,
        type: "AIR",
        locationType: "ARRIVAL",
        arrivalIataCode: lastFlight.arrival.iataCode,
        departureIataCode: firstFlight.departure.iataCode,
        departureDateTime: DateUtils.getUTCTime(firstFlight.departure.dateTime),
        arrivalDateTime: DateUtils.getUTCTime(lastFlight.arrival.dateTime),
        serviceProviderInfo: getFlightServiceProviderInfo(
          flight?.extendedDetails
        ),
      };
      const flightObjectDeparture = {
        departureAirportName: firstFlight?.departure.airportName,
        airportCode: firstFlight?.departure.iataCode,
        date: firstFlight?.departure.dateTime,
        flightReference: `${firstFlight?.carrierCode}${firstFlight?.number}`,
        prevFlightReference: `${firstFlight?.carrierCode}${firstFlight?.number}`,
        terminal: firstFlight?.departure.terminal,
        type: "AIR",
        locationType: "DEPARTURE",
        arrivalIataCode: lastFlight.arrival.iataCode,
        departureIataCode: firstFlight.departure.iataCode,
        departureDateTime: DateUtils.getUTCTime(firstFlight.departure.dateTime),
        arrivalDateTime: DateUtils.getUTCTime(lastFlight.arrival.dateTime),
        serviceProviderInfo: getFlightServiceProviderInfo(
          flight?.extendedDetails
        ),
      };
      const notExpired = getExpiration(flight.basicDetails.arrivalDateTime);
      if (notExpired) {
        sharedInfoAllSegments = setSharedInfoObject(
          sharedInfoAllSegments,
          flightObjectArrival,
          flightObjectDeparture,
          originFeatureEnabled
        );
      }
    });
  }

  const sortedSegments = [...sharedInfoAllSegments].sort((a: any, b: any) =>
    a.date > b.date ? 1 : -1
  );

  return sortedSegments;
}
