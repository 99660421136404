import { useEffect, useState } from "react";

const getWindowDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export default function useScreenSize() {
  const [size, setSize] = useState(0);

  useEffect(() => {
    const updateSize = () => {
      const { width } = getWindowDimensions();
      setSize(width);
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return size;
}
