const DateUtils = {
  formatDate: (date: Date) => {
    const dateFormat = new Date(date);
    let month = `${dateFormat.getMonth() + 1}`;
    let day = `${dateFormat.getDate()}`;
    const year = dateFormat.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  },
  formatLongDate: (date: string) => {
    const dateFormat = new Date(date);
    return dateFormat.toLocaleDateString("en-GB", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  },
  formatLongestDate: (date: string, local: string) => {
    const dateFormat = new Date(date);
    return dateFormat.toLocaleDateString(local, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  },
  getDateFromString: (date: string) => new Date(date),
  getDateInMiliseconds: (date: string) => new Date(date).valueOf(),
  getTimeFromDate: (date: string) =>
    new Date(date).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    }),
  getHoursFromMilliseconds: (millisec: number) => {
    const minutes = Math.floor((millisec / (60 * 1000)) % 60);
    const hours = Math.floor((millisec / (3600 * 1000)) % 24);
    const days = Math.floor((millisec / (24 * 3600 * 1000)) % 365);

    if (days === 0 && hours === 0 && minutes === 0) {
      return "0m";
    }

    const getDays = () => (days > 0 ? `${days}d ` : "");
    const getHours = () => (hours > 0 ? `${hours}h ` : "");
    const getMinutes = () => (minutes > 0 ? `${minutes}m` : "");

    return `${getDays()} ${getHours()} ${getMinutes()}`;
  },
  getDayDifference: (
    departureDate: string | Date,
    arrivalDate: string | Date
  ) => {
    const departure = departureDate?.toString()?.substring(0, 10);
    const arrival = arrivalDate?.toString()?.substring(0, 10);
    const sameDay = departure === arrival;

    if (sameDay) {
      return "";
    }

    const diffInMs =
      new Date(arrival).valueOf() - new Date(departure).valueOf();
    const diffInDays = diffInMs / (1000 * 3600 * 24);

    return `+${diffInDays}`;
  },
  getUTCTime: (date: string | Date) => {
    if (!date) {
      return "";
    }

    const getHours = new Date(date).getUTCHours();

    const getMinutes =
      new Date(date).getUTCMinutes() < 10
        ? `0${new Date(date).getUTCMinutes()}`
        : new Date(date).getUTCMinutes();

    return `${getHours}:${getMinutes}`;
  },
  getUTCDate: (date: string) => new Date(date).toUTCString().slice(0, 17),
};

export default DateUtils;
