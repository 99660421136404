/* eslint-disable no-param-reassign */
/* eslint-disable no-debugger */
import i18next from "i18next";
import HostSettings from "../../../../utils/host.settings";
import getBatchChannelsGraphCall from "./GetBatchChannels";
import getBatchPhotos from "./GetBatchPhotos";
import getImageObject from "./GetImageObject";

export function FilterChannels(
  teamsInfo: any,
  listChannels: any,
  searchQuery: any
) {
  const finalChannels: any = [];
  listChannels
    .filter((channelItem: any) => channelItem.body.value)
    .forEach((response: any) => {
      response.body?.value.map((channel: any) => {
        const teamChannelInfo = teamsInfo.find(
          (team: any) => team.id === response.id
        );
        if (teamChannelInfo !== undefined) {
          channel.teamId = teamChannelInfo.id;
          channel.teamName = teamChannelInfo.displayName;
        }
        if (
          channel.displayName
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          channel.teamName
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        ) {
          finalChannels.push(channel);
          return finalChannels;
        }
        return finalChannels;
      });
    });

  return finalChannels;
}

export default async function GetChannels(
  teamsInfo: any,
  graph: any,
  query: any,
  searchQuery: any
) {
  let channels: any;
  let imageTeam: any;
  let formattedImages: any;
  const finalChannels: any = [];

  if (teamsInfo) {
    const graphChannels = await getBatchChannelsGraphCall(teamsInfo, graph);

    if (graphChannels.length) {
      channels = await FilterChannels(teamsInfo, graphChannels, searchQuery);
      const filteredTeams = Array.from(
        new Set(channels.map((channel: any) => channel.teamId))
      );
      imageTeam = await getBatchPhotos(filteredTeams, graph, "teams");
      const photoError = `${HostSettings.getCDNImages}default-avatar.png`;
      formattedImages = getImageObject(imageTeam, photoError);
    }

    graphChannels
      .filter((channelsOK) => channelsOK.body.value)
      .forEach((graphChannel: any) => {
        graphChannel.body?.value.map((channel: any) => {
          const teamsImage = formattedImages.find(
            (image: any) => image.id === channel.teamId
          );
          if (teamsImage !== undefined) {
            channel.image = teamsImage;
          }
          return channel;
        });
      });

    const { t } = i18next;
    channels.forEach((elem: any) => {
      const newElement = {
        header: `${elem.teamName} - ${elem.displayName}`,
        key: elem.id,
        teamid: elem.teamId,
        image: elem.image.image,
        type: "channel",
        content: t("searchContacts.channel"),
      };
      finalChannels.push(newElement);
    });
  }
  return finalChannels.sort((a: any, b: any) => (a.header > b.header ? 1 : -1));
}
