import { Open16Filled } from "@fluentui/react-icons";
import { Button, Divider, Flex } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import FeatureToggle from "../../../../../common/models/FeatureToggle";
import { isPunchOut } from "../PunchOutLogic";

interface IFooterActionsProps {
  setOpen: (value: boolean) => void;
  bookTrain: () => void;
  value: string;
  setValue: (text: string) => void;
  toggle: FeatureToggle | undefined;
}

function DialogFooter({
  setOpen,
  bookTrain,
  value,
  setValue,
  toggle,
}: IFooterActionsProps) {
  const { t } = useTranslation();
  const buttonContent = t(
    "upcoming_trips_dashboard.upcomingTrips.newBook.dialog.search"
  );

  const closePopUp = () => {
    setValue("");
    setOpen(false);
  };

  return (
    <Flex column gap="gap.medium">
      <Divider />
      <Flex
        gap="gap.medium"
        style={{
          justifyContent: "end",
        }}
      >
        <Button
          content={t("events.taskModuleEvents.backButton")}
          onClick={() => closePopUp()}
          data-testid="event-button-template-back"
        />
        <Button
          primary
          content={t(
            "upcoming_trips_dashboard.upcomingTrips.newBook.dialog.search"
          )}
          onClick={bookTrain}
          disabled={value === ""}
          data-testid="event-button-template-next"
          icon={
            isPunchOut(value, toggle) ? (
              <Open16Filled data-testid="external-icon-book-train-taskmodule" />
            ) : null
          }
          iconPosition="before"
          aria-label={
            isPunchOut(value, toggle)
              ? t(
                  "upcoming_trips_dashboard.upcomingTrips.newBook.dialog.searchExternalLink"
                )
              : buttonContent
          }
        />
      </Flex>
    </Flex>
  );
}

export default DialogFooter;
