import { FormTextArea } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import stylesShareFlight from "../../common/styles/share-flight.module.scss";

interface MessageToShareProps {
  setMessageText: (text: string) => void;
  isOptional: boolean;
}

function MessageToShare(props: MessageToShareProps) {
  const { setMessageText, isOptional } = props;
  const textareaRef = React.useRef<any>(null);
  const [textAreaHeight] = useState(16.1);
  const [timer, setTimer] = useState<any>();
  const { t } = useTranslation("translation", {
    keyPrefix: "shareTrip",
  });

  const handleTextAreaResize = (e: any) => {
    // this height is selected after checking the height of the text area with one row
    textareaRef.current.style.height = "36px";
    // Set height
    if (textareaRef.current.scrollHeight > textAreaHeight) {
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + 6
      }px`;
    }
    clearTimeout(timer);
    let text = e.target.value;
    text = text.replace(/\\/g, "\\\\").replace(/"/g, '\\"');

    const newTimer = setTimeout(() => {
      setMessageText(text);
    }, 500);

    setTimer(newTimer);
  };
  return (
    <FormTextArea
      onChange={handleTextAreaResize}
      data-testid="autoCompleteShareTrip"
      ref={textareaRef}
      label={isOptional ? t("optionalMessageLabel") : t("messageLabel")}
      name="message"
      placeholder={t("optionalMessagePlaceholder")}
      fluid
      style={{
        minHeight: "36px",
        height: "36px",
        maxHeight: "82px",
      }}
      className={stylesShareFlight.formBlock}
    />
  );
}

export default MessageToShare;
