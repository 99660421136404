/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  isError: false,
  expensesToSubmit: [] as any,
};

const expenseStatementsToSubmitSlice = createSlice({
  name: "expenseStatementsToSubmit",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsError(state, action) {
      state.isError = action.payload;
    },
    setExpensesToSubmit(state, action) {
      state.expensesToSubmit = action.payload;
    },
  },
});

export const expenseStatementsToSubmitActions =
  expenseStatementsToSubmitSlice.actions;

export default expenseStatementsToSubmitSlice.reducer;
