import Settings from "./Settings";
import "./Settings.scss";

function SettingsWrapper() {
  return (
    <div data-testid="settings-tab" className="settingsWrapper">
      <Settings />
    </div>
  );
}

export default SettingsWrapper;
