/* eslint-disable no-unneeded-ternary */
import {
  DateFormat,
  DateUtils,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  Box,
  Button,
  Checkbox,
  EditIcon,
  MoreIcon,
  Popup,
  Text,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/i18n";
import { useAppDispatch } from "../../../../store/StoreHooks";
import { todosActions } from "../../../../store/todos/todos-slice";
import { KeepSessionActions } from "../../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../../utils/KeepUserActivityContext";
import useTodoRequest from "../../useTodoRequest";
import { ISubmenuItem, IToDoTaskProps } from "./ToDoTask.model";
import Styles from "./ToDoTask.module.scss";

const UNCHECKED = "UNCHECKED";
const CHECKED = "CHECKED";

function SubmenuItem({ icon, content, event, testId }: ISubmenuItem) {
  return (
    <Button
      as="div"
      icon={icon}
      content={content}
      onClick={event}
      text
      data-testid={testId}
    />
  );
}

function ToDoTask({ todo, dispatchDialog }: IToDoTaskProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getTodos, updateTodo, deleteTodo } = useTodoRequest();
  const [showPopup, setShowPopup] = useState(false);

  const reloadTodos = () => {
    getTodos().then((resData) => {
      dispatch(todosActions.setTodos(resData));
    });
  };

  const checkHandler = () => {
    const { id, status } = todo;
    const newStatus = status === UNCHECKED ? CHECKED : UNCHECKED;
    const payload = {
      ...todo,
      id,
      status: newStatus,
    };

    updateTodo(id, payload).then(reloadTodos);

    telemetryService.trackEvent(
      { name: "travel-todo-check" },
      {
        isChecked: newStatus === CHECKED ? true : false,
      }
    );
  };

  const onDeleteHandler = () => {
    setShowPopup(false);
    deleteTodo(todo.id).then(reloadTodos);

    telemetryService.trackEvent({ name: "travel-todo-delete" });
  };

  const onEditHandler = () => {
    new ActivityClass().update(KeepSessionActions.DETAILS_ADDTASK, {
      type: "editTask",
      payload: todo,
    });
    dispatchDialog({ type: "editTask", payload: todo });
  };

  const submenuContent = (
    <div className={Styles.submenu}>
      <SubmenuItem
        icon={<EditIcon outline />}
        content={t("toDo.toDoList.edit")}
        event={onEditHandler}
        testId="to-do-task-edit-task"
      />
      <SubmenuItem
        icon={<TrashCanIcon outline />}
        content={t("toDo.toDoList.delete")}
        event={onDeleteHandler}
        testId="to-do-task-delete-task"
      />
    </div>
  );

  const taskIsDone = todo.status === CHECKED;

  const isDoneColor = taskIsDone ? "checkboxLabelDone" : "checkboxLabel";

  const dateColor = () => {
    const dateIsOver = moment().startOf("day").isAfter(moment(todo.dueDate));
    const dateShouldBeRed = dateIsOver && !taskIsDone;
    const dateColorTaskNotDone = dateShouldBeRed ? "dateOver" : "date";
    return taskIsDone ? "dateDone" : dateColorTaskNotDone;
  };

  const formattedDate =
    todo?.dueDate &&
    DateUtils.getDateFormat(
      todo?.dueDate,
      i18n.language,
      DateFormat.DATE_SHORT
    );

  return (
    <Box
      className={Styles["to-do-task"]}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables.colorScheme?.default.background1,
        borderColor: theme.theme.siteVariables?.toDoList?.borderColor,
      })}
    >
      <div>
        <Checkbox
          className={`${Styles["to-do-task__title"]} ${
            taskIsDone && Styles["to-do-task--done"]
          }`}
          label={todo?.title}
          styles={(theme) => ({
            color: theme.theme.siteVariables?.toDoList?.[isDoneColor],
          })}
          data-testid="to-do-task-checkbox"
          checked={taskIsDone}
          onChange={checkHandler}
        />
        <Text
          as="p"
          className={`${
            taskIsDone
              ? Styles["to-do-task__date--done"]
              : Styles["to-do-task__date"]
          }`}
          styles={(theme) => ({
            color: theme.theme.siteVariables?.toDoList?.[dateColor()],
          })}
          aria-label={
            formattedDate
              ? `${t("toDo.toDoList.dueDate")} ${todo?.dueDate}`
              : ""
          }
        >
          {formattedDate}
        </Text>
      </div>
      <Popup
        trigger={
          <Button
            text
            iconOnly
            icon={
              <MoreIcon
                data-testid="to-do-task-more-icon"
                styles={(theme) => ({
                  color: theme.theme.siteVariables?.toDoList?.moreIcon,
                })}
                className={Styles["to-do-task__more-options"]}
                aria-hidden={false}
                aria-label="More icon"
              />
            }
          />
        }
        content={submenuContent}
        inline
        position="below"
        align="end"
        open={showPopup}
        onOpenChange={() => setShowPopup((prev) => !prev)}
      />
    </Box>
  );
}

export default ToDoTask;
