import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CytricContextModel {
  lastVisitedUrlExpense: string;
  lastVisitedPageExpense: string;
  lastUrlDateTimeoutExpense: number;
}

const INITIAL_STATE: CytricContextModel = {
  lastVisitedUrlExpense: localStorage.getItem("lastVisitedUrlExpense") || "",
  lastVisitedPageExpense: localStorage.getItem("lastVisitedPageExpense") || "",
  lastUrlDateTimeoutExpense: Number(
    localStorage.getItem("lastUrlDateTimeoutExpense") || 0
  ),
};

const IFRAME_SESSION_TIMEOUT: number = Number(
  process.env.REACT_APP_CYTRIC_IFRAME_SESSION_TIMEOUT || 600000
);

export const cytricContextSlice = createSlice({
  name: "cytricExpenseContext",
  initialState: INITIAL_STATE,
  reducers: {
    updateCytricExpenseContext: (
      state,
      action: PayloadAction<{
        urlExpense: string;
        pageNameExpense: string;
      }>
    ) => {
      const timeoutSession = new Date(
        new Date().getTime() + IFRAME_SESSION_TIMEOUT
      ).getTime();

      localStorage.setItem("lastVisitedUrlExpense", action.payload.urlExpense);
      localStorage.setItem(
        "lastVisitedPageExpense",
        action.payload.pageNameExpense
      );
      localStorage.setItem("lastUrlDateTimeoutExpense", String(timeoutSession));

      return {
        ...state,
        lastVisitedUrlExpense: action.payload.urlExpense,
        lastVisitedPageExpense: action.payload.pageNameExpense,
        lastUrlDateTimeoutExpense: timeoutSession,
      };
    },

    resetCytricExpenseContext: (state) => {
      localStorage.setItem("lastVisitedUrlExpense", "");
      localStorage.setItem("lastVisitedPageExpense", "");
      localStorage.setItem("lastUrlDateTimeoutExpense", "0");
      return {
        ...state,
        lastVisitedUrlExpense: "",
        lastVisitedPageExpense: "",
        lastUrlDateTimeoutExpense: 0,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCytricExpenseContext, resetCytricExpenseContext } =
  cytricContextSlice.actions;

export default cytricContextSlice.reducer;
