import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex, TeamCreateIcon } from "@fluentui/react-northstar";
import i18next from "i18next";
import { useStore } from "../../context/ShareMyRideContext";
import { createChat } from "../share-my-ride-container/functions/shareMyRideApi";
import { IShareMyRideFooter } from "./ShareMyRideFooter.model";
import Styles from "./ShareMyRideFooter.module.scss";

function ShareMyRideFooter({
  themeName,
  selectedUsers,
  isMobile,
  tripId,
}: IShareMyRideFooter) {
  const { activeSegment } = useStore();

  const filteredUsers = selectedUsers.filter((str: string) => str !== "");

  return (
    <Flex
      hAlign="end"
      className={[
        themeName === "light" ? Styles.white : Styles.dark,
        Styles["footer-container"],
      ].join(" ")}
      data-testid="footer-container"
    >
      <Button
        className={Styles["footer-container__button"]}
        icon={<TeamCreateIcon outline />}
        content={i18next.t("shareMyRide.footer.groupChatButtonLabel", {
          numberOfSelectedUser: filteredUsers.length,
        })}
        iconPosition="before"
        primary
        fluid={isMobile}
        onClick={() => {
          telemetryService.trackEvent(
            { name: "travel-shareRide-groupChat" },
            { tripId, numOfItems: filteredUsers.length }
          );
          createChat(
            activeSegment?.locationType,
            activeSegment?.productDetails,
            activeSegment?.date,
            filteredUsers,
            activeSegment?.type,
            tripId
          );
        }}
        data-testid="group-chat-button"
      />
    </Flex>
  );
}

export default ShareMyRideFooter;
