import { createSlice } from "@reduxjs/toolkit";
import { IError } from "../../common/models/Error";
import Trip from "../../common/models/Trip";
import { fetchUserTrips, TRIPS_STORE_NAME } from "./TripsActions";

interface TripsState {
  values: Trip[];
  isEmpty: boolean;
  isPending: boolean;
  isError: boolean;
  error: IError;
}

const initialState: TripsState = {
  values: [],
  isEmpty: false,
  isPending: false,
  isError: false,
  error: {} as IError,
};

/*
  Reducers to overwrite the state
 */
export const tripsSlice = createSlice({
  name: TRIPS_STORE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserTrips.pending, (state) => ({
      ...state,
      values: [],
      isPending: true,
      isError: false,
      isEmpty: false,
      error: {} as IError,
    }));

    builder.addCase(fetchUserTrips.fulfilled, (state, action) => {
      if (action.payload && action.payload.length) {
        return {
          ...state,
          values: action.payload,
          isPending: false,
          error: {} as IError,
        };
      }
      return {
        ...state,
        values: [],
        isEmpty: true,
        isPending: false,
        error: {} as IError,
      };
    });

    builder.addCase(fetchUserTrips.rejected, (state, action: any) => ({
      ...state,
      isPending: false,
      isError: true,
      isEmpty: false,
      error: action.payload as IError,
    }));
  },
});

export default tripsSlice.reducer;
