/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/StoreHooks";
import { getProductsByType } from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors";
import { getHotelDetails } from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors-hotel";
import { TRIP_PRODUCT_HOTEL } from "../../../../../../data/constants";
import BookingRedirections from "../../../../BookingRedirections";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import NewProduct from "../components/NewProduct/NewProduct";
import { IHotelSegment } from "./Hotel.model";
import HotelSegment from "./HotelSegment/HotelSegment";

function Hotel({ pastTrip }: any) {
  const { t } = useTranslation();
  const hotels = useAppSelector((state) =>
    getProductsByType(state, TRIP_PRODUCT_HOTEL)
  );
  const trip = useAppSelector((state) => state.tripDetail.trip);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const bookHotel = () => {
    const name = trip.name ?? "";
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookHotel" },
      {
        numOfHotels: 0,
        tripId: trip.id,
      }
    );
    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_HOTEL,
      tripId: trip.id,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  if (hotels?.length) {
    return (
      <>
        {hotels &&
          hotels.map((hotel: IHotelSegment, index: number) => {
            const details = getHotelDetails(hotel);
            return (
              <HotelSegment
                details={details}
                key={`${hotel.name}-${index}`}
                index={index + 1}
                totalitems={hotels.length}
              />
            );
          })}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({ productType: "hotel", numOfProducts: hotels.length })}
      </>
    );
  }

  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.hotel")}
      />
      {CheckFeatureEnabled() && (
        <Button
          aria-label={t(
            "upcoming_trips_dashboard.tripDetails.newProduct.buttons.hotel"
          )}
          content={t(
            "upcoming_trips_dashboard.tripDetails.newProduct.buttons.hotel"
          )}
          onClick={() => bookHotel()}
          flat
        />
      )}
    </Flex>
  );
}
export default Hotel;
