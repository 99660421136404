import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SegmentObj {
  productType: string;
  id: string;
}

interface SegmentSelectedModel {
  selectedTripId: string;
  selectedSegments: SegmentObj[];
}

const INITIAL_STATE: SegmentSelectedModel = {
  selectedTripId: "",
  selectedSegments: new Array<SegmentObj>(),
};

const getSegments = (actualSelected: SegmentObj[], payload: any) => {
  let selected = [...actualSelected];
  if (payload.isSelected) {
    selected = selected.find(
      (segmentId) =>
        segmentId.id === payload.segmentId.id &&
        segmentId.productType === payload.segmentId.productType
    )
      ? selected
      : [...selected, payload.segmentId];
  } else {
    selected = selected.filter((value) => value.id !== payload.segmentId.id);
  }

  return selected;
};

export const segmentSelectedSlice = createSlice({
  name: "segmentSelected",
  initialState: INITIAL_STATE,
  reducers: {
    updateSegmentSelected: (
      state,
      action: PayloadAction<{
        tripId: string;
        segmentId: SegmentObj;
        isSelected: boolean;
      }>
    ) => {
      const currentState: SegmentSelectedModel = {
        selectedTripId: state.selectedTripId,
        selectedSegments: state.selectedSegments,
      };

      if (state.selectedTripId !== action.payload.tripId) {
        currentState.selectedSegments = new Array<SegmentObj>();
      }
      currentState.selectedTripId = action.payload.tripId;

      currentState.selectedSegments = getSegments(
        currentState.selectedSegments,
        action.payload
      );

      return {
        ...state,
        selectedSegments: currentState.selectedSegments,
        selectedTripId: currentState.selectedTripId,
      };
    },
    updateTripSelected: (
      state,
      action: PayloadAction<{ selectedTripId: string }>
    ) => ({
      ...state,
      selectedSegments: new Array<SegmentObj>(),
      selectedTripId: action.payload.selectedTripId,
    }),

    resetSegmentSelected: (state) => ({
      ...state,
      selectedSegments: new Array<SegmentObj>(),
      selectedTripId: "",
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSegmentSelected,
  updateTripSelected,
  resetSegmentSelected,
} = segmentSelectedSlice.actions;

export default segmentSelectedSlice.reducer;
