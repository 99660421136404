import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import { dialog } from "@microsoft/teams-js";
import HostSettings from "../../../utils/host.settings";

function postEvents(data: any): Promise<any> {
  const url = HostSettings.getBaseAPI;
  return Http.post(
    `${url}nc/v0/corporation/notifications/shared-bookings`,
    data
  );
}

export async function SubmitEvents(data: any) {
  const response = await postEvents(data);
  return response;
}

async function SubmitTaskModule(dataToSendObject: any) {
  const data = { data: dataToSendObject };
  const { origin, appCatalogId } = dataToSendObject;

  if (origin === "events") {
    const response = await SubmitEvents(data);
    window.location.href = response?.data.task.value.url;
  } else {
    dialog.url.submit(dataToSendObject, appCatalogId);
  }
}

export default SubmitTaskModule;
