import i18next from "i18next";
import Trip from "../../../../common/models/Trip";
import { DeeplinkOptions } from "./DeeplinkOptions";
import GetCardDeeplinksUrls from "./GetCardUrlFunctions";

export default function cardActions(
  trip: Trip,
  appCatalogId: string,
  deeplinkOptions: DeeplinkOptions
) {
  const { t } = i18next;

  const { flightDeepLink, railDeepLink, carDeepLink, hotelDeepLink } =
    GetCardDeeplinksUrls(trip, appCatalogId, deeplinkOptions);

  const actionType = "Action.OpenUrl";

  const actionsArray = [];
  if (flightDeepLink !== "") {
    actionsArray.push({
      type: actionType,
      title: t("shareTrip.flightDeepLink"),
      url: flightDeepLink,
    });
  }
  if (hotelDeepLink !== "") {
    actionsArray.push({
      type: actionType,
      title: t("shareTrip.hotelDeepLink"),
      url: hotelDeepLink,
    });
  }
  if (carDeepLink !== "") {
    actionsArray.push({
      type: actionType,
      title: t("shareTrip.carDeepLink"),
      url: carDeepLink,
    });
  }
  if (railDeepLink !== "") {
    actionsArray.push({
      type: actionType,
      title: t("shareTrip.railDeepLink"),
      url: railDeepLink,
    });
  }

  return {
    type: "ColumnSet",
    width: "stretch",
    columns: [
      {
        type: "Column",
        width: "stretch",
        items: [
          {
            type: "ActionSet",
            actions: [...actionsArray],
          },
        ],
      },
    ],
  };
}
