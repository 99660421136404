import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import Trip from "../../../common/models/Trip";
import { CP_FLOW_IDENTIFIER } from "../../../utils/constants";
import GetTripTrackInformation from "../functions/GetTripTrackInformation";

const getEventId = (
  recipientGroupsSelected: any,
  recipientChannelsSelected: any
) => {
  if (recipientGroupsSelected[0]) {
    return recipientGroupsSelected[0];
  }
  if (recipientChannelsSelected[0]?.channelId) {
    return recipientChannelsSelected[0]?.channelId;
  }
  return null;
};

export default function trackTelemetry(
  tripToShare: Trip,
  tripTemplate: Trip,
  recipientSelected: any,
  recipientGroupsSelected: any,
  recipientChannelsSelected: any,
  origin: string,
  initialSegments: any
) {
  const tripTrackInformation = GetTripTrackInformation(
    tripToShare,
    tripTemplate,
    origin === CP_FLOW_IDENTIFIER ? initialSegments : null
  );

  if (
    origin === "sharetrip" ||
    origin === "notification" ||
    origin === CP_FLOW_IDENTIFIER
  ) {
    telemetryService.trackEvent(
      {
        name:
          origin === CP_FLOW_IDENTIFIER
            ? "travel-CP-share"
            : "travel-trip-share",
      },
      {
        tripId: tripToShare.id,
        numOfPeople: recipientSelected ? recipientSelected.length : 0,
        numOfGroups: recipientGroupsSelected
          ? recipientGroupsSelected.length
          : 0,
        numOfChannels: recipientChannelsSelected
          ? recipientChannelsSelected.length
          : 0,
        numOfInitialFlights: tripTrackInformation.numOfInitialFlights,
        numOfInitialHotels: tripTrackInformation.numOfInitialHotels,
        numOfInitialCars: tripTrackInformation.numOfInitialCars,
        numOfInitialTrains: tripTrackInformation.numOfInitialTrains,
        numOfInitialItems: tripTrackInformation.numOfInitialItems,
        numOfSharedFlights: tripTrackInformation.numOfSharedFlights,
        numOfSharedHotels: tripTrackInformation.numOfSharedHotels,
        numOfSharedCars: tripTrackInformation.numOfSharedCars,
        numOfSharedTrains: tripTrackInformation.numOfSharedTrains,
        numOfSharedItems: tripTrackInformation.numOfSharedItems,
      }
    );
  } else if (
    (origin === "events" && recipientChannelsSelected[0]?.channelId) ||
    recipientGroupsSelected[0]
  ) {
    telemetryService.trackEvent(
      { name: "event-dashboard-share" },
      {
        tripId: tripToShare.id,
        eventId: getEventId(recipientGroupsSelected, recipientChannelsSelected),
        numOfInitialFlights: tripTrackInformation.numOfInitialFlights,
        numOfInitialHotels: tripTrackInformation.numOfInitialHotels,
        numOfInitialCars: tripTrackInformation.numOfInitialCars,
        numOfInitialTrains: tripTrackInformation.numOfInitialTrains,
        numOfInitialItems: tripTrackInformation.numOfInitialItems,
        numOfSharedFlights: tripTrackInformation.numOfSharedFlights,
        numOfSharedHotels: tripTrackInformation.numOfSharedHotels,
        numOfSharedCars: tripTrackInformation.numOfSharedCars,
        numOfSharedTrains: tripTrackInformation.numOfSharedTrains,
        numOfSharedItems: tripTrackInformation.numOfSharedItems,
      }
    );
  } else {
    telemetryService.trackEvent(
      { name: "chat-extension-share" },
      {
        tripId: tripToShare.id,
        numOfInitialFlights: tripTrackInformation.numOfSharedFlights,
        numOfInitialHotels: tripTrackInformation.numOfSharedHotels,
        numOfInitialCars: tripTrackInformation.numOfSharedCars,
        numOfInitialTrains: tripTrackInformation.numOfSharedTrains,
        numOfInitialItems: tripTrackInformation.numOfSharedItems,
        numOfSharedFlights: tripTrackInformation.numOfSharedFlights,
        numOfSharedHotels: tripTrackInformation.numOfSharedHotels,
        numOfSharedCars: tripTrackInformation.numOfSharedCars,
        numOfSharedTrains: tripTrackInformation.numOfSharedTrains,
        numOfSharedItems: tripTrackInformation.numOfSharedItems,
      }
    );
  }
}
