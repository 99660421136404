import FeatureToggle from "../../common/models/FeatureToggle";
import { RootState } from "../StoreHooks";

export const selectFeatureToggles = (state: RootState): FeatureToggle[] =>
  state.featureToggles.featureToggles;

export const selectActiveFeatureToggles = (state: RootState): string[] =>
  state.featureToggles.featureToggles
    .filter((toggle) => toggle.isActive)
    .map((toggle) => toggle.feature || "");

export const selectFeatureToggle = (
  state: RootState,
  toggleName: string
): FeatureToggle | undefined =>
  state.featureToggles.featureToggles.find(
    (featureToggle) => toggleName === featureToggle.feature
  );

export const selectFeatureTogglesIsPending = (state: RootState): boolean =>
  state.featureToggles.isPending;

export const selectFeatureTogglesIsError = (state: RootState): boolean =>
  state.featureToggles.isError;

export const selectFeatureTogglesIsCompleted = (state: RootState): boolean =>
  state.featureToggles.isCompleted;
