/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Dropdown, Flex } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../../../store/StoreHooks";
import { getProductsByType } from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors";
import { getCarDetails } from "../../../../../../../../store/upcoming-trips-dashboard/trip-detail-selectors/trip-detail-selectors-car";
import { TRIP_PRODUCT_CAR } from "../../../../../../data/constants";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import NewProduct from "../components/NewProduct/NewProduct";
import { ICarSegment } from "./Car.model";
import Styles from "./Car.module.scss";
import CarSegment from "./CarSegment/CarSegment";

function Car({ pastTrip }: any) {
  const { t } = useTranslation();
  const cars = useAppSelector((state) =>
    getProductsByType(state, TRIP_PRODUCT_CAR)
  );
  const trip = useAppSelector((state) => state.tripDetail.trip);

  const bookCar = () => {
    telemetryService.trackEvent(
      { name: "travel-booking-bookCar" },
      {
        numOfCars: 0,
        tripId: trip.id,
      }
    );
  };

  const bookTransfer = () => {
    telemetryService.trackEvent(
      { name: "travel-booking-bookTransfer" },
      {
        numOfCars: 0,
        tripId: trip.id,
      }
    );
  };

  const getCarValue = (_e: any, val: any) => {
    if (val.searchQuery !== "") {
      const trad = t("upcoming_trips_dashboard.upcomingTrips.newBook.Car");
      if (val.searchQuery === trad) {
        bookCar();
      } else {
        bookTransfer();
      }
    }
  };

  if (cars?.length) {
    return (
      <>
        {cars &&
          cars.map((car: ICarSegment, index: number) => {
            const details = getCarDetails(car);
            const key = `${car.confirmationNumber}-${index}`;
            return (
              <CarSegment
                details={details}
                key={key}
                index={index + 1}
                totalitems={cars.length}
              />
            );
          })}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({ productType: "car", numOfProducts: cars.length })}
      </>
    );
  }
  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.car")}
      />
      {CheckFeatureEnabled() && (
        <Flex>
          <Dropdown
            inverted
            styles={(theme) => ({
              borderColor:
                theme.theme.siteVariables?.colorScheme?.default.border,
              backgroundColor:
                theme.theme.siteVariables?.colorScheme?.default.background2,
            })}
            className={Styles.dropDown}
            items={[
              t("upcoming_trips_dashboard.upcomingTrips.newBook.Car"),
              t("upcoming_trips_dashboard.upcomingTrips.newBook.Transfer"),
            ]}
            data-showValueid="button-card-book-car"
            data-testid="button-card-book-car"
            placeholder={t(
              "upcoming_trips_dashboard.tripDetails.placeholder.carTransfer"
            )}
            onSearchQueryChange={getCarValue}
          />
        </Flex>
      )}
    </Flex>
  );
}
export default Car;
