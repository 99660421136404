import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IError } from "../../../common/models/Error";
import { ReverseCollaboratorsModel } from "../../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import { getReverseCollaborators } from "./ReverseCollaboratorsAction";

interface ReverseCollaboratorsStoreModel {
  reverseCollaborators: ReverseCollaboratorsModel[] | undefined;
  isPending: boolean;
  isError: boolean;
  error: IError;
}

const INITIAL_STATE: ReverseCollaboratorsStoreModel = {
  reverseCollaborators: undefined,
  isPending: false,
  isError: false,
  error: {} as IError,
};

export const reverseCollaboratorsSlice = createSlice({
  name: "reverseCollaborators",
  initialState: INITIAL_STATE,
  reducers: {
    resetReverseCollaborators: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getReverseCollaborators.pending, (state) => ({
      ...state,
      ...INITIAL_STATE,
      isPending: true,
    }));

    builder.addCase(
      getReverseCollaborators.fulfilled,
      (
        state,
        action: PayloadAction<{
          reverseCollaborators: ReverseCollaboratorsModel[];
        }>
      ) => ({
        ...state,
        reverseCollaborators: action.payload.reverseCollaborators,
        isPending: false,
        isError: false,
      })
    );

    builder.addCase(
      getReverseCollaborators.rejected,
      (state: any, action: any) => ({
        ...state,
        ...INITIAL_STATE,
        reverseCollaborators: [],
        isPending: false,
        isError: true,
        error: action.payload.error as IError,
      })
    );
  },
});

export const { resetReverseCollaborators } = reverseCollaboratorsSlice.actions;

export default reverseCollaboratorsSlice.reducer;
