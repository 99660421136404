import i18next from "../../../../i18n/i18n";

export default function getFinalObjet(
  users: any,
  usersImages: any,
  type: string
) {
  const isImproveEnabled =
    process.env.REACT_APP_SHARE_GROUPS_TOP_HITS_ENABLED !== "0";

  const usersWithImages = users.map((item: any) => {
    const user: any = item;
    user.image = usersImages.find((y: any) => y.id === item.id);
    return user;
  });

  const { t } = i18next;

  let finalgroupsusers;
  if (isImproveEnabled) {
    finalgroupsusers = usersWithImages.map((item: any) => ({
      header: item.displayName,
      image: item.image?.image,
      key: item.id,
      type: "user",
      content:
        type === "hits"
          ? t("searchContacts.topHit")
          : t("searchContacts.contact"),
    }));
  } else {
    finalgroupsusers = usersWithImages.map((item: any) => ({
      header: item.displayName,
      image: item.image?.image,
      key: item.id,
      type: "user",
    }));
  }

  return finalgroupsusers;
}
