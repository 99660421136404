export default function GetAirportData(
  defaultAirportData: any,
  defaultAirport: string
) {
  const location = defaultAirportData?.data?.data[0]?.location;
  let name = "";
  if (location && location.subType === "city") {
    name = defaultAirportData?.data?.dictionaries?.city[defaultAirport]?.name;
  } else if (location && location.subType === "airport") {
    name =
      defaultAirportData?.data?.dictionaries?.airport[defaultAirport]?.name;
  }

  return {
    name: `${name} (${defaultAirport})`,
    iatacode: defaultAirport,
  };
}
