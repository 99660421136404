/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import lodash from "lodash";
import SearchModel from "../models/SearchModel";

function OverlappingLocations(airState: SearchModel | undefined) {
  let newState;
  const copyState = lodash.cloneDeep(airState);

  removeOverlappingLocations(copyState);

  newState = airState as SearchModel;
  newState = {
    ...airState,
    ...copyState,
    departure: copyState?.departure,
    arrival: copyState?.arrival,
    dateTime: copyState?.dateTime,
    airlineCode: copyState?.airlineCode,
    airlineNumber: copyState?.airlineNumber,
    basisCode: copyState?.basisCode,
    dateTimeType: copyState?.dateTimeType,
    timeWindowInHours: copyState?.timeWindowInHours,
    numberOfFlights: copyState?.numberOfFlights,
    stopOnStartPage: shouldStopOnStartPageWhenOverlappingLocations(copyState),
  };

  return newState;
}

export function HasOverlappingLocations(airState: SearchModel | undefined) {
  const departures = airState?.departure;
  const arrivals = airState?.arrival;

  if (
    departures &&
    arrivals &&
    departures.length > 2 &&
    (departures[0] === arrivals[0] ||
      departures[departures.length - 1] === arrivals[arrivals.length - 1])
  ) {
    return true;
  }

  return false;
}

export default OverlappingLocations;

function removeOverlappingLocations(copyState: SearchModel | undefined) {
  const departures = copyState?.departure;
  const arrivals = copyState?.arrival;

  if (departures && arrivals) {
    removeLocations(departures, arrivals, copyState);
  }
}

function removeLocations(
  departures: string[],
  arrivals: string[],
  copyState: SearchModel | undefined
) {
  const airlineCodes = copyState?.airlineCode;
  const airlineNumbers = copyState?.airlineNumber;
  const basisCodes = copyState?.basisCode;
  const numberOfFlights = copyState?.numberOfFlights;

  if (departures[0] === arrivals[0]) {
    departures.shift();
    arrivals.shift();
    copyState?.dateTime?.shift();
    copyState?.dateTimeType?.shift();
    copyState?.timeWindowInHours?.shift();
    const numberOfFlightsPerLeg = numberOfFlights?.shift();
    // eslint-disable-next-line no-plusplus
    for (
      let index = 0;
      numberOfFlightsPerLeg && index < numberOfFlightsPerLeg;
      index++
    ) {
      airlineCodes?.shift();
      airlineNumbers?.shift();
      basisCodes?.shift();
    }
  }
  if (departures[departures.length - 1] === arrivals[arrivals.length - 1]) {
    departures.pop();
    arrivals.pop();
    copyState?.dateTime?.pop();
    copyState?.dateTimeType?.pop();
    copyState?.timeWindowInHours?.pop();
    const numberOfFlightsPerLeg = numberOfFlights?.pop();
    // eslint-disable-next-line no-plusplus
    for (
      let index = 0;
      numberOfFlightsPerLeg && index < numberOfFlightsPerLeg;
      index++
    ) {
      airlineCodes?.pop();
      airlineNumbers?.pop();
      basisCodes?.pop();
    }
  }
}

// Stop on start booking page if first departure and last arrival locations are the same.
// Probably the user will wants to use the same departure for the last arrival location.
export function shouldStopOnStartPageWhenOverlappingLocations(
  copyState: SearchModel | undefined
) {
  const departures = copyState?.departure;
  const arrivals = copyState?.arrival;

  if (
    departures &&
    arrivals &&
    departures[0] !== arrivals[arrivals.length - 1]
  ) {
    return true;
  }

  return copyState?.stopOnStartPage;
}
