import { Loader, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import Divider from "../../../../common/components/divider/Divider";
import GenericInfo from "../../../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  InfoImage,
} from "../../../../common/components/generic-info-component/GenericInfo.model";
import { useAppSelector } from "../../../../store/StoreHooks";
import styles from "./DialogBody.module.scss";
import { IExpenseStatement } from "./ExpenseStatementInterface";
import ExpenseItem from "./expense-item/ExpenseItem";

function DialogBody() {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.popup.viewList",
  });

  const expenseStatementsToSubmit = useAppSelector(
    (state) => state.expenseStatementsToSubmit.expensesToSubmit
  );
  const isLoading = useAppSelector(
    (state) => state.expenseStatementsToSubmit.isLoading
  );
  const error = useAppSelector(
    (state) => state.expenseStatementsToSubmit.isError
  );

  const errorInfo: IGenericInfo = {
    title: t("ERR_Generic"),
    detail: t("ERR_detail"),
  };

  const ExpensesListComponent = (
    <>
      <Text as="p" className={styles.title}>
        {t("title")}
      </Text>
      <Divider />
      <ul className={styles.list}>
        {expenseStatementsToSubmit?.map((expense: IExpenseStatement) => (
          <ExpenseItem expense={expense} key={expense.id} />
        ))}
      </ul>
    </>
  );

  return (
    <div className={styles["dialog-body"]}>
      {error && (
        <GenericInfo
          image={InfoImage.ERROR}
          infoData={errorInfo}
          isPopupComponent
        />
      )}
      {isLoading && <Loader style={{ margin: 100 }} />}
      {!isLoading && !error && ExpensesListComponent}
    </div>
  );
}

export default DialogBody;
