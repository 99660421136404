import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import HostSettings from "../../utils/host.settings";

export const getCurrencyCode = async () => {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}${HostSettings.getCurrencyCode}`
    );
    if (response.status === 200) {
      const currencyData = await response.data;
      return currencyData.currencyCode;
    }
  } catch (error: any) {
    if (error?.response === undefined) {
      return {
        statusCode: 1,
        errorData: [],
        isError: true,
      };
    }
    return {
      statusCode: error.response.status,
      errorData: error.response.data,
      isError: true,
    };
  }
  return "";
};

export const getDashboardInfoOld = async (item: string) => {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}${HostSettings.getDashboardInfo}?item=${item}`
    );
    if (response.status === 200) {
      const allInfo = await response.data;
      return allInfo.data;
    }
  } catch (error: any) {
    if (error?.response === undefined) {
      return {
        statusCode: 1,
        errorData: [],
        isError: true,
      };
    }
    return {
      statusCode: error.response.status,
      errorData: error.response.data,
      isError: true,
    };
  }
  return null;
};

export const getDashboardInfo = async (item: string, userRoles: string) => {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}${HostSettings.getDashboardInfo}?item=${item}&${userRoles}`
    );
    if (response.status === 200) {
      const allInfo = await response.data;
      return allInfo.data;
    }
  } catch (error: any) {
    if (error?.response === undefined) {
      return {
        statusCode: 1,
        errorData: [],
        isError: true,
      };
    }
    return {
      statusCode: error.response.status,
      errorData: error.response.data,
      isError: true,
    };
  }
  return null;
};

export const getExpenseStatements = async () => {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}${HostSettings.getExpenseStatements}`
    );
    if (response.status === 200) {
      const expenseStatementList = await response.data;
      return expenseStatementList;
    }
  } catch (error) {
    return null;
  }
  return null;
};
