/* eslint-disable @typescript-eslint/no-unused-vars */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";

import { Open16Regular } from "@fluentui/react-icons";
import {
  Box,
  Button,
  CloseIcon,
  ComponentStyleFunctionParam,
  Dialog,
  Flex,
  FlexItem,
  ProviderConsumer,
  Text,
} from "@fluentui/react-northstar";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import doLogout, {
  doSwitchAccount,
} from "../../../common/components/logout/logout-component";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import CheckFeatureToggle from "../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../utils/constants";
import { isMobileOrTablet } from "../../../utils/device.utils";
import GetUrlCytricNewTab from "../../upcoming-trips-dashboard/utils/GetUrlCytricNewTab";
import SettingsOptionsStyles from "./SettingsOptions.module.scss";

interface AccountSettingsOptionsProps {
  isUserProfileAllowed: boolean;
}

function AccountSettingsOptions({
  isUserProfileAllowed,
}: AccountSettingsOptionsProps) {
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [isSwitchAccountDialog, setIsSwitchAccountDialog] = useState(false);
  const { t } = useTranslation("translation");

  const isMobileOrTabletDevice = isMobileOrTablet();

  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPSettings.id
  );

  // Hide Account section on TRIPPSettings users
  if (isTRIPPUser) {
    return null;
  }

  const renderUserProfileLink = () => (
    <Button
      className="userProfile justifySelfRight"
      styles={(theme) => ({
        color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
      })}
      text
      primary
      fluid
      onClick={() => {
        telemetryService.trackEvent({
          name: "redirect-user-profile",
        });
        GetUrlCytricNewTab(RedirectionTypeEnum.USER_PROFILE);
      }}
      size="small"
      iconPosition="after"
      icon={<Open16Regular />}
      content={t("Settings.account.Cytric_user_profile_link")}
      aria-label={t("Settings.account.Cytric_user_profile_link")}
      data-testid="cytric-user-profile"
    />
  );

  const displayLogoutDialog = (isSwitchAccount: boolean) => {
    setIsSwitchAccountDialog(isSwitchAccount);
    setOpenLogoutDialog(true);
  };

  const closeLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const renderPopupDialog = () => {
    const popupDialogTitle = isSwitchAccountDialog
      ? t("Settings.account.switchAccountConfirmationTitle")
      : t("Settings.account.logoutConfirmationTitle");

    const popupDialogText = isSwitchAccountDialog
      ? t("Settings.account.switchAccountConfirmationText")
      : t("Settings.account.logoutConfirmationText");

    return (
      <Dialog
        closeOnOutsideClick
        onCancel={closeLogoutDialog}
        header={popupDialogTitle}
        headerAction={{
          icon: <CloseIcon />,
          title: t("Settings.account.closeDialog"),
          className: "close__btn",
          onClick: closeLogoutDialog,
        }}
        content={<Text>{popupDialogText}</Text>}
        backdrop
        open={openLogoutDialog}
        confirmButton={
          isSwitchAccountDialog
            ? t("Settings.account.SwitchAccount_btn")
            : t("Settings.account.Logout_btn")
        }
        cancelButton={t("Settings.account.cancel")}
        onConfirm={isSwitchAccountDialog ? doSwitchAccount : doLogout}
        aria-labelledby={popupDialogTitle}
        aria-describedby={popupDialogText}
        className="dialog"
      />
    );
  };

  const buttonStyles = (theme: ComponentStyleFunctionParam<any, any>) => ({
    backgroundColor:
      theme.theme.siteVariables?.colorScheme?.default.background2,
    backgroundColorHover:
      theme.theme.siteVariables?.colorScheme?.brand.backgroundHover3,
    ...(isMobileOrTabletDevice && {
      color: theme.theme.siteVariables.colorScheme?.brand.backgroundHover,
      borderColor: theme.theme.siteVariables.colorScheme?.brand.backgroundHover,
    }),
  });

  const renderButton = (
    buttonText: string,
    onClickHandler: () => void,
    telemetryKey: string,
    customClassName: string = ""
  ) => (
    <Button
      className={`accountButton ${customClassName}`}
      styles={(theme) => buttonStyles(theme)}
      tinted={isMobileOrTabletDevice}
      flat
      onClick={() => {
        telemetryService.trackEvent({
          name: telemetryKey,
        });
        onClickHandler();
      }}
      aria-label={buttonText}
    >
      {buttonText}
    </Button>
  );

  const loggedInText = () => (
    <Text className="loggedIn">{t("Settings.account.You_are_logged_in")}</Text>
  );

  const switchAccountButton = () =>
    renderButton(
      t("Settings.account.SwitchAccount_btn"),
      () => displayLogoutDialog(true),
      "settings-switch-account"
    );

  const logoutButton = () =>
    renderButton(
      t("Settings.account.Logout_btn"),
      () => displayLogoutDialog(false),
      "settings-logout",
      "logoutButton"
    );

  const userProfileLink = () =>
    isUserProfileAllowed && <Flex>{renderUserProfileLink()}</Flex>;

  const renderAccountContent = () => (
    <>
      <Flex space="between" gap="gap.small" vAlign="center">
        {loggedInText()}
        <FlexItem push>{switchAccountButton()}</FlexItem>
        {logoutButton()}
      </Flex>
      {userProfileLink()}
    </>
  );

  const renderAccountContentMobile = () => (
    <Flex space="between" gap="gap.small" vAlign="start" column>
      {loggedInText()}
      {userProfileLink()}

      <Flex vAlign="center" gap="gap.smaller">
        {switchAccountButton()}
        {logoutButton()}
      </Flex>
    </Flex>
  );

  const renderAccountContentAllDevices = () =>
    isMobileOrTablet() ? renderAccountContentMobile() : renderAccountContent();

  return (
    <>
      <Flex className="sectionTitle" style={{ backgroundColor: "transparent" }}>
        <Text weight="bold">{t("Settings.titles.Account")}</Text>
      </Flex>
      <Box
        className={SettingsOptionsStyles.divider}
        styles={(theme) => ({
          color: theme.theme.siteVariables
            ? theme.theme.siteVariables.settings?.divider
            : "",
        })}
      />
      <Flex
        className="sectionItem"
        column
        style={{ backgroundColor: "transparent" }}
      >
        <ProviderConsumer render={() => renderAccountContentAllDevices()} />
      </Flex>
      {renderPopupDialog()}
    </>
  );
}

export default AccountSettingsOptions;
