import {
  Button,
  CloseIcon,
  Flex,
  Image,
  Text,
} from "@fluentui/react-northstar";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../../utils/host.settings";
import { useDispatch, useStore } from "../../context/ShareMyRideContext";
import { types } from "../../context/ShareMyRideReducer";
import ShareMyRideErrorStyles from "./ShareMyRideError.module.scss";

function ShareMyRideError({ themeName }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errorEnableAll } = useStore();

  const removeEnableAllError = () => {
    dispatch({ type: types.setErrorEnableAll, payload: "success" });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: types.setErrorEnableAll, payload: "success" });
    }, 10000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      column
      hAlign="center"
      styles={(theme: any) => ({
        background: theme.theme.siteVariables.colorScheme?.default.background1,
      })}
      className={ShareMyRideErrorStyles.errorContainer}
    >
      <Button
        className={ShareMyRideErrorStyles.errorButton}
        icon={<CloseIcon className={ShareMyRideErrorStyles.errorIcon} />}
        text
        onClick={() => {
          removeEnableAllError();
        }}
        data-testid="enableAll-error-close"
        title={t("shareMyRide.closeErrorMessage")}
      />

      <Image
        src={`${HostSettings.getCDNIcons}request-error-${themeName}.svg`}
        alt={t("shareMyRide.altErrorImage")}
        className={ShareMyRideErrorStyles.errorImage}
      />
      <Text className={ShareMyRideErrorStyles.errorTitle}>
        {t("shareMyRide.requestErrorTitle")}
      </Text>
      <Text
        className={ShareMyRideErrorStyles.errorSubtitle}
        styles={(theme: any) => ({
          color: theme.theme.siteVariables.colorScheme?.default.foreground2,
        })}
      >
        {errorEnableAll === "errorEnableAll" &&
          t("shareMyRide.requestErrorSubtitleEnableAll")}
        {errorEnableAll === "errorDisableAll" &&
          t("shareMyRide.requestErrorSubtitleDisableAll")}
        {errorEnableAll === "errorEnableIndividual" &&
          t("shareMyRide.requestErrorSubtitleEnableIndividual")}
        {errorEnableAll === "errorDisableIndividual" &&
          t("shareMyRide.requestErrorSubtitleDisableIndividual")}
      </Text>
    </Flex>
  );
}

export default ShareMyRideError;
