import { RootState } from "../StoreHooks";

export const selectLastVisitedUrl = (state: RootState): string =>
  state.cytricContext.lastVisitedUrl;

export const selectLastVisitedPage = (state: RootState): string =>
  state.cytricContext.lastVisitedPage;

export const selectLastUrlDateTimeout = (state: RootState): number =>
  state.cytricContext.lastUrlDateTimeout;
