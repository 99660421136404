import AirRedirectionInput from "../../../common/models/redirection/AirRedirectionInput";
import CarRedirectionInput from "../../../common/models/redirection/CarRedirectionInput";
import HotelRedirectionInput from "../../../common/models/redirection/HotelRedirectionInput";
import RailRedirectionInput from "../../../common/models/redirection/RailRedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";

export function preparePayloadForFlightBooking(
  bookingFor: string,
  subEntityId: any,
  userId: string
): AirRedirectionInput {
  const travelerDetails =
    bookingFor === "myself"
      ? { numberOfPassengers: 1, travelerUserId: [] }
      : { numberOfPassengers: 1, travelerUserId: [userId] };
  const segmentType =
    bookingFor === "myself"
      ? RedirectionTypeEnum.FLIGHT
      : RedirectionTypeEnum.TRAVEL_ARRANGER_FLIGHT;
  return {
    ...subEntityId,
    ...travelerDetails,
    // has to go last to not get overwritten by subEntityId.
    segmentType,
  };
}

export function preparePayloadForHotelBooking(
  bookingFor: string,
  subEntityId: any,
  userId: string
): HotelRedirectionInput {
  const travelerDetails =
    bookingFor === "myself"
      ? { travelerUserId: [] }
      : { travelerUserId: [userId] };
  const segmentType =
    bookingFor === "myself"
      ? RedirectionTypeEnum.HOTEL
      : RedirectionTypeEnum.TRAVEL_ARRANGER_HOTEL;
  return {
    ...subEntityId,
    ...travelerDetails,
    // has to go last to not get overwritten by subEntityId.segmentType
    segmentType,
  };
}

export function preparePayloadForRailBooking(
  bookingFor: string,
  subEntityId: any,
  userId: string
): RailRedirectionInput {
  const travelerDetails =
    bookingFor === "myself"
      ? { travelerUserId: [] }
      : { travelerUserId: [userId] };
  const segmentType =
    bookingFor === "myself"
      ? RedirectionTypeEnum.RAIL
      : RedirectionTypeEnum.TRAVEL_ARRANGER_RAIL;
  return {
    ...subEntityId,
    ...travelerDetails,
    // has to go last to not get overwritten by subEntityId.segmentType
    segmentType,
  };
}

export function preparePayloadForCarBooking(
  bookingFor: string,
  subEntityId: any,
  userId: string
): CarRedirectionInput {
  const travelerDetails =
    bookingFor === "myself"
      ? { travelerUserId: [] }
      : { travelerUserId: [userId] };
  const segmentType =
    bookingFor === "myself"
      ? RedirectionTypeEnum.CAR
      : RedirectionTypeEnum.TRAVEL_ARRANGER_CAR;
  return {
    ...subEntityId,
    ...travelerDetails,
    // has to go last to not get overwritten by subEntityId.segmentType
    segmentType,
  };
}
