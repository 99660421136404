/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import HostSettings from "../../utils/host.settings";
import { IUpcomingTripDetailProps } from "../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetail.model";

function useTodoRequest() {
  const { tripId } = useParams<IUpcomingTripDetailProps>();
  const tripIdWithoutColon = tripId.includes(":")
    ? tripId.replace(":", "")
    : tripId;

  const request = useCallback(
    async ({ method = Http.get, payload = {}, id = "" }: any) => {
      const url = `${HostSettings.getBaseAPI}${HostSettings.getTodoList}${tripIdWithoutColon}/to-dos/${id}`;

      try {
        const response = await method(url, payload);
        if (response.status === 200 || response.status === 201) {
          return (await response.data.data) || (await response.data);
        }
      } catch (error: any) {
        return [];
      }
      return [];
    },
    [tripIdWithoutColon]
  );

  return {
    getTodos: useCallback(
      async () => request({ method: Http.get }).then((res) => res),
      [request]
    ),
    createTodo: useCallback(
      async (payload: any) =>
        request({ method: Http.post, payload }).then((res) => res),
      [request]
    ),
    updateTodo: useCallback(
      async (id: string, payload: any) =>
        request({ method: Http.put, payload, id }).then((res) => res),
      [request]
    ),
    deleteTodo: useCallback(
      async (id: string) =>
        request({ method: Http.delete, id }).then((res) => res),
      [request]
    ),
  };
}

export default useTodoRequest;
