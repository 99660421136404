import { app } from "@microsoft/teams-js";
import ShareFlightGroup from "../ShareFlightGroup";
import ShareFlightSearch from "../ShareFlightSearch";
import ShareFlightTopHits from "../ShareFlightTopHits";
import getAllItems from "./GetAllItems";
import ShareFlightChannel from "./ShareFlightChannel";

export default async function GetSearchItems(
  query: any,
  context: app.Context,
  searchItems: any[],
  setRecipientGroupsSelected?: any,
  setRecipientChannelsSelected?: any
) {
  const [channels, hits, users, groups] = await Promise.all([
    ShareFlightChannel(query, setRecipientChannelsSelected),
    ShareFlightTopHits(query, context),
    ShareFlightSearch(query, context),
    ShareFlightGroup(query, context),
  ]);

  return getAllItems(
    searchItems,
    hits,
    users,
    groups,
    channels,
    setRecipientGroupsSelected,
    setRecipientChannelsSelected
  );
}
