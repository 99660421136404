import {
  createMicrosoftGraphClient,
  TeamsUserCredential,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IError } from "../../../common/models/Error";
import mapCollabImages from "../../../components/collaborators/close-collaborators/close-collaborators-list/utils/FunctionsCloseCollaborators";
import getImages from "../../../components/collaborators/close-collaborators/close-collaborators-list/utils/GetImages";
import { ReverseCollaboratorsModel } from "../../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import getPresenceCollaborators from "../../../components/collaborators/close-collaborators/utils/getPresenceCollaborators";
import CloseCollaboratorService from "../../../services/close-collaborators/close-collaborators.service";

export const REVERSE_COLLABORATORS_STORE_NAME = "reverseCollaborators";
export const GET_REVERSE_COLLABORATORS = "getReverseCollaborators";

const defaultError: IError = {
  code: 100,
  status: 500,
  title: "Something went wrong",
  detail: "Please try again later or contact your system administrator",
};

const enhanceReverseCollaborators = async (
  collaborators: ReverseCollaboratorsModel[]
) => {
  if (collaborators.length === 0) {
    return collaborators;
  }

  const graph = createMicrosoftGraphClient(new TeamsUserCredential(), [
    "User.Read",
  ]);

  const collabList = collaborators.map(({ id }: any) => id);
  const images = await getImages(graph, collabList);
  const presencesGraph = await getPresenceCollaborators(graph, collabList);

  return mapCollabImages(collaborators, images, presencesGraph);
};

export const getReverseCollaborators = createAsyncThunk(
  `${REVERSE_COLLABORATORS_STORE_NAME}/${GET_REVERSE_COLLABORATORS}`,
  async (_data, { rejectWithValue }) => {
    try {
      const reverseCollaboratorData =
        await CloseCollaboratorService.getReverseCollaborators();

      const reverseCollaborators = await enhanceReverseCollaborators(
        reverseCollaboratorData.senders
      );

      return { reverseCollaborators };
    } catch (error: any) {
      if (error.response?.data?.length) {
        const errorData = error.response?.data[0];
        return rejectWithValue({ error: errorData as IError });
      }
      return rejectWithValue({ error: defaultError });
    }
  }
);
