import { IError } from "../../common/models/Error";
import Trip from "../../common/models/Trip";
import { RootState } from "../StoreHooks";

export const selectTrips = (state: RootState): Trip[] => state.trips.values;

export const selectIsPending = (state: RootState): boolean =>
  state.trips.isPending;

export const selectIsEmptyTripList = (state: RootState): boolean =>
  state.trips.isEmpty;

export const selectTripListIsError = (state: RootState): boolean =>
  state.trips.isError;

export const errorInformation = (state: RootState): IError => state.trips.error;
