import NotificationSettings, {
  ScheduleExpenseStatements,
  ScheduleShareMyTransfer,
} from "../../common/models/NotificationSettings";
import { RootState } from "../StoreHooks";

export const isNotificationPendingSelector = (state: RootState): boolean =>
  state.notificationSettings.isPending;

export const isNotificationErrorSelector = (state: RootState): boolean =>
  state.notificationSettings.isError;

export const isNotificationLoadDoneSelector = (state: RootState): boolean =>
  state.notificationSettings.isInitialLoadDone;

export const notificationSettingsSelector = (
  state: RootState
): NotificationSettings => state.notificationSettings.notificationSettings;

export const statusChangeSelector = (
  state: RootState
): ScheduleExpenseStatements | null =>
  state.notificationSettings.notificationSettings.scheduleExpenseStatements;

export const scheduleSmrSelector = (
  state: RootState
): ScheduleShareMyTransfer | null =>
  state.notificationSettings.notificationSettings.scheduleShareMyTransfer;

export const isChatAssistantEnabled = (state: RootState): boolean =>
  state.notificationSettings.notificationSettings.isChatAssistantEnabled;
