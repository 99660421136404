import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import HostSettings from "../../utils/host.settings";

export default class FeatureTogglesService {
  private static readonly SERVER_URL = HostSettings.getBaseAPI;

  private static readonly FEATURE_TOGGLES_ENDPOINT =
    HostSettings.getFeatureTogglesEndpoint;

  public static async getFeatureToggles() {
    const resp = await Http.get(
      `${this.SERVER_URL}${this.FEATURE_TOGGLES_ENDPOINT}`
    );
    return resp?.data;
  }
}
