/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import { Box, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { IExtendedDetailsProps } from "../ExtendedDetails.model";
import { IHeader, IItem, IReservationInfoProps } from "./ReservationInfo.model";
import Styles from "./ReservationInfo.module.scss";

const STYLES_BOLD_TEXT = Styles["container__bold-text"];
const STYLES_NORMAL_TEXT = Styles["container__normal-text"];

function titleScreenReader({ tripSegment }: IExtendedDetailsProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const isFlight = "airportName" in tripSegment.departure;

  const seatSR =
    tripSegment?.seats === ""
      ? t("tripDetails.flightTrain.noSeat")
      : `${t("tripDetails.flightTrain.seat")}: ${tripSegment?.seats}`;

  if (isFlight) {
    return `${t("tripDetails.flightTrain.airline")} ${
      tripSegment?.serviceProvider
    } ${t("tripDetails.flightTrain.flight")} ${tripSegment?.number} ${t(
      "tripDetails.flightTrain.airplane"
    )} ${tripSegment?.airCraftType}. ${t("tripDetails.airlineReference")} ${
      tripSegment?.confirmationNumber
    }. ${`${t(
      "tripDetails.flightTrain.co2Emissions"
    )} ${tripSegment?.emissions.toLowerCase()}`}.
${seatSR}`;
  }
  return `${t("tripDetails.flightTrain.trainCompany")} ${
    tripSegment?.serviceProvider
  } ${t("tripDetails.flightTrain.trainNumber")} ${
    tripSegment.reservationInfo.header.title
  }. ${t("tripDetails.railReference")} ${tripSegment?.confirmationNumber}.
${seatSR}`;
}

function ConfirmationNumberInfo({ tripSegment }: IExtendedDetailsProps) {
  const { t } = useTranslation();
  const isFlight = "airportName" in tripSegment.departure;
  if (isFlight && tripSegment.confirmationNumber) {
    return (
      <div className={Styles.container__paragraph}>
        <Text className={STYLES_BOLD_TEXT}>{` ${t(
          "tripDetails.airlineReference"
        )}${": "}`}</Text>
        <Text
          className={STYLES_NORMAL_TEXT}
          styles={(theme) => ({
            color: theme.theme.siteVariables.extendedDetails?.tripData,
          })}
        >
          {tripSegment.confirmationNumber}
        </Text>
      </div>
    );
  }
  if (!isFlight && tripSegment.confirmationNumber) {
    return (
      <div className={Styles.container__paragraph}>
        <Text className={STYLES_BOLD_TEXT}>{` ${t(
          "tripDetails.railReference"
        )}${": "}`}</Text>
        <Text
          className={STYLES_NORMAL_TEXT}
          styles={(theme) => ({
            color: theme.theme.siteVariables.extendedDetails?.tripData,
          })}
        >
          {tripSegment.confirmationNumber}
        </Text>
      </div>
    );
  }
  return null;
}

function Header({ header }: IHeader) {
  const { title, subTitle } = header;

  return (
    <div aria-hidden="true" className={Styles.container__paragraph}>
      <div className={STYLES_BOLD_TEXT}>{`${title}`}</div>
      <Box
        className={STYLES_NORMAL_TEXT}
        styles={(theme) => ({
          color: theme.theme.siteVariables.extendedDetails?.tripData,
        })}
      >
        {subTitle}
      </Box>
    </div>
  );
}

function TranslateTripDetails(element: any) {
  const { t } = useTranslation();
  if (element === "Seat" || element === "Seats") {
    return t(`tripDetails.${element}`);
  }
  return element;
}

function CapitalizeSeatLetter(element: any, index: any) {
  if (index === 1) {
    return <span>{element || " - "}</span>;
  }
  return <span className={Styles["emissions-value"]}>{element || " - "}</span>;
}

function Items({ tripSegment }: IReservationInfoProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {tripSegment.reservationInfo.items?.map((item: IItem, index: number) => {
        if (
          (item.label.props &&
            item.label.props.children[0] !== undefined &&
            item.label.props.children[0] === "CO") ||
          item.label === "Seats"
        ) {
          return (
            <div
              className={Styles.container__paragraph}
              key={`${item?.label} - ${index}`}
              aria-hidden="true"
            >
              <ConfirmationNumberInfo tripSegment={tripSegment} />

              <div className={STYLES_BOLD_TEXT} aria-hidden="true">
                {TranslateTripDetails(item.label)}
                {": "}
                <Text
                  className={STYLES_NORMAL_TEXT}
                  styles={(theme) => ({
                    color: theme.theme.siteVariables.extendedDetails?.tripData,
                  })}
                >
                  {CapitalizeSeatLetter(item.value, index)}
                </Text>
              </div>
            </div>
          );
        }

        return (
          <div
            className={Styles.container__paragraph}
            key={`${item?.label} - ${index}`}
            aria-hidden="true"
          >
            <div className={STYLES_BOLD_TEXT} aria-hidden="true">
              {TranslateTripDetails(item.label)}
              {": "}
              <Text
                className={STYLES_NORMAL_TEXT}
                styles={(theme) => ({
                  color: theme.theme.siteVariables.extendedDetails?.tripData,
                })}
              >
                {CapitalizeSeatLetter(item.value, index)}
              </Text>
            </div>
          </div>
        );
      })}
    </>
  );
}

function ReservationInfo({ tripSegment }: IReservationInfoProps) {
  return (
    <div className={Styles.container} role="document">
      <div title={titleScreenReader({ tripSegment })}>
        <Header
          header={tripSegment.reservationInfo.header}
          aria-hidden="true"
        />
        <Items tripSegment={tripSegment} aria-hidden="true" />
      </div>
    </div>
  );
}

export default ReservationInfo;
