import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import HostSettings from "../../../utils/host.settings";

async function CancelTrip(id: string) {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}api/corporation/redirect/trips/${id}/cancel`
    );
    if (response.status === 200) {
      localStorage.setItem(
        "deleteTripCancellationResponse",
        JSON.stringify({ status: response.status, trip: null })
      );
      return true;
    }
    localStorage.setItem(
      "deleteTripCancellationResponse",
      JSON.stringify({ status: response.status, trip: id })
    );
    return false;
  } catch (error: any) {
    localStorage.setItem(
      "deleteTripCancellationResponse",
      JSON.stringify({ status: "400", trip: id })
    );
    return false;
  }
}

export default CancelTrip;
