import React from "react";

type CancelContextType = {
  cancelPopup: boolean;
  setCancelPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

const CancelContext = React.createContext<CancelContextType>({
  cancelPopup: false,
  setCancelPopup: () => {},
});

export default CancelContext;
