import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import Traveler, { TravelerDictionary } from "../../common/models/Traveler";

export interface TravelerServicePostBody {
  start?: number;
  count?: number;
  name?: string;
  locationId?: number;
  favorites?: boolean;
}

export interface TravelersServiceResponse {
  data: Traveler[];
  dictionaries: TravelerDictionary;
  totalCount?: number;
}

export default class TravelersService {
  private static readonly SERVER_URL = process.env.REACT_APP_BASE_API;

  private static readonly API_URL = process.env.REACT_APP_TRAVELERS;

  public static getTravelers(
    graphtToken: string,
    postBody?: TravelerServicePostBody
  ): Promise<TravelersServiceResponse | any> {
    return Http.post(`${this.SERVER_URL}${this.API_URL}`, postBody, {
      headers: {
        GraphToken: graphtToken,
      },
    });
  }
}
