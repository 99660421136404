import i18next from "../../../../../i18n/i18n";

const getNumberOfStopsText = (stops: number) => {
  const { t } = i18next;
  if (stops > 0) {
    if (stops === 1) {
      return `${stops} ${t("shareTrip.adaptiveCard.stopSingular")}, `;
    }
    return `${stops} ${t("shareTrip.adaptiveCard.stopPlural")}, `;
  }
  return `${t("shareTrip.adaptiveCard.nonStop")}`;
};

export default getNumberOfStopsText;
