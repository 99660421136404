enum CloseCollaboratorErrorCodeEnum {
  GENERAL = 100,
  BAD_INPUT = 101,
  KEYCLOAK = 102,
  TMS = 103,
  GRAPH = 104,
  SEARCH = 105,
  COUCHBASE = 106,
}

export default CloseCollaboratorErrorCodeEnum;
