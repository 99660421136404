import {
  createMicrosoftGraphClient,
  GraphService,
  TeamsUserCredential,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import TripDestination from "../../../../common/models/TripDestination";
import CloseCollaboratorService from "../../../../services/close-collaborators/close-collaborators.service";
import { createCards } from "../../../../store/collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationAction";
import { setNotificationSentConfirmation } from "../../../../store/collaborators/close-collaborators/CloseCollaboratorsSlice";
import Store from "../../../../store/Store";
import { CollaboratorsModel } from "../models/CloseCollaboratorsModel";

export default async function notifyCloseCollaborators(data: {
  checkedCollaboratorIds: string[];
  collaborators: CollaboratorsModel[];
  tripDestination: TripDestination;
  tripId: string;
  customMessage?: string;
}): Promise<boolean> {
  try {
    // Track the event
    telemetryService.trackEvent(
      { name: "travel-closeCollaborators-notify" },
      {
        numOfItems: data.checkedCollaboratorIds.length,
        tripId: data.tripId,
      }
    );

    // Initialize credentials and graph client
    const credential = new TeamsUserCredential();
    const graphClient = createMicrosoftGraphClient(credential, "User.Read");

    // Fetch sender data
    const senderData = await GraphService.getMe(graphClient);

    // Create notification cards
    const cardData = await createCards(graphClient, senderData, data);

    // Notify close collaborators
    try {
      await CloseCollaboratorService.notifyCloseCollaborators(
        cardData.senderId,
        cardData.senderUserPrincipalName,
        cardData.cards
      );
    } catch (error: any) {
      Store.dispatch(
        setNotificationSentConfirmation({ isVisible: true, isError: true })
      );
      return false; // Return immediately if notification fails
    }

    // Add reverse collaborators
    try {
      await CloseCollaboratorService.addReverseCollaborators(
        {
          id: senderData.id,
          userPrincipalName: senderData.userPrincipalName,
          displayName: senderData.displayName,
          jobTitle: senderData.jobTitle,
        },
        data.checkedCollaboratorIds.map(
          (id) => data.collaborators.find((collab) => collab.id === id)!
        ),
        {
          tripId: data.tripId,
          startDate: data.tripDestination.startDate,
          endDate: data.tripDestination.endDate,
          cityLocation: data.tripDestination.city,
        }
      );
    } catch (error: any) {
      Store.dispatch(
        setNotificationSentConfirmation({ isVisible: true, isError: true })
      );
      return false; // Return false if adding reverse collaborators fails
    }
    Store.dispatch(
      setNotificationSentConfirmation({ isVisible: true, isError: false })
    );

    return true; // Return true if both operations succeed
  } catch (error: any) {
    Store.dispatch(
      setNotificationSentConfirmation({ isVisible: true, isError: true })
    );
    return false;
  }
}
