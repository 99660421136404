/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ITrip } from "../../components/upcoming-trips-dashboard/UpcomingTrips/UpcomingTrips.model";

const initialState = {
  trips: [] as ITrip[],
  isSidebarHidden: true,
};

const tripsSummarySlice = createSlice({
  name: "trips",
  initialState,
  reducers: {
    setTrips(state, action) {
      state.trips = action.payload;
    },
    setIsSidebarHidden(state, action) {
      state.isSidebarHidden = action.payload;
    },
    toggleIsSidebarHidden(state) {
      state.isSidebarHidden = !state.isSidebarHidden;
    },
  },
});

export const tripsSummaryActions = tripsSummarySlice.actions;

export default tripsSummarySlice.reducer;
