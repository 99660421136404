import {
  createMicrosoftGraphClient,
  TeamsUserCredential,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import CloseCollaboratorsConstant from "./CloseCollaboratorsConstant";
import getFinalConstructObjet from "./GetFinalConstructObjet";
import getImages from "./GetImages";

export function addImagesToUsers(users: any, images: any) {
  return users.map((user: any) => ({
    ...user,
    image: images.responses.find((y: any) => y.id === user.id),
  }));
}

export default async function searchUsersInCountry(
  searchQuery: any,
  countryCode: string,
  setFindcontacts: any,
  setLoading: any,
  userOId: string
) {
  setLoading(true);

  const query = {
    select: "id, displayName, jobTitle, userPrincipalName, city",
    top: 3,
    filter: ` usageLocation eq '${countryCode}' and accountEnabled eq true`,
  };

  const requestUrl = `users?$top=${query.top}&$select=${
    query.select
  }&$search="displayName:${searchQuery.toLowerCase()}"`;

  const client = createMicrosoftGraphClient(new TeamsUserCredential(), [
    "User.Read.All",
  ]);

  let collaboratorsContact: any;

  try {
    const users = await client
      .api(requestUrl)
      .header("ConsistencyLevel", "eventual")
      .filter(query.filter)
      .get();

    const usersFilter = users.value.filter(
      (element: any) => element.id !== userOId
    );

    const usersList = usersFilter.map(({ id }: any) => id);

    const images = await getImages(client, usersList);

    const usersComparison = addImagesToUsers(usersFilter, images);

    collaboratorsContact = getFinalConstructObjet(
      usersComparison,
      CloseCollaboratorsConstant.graphDefaultImage
    );
  } catch {
    collaboratorsContact = [];
  }

  setFindcontacts(collaboratorsContact);
  setLoading(false);

  return collaboratorsContact;
}
