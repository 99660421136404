/* eslint-disable react-hooks/exhaustive-deps */
import {
  KEYCLOACK_ERROR_CODE,
  telemetryService,
  UNAUTHORIZED_ERROR_CODE,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  CustomChart,
  Summary,
} from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-expenses";
import { Loader } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
} from "../../common/components/generic-info-component/GenericInfo.model";
import LoginComponent from "../../common/components/login-component/login-component";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import { isLoggedInSelector } from "../../store/authentication/AuthenticationSelector";
import { breadcrumbActions } from "../../store/breadcrumb/breadcrumb-slice";
import { expenseDashboardActions } from "../../store/expense-dashboard/ExpenseDashboardSlice";
import Store from "../../store/Store";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import {
  EXPENSE_DENIED_ACCESS_CODE,
  EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY,
} from "../../utils/constants";
import NO_LABEL_NO_URL from "../personal-tab/breadcrumb/constants";
import WidgetContainer from "../upcoming-trips-dashboard/UpcomingTripDetail/WidgetContainer/WidgetContainer";
import MergeAlert from "./duplicates/alert/MergeAlert";
import styles from "./ExpenseDashboard.module.scss";
import { ErrorAccessInterface } from "./ExpenseDashboardInterfaces";
import { getCurrencyCode } from "./ExpenseDashboardRequests";
import DashboardHeader from "./header/DashboardHeader";
import { isExpenseRelatedSegmentType } from "./redirection/ExpenseRedirectionHelper";
import Todo from "./todo/Todo";

function ExpenseDashboard(): React.ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const subEntityStored = useAppSelector(
    (state) => state.expenseDashbard.subEntity
  );

  const refreshWindow = () => {
    window.location.reload();
  };

  const errorInfoGeneric: IGenericInfo = {
    title: t("messages.ERR_Generic"),
    detail: t("upcoming_trips_dashboard.ERR_api_call"),
  };

  const errorInfoExpenseAccess: IGenericInfo = {
    title: t("messages.ERR_ExpenseTabActivation"),
    detail: t("messages.ERR_ExpenseTabActivationDetail"),
  };

  const [mergeActionType, setMergeActionType] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string>("");
  const [errorButtonAction, setErrorButtonAction] = useState<
    IGenericInfoAction | undefined
  >(undefined);
  const [accessError, setAccessError] =
    React.useState<ErrorAccessInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isKeycloackError, setIsKeycloackError] = useState(false);
  const [errorImage, setErrorImage] = useState<InfoImage>(InfoImage.ERROR);
  const [errorObject, setErrorObject] =
    useState<IGenericInfo>(errorInfoGeneric);
  const dispatch = useAppDispatch();

  const getCurrency = async () => {
    try {
      const currencyInfo = await getCurrencyCode();
      if (currencyInfo.isError) {
        setAccessError(currencyInfo);
      } else {
        setCurrency(currencyInfo);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const checkAccess = () => {
    if (accessError?.errorData?.errors[0].code === EXPENSE_DENIED_ACCESS_CODE) {
      setErrorObject(errorInfoExpenseAccess);
      setErrorImage(InfoImage.NOT_ACTIVATED);
    } else {
      setErrorObject(errorInfoGeneric);
      setErrorImage(InfoImage.ERROR);
    }
  };

  const checkErrors = () => {
    if (accessError) {
      if (accessError.statusCode === KEYCLOACK_ERROR_CODE) {
        setIsKeycloackError(true);
      } else if (accessError.statusCode === UNAUTHORIZED_ERROR_CODE) {
        checkAccess();
      } else {
        setErrorButtonAction({
          text: t("App.Try_again"),
          command: refreshWindow,
        });
        setErrorObject(errorInfoGeneric);
      }
    }
  };

  const isLoggedIn: boolean = useAppSelector((state: any) =>
    isLoggedInSelector(state)
  );

  const updateBreadcrumb = () => {
    dispatch(
      breadcrumbActions.setExpenseLevel2({
        label: t("expenses-dashboard.yourExpenses"),
        url: "",
      })
    );
    dispatch(breadcrumbActions.setExpenseLevel3(NO_LABEL_NO_URL));
  };

  const handleEasyExpenseSessionInfo = (easyExpenseSessionInfo: string) => {
    const now = new Date();
    const { expiryTime } = JSON.parse(easyExpenseSessionInfo);
    if (now.getTime() > expiryTime) {
      localStorage.removeItem(EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY);
    } else {
      history.push(
        `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}`
      );
      updateBreadcrumb();
    }
  };

  const handleSubEntity = (
    subEntity: string,
    easyExpenseSessionInfo: string | null
  ) => {
    const entityJson = JSON.parse(subEntity);
    if (isExpenseRelatedSegmentType(entityJson.segmentType)) {
      const urlParams = Object.keys(entityJson)
        .map((key) => `${key}=${entityJson[key]}`)
        .join("&");

      history.push(`/classic?${urlParams}`);
      Store.dispatch(expenseDashboardActions.setSubEntity(subEntity));
    } else if (easyExpenseSessionInfo) {
      handleEasyExpenseSessionInfo(easyExpenseSessionInfo);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getCurrency();
    if (isLoggedIn) {
      telemetryService.trackPageView({
        name: "expenses-dashboard",
      });
    }

    app.getContext().then((context: app.Context) => {
      const subEntity = context?.page?.subPageId;
      const easyExpenseSessionInfo = localStorage.getItem(
        EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY
      );

      if (subEntity && subEntity !== subEntityStored) {
        handleSubEntity(subEntity, easyExpenseSessionInfo);
      } else if (easyExpenseSessionInfo) {
        handleEasyExpenseSessionInfo(easyExpenseSessionInfo);
      }
    });
  }, []);

  useEffect(() => {
    checkErrors();
  }, [accessError]);

  if (isLoading) {
    return (
      <Loader
        style={{ margin: 100 }}
        data-testid="loadingSpinner"
        label={t("spinner.waitAMoment")}
      />
    );
  }

  if (isKeycloackError) {
    return <LoginComponent />;
  }

  const wigdetContainerClass = styles["widget-container"];

  return accessError ? (
    <GenericInfo
      infoData={errorObject}
      action={errorButtonAction}
      image={errorImage}
    />
  ) : (
    <div data-testid="expense-dashboard">
      {mergeActionType && (
        <MergeAlert
          mergeType={mergeActionType}
          setMergeType={setMergeActionType}
        />
      )}
      <DashboardHeader />
      <main
        aria-label="Expense dashboard content"
        className={styles["tab-content"]}
      >
        <section
          aria-labelledby="expense__todo-list--header"
          className={styles["todo-segment"]}
        >
          <WidgetContainer
            className={`${wigdetContainerClass} ${styles.todo}`}
            data-testid="expense__todo-list"
          >
            <Todo
              setMergeType={setMergeActionType}
              setAccessError={setAccessError}
            />
          </WidgetContainer>
        </section>
        <section
          aria-labelledby="summary-kpi-title"
          className={styles["summary-segment"]}
        >
          <WidgetContainer
            className={wigdetContainerClass}
            data-testid="expense__summary-kpi"
          >
            <Summary currency={currency} setAccessError={setAccessError} />
          </WidgetContainer>
        </section>
        <section
          aria-labelledby="spendings-chart-title"
          className={styles["chart-segment"]}
        >
          <WidgetContainer
            className={`${wigdetContainerClass} ${styles.chart}`}
          >
            <CustomChart currency={currency} setAccessError={setAccessError} />
          </WidgetContainer>
        </section>
      </main>
    </div>
  );
}

export default ExpenseDashboard;
