/* eslint-disable arrow-body-style */
/* eslint-disable react/react-in-jsx-scope */
import { Flex, Header, Loader, Text } from "@fluentui/react-northstar";
import { useEffect, useRef, useState } from "react";
import Responsive from "../../../../common/components/responsive/Responsive";
import i18next from "../../../../i18n/i18n";
import Store from "../../../../store/Store";
import { useAppSelector } from "../../../../store/StoreHooks";
import { userObjectIdSelector } from "../../../../store/authentication/AuthenticationSelector";
import {
  selectCloseCollaborators,
  selectCloseCollaboratorsIsPending,
} from "../../../../store/collaborators/close-collaborators/CloseCollaboratorsSelector";
import { resetCloseCollaboratorToNotify } from "../../../../store/collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationSlice";
import stylesCloseCollaborators from "../../common/styles/close-collaborators.module.scss";
import CloseCollaboratorsConfirmation from "../close-collaborators-confirmation/CloseCollaboratorsConfirmation";
import CloseCollaboratorsTable from "../close-collaborators-table/CloseCollaboratorsTable";
import { CollaboratorsModel } from "../models/CloseCollaboratorsModel";
import CloseCollaboratorsListComponents from "./CloseCollaboratorsListComponents";

export default function CloseCollaboratorsList({
  destination,
  themeName,
  updateDisplayedCollaborators,
  fetchCloseCollaborators,
}: any) {
  const { countryCode } = destination;
  const updateParent = useRef(updateDisplayedCollaborators);

  const [filteredCollaborators, setFilteredCollaborators] = useState<
    CollaboratorsModel[]
  >([]);
  const [loadingCollaborators, setLoadingCollaborators] = useState(false);

  const closeCollaborators: CollaboratorsModel[] = useAppSelector((state) =>
    selectCloseCollaborators(state)
  );

  const isPending: boolean = useAppSelector((state) =>
    selectCloseCollaboratorsIsPending(state)
  );

  const userOId: string = useAppSelector((state) =>
    userObjectIdSelector(state)
  );

  const notificationSentConfirmation = useAppSelector(
    (state) => state.closeCollaborators.notificationSentConfirmation
  );

  const closeCollaboratorsFindaContact = CloseCollaboratorsListComponents(
    countryCode || "",
    setFilteredCollaborators,
    closeCollaborators,
    setLoadingCollaborators,
    userOId
  );

  useEffect(() => {
    setFilteredCollaborators(closeCollaborators || []);
  }, [closeCollaborators]);

  useEffect(() => {
    Store.dispatch(resetCloseCollaboratorToNotify());
    updateParent.current(filteredCollaborators);
  }, [filteredCollaborators]);

  return (
    <div
      className={[
        themeName === "light"
          ? stylesCloseCollaborators.white
          : stylesCloseCollaborators.dark,
        stylesCloseCollaborators.card,
      ].join(" ")}
    >
      <Flex gap="gap.small" className={stylesCloseCollaborators.cardHeader}>
        <Flex column>
          <Header
            as="h3"
            content={i18next.t("closeCollaborators.list.card.title")}
            className={stylesCloseCollaborators.title}
            data-joyride-id="closeCollaborators"
          />
          <Text
            content={i18next.t(
              "closeCollaborators.list.card.subtitleDescription"
            )}
            className={stylesCloseCollaborators.subtitle}
          />
          <Text
            content={i18next.t("closeCollaborators.list.card.subtitleSlogan")}
            className={stylesCloseCollaborators.subtitle}
          />
        </Flex>
      </Flex>
      {notificationSentConfirmation.isVisible ? (
        <CloseCollaboratorsConfirmation
          themeName={themeName}
          fetchCloseCollaborators={fetchCloseCollaborators}
        />
      ) : (
        <div>
          {loadingCollaborators || isPending ? (
            <Loader label={i18next.t("closeCollaborators.list.card.loading")} />
          ) : (
            <>
              <Responsive forRange="mobile">
                <Text
                  content={i18next.t("closeCollaborators.list.findContact")}
                  className={stylesCloseCollaborators.label}
                />
              </Responsive>
              <Flex className={stylesCloseCollaborators.cardSearch}>
                {closeCollaboratorsFindaContact}
              </Flex>
              <div className={stylesCloseCollaborators.cardBody}>
                <CloseCollaboratorsTable
                  collaborators={filteredCollaborators}
                  themeName={themeName}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
