import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/i18n";
import firstLetterUppercase from "../../utils/firstLetterUppercase";
import { IDateProps } from "./DateComponent.model";

function DateComponent({ start_date, end_date }: IDateProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.tripcard",
  });

  const startDate = firstLetterUppercase(
    DateUtils.getDateFormat(start_date, i18n.language, DateFormat.DATE_SHORT)
  );

  const endDate = firstLetterUppercase(
    DateUtils.getDateFormat(end_date, i18n.language, DateFormat.DATE_SHORT)
  );

  const displayDate = () => {
    if (startDate === endDate) {
      return startDate;
    }

    return start_date && end_date ? `${startDate} - ${endDate}` : null;
  };

  let titleScreenReader = `${DateUtils.getDateFormat(
    start_date,
    i18n.language,
    DateFormat.DATE_LONG
  )}`;
  if (start_date !== end_date) {
    titleScreenReader = `${t("from")} ${DateUtils.getDateFormat(
      start_date,
      i18n.language,
      DateFormat.DATE_LONG
    )} ${t("to")} ${DateUtils.getDateFormat(
      end_date,
      i18n.language,
      DateFormat.DATE_LONG
    )}`;
  }

  return (
    <Text
      title={titleScreenReader}
      styles={(theme) => ({
        color: theme.theme.siteVariables.upcomingTrips?.header.title,
      })}
      data-testid="trip-date"
    >
      <div aria-hidden="true">{displayDate()}</div>
    </Text>
  );
}

export default DateComponent;
