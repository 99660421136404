import AutoCompleteService from "../../../services/search-flight/autocomplete.service";
import UserPreferredLocationService from "../../../services/search-flight/user-preferred-location.service";
import GetAirportData from "./AirportData";
import GetPreferredLocation from "./PreferredLocation";

interface ReturnAirportInfo {
  name: string;
  iatacode: string;
}
// Services
const autoCompleteService = new AutoCompleteService();
const userPreferredLocationService = new UserPreferredLocationService();

export async function getPreferredLoc() {
  return userPreferredLocationService.getPreferredLocation();
}

export async function getAirports(defaultAirport: string) {
  return autoCompleteService.getAirportsAndCitiesv2(defaultAirport);
}

export async function getDefaultAirport(defaultAirport: string) {
  const defaultAirportData = await getAirports(defaultAirport);
  return GetAirportData(defaultAirportData, defaultAirport);
}

export default async function GetAirportInformation(defaultAirport: string) {
  let airportInfo: ReturnAirportInfo;
  try {
    const userSavedLocation = await getPreferredLoc();
    if (
      Object.keys(userSavedLocation?.data).length !== 0 &&
      userSavedLocation?.data?.departureLocation
    ) {
      airportInfo = GetPreferredLocation(userSavedLocation);
      if (!airportInfo?.iatacode) {
        airportInfo = await getDefaultAirport(defaultAirport);
      }
    } else {
      airportInfo = await getDefaultAirport(defaultAirport);
    }
  } catch {
    airportInfo = await getDefaultAirport(defaultAirport);
  }

  return airportInfo;
}
