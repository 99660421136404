import { Flex, List, Text } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../../../store/StoreHooks";
import { selectFeatureToggle } from "../../../../../../store/feature-toggles/FeatureTogglesSelector";
import {
  getProductsByTypeV1,
  selectTripDetailsV1,
  sortTrainProductsV1,
} from "../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors";
import returnKeepSession, {
  KeepSessionActions,
} from "../../../../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../../../../utils/KeepUserActivityContext";
import { FeatureToggleDefinition } from "../../../../../../utils/constants";
import {
  TRIP_PRODUCT_AIR,
  TRIP_PRODUCT_CAR,
  TRIP_PRODUCT_HOTEL,
  TRIP_PRODUCT_RAIL,
} from "../../../../data/constants";
import CheckFeatureEnabled from "../../CheckFeatureEnabled";
import { ITabContentProps, ITabListProps } from "./SegmentTabs.model";
import Styles from "./SegmentTabs.module.scss";
import Car from "./Tabs/Car/Car";
import Flight from "./Tabs/Flight/Flight";
import Hotel from "./Tabs/Hotel/Hotel";
import Rail from "./Tabs/Train/Rail";

function TabContent({ id, activeTab, children }: ITabContentProps) {
  return activeTab === id ? (
    <Flex column className={Styles.gapSegments}>
      {children}
    </Flex>
  ) : null;
}

function SegmentTabs() {
  const [tabList, setTabList] = useState<ITabListProps[]>();
  const [activeTab, setActiveTab] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const history = useHistory();
  const param = history.location.state;
  const previousSessionObject = returnKeepSession();
  const pastTrip: any =
    previousSessionObject && previousSessionObject.value
      ? previousSessionObject.value[0].pastTrip
      : param === "past";

  const userActivity = new ActivityClass();

  const noEmptyTabFeatureEnabled = CheckFeatureEnabled();
  const featureToggleTrain = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.addRailElementTrip.id)
  );
  const featureToggleCar = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.addCarElementTrip.id)
  );

  const tripV1 = useAppSelector((state) => selectTripDetailsV1(state));

  const hotelsV1 = useAppSelector((state) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_HOTEL)
  );
  const flightsV1 = useAppSelector((state) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_AIR)
  );
  const disorderedTrains = useAppSelector((state) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_RAIL)
  );

  const trainsV1 = sortTrainProductsV1(disorderedTrains);

  const carsV1 = useAppSelector((state) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_CAR)
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.tripDetails",
  });

  const getItemsByTime = () => {
    const products = tripV1?.products;
    let arrayOfProducts: { type: string; date: any }[] = [];
    if (products && products.length > 0) {
      products.forEach((element: any) => {
        if (element.productType === "Trip_Product_Air") {
          const data = { type: "flight", date: element.departure?.dateTime };
          arrayOfProducts.push(data);
        }
        if (element.productType === "Trip_Product_Train") {
          const data = { type: "train", date: element.departureDateTime };
          arrayOfProducts.push(data);
        }
        if (element.productType === "Trip_Product_Hotel") {
          const data = { type: "hotel", date: element.checkInDate };
          arrayOfProducts.push(data);
        }
        if (element.productType === "Trip_Product_Car") {
          const data = { type: "car", date: element.pickup?.localDateTime };
          arrayOfProducts.push(data);
        }
      });
      arrayOfProducts.sort(
        (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
      );
      arrayOfProducts = arrayOfProducts.filter(
        (obj, pos, arr) =>
          arr.map((mapObj) => mapObj.type).indexOf(obj.type) === pos
      );
    }
    return arrayOfProducts;
  };

  const addElementsToList = (items: any[]) => {
    const listOfProductTabs: {
      id: string;
      title: string;
      content: JSX.Element;
    }[] = [];

    if (items.length > 0) {
      items.forEach((item: any) => {
        if (item.type === "flight") {
          listOfProductTabs.push({
            id: "tab1",
            title: t("Flight"),
            content: <Flight pastTrip={pastTrip} />,
          });
        }
        if (item.type === "train") {
          listOfProductTabs.push({
            id: "tab2",
            title: t("Train"),
            content: <Rail pastTrip={pastTrip} />,
          });
        }
        if (item.type === "hotel") {
          listOfProductTabs.push({
            id: "tab3",
            title: t("Hotel"),
            content: <Hotel pastTrip={pastTrip} />,
          });
        }
        if (item.type === "car") {
          listOfProductTabs.push({
            id: "tab4",
            title: t("Car"),
            content: <Car pastTrip={pastTrip} />,
          });
        }
      });
    }

    return listOfProductTabs;
  };

  const getListOfProductsWithoutFeature = () => {
    const listOfProductTabs = [];
    if (flightsV1?.length) {
      listOfProductTabs.push({
        id: "tab1",
        title: t("Flight"),
        content: <Flight pastTrip={pastTrip} />,
      });
    }
    if (trainsV1?.length) {
      listOfProductTabs.push({
        id: "tab2",
        title: t("Train"),
        content: <Rail pastTrip={pastTrip} />,
      });
    }
    if (hotelsV1?.length) {
      listOfProductTabs.push({
        id: "tab3",
        title: t("Hotel"),
        content: <Hotel pastTrip={pastTrip} />,
      });
    }
    if (carsV1?.length) {
      listOfProductTabs.push({
        id: "tab4",
        title: t("Car"),
        content: <Car pastTrip={pastTrip} />,
      });
    }

    return listOfProductTabs;
  };

  const tabListnotEmpty = () => {
    let listOfProductTabs = [];
    if (noEmptyTabFeatureEnabled && !pastTrip) {
      const items = getItemsByTime();

      listOfProductTabs = addElementsToList(items);

      if (!flightsV1?.length) {
        listOfProductTabs.push({
          id: "tab1",
          title: t("Flight"),
          content: <Flight pastTrip={pastTrip} />,
        });
      }
      if (!trainsV1?.length && featureToggleTrain?.isActive) {
        listOfProductTabs.push({
          id: "tab2",
          title: t("Train"),
          content: <Rail pastTrip={pastTrip} />,
        });
      }
      if (!hotelsV1?.length) {
        listOfProductTabs.push({
          id: "tab3",
          title: t("Hotel"),
          content: <Hotel pastTrip={pastTrip} />,
        });
      }
      if (!carsV1?.length && featureToggleCar?.isActive) {
        listOfProductTabs.push({
          id: "tab4",
          title: t("Car"),
          content: <Car pastTrip={pastTrip} />,
        });
      }
    } else {
      listOfProductTabs = getListOfProductsWithoutFeature();
    }

    const definedTabList: ITabListProps[] = listOfProductTabs;

    setTabList(definedTabList);

    return listOfProductTabs;
  };

  const matchTrip = () => {
    const keepSessionInfo = localStorage.getItem("KeepSessionCache");

    return keepSessionInfo?.includes(tripV1.id);
  };

  function updateUserActivityContext(selectdIndex: number) {
    userActivity.update(KeepSessionActions.DETAILS_DETAILTAB, selectdIndex);
  }

  const getActiveTab = () => {
    const storedIndex = userActivity.get(KeepSessionActions.DETAILS_DETAILTAB);
    if (storedIndex && matchTrip() && tabList) {
      setActiveTab(tabList[storedIndex]?.id);
    } else if (tabList) {
      setActiveTab(tabList[0]?.id);
    }
  };

  const getSelectedTab = () => {
    const storedIndex = userActivity.get(KeepSessionActions.DETAILS_DETAILTAB);
    if (storedIndex && tabList) {
      setSelectedIndex(storedIndex);
    }
  };

  useEffect(() => {
    tabListnotEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripV1]);

  useEffect(() => {
    getActiveTab();
    getSelectedTab();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabList]);

  const getColorTheme = (tabNavItem: any, id: string) => {
    if (tabNavItem) {
      return tabNavItem[activeTab === id ? "tabActive" : "tabInactive"];
    }
    return "";
  };

  const TabNavItem = () => {
    const items: { key: string; content: JSX.Element }[] = [];
    tabList?.forEach(({ id, title }) => {
      const element = {
        key: id,
        content: (
          <Text
            className={
              Styles[
                `tab-nav__button${activeTab === id ? "--active" : "--inactive"}`
              ]
            }
            styles={(theme) => ({
              color: getColorTheme(theme.theme.siteVariables.tabNavItem, id),
              borderBottomColor: getColorTheme(
                theme.theme.siteVariables.tabNavItem,
                id
              ),
            })}
            content={title}
          />
        ),
      };
      items.push(element);
    });
    return items;
  };

  return (
    <>
      <List
        selectable
        selectedIndex={selectedIndex}
        onSelectedIndexChange={(_e, newProps) => {
          if (newProps?.selectedIndex !== undefined && tabList) {
            setActiveTab(tabList[newProps?.selectedIndex].id);
            setSelectedIndex(newProps?.selectedIndex);
            updateUserActivityContext(newProps?.selectedIndex);
          }
        }}
        data-testid="nav"
        aria-label="segment list"
        items={TabNavItem()}
        horizontal
        className={Styles[`tab-nav-background`]}
      />

      {tabList?.map(({ id, content }) => (
        <TabContent key={id} id={id} activeTab={activeTab}>
          {content}
        </TabContent>
      ))}
    </>
  );
}
export default SegmentTabs;
