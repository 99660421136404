enum RedirectionTypeEnum {
  FLIGHT = "Flight",
  ADD_FLIGHT = "AddFlight",
  CAR = "Car",
  ADD_CAR = "ADD_CAR",
  ADD_TRANSFER = "ADD_TRANSFER",
  HOTEL = "Hotel",
  ADD_HOTEL = "AddHotel",
  RAIL = "Rail",
  ADD_RAIL = "AddRail",
  DASHBOARD = "Dashboard",
  DASHBOARD_WITHOUT_CONTEXT = "DashboardContextless",
  TRAVEL_ARRANGER_DASHBOARD = "TravelArrangerDashboard",
  TRAVEL_ARRANGER_FLIGHT = "TravelArrangerFlight",
  TRAVEL_ARRANGER_HOTEL = "TravelArrangerHotel",
  TRAVEL_ARRANGER_RAIL = "TravelArrangerRail",
  TRAVEL_ARRANGER_CAR = "TravelArrangerCar",
  EXPENSE_DASHBOARD = "ViewExpense",
  EXPENSE_CREATE = "CreateExpense",
  SHARE_HOTEL = "ShareHotel",
  SHARE_FLIGHT = "ShareFlight",
  SHARE_RAIL = "ShareRail",
  SHARE_CAR = "ShareCar",
  SHARE_CONFIRMED_BOOKING = "ShareConfirmedBooking",
  CHAT_TRAVELER = "ChatTraveler",
  HOTEL_STOP = "HotelStop",
  USER_PROFILE = "UserProfile",
  PAST_TRIPS = "PastTrips",
  RAIL_CARD = "RailCard",
  CAR_CARD = "CarCard",
  FLIGHT_CARD = "FlightCard",
  CONFIRMATION_PAGE = "ConfirmationPage",
  TRIP_APPROVAL_PAGE = "TripApprovalPage",
  TRIP_APPROVE_ACTION = "TripApproveAction",
  TRIP_REJECT_ACTION = "TripRejectAction",
}

export default RedirectionTypeEnum;
