function CheckPermissions(permissions: any, value: string) {
  let found = false;

  if (
    permissions.filter((elements: any) => elements.type === value).length > 0
  ) {
    found = true;
  }
  return found;
}

export default CheckPermissions;
