/* eslint-disable @typescript-eslint/no-unused-vars */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  Box,
  Card,
  CardBody,
  cardSelectableBehavior,
} from "@fluentui/react-northstar";
import { BotUrlDialogInfo, app, dialog } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GetAppRoot from "../../../../utils/GetAppRoot";
import HostSettings from "../../../../utils/host.settings";
import { useCancelPopUp } from "../../cancel/context/CancelPopUpContext";
import Destination from "./Destination/Destination";
import DestinationImage from "./DestinationImage/DestinationImage";
import SegmentIcons from "./Segments/SegmentIcons";
import { ITripCardProps } from "./TripCard.model";
import Styles from "./TripCard.module.scss";

export const onShowShareTrip = (
  trip: any,
  t: any,
  taskModuleUrl: string
): void => {
  if (trip) {
    const taskModuleInfo: BotUrlDialogInfo = {
      title: t("upcoming_trips_dashboard.upcomingTrips.tripCardShareTitle"),
      url: `${GetAppRoot()}/index.html#/${taskModuleUrl}?origin=sharetrip&tripId=${
        trip.id
      }`,
      completionBotId: HostSettings.getBotId,
      size: {
        width: 600,
        height: 570,
      },
    };
    telemetryService.trackEvent({ name: "travel-tripDashboard-sharePopup" });
    dialog.url.bot.open(taskModuleInfo);
  }
};

function TripCard({ trip, index, totalitems }: ITripCardProps) {
  const taskModuleUrl = "share-join";
  const { t } = useTranslation();
  const history = useHistory();
  const { setDialogParams } = useCancelPopUp();

  const selectTripClickHandler = (e: any) => {
    if (
      e.target.innerText ===
        t("upcoming_trips_dashboard.upcomingTrips.tripCardShare") &&
      e.target.className.includes("ui-menu__item")
    ) {
      onShowShareTrip(trip, t, taskModuleUrl);
    } else if (
      e.target.innerText ===
        t(
          "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.cancelTrip"
        ) &&
      e.target.className.includes("ui-menu__item")
    ) {
      setDialogParams({ openDialog: true, id: trip.id });
    } else {
      const { id } = trip;
      history.push(`/trip${id}`);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      selectTripClickHandler(e);
    }
  };

  const [themeName, setThemeName] = useState<any>();

  useEffect(() => {
    app.getContext().then((ctx: app.Context) => setThemeName(ctx.app.theme));
  }, []);

  return (
    <Card
      accessibility={cardSelectableBehavior}
      aria-labelledby={`card${index === 0 ? "tripCard" : ""}`}
      fluid
      compact
      className={
        themeName === "default"
          ? Styles["trip-card__default"]
          : Styles["trip-card__dark"]
      }
      tabIndex={index + 1}
      style={{
        borderStyle: "none",
        borderRadius: "4px",
      }}
      onKeyDown={handleKeyDown}
      onClick={selectTripClickHandler}
      styles={(theme) => ({
        background: theme.theme.siteVariables.tripCard?.background,
      })}
      data-testid="card"
      data-joyride-id={index === 0 ? "tripCard" : ""}
    >
      <DestinationImage trip={trip} />
      <CardBody className={Styles["trip-card__body"]}>
        <Destination trip={trip} />
        <Box
          className={Styles["trip-card__line"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.tripCard?.divider,
          })}
        />
        <SegmentIcons trip={trip} />
      </CardBody>
    </Card>
  );
}

export default TripCard;
