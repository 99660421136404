/* eslint-disable react/no-array-index-key */
import { Card, Flex, Image, StarIcon, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../../../../../../common/components/responsive/Responsive";
import { handleProviderLogoError } from "../../../../../../../utils/trip-utils";
import ContactInformation from "../ContactInformation/ContactInformation";
import { IHotelSegmentProps } from "../HotelSegment/HotelSegment.model";
import Styles from "./Header.module.scss";

const displayHotelStarRating = (ratingNumber: string | number) =>
  [...Array(Math.floor(+ratingNumber))].map((index: number) => {
    const key = `${ratingNumber}-${index}`;
    return (
      <StarIcon
        size="small"
        className={Styles["hotel-header__star"]}
        key={key}
      />
    );
  });

function Header({ details }: IHotelSegmentProps) {
  const {
    name,
    hotelPicture,
    address,
    postalCode,
    city,
    country,
    numberOfNights,
    ratingNumber,
    phone,
    email,
    confirmationNumber,
    bookingCode,
  } = details;
  const { t } = useTranslation("translation", {
    keyPrefix: "tripDetails.hotel",
  });
  const addressClassname = "hotel-header__address";
  const hotelImage = (
    <Text
      as="div"
      className={Styles["hotel-header__image-container"]}
      styles={(theme) => ({
        borderColor: theme.theme.siteVariables
          ? theme.theme.siteVariables.hotelDetails?.imageBorder
          : "",
      })}
    >
      <Image
        className={Styles["hotel-header__image"]}
        src={hotelPicture}
        alt="Hotel picture"
        aria-hidden
        onError={handleProviderLogoError}
      />
    </Text>
  );
  const hotelName = (
    <Text
      className={Styles["hotel-header__name"]}
      styles={(theme) => ({
        color: theme.theme.siteVariables.hotelDetails?.textColor,
      })}
      title={name}
    >
      {name}
    </Text>
  );
  const hotelStarRating = (
    <div
      className={Styles["hotel-header__rating-stars"]}
      title={ratingNumber ? `${ratingNumber} ${t("stars")}` : ""}
    >
      {displayHotelStarRating(ratingNumber)}
    </div>
  );
  const hotelAddress = (
    <Text
      className={Styles[addressClassname]}
      as="div"
      styles={(theme) => ({
        color: theme.theme.siteVariables.hotelDetails?.addressColor,
      })}
      title={`${address}, ${postalCode} ${city}, ${country}`}
    >
      {`${address}, ${postalCode} ${city}, ${country}`}
    </Text>
  );
  const hotelReferenceScreenReader = ` ${t(
    "hotelReference"
  )}${": "} ${confirmationNumber}`;
  const hotelReference =
    confirmationNumber == null || confirmationNumber === "" ? (
      <div />
    ) : (
      <Flex title={hotelReferenceScreenReader}>
        <Text
          className={Styles[addressClassname]}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.hotelDetails?.textColor,
          })}
        >
          {` ${t("hotelReference")}${": "}`}&nbsp;
        </Text>
        <Text
          className={Styles[addressClassname]}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.hotelDetails?.addressColor,
          })}
        >
          {confirmationNumber}
        </Text>
      </Flex>
    );

  const nightsInHotelText = (classValue: string) => (
    <Text
      className={Styles[classValue]}
      styles={(theme) => ({
        color: theme.theme.siteVariables.hotelDetails?.textColor,
      })}
      title={
        numberOfNights === 1
          ? `${numberOfNights} ${t("night")}`
          : `${numberOfNights} ${t("nights")}`
      }
      content={
        numberOfNights === 1
          ? `${numberOfNights} ${t("night")}`
          : `${numberOfNights} ${t("nights")}`
      }
    />
  );
  const nightsInHotel = (
    <>
      <Responsive forMinSize="tablet">
        {nightsInHotelText("hotel-header__nights")}
      </Responsive>
      <Responsive forMaxSize="mobile">
        {nightsInHotelText("hotel-header__nights-mobile")}
      </Responsive>
    </>
  );
  const nightsSpentInHotel = () => (numberOfNights === 0 ? "" : nightsInHotel);
  const bookingCodeScreenReader = `${t(
    "tripDetails.bookingCode"
  )}: ${bookingCode}`;
  const bookingCodeComponent = bookingCode ? (
    <Flex column gap="gap.small" title={bookingCodeScreenReader}>
      <Text
        className={Styles["hotel-header__bookin-code"]}
        styles={(theme) => ({
          color: theme.theme.siteVariables.carDetails?.textColor,
        })}
        aria-hidden
        as="div"
        content={bookingCode}
      />

      <Text
        className={Styles[addressClassname]}
        styles={(theme) => ({
          color: theme.theme.siteVariables.carDetails?.textColor,
        })}
        aria-hidden
        as="div"
        content={t("tripDetails.bookingCode")}
      />
    </Flex>
  ) : (
    <div />
  );

  return (
    <>
      <div role="cell" className={Styles["hotel-header"]}>
        <div className={Styles["hotel-header__wrapper"]}>
          {hotelImage}
          <div>
            <div className={Styles["hotel-header__name-stars-wrapper"]}>
              {hotelName} {hotelStarRating}
            </div>
            <Responsive forMinSize="tablet">
              {hotelAddress}
              <ContactInformation phone={phone} email={email} />
              {hotelReference}
            </Responsive>
          </div>
        </div>
        <Responsive forMaxSize="mobile">
          <div className={Styles["hotel-header__address-mobile"]}>
            {hotelAddress}
            <ContactInformation phone={phone} email={email} />
            {hotelReference}
          </div>
        </Responsive>
        <Responsive forMinSize="tablet">
          <Flex>
            {nightsSpentInHotel()}
            {bookingCodeComponent}
          </Flex>
        </Responsive>
      </div>
      <Responsive forMaxSize="mobile">
        <Flex>
          <Card
            styles={(theme) => ({
              pointerEvents: "none",
              width: "100%",
              margin: "16px",
              backgroundColor:
                theme.theme.siteVariables.hotelDetails?.cardColor,
            })}
          >
            <Flex space="between">
              {nightsSpentInHotel()}
              {bookingCodeComponent}
            </Flex>
          </Card>
        </Flex>
      </Responsive>
    </>
  );
}
export default Header;
