import { Flex, Image, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../../utils/host.settings";
import Styles from "./CommonError.module.scss";

function CommonError({ themeName }: any) {
  const { t } = useTranslation();

  return (
    <Flex
      className={[
        themeName === "light" ? Styles.white : Styles.dark,
        Styles.card,
      ].join(" ")}
    >
      <Flex className={Styles.disabledCardBody} vAlign="center" column>
        <Flex column>
          <div>
            <Image
              src={`${HostSettings.getCDNIcons}generic-error-${themeName}.svg`}
              alt={t("closeCollaborators.list.generic.errorMessage")}
            />
          </div>

          <Text
            content={t("messages.ERR_Generic")}
            className={Styles.noCollaboratorsMsg}
          />
          <Text
            content={t("shareMyRide.commonErrorSubtitle")}
            className={Styles.subTitle}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CommonError;
