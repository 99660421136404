import { GraphService } from "@amadeus-cytric/cytric-teams-react-common-library";

export default async function getImages(client: any, usersList: any) {
  const distinctUsersList = usersList.filter(
    (item: any, index: number) => usersList.indexOf(item) === index
  );

  const images = await GraphService.getGraphUsersPhotos(
    client,
    distinctUsersList
  );

  return { responses: images };
}
