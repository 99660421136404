/* eslint-disable react/destructuring-assignment */
import { Alert } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import styles from "./TaskModuleResponseSuccess.module.scss";

interface TaskModuleResponseProps {
  alertText: string;
}

function TaskModuleResponseSuccess({ alertText }: TaskModuleResponseProps) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Alert
      visible
      content={alertText}
      className={styles.confirmationMessage}
      data-testid="cancel-alert-ok"
      role="alert"
    />
  );
}
export default TaskModuleResponseSuccess;
