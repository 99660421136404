/* eslint-disable react/react-in-jsx-scope */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  ChatIcon,
  Checkbox,
  ContactCardIcon,
  EmailIcon,
  Flex,
  Label,
  MeetingNewIcon,
  Text,
} from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import Responsive from "../../../../../common/components/responsive/Responsive";
import i18next from "../../../../../i18n/i18n";
import Store from "../../../../../store/Store";
import {
  addAllCloseCollaboratorToNotify,
  resetCloseCollaboratorToNotify,
} from "../../../../../store/collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationSlice";
import stylesCloseCollaborators from "../../../common/styles/close-collaborators.module.scss";
import {
  CollaboratorsModel,
  RoleModel,
} from "../../models/CloseCollaboratorsModel";

export const headerName = (
  collaborators: CollaboratorsModel[],
  checkedCollaboratorIdsLength: number
) => {
  const contentHeaderName = (
    <Flex gap="gap.small" vAlign="center" hAlign="center">
      <Checkbox
        checked={collaborators.length === checkedCollaboratorIdsLength}
        onClick={(_e, { checked }: any) => {
          if (checked) {
            Store.dispatch(
              addAllCloseCollaboratorToNotify({
                closeCollaboratorIds: collaborators.map((collab) => collab.id),
              })
            );
          } else {
            Store.dispatch(resetCloseCollaboratorToNotify());
          }
        }}
        aria-label={i18next.t("deleteBookings.selectAll")}
      />
      <ContactCardIcon outline />
      <Text
        content={i18next.t("closeCollaborators.table.headerName")}
        size="small"
      />
    </Flex>
  );
  return contentHeaderName;
};

export const getHeader = (
  collaborators: CollaboratorsModel[],
  checkedCollaboratorIdsLength: number
) => ({
  key: "header",
  className: stylesCloseCollaborators.tableHeader,
  items: [
    {
      content: headerName(collaborators, checkedCollaboratorIdsLength),
      key: "header-user",
      className: stylesCloseCollaborators.firstCol,
    },
    {
      content: i18next.t("closeCollaborators.table.role"),
      key: "header-role",
      className: stylesCloseCollaborators.roleHeader,
    },
    {
      content: "",
      key: "header-options",
      className: stylesCloseCollaborators.options,
    },
  ],
});

export const getMobileHeader = (
  collaborators: CollaboratorsModel[],
  checkedCollaboratorIdsLength: number
) => ({
  key: "header",
  items: [
    {
      content: headerName(collaborators, checkedCollaboratorIdsLength),
      key: "header-user",
      className: stylesCloseCollaborators.firstCol,
      tabIndex: 0,
    },
  ],
});

export const role = (contentPropsRole: RoleModel, hasBeenNotified: boolean) => {
  const propsRole = contentPropsRole || "";
  const contentRole = (
    <>
      <Responsive forMinSize="tablet">
        <Flex column>
          <Text content={propsRole} size="medium" weight="regular" />
        </Flex>
      </Responsive>
      <Responsive forMaxSize="mobile">
        {hasBeenNotified && (
          <Label
            circular
            content="Notified"
            className={stylesCloseCollaborators.notifiedLabel}
          />
        )}
      </Responsive>
    </>
  );
  return contentRole;
};

export const openOutlook = (email: string, tripId: string) => {
  telemetryService.trackEvent(
    { name: "travel-closeCollaborators-email" },
    { tripId }
  );
  window.location.href = `mailto:${email}`;
};

export const openCalendar = (email: string, tripId: string) => {
  telemetryService.trackEvent(
    { name: "travel-closeCollaborators-calendar" },
    { tripId }
  );
  const meetingLink = `https://teams.microsoft.com/l/meeting/new?attendees=${email}`;
  app.openLink(meetingLink);
};

export const openChat = (email: string, tripId: string) => {
  const chatUrl = `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
  telemetryService.trackEvent(
    { name: "travel-closeCollaborators-chat" },
    { tripId }
  );
  app.openLink(chatUrl);
};

export const optionItemsCollaborator = (
  name: string,
  email: string,
  isDisabled: boolean,
  tripId: string
) => {
  const OptionItems = [
    {
      icon: <ChatIcon outline />,
      key: "chat",
      active: false,
      className: isDisabled
        ? stylesCloseCollaborators.iconEnabledHover
        : stylesCloseCollaborators.iconDisabledHover,
      title: i18next.t("closeCollaborators.table.chatWith", {
        user: `${name}`,
      }),
      disabled: isDisabled,
      onClick: () => {
        openChat(email, tripId);
      },
    },
    {
      icon: <EmailIcon outline />,
      key: "email",
      active: false,
      className: isDisabled
        ? stylesCloseCollaborators.iconEnabledHover
        : stylesCloseCollaborators.iconDisabledHover,
      title: i18next.t("closeCollaborators.table.sendEmailTo", {
        user: `${email}`,
      }),
      disabled: isDisabled,
      onClick: () => {
        openOutlook(email, tripId);
      },
    },
    {
      key: "menu-meeting",
      title: i18next.t("closeCollaborators.table.scheduleMeetingWith", {
        user: `${name}`,
      }),
      icon: <MeetingNewIcon outline />,
      className: isDisabled
        ? stylesCloseCollaborators.iconEnabledHover
        : stylesCloseCollaborators.iconDisabledHover,
      disabled: isDisabled,
      onClick: () => {
        openCalendar(email, tripId);
      },
    },
  ];
  return OptionItems;
};
