/* eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import NotificationSettings from "../../common/models/NotificationSettings";
import {
  loadNotificationSettings,
  NOTIFICATION_SETTINGS_STORE_NAME,
  setNotificationSettings,
} from "./NotificationSettingsActions";

interface NotificationSettingsState {
  isPending: boolean;
  isError: boolean;
  isInitialLoadDone: boolean;
  notificationSettings: NotificationSettings;
}

const initialState: NotificationSettingsState = {
  isPending: false,
  isError: false,
  isInitialLoadDone: false,
  notificationSettings: {} as NotificationSettings,
};

export const notificationSettingsSlice = createSlice({
  name: NOTIFICATION_SETTINGS_STORE_NAME,
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(loadNotificationSettings.pending, (state) => ({
      ...state,
      isPending: true,
    }));

    builder.addCase(
      loadNotificationSettings.fulfilled,
      (state, action: PayloadAction<NotificationSettings>) => ({
        ...state,
        isPending: false,
        isError: false,
        isInitialLoadDone: true,
        notificationSettings: action.payload,
      })
    );

    builder.addCase(loadNotificationSettings.rejected, (state) => ({
      ...state,
      isPending: false,
      isError: true,
      isInitialLoadDone: false,
    }));

    builder.addCase(setNotificationSettings.pending, (state) => ({
      ...state,
      isPending: false,
      isError: false,
    }));

    builder.addCase(
      setNotificationSettings.fulfilled,
      (state, action: PayloadAction<NotificationSettings>) => ({
        ...state,
        isPending: false,
        isError: false,
        notificationSettings: action.payload,
      })
    );

    builder.addCase(setNotificationSettings.rejected, (state) => ({
      ...state,
      isPending: false,
      isError: true,
    }));
  },
});

export default notificationSettingsSlice.reducer;
