import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";
import GetBatchPhoto from "./GetBatchPhoto";

export default async function getPhotosBatch(items: any, graph: any, url: any) {
  const graphPhotos = [];
  if (items.length) {
    const batchLimit = 20;
    for (let i = 0; i < items.length; i += batchLimit) {
      const start = i;
      const end = i + batchLimit;

      const teamsInfoFractions = items.slice(start, end);

      const batchRequestContent = new MicrosoftGraph.BatchRequestContent(
        // eslint-disable-next-line no-await-in-loop
        await GetBatchPhoto(teamsInfoFractions, url)
      );

      // eslint-disable-next-line no-await-in-loop
      const content = await batchRequestContent.getContent();
      // eslint-disable-next-line no-await-in-loop
      const batchResponses = await graph.api("/$batch").post(content);

      graphPhotos.push(...batchResponses.responses);
    }
  }

  return graphPhotos;
}
