/* eslint-disable array-callback-return */
import {
  AddIcon,
  Box,
  Button,
  ComponentStyleFunctionParam,
  Header,
  List,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../common/components/responsive/useScreenSize";
import { KeepSessionActions } from "../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../utils/KeepUserActivityContext";
import { IToDo } from "../index.model";
import { IToDoListProps } from "./ToDoList.model";
import Styles from "./ToDoList.module.scss";
import ToDoTask from "./ToDoTask/ToDoTask";

function ToDoList({ dispatchDialog, todos }: IToDoListProps) {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const toDoImage =
    screenSize < 640 || (screenSize > 965 && screenSize < 1372)
      ? "imageMobile"
      : "image";

  const BACKGROUND_STYLE = (theme: ComponentStyleFunctionParam) => ({
    background: theme.theme.siteVariables.toDoList?.background
      ? theme.theme.siteVariables.toDoList?.background
      : "",
  });

  const openTodoDialog = () => {
    dispatchDialog({ type: "newTask" });
    new ActivityClass().update(KeepSessionActions.DETAILS_ADDTASK, {
      type: "newTask",
    });
  };

  return (
    <div className={Styles.wrapper} aria-label="to do list">
      <div className={Styles["image-section"]}>
        <Header
          as="h3"
          className={Styles["image-section__title"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.toDoList?.heading
              ? theme.theme.siteVariables.toDoList?.heading
              : "",
          })}
        >
          {t("toDo.toDoList.dontForget")}
        </Header>
        <div className={Styles["image-section__image-container"]}>
          <Box
            className={Styles["image-section__image"]}
            as="div"
            data-testid="to-do-list-image"
            styles={(theme) => ({
              background: theme.theme.siteVariables.toDoList?.[toDoImage]
                ? theme.theme.siteVariables.toDoList?.[toDoImage]
                : "",
            })}
          />
        </div>
        <Button
          className={Styles["image-section__button"]}
          icon={<AddIcon size="small" />}
          text
          content={t("toDo.toDoList.addNewTask")}
          styles={(theme) => ({
            color: theme.theme.siteVariables?.toDoList?.button,
          })}
          onClick={openTodoDialog}
          data-testid="button-new-task"
        />
      </div>
      <div className={Styles["todo-list-section"]}>
        <Box
          as="div"
          className={Styles["todo-list-section__scroll-helper--top"]}
          styles={BACKGROUND_STYLE}
          aria-hidden
        />
        <Box
          as="div"
          className={Styles["todo-list-section__scroll-helper--bottom"]}
          styles={BACKGROUND_STYLE}
          aria-hidden
        />
        <List
          className={Styles["list-of-to-dos"]}
          styles={(theme) => ({
            backgroundColor: theme.theme.siteVariables.tripCard?.background1,
          })}
          data-testid="to-do-list-list"
          aria-label="to do list"
        >
          {todos?.map((todo: IToDo, index: number) => (
            <List.Item
              content={<ToDoTask todo={todo} dispatchDialog={dispatchDialog} />}
              index={index}
              key={todo.id}
              aria-label={todo.title}
            />
          ))}
        </List>
      </div>
    </div>
  );
}

export default ToDoList;
