/* eslint-disable react/require-default-props */
import {
  ErrorModel,
  getLastHttpError,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Open16Regular } from "@fluentui/react-icons";
import {
  Button,
  Flex,
  Grid,
  Image,
  ProviderConsumer,
  Text,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/StoreHooks";
import { userObjectIdSelector } from "../../../store/authentication/AuthenticationSelector";
import HostSettings from "../../../utils/host.settings";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
  TrackError,
} from "./GenericInfo.model";
import "./GenericInfo.scss";

interface IInfoProps {
  infoData: IGenericInfo;
  action?: IGenericInfoAction;
  action2?: IGenericInfoAction;
  image: InfoImage;
  isLoginComponent?: boolean;
  isPopupComponent?: boolean;
}

const images = {
  errorLight: `${HostSettings.getCDNIcons}system-error-light.svg`,
  errorDark: `${HostSettings.getCDNIcons}system-error-dark.svg`,
  errorRequestLight: `${HostSettings.getCDNIcons}request-error-light.svg`,
  errorRequestDartk: `${HostSettings.getCDNIcons}request-error-dark.svg`,
  sessionExpired: `${HostSettings.getCDNIcons}session-expired.svg`,
  cytricEasy: `${HostSettings.getCDNIcons}cytric-easy-full-logo.svg`,
  welcome: `${HostSettings.getCDNIcons}welcome.svg`,
  bigGlass: `${HostSettings.getCDNIcons}search-magnifying-glass.svg`,
  notActivatedLight: `${HostSettings.getCDNIcons}expense-not-activated_light.svg`,
  notActivatedDark: `${HostSettings.getCDNIcons}expense-not-activated_dark.svg`,
  notActivatedCustomizedLight: `${HostSettings.getCDNIcons}chrome-river-light.svg`,
  notActivatedCustomizedDark: `${HostSettings.getCDNIcons}chrome-river-dark.svg`,
};

/* Order of theme images array is : Default, DarkMode, HighContrast */
const imageMap = {
  ERROR: [images.errorLight, images.errorDark, images.errorLight],
  ERROR_REQUEST: [
    images.errorRequestLight,
    images.errorRequestDartk,
    images.errorRequestLight,
  ],
  SESSION_EXPIRED: [
    images.sessionExpired,
    images.sessionExpired,
    images.sessionExpired,
  ],
  CYTRIC_EASY: [images.cytricEasy, images.cytricEasy, images.cytricEasy],
  WELCOME: [images.welcome, images.welcome, images.welcome],
  BIG_GLASS: [images.bigGlass, images.bigGlass, images.bigGlass],
  NOT_ACTIVATED: [
    images.notActivatedLight,
    images.notActivatedDark,
    images.notActivatedLight,
  ],
  NOT_ACTIVATED_CUSTOMIZED: [
    images.notActivatedCustomizedLight,
    images.notActivatedCustomizedDark,
    images.notActivatedCustomizedLight,
  ],
};

const getThemedImage = (theme: any, image: InfoImage) => {
  switch (theme.siteVariables.bodyBackground) {
    /* HighContrast */
    case "#000":
      return imageMap[image][2];
    /* Default */
    case "#fff":
      return imageMap[image][0];
    /* DarkMode */
    default:
      return imageMap[image][1];
  }
};

function GenericInfo({
  infoData,
  action,
  image,
  isLoginComponent,
  isPopupComponent,
  action2,
}: IInfoProps) {
  const { title, detail, link, subdetail, track } = infoData;
  const { t } = useTranslation();
  const userObjectId = useAppSelector((state) => userObjectIdSelector(state));
  const [httpOperationId, setHttpOperationId] = useState<ErrorModel>(
    {} as ErrorModel
  );

  useEffect(() => {
    switch (track) {
      case TrackError.TRAVEL_WORKFLOW_ERROR_VIEW:
        telemetryService.trackPageView({
          name: "travel-errorRequestWorkflowView",
        });
        break;
      default:
        break;
    }
  }, [track]);

  useEffect(() => {
    setHttpOperationId(getLastHttpError());
  }, []);

  const loginButton = (
    <Button
      className="generic-info__loginButton"
      flat
      size="medium"
      icon={
        <Image
          src={`${HostSettings.getCDNIcons}cytric-easy-app-logo.svg`}
          alt="Cytric Easy App Logo"
          className="generic-info__imgButton"
        />
      }
      content={action?.text}
      onClick={action?.command}
    />
  );

  const loginSubtitle = (
    <Flex className="generic-info__subtitle" hAlign="center">
      <Text>{t("LoginComponent.notCytricUser")} </Text>
      <Button
        className="generic-info__linkButton"
        styles={(theme) => ({
          color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
        })}
        text
        primary
        icon={<Open16Regular />}
        iconPosition="after"
        content={<>{t("LoginComponent.learnMore")}</>}
        onClick={() => {
          window.open(HostSettings.getUrlLearningPortal, "_blank");
        }}
      />
    </Flex>
  );

  const defaultButton = (
    <Button
      className="generic-info__button"
      data-testid="action-button-default"
      primary
      onClick={action?.command}
    >
      {action?.text}
    </Button>
  );

  const secondButton = (
    <Button
      className="generic-info__button second_button"
      secondary
      onClick={action2?.command}
    >
      {action2?.text}
    </Button>
  );

  const renderLoginSubtitle = () => {
    if (isLoginComponent) {
      return loginSubtitle;
    }
    return null;
  };

  const renderUserId = () => {
    if (userObjectId) {
      return (
        <Flex.Item>
          <Text>
            {t("Settings.Log_Id")} {userObjectId}
          </Text>
        </Flex.Item>
      );
    }
    return null;
  };

  const renderOperationId = () => {
    if (httpOperationId) {
      return (
        <Flex.Item>
          <Text>
            {t("Settings.Operation_Id")} {httpOperationId?.operationId}
          </Text>
        </Flex.Item>
      );
    }
    return null;
  };

  const renderTimestamp = () => {
    if (!isLoginComponent || httpOperationId) {
      return (
        <Flex.Item>
          <Text>
            {t("Settings.Timestamp")} {new Date().toISOString()}
          </Text>
        </Flex.Item>
      );
    }
    return null;
  };

  function renderError(theme: any) {
    return (
      <Grid
        className={isPopupComponent ? "generic-info__popup" : "generic-info"}
        columns="1"
        rows={isLoginComponent || userObjectId ? "85% 15%" : "100%"}
      >
        <Flex hAlign="center" vAlign="center" column>
          <Image
            src={getThemedImage(theme, image)}
            className="generic-info__img"
            alt="info-message"
          />
          <Text
            id="generic-info-header-title"
            className={
              isPopupComponent
                ? "generic-info__header generic-info__header__popup"
                : "generic-info__header"
            }
          >
            {title}
          </Text>
          {detail && <Text className="generic-info__content">{detail}</Text>}
          {link && (
            <Text
              styles={() => ({
                color: theme.siteVariables.colorScheme?.brand.foreground2,
              })}
            >
              <a
                href={link.url}
                target="_blank"
                rel="noreferrer"
                className="generic-info__content generic-info__custom-link"
              >
                {link.textToDisplay} <Open16Regular />
              </a>
            </Text>
          )}
          {subdetail && <Text>{subdetail}</Text>}
          {action && isLoginComponent ? loginButton : action && defaultButton}
          {action2 ? secondButton : null}
        </Flex>
        <Flex className="generic-info__footer" hAlign="center" column>
          {renderLoginSubtitle()}
          {renderUserId()}
          {renderOperationId()}
          {renderTimestamp()}
        </Flex>
      </Grid>
    );
  }

  return (
    <ProviderConsumer render={(globalTheme) => renderError(globalTheme)} />
  );
}

export default GenericInfo;
