/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import Styles from "./BookingDetails.module.scss";
import SegmentTabs from "./SegmentTabs/SegmentTabs";

function BookingDetailsV1() {
  const { t } = useTranslation();

  return (
    <div className={Styles.body} data-testid="trip-details-body">
      <div className={Styles["booking-details-widget__header"]}>
        <h3>
          <Text
            content={t("tripDetails.yourBooking")}
            className={Styles["booking-details-widget__title"]}
            styles={(theme) => ({
              color: theme.theme.siteVariables.bookingDetail?.mainInfo,
            })}
          />
        </h3>
      </div>
      <SegmentTabs />
    </div>
  );
}

export default BookingDetailsV1;
