/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
// Dependencies
import { Dropdown, DropdownProps } from "@fluentui/react-northstar";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem } from "../../../common/models/Location.model";
import AirCityModel from "../models/AirSearch";
import matchMyTrip from "../styles/match-my-trip.module.scss";

type EventOrNull = React.MouseEvent | React.KeyboardEvent | null;

// Styles

function AirportDropDown({
  options,
  isLoading,
  airCityModel,
  setAirCityModel,
}: {
  options: DropdownItem[];
  isLoading: boolean;
  airCityModel: AirCityModel;
  setAirCityModel: (_: AirCityModel) => void;
}) {
  const { t } = useTranslation();

  const [dropDownMessage, setDropDownMessage] = useState<string>("");

  // Functions
  const handleSearchQuery = (items: any, searchQuery: any) => {
    if (searchQuery.length < 3) {
      setDropDownMessage(t("dropDownMessage.enter3orMore"));
      return [];
    }
    setDropDownMessage(t("dropDownMessage.noResults"));
    return items;
  };

  const handleFromChange = (e: any, props: any) => {
    setAirCityModel({
      name: props.value?.name,
      iatacode: props.value?.iatacode,
      countrycode: props.value?.countrycode,
      destinationType: props.value?.city ? "CITY" : "AIRPORT",
    });
  };

  const handleSearchQueryChange = (_e: EventOrNull, data: DropdownProps) => {
    if (data.searchQuery !== undefined) {
      setAirCityModel({
        ...airCityModel,
        name: data.searchQuery,
        iatacode: "",
      });
    }
  };

  return (
    <Dropdown
      data-testid="cytric-fromDropDown"
      className={[
        matchMyTrip.airportOrCityDropdown,
        matchMyTrip.dropdownOptions,
      ].join(" ")}
      styles={(theme) => ({
        backgroundColor: theme.theme.siteVariables?.formDropdown?.background,
        color: theme.theme.siteVariables?.formDropdown?.color,
        width: "100%",
      })}
      search={handleSearchQuery}
      loading={isLoading}
      loadingMessage={t("searchContacts.loadingMessage")}
      items={options}
      searchQuery={airCityModel.name}
      noResultsMessage={dropDownMessage}
      onSearchQueryChange={handleSearchQueryChange}
      fluid
      onChange={handleFromChange}
      placeholder={t("searchForm.cityAirport")}
    />
  );
}

export default AirportDropDown;
